import { MaterialDto, MaterialUpdateDto } from '@app/api/v6.api';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Utils } from '@app/shared/helpers/utils';
import { Checkbox, Form, FormInstance, InputNumber, Row, Select } from 'antd';
import React, { useCallback, useMemo } from 'react';

interface IMaterialEdit {
    isEditting: boolean;
    form: FormInstance<MaterialUpdateDto>;
    editMaterial: MaterialDto;
}

const MaterialInventory: React.FC<IMaterialEdit> = ({ isEditting }: IMaterialEdit) => {

    const validateTextCharater = (maxLen: number) => (_: any, value: any) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'partNo', label: 'Part Number',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(16) }],
            },
            { colSpan: 12, name: '', label: '', render: (<></>) },
            {
                colSpan: 12, name: 'quantityMin', label: 'Số đơn tồn tối thiểu', render: (
                    <InputNumber formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập số đơn tồn tối thiểu' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 12, name: 'quantityMax', label: 'Số lượng tồn tối đa', render: (
                    <InputNumber formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập số lượng tồn tối đa' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 9, name: 'shipmentTracking', label: 'Theo dõi lô',
                render: (< Select
                    style={{ width: 200, height: 30 }}
                    allowClear
                    options={[{ value: '1', label: '1' }, { value: '0', label: '0' }]}
                />)
            },
            { colSpan: 15, name: '', label: '1 - Có, 0 - Không theo dõi lô', width: 300, render: (<></>) },
            {
                colSpan: 9, name: 'trackLocation', label: 'Theo dõi vị trí',
                render: (< Select
                    style={{ width: 200, height: 30 }}
                    allowClear
                    options={[{ value: '1', label: '1' }, { value: '2', label: '2' }]}
                />)
            },
            { colSpan: 15, name: '', label: '1 - Có , 2 - Không theo dõi vị trí', width: 300, render: (<></>) },
            {
                colSpan: 9, name: 'trackDate', label: 'Theo dõi ngày',
                render: (< Select
                    style={{ width: 200, height: 30 }}
                    allowClear
                    options={[{ value: '1', label: '1' }, { value: '2', label: '2' }]}
                />)
            },
            { colSpan: 15, name: '', label: '1 - Có , 2 - Không theo dõi ngày', width: 300, render: (<></>) },
        ]
    }, [isEditting])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={24}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, []);

    return (
        <>
            {renderColumns(session1Cols)}
            < Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 130 } }
                }
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item >
        </>
    );
};

export default MaterialInventory;