import { InvoiceAMColumnConstant, InvoiceColumnConstant } from "../../shared/constants/invoice.constant";

export const Invoice91_FColumnConstant = {
    ma_vt: 'ma_vt',
    MAVV_I: 'ma_vv_i',
    SO_LUONG1: InvoiceColumnConstant.SO_LUONG1,
    SO_LUONG: InvoiceColumnConstant.SO_LUONG,
    GIA_NT21: 'gia_nt21',
    TIEN2: InvoiceColumnConstant.TIEN2,
    TIEN_NT2: InvoiceColumnConstant.TIEN_NT2,
    DVT1: 'dvt1',
    CK: 'ck',
    CK_NT: 'ck_nt',
    PT_CKI: 'pt_cki',
    THUE_NT: 'thue_nt',
    THUE: 'thue',
    NHIEU_DVT: 'nhieu_dvt'
}

export const MoneyColumnsSetDefaultByGiaNT21 = [
    InvoiceColumnConstant.GIA_BAN,
    InvoiceColumnConstant.GIA_BAN_NT,
    InvoiceColumnConstant.GIA_NT,
    InvoiceColumnConstant.GIA,
    InvoiceColumnConstant.GIA2
]

export const AM91FieldFormatBoolToString = [
    InvoiceAMColumnConstant.LOAI_CK
]

export const AD91FieldFormatBoolToString = [
    Invoice91_FColumnConstant.NHIEU_DVT
]

export const AM91FieldFormatBoolToNumber = [
    InvoiceAMColumnConstant.SUA_CK,
    InvoiceAMColumnConstant.SUA_THUE,
    InvoiceAMColumnConstant.SUA_TKTHUE,
    InvoiceAMColumnConstant.AUTO_CK,
    InvoiceAMColumnConstant.SUA_TIEN,
    InvoiceAMColumnConstant.TINH_CK
]

const ColumnsDisabledByLoaiCK = [
    Invoice91_FColumnConstant.CK,
    Invoice91_FColumnConstant.CK_NT,
    Invoice91_FColumnConstant.PT_CKI
]
export const ColumnsDisabledByLoaiCKFunc = (fcolumn: string, loaiCK: boolean): boolean => {
    return loaiCK && ColumnsDisabledByLoaiCK.includes(fcolumn);
}

const ColumnsDisabledByThanhTien = [
    Invoice91_FColumnConstant.TIEN2,
    Invoice91_FColumnConstant.TIEN_NT2
]
export const ColumnsDisabledByThanhTienFunc = (fcolumn: string, suaTien: boolean): boolean => {
    if (!ColumnsDisabledByThanhTien.includes(fcolumn)) {
        return false;
    }

    return !suaTien;
}

export const Invoice91ColumnsDisabled = [
    Invoice91_FColumnConstant.TIEN2
]