import { DictionaryUpdateDto } from '@app/api/v6.api';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Utils } from '@app/shared/helpers/utils';
import { Col, DatePicker, InputNumber, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import Form, { Rule } from 'antd/lib/form';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';

interface IDictionaryEdit {
    code?: string;
    form?: FormInstance<DictionaryUpdateDto>;
    currentMode: "add" | "edit" | "detail";
}

const DictionarySelfDefinition: React.FC<IDictionaryEdit> = ({ code, currentMode, form }: IDictionaryEdit) => {

    const validateTextCharater = (maxLen: number) => (_: any, value: string) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const baseDatePickerForm = useCallback((name: string, lable: string) => {
        const valueForm = form ? form.getFieldValue(name) : null;
        return (
            <Col span={12}>
                <Form.Item
                    label={lable}
                    name={name}
                    labelAlign='left'
                    labelCol={{ style: { width: 130 } }}
                >
                    <DatePicker
                        disabled={currentMode === "detail"}
                        size='small'
                        placeholder={valueForm ? moment(valueForm, "YYYY-MM-DD").toString() : `${lable}`}
                        defaultValue={valueForm ? moment(valueForm, "YYYY-MM-DD") : undefined}
                        style={{ width: "100%", borderRadius: "0px" }}
                    />

                </Form.Item>
            </Col>
        )
    }, [currentMode]);

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'dictionaryCode1', label: 'giờ bắt đầu',
                placeholder: "Nhập giờ bắt đầu", disabled: currentMode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(16) }] as Rule[]
            },
            {
                colSpan: 12, name: 'codeEx1', label: 'Mã EX1',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(16) }],
                disabled: currentMode === "detail", placeholder: "Nhập Mã EX1",
            },
            {
                colSpan: 12, name: 'dictionaryCode2', label: 'giờ kết thúc',
                placeholder: "Nhập giờ kết thúc", disabled: currentMode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(16) }] as Rule[]
            },
            {
                colSpan: 12, name: 'codeEx2', label: 'Mã EX2',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(16) }],
                disabled: currentMode === "detail", placeholder: "Nhập Mã EX2"
            },
            {
                colSpan: 12, name: 'dictionaryCode3', label: 'Ngày hôm sau',
                placeholder: "Nhập ngày hôm sau", disabled: currentMode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(16) }] as Rule[]
            },
            {
                colSpan: 12, name: 'codeEx3', label: 'Mã EX3',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(16) }],
                disabled: currentMode === "detail", placeholder: "Nhập Mã EX3"
            }
        ]
    }, [currentMode, code])

    const session2Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'quantityDictionary1', label: 'Số lượng ĐN1',
                render: (
                    <InputNumber disabled={currentMode === "detail"} defaultValue={0} maxLength={16} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập số lượng ĐN1' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 12, name: 'quantityEx1', label: 'Số lượng EX1', disabled: currentMode === "detail",
                render: (
                    <InputNumber disabled={currentMode === "detail"} defaultValue={0} maxLength={16} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập số lượng EX1' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 12, name: 'quantityDictionary2', label: 'Số lượng ĐN2',
                render: (
                    <InputNumber disabled={currentMode === "detail"} defaultValue={0} maxLength={16} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập số lượng ĐN2' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 12, name: 'quantityEx2', label: 'Số lượng EX2', disabled: currentMode === "detail",
                render: (
                    <InputNumber disabled={currentMode === "detail"} defaultValue={0} maxLength={16} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập số lượng EX2' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 12, name: 'quantityDictionary3', label: 'Số lượng ĐN3',
                render: (
                    <InputNumber disabled={currentMode === "detail"} defaultValue={0} maxLength={16} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập số lượng ĐN3' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 12, name: 'quantityEx3', label: 'Số lượng EX3', disabled: currentMode === "detail",
                render: (
                    <InputNumber disabled={currentMode === "detail"} defaultValue={0} maxLength={16} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập số lượng EX3' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 24, name: 'note1', label: 'Ghi chú ĐN1',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(48) }],
                disabled: currentMode === "detail", placeholder: "Nhập ghi chú ĐN1"
            },
            {
                colSpan: 24, name: 'note2', label: 'Ghi chú ĐN2', disabled: currentMode === "detail",
                placeholder: "Nhập ghi chú ĐN2",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(16) }] as Rule[]
            },
            {
                colSpan: 24, name: 'note3', label: 'Ghi chú ĐN3',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(48) }],
                disabled: currentMode === "detail", placeholder: "Nhập ghi chú ĐN3"
            },
        ]
    }, [currentMode, code])
    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={24}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, []);

    return (
        <>
            {renderColumns(session1Cols)}
            <Row gutter={24}>
                {baseDatePickerForm("dateDictionary1", "Nhập ngày TD1")}
                {baseDatePickerForm("dateEx1", "Nhập ngày 1")}
                {baseDatePickerForm("dateDictionary2", "Nhập ngày TD2")}
                {baseDatePickerForm("dateEx2", "Nhập ngày 2")}
                {baseDatePickerForm("dateDictionary3", "Nhập ngày TD3")}
                {baseDatePickerForm("dateEx3", "Nhập ngày 3")}
            </Row>
            {renderColumns(session2Cols)}
        </>
    );
};

export default DictionarySelfDefinition;