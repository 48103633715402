import { Modal } from '@app/components/common/Modal/Modal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Checkbox, Form, InputNumber, Row } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import apiServices from '@app/api/api.services';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Rule } from 'antd/lib/form';
import { antFormFinishErrorNotification } from '@app/shared/functions/common.function';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { Utils } from '@app/shared/helpers/utils';

interface IModalAffairGroup {
    code?: string;
    visible: boolean;
    mode: "add" | "edit" | "detail";
    onClose: (reload?: boolean) => void;
}

export const AffairGroupModal: React.FC<IModalAffairGroup> = ({ code, visible, mode, onClose }: IModalAffairGroup) => {
    const [form] = BaseForm.useForm();
    const [titleModal, setTileModal] = useState("Thêm mới nhóm vụ việc");
    const [currentMode, setCurrentMode] = useState(mode);
    const [loading, setLoading] = useState<boolean>(false);

    const handleCancel = async () => {
        onClose();
    };

    const fetchData = useCallback(async () => {
        if (code) {
            if (code) {
                const dataEditAffairGroup = await apiServices.v6Api.affairGroupsGetById(code);
                form.setFieldsValue({
                    ...dataEditAffairGroup,
                    status: (dataEditAffairGroup.status === '1') as any
                })
            }
            setTileModal(`${mode === "detail" ? "Chi tiết" : "Chỉnh sửa"} nhóm vụ việc - ${code}`);
        } else {
            setTileModal(`Thêm mới nhóm vụ việc`);
        }
    }, [code, mode])

    useEffect(() => {
        form.resetFields();
        code ? setCurrentMode(mode) : setCurrentMode("add");
        fetchData();
    }, [fetchData, form, code, mode])

    const handleDisable = (() => {
        setCurrentMode("edit")
        setTileModal(`${currentMode !== "detail" ? "Chi tiết" : "Chỉnh sửa"} nhóm vụ việc - ${code}`);
    });

    const validateTextCharater = (maxLen: number) => (_: any, value: any) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const mainInforCols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'groupCode', label: 'Mã nhóm vụ việc', disabled: code ? true : false,
                placeholder: "Nhập mã nhóm vụ việc",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, {
                    required: true,
                    message: "Vui lòng nhập mã nhóm vụ việc"
                }, { validator: validateTextCharater(16) }] as Rule[]
            },
            {
                colSpan: 12, name: 'groupType', label: 'Loại nhóm',
                render: (
                    <InputNumber disabled={currentMode === "detail"} defaultValue={0} maxLength={1} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập loại nhóm' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 12, name: 'groupName', label: 'Tên nhóm vụ việc',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập tên vụ việc" }, { validator: validateTextCharater(128) }] as Rule[],
                disabled: currentMode === "detail", placeholder: "Nhập tên nhóm vụ việc"
            },
            {
                colSpan: 12, name: 'groupName2', label: 'Tên nhóm vụ việc 2',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(128) }] as Rule[],
                placeholder: "Nhập tên nhóm vụ việc 2", disabled: currentMode === "detail"
            },
        ]
    }, [code, currentMode])

    const renderColumns = () => {
        return (
            <Row gutter={24}>
                {
                    mainInforCols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }

    const onFinish = async () => {
        if (currentMode === "detail") {
            handleDisable();
            return;
        }

        const data = await form.validateFields();
        data.status = data.status ? '1' : '0';

        setLoading(true);
        const promise = code
            ? apiServices.v6Api.affairGroupsUpdateUser(code, data)
            : apiServices.v6Api.affairGroupsCreateUser(data);

        promise.then((response) => {
            if (response.isSuccess) {
                notificationController.success({ message: 'Thành công' });
                onClose(true);
                return;
            }

            notificationController.error({ message: response.message });
        }).catch(() => {
            notificationController.error({ message: 'Thất bại' });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal
            title={titleModal}
            confirmLoading={loading}
            open={visible}
            destroyOnClose={true}
            width={"1000px"}
            onOk={() => form.submit()}
            onCancel={() => handleCancel()}
            okText={currentMode === "detail" ? "Chỉnh sửa" : "Lưu"}
            cancelText="Hủy"
            okButtonProps={{ style: { padding: "5px", height: "36px" } }}
            cancelButtonProps={{ style: { padding: "5px", height: "36px" } }}
        >
            <div className='basic-form'>
                <Form
                    form={form}
                    style={{ width: "95%" }}
                    name="basic"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onFinish={onFinish}
                    onFinishFailed={antFormFinishErrorNotification}
                >
                    {renderColumns()}
                    <Form.Item
                        label="Trạng thái"
                        name="status"
                        labelCol={{ style: { width: 130 } }}
                        labelAlign='left'
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Checkbox
                            style={{
                                marginRight: "10px",
                            }}
                            disabled={currentMode === "detail"}
                        >
                            Hoạt động?
                        </Checkbox>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    )
}