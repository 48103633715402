import { Modal } from '@app/components/common/Modal/Modal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Checkbox, Form, InputNumber, Row } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import apiServices from '@app/api/api.services';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Rule } from 'antd/lib/form';
import { antFormFinishErrorNotification } from '@app/shared/functions/common.function';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { MaterialDto, MaterialGroupDto } from '@app/api/v6.api';
import { Utils } from '@app/shared/helpers/utils';

interface IModalMaterialGroup {
    code?: string;
    visible: boolean;
    mode: "add" | "edit" | "detail";
    disableGroup?: boolean;
    initialValues?: MaterialGroupDto;
    onClose: (reload?: boolean) => void;
    onDispatchResponse?: (data: MaterialGroupDto) => void;

}

export const MaterialGroupModal: React.FC<IModalMaterialGroup> = ({ code, visible, mode, disableGroup, initialValues, onClose, onDispatchResponse }: IModalMaterialGroup) => {
    const [form] = BaseForm.useForm();
    const [titleModal, setTileModal] = useState("Thêm mới nhóm vật tư");
    const [currentMode, setCurrentMode] = useState(mode);
    const [loading, setLoading] = useState<boolean>(false);

    const handleCancel = async () => {
        onClose();
    };

    const fetchData = useCallback(async () => {
        if (code) {
            if (code) {
                const dataEditMaterialGroup = await apiServices.v6Api.materialGroupsGetById(code);
                form.setFieldsValue({
                    ...dataEditMaterialGroup,
                    status: (dataEditMaterialGroup.status === '1' ? true : false) as any
                })
            }
            setTileModal(`${currentMode !== "detail" ? "Chi tiết" : "Chỉnh sửa"} nhóm vật tư - ${code}`)
        } else {
            if (initialValues) {
                form.setFieldValue("groupType", initialValues.groupType);
            }
            setTileModal(`Thêm mới nhóm vật tư`);
        }
    }, [code, mode])

    useEffect(() => {
        form.resetFields();
        code ? setCurrentMode(mode) : setCurrentMode("add");
        fetchData();
    }, [fetchData, form, code, mode])

    const handleDisable = (() => {
        setCurrentMode("edit")
        setTileModal(`${currentMode !== "detail" ? "Chi tiết" : "Chỉnh sửa"} nhóm vật tư - ${code}`);
    });

    const validateTextCharater = (maxLen: number) => (_: any, value: string) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const mainInforCols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 13, name: 'groupCode', label: 'Mã nhóm VT', disabled: code ? true : false,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, {
                    required: true,
                    message: "Vui lòng nhập mã nhóm vật tư"
                }, { validator: validateTextCharater(16) }] as Rule[]
            },
            {
                colSpan: 11, name: 'groupType', label: 'Loại nhóm', width: 110,
                render: (
                    <InputNumber disabled={currentMode === "detail" || disableGroup} defaultValue={0} maxLength={1} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập loại nhóm' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 13, name: 'groupName', label: 'Tên nhóm vật tư',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập tên nhóm vật tư" },
                { validator: validateTextCharater(128) }] as Rule[],
                disabled: currentMode === "detail"
            },
            {
                colSpan: 11, name: 'groupName2', label: 'Tên nhóm vật tư 2', width: 110,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(128) }
                ] as Rule[],
                placeholder: "Nhập tên nhóm vật tư 2", disabled: currentMode === "detail"
            }
        ]
    }, [code, currentMode])

    const renderColumns = () => {
        return (
            <Row gutter={24}>
                {
                    mainInforCols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }

    const onFinish = async () => {
        if (currentMode === "detail") {
            handleDisable();
            return;
        }
        const data = await form.validateFields();
        data.status = data.status ? '1' : '0';

        setLoading(true);
        const promise = code
            ? apiServices.v6Api.materialGroupsUpdateUser(code, data)
            : apiServices.v6Api.materialGroupsCreateUser(data);

        promise.then((response) => {
            if (response.isSuccess) {
                notificationController.success({ message: 'Thành công' });
                onClose(true);
                if (onDispatchResponse) {
                    onDispatchResponse(response.data as MaterialGroupDto);
                }
                return;
            }

            notificationController.error({ message: response.message });
        }).catch(() => {
            notificationController.error({ message: 'Thất bại' });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal
            title={titleModal}
            confirmLoading={loading}
            open={visible}
            destroyOnClose={true}
            width={"900px"}
            onOk={() => form.submit()}
            onCancel={() => handleCancel()}
            okText={currentMode === "detail" ? "Chỉnh sửa" : "Lưu"}
            cancelText="Hủy"
            okButtonProps={{ style: { padding: "5px", height: "36px" } }}
            cancelButtonProps={{ style: { padding: "5px", height: "36px" } }}
        >
            <div className='basic-form'>
                <Form
                    form={form}
                    style={{ width: "95%" }}
                    name="basic"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onFinish={onFinish}
                    onFinishFailed={antFormFinishErrorNotification}
                >
                    {renderColumns()}
                    <Form.Item
                        label="Trạng thái"
                        name="status"
                        labelCol={{ style: { width: 130 } }}
                        labelAlign='left'
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Checkbox
                            style={{
                                marginRight: "10px",
                            }}
                            disabled={currentMode === "detail"}
                        >
                            Hoạt động?
                        </Checkbox>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    )
}