import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Checkbox, Form, Row } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { Rule } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';

interface IFeeEdit {
    mode: "add" | "edit" | "detail";
}
const FeeKeyInfomation: React.FC<IFeeEdit> = ({ mode
}: IFeeEdit) => {
    const validateTextCharater = (maxLen: number) => (_: any, value: any) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 24, name: 'nh_phi1', label: 'Nhóm phí 1', disabled: mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(16) }] as Rule[],
            },
            {
                colSpan: 24, name: 'nh_phi2', label: 'Nhóm phí 2', disabled: mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(16) }] as Rule[],
            },
            {
                colSpan: 24, name: 'nh_phi3', label: 'Nhóm phí 3', disabled: mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(16) }] as Rule[],
            },
            {
                colSpan: 24, name: 'ghi_chu', label: 'Ghi chú',
                render: (
                    <TextArea
                        disabled={mode === "detail"}
                        rows={4}
                    />
                )
            },
        ]
    }, [mode])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={10}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, [mode]);

    return (
        <>
            {renderColumns(session1Cols)}

            <Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 130 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={mode === "detail"}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item>
        </>
    );
};

export default FeeKeyInfomation;