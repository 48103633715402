import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import apiServices from '@app/api/api.services';
import { notificationController } from '@app/controllers/notificationController';
import { BaseUnitRequest } from '@app/api/v6.api';

interface LoginFormData {
    userName: string;
    password: string;
}

interface LoginSelectBaseUnitData {
    userName?: string;
    password?: string;
    onFishLoginSelectBaseUnit: (baseUnits?: any[], dataLogin?: LoginFormData) => void;
}

export const initValues: LoginFormData = {
    userName: '',
    password: '',
};

export const LoginSelectBaseUnit: React.FC<LoginSelectBaseUnitData> = (dataLogin) => {
    const [form] = BaseForm.useForm();
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        form.setFieldValue("userName", dataLogin.userName);
        form.setFieldValue("password", dataLogin.password);
    }, [dataLogin]);

    const handleSubmit = async (values: LoginFormData) => {
        const dataUserBaseUnits: BaseUnitRequest = {
            userName: values.userName,
            password: values.password
        } as BaseUnitRequest;
        setLoading(true);
        try {
            const res = await apiServices.v6Api.authsGetBaseUnits(dataUserBaseUnits);
            if (res.isSuccess) {
                setLoading(false);
                const data = res.baseUnits?.map(item => ({
                    key: item.code,
                    value: item.name
                }));
                dataLogin.onFishLoginSelectBaseUnit(data, values);
                return;
            }
        } catch {
            setLoading(false);
        }
        setLoading(false);
        notificationController.error({
            message: 'Tài khoản hoặc mật khẩu không đúng'
        });
    };

    return (
        <BaseForm layout="vertical" onFinish={handleSubmit} form={form}>

            <Auth.FormItem
                name="userName"
                label={'Tài khoản'}
                rules={[
                    { required: true, message: t('common.requiredField') }
                ]}
            >
                <Auth.FormInput placeholder={'UserName'} />
            </Auth.FormItem>
            <Auth.FormItem
                label={t('Mật khẩu')}
                name="password"
                rules={[{ required: true, message: t('common.requiredField') }]}
            >
                <Auth.FormInputPassword placeholder={t('common.password')} />
            </Auth.FormItem>
            <BaseForm.Item style={{ margin: "16px 0" }} >
                <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
                    {t('Tiếp theo')}
                </Auth.SubmitButton>
            </BaseForm.Item>
        </BaseForm>
    );
};

