import { BankContractGroupDto } from '@app/api/v6.api';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Utils } from '@app/shared/helpers/utils';
import { Checkbox, InputNumber, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import Form, { Rule } from 'antd/lib/form';
import React, { useCallback, useMemo } from 'react';

interface IBankContractGroupEdit {
    code?: string;
    form: FormInstance<any>;
    disableGroup?: boolean;
    dataTab: ITab | undefined
}

interface ITab {
    dataEdit?: BankContractGroupDto;
    mode: "add" | "edit" | "detail";
}

const BankContractGroupMainInfomation: React.FC<IBankContractGroupEdit> = ({ code, dataTab, disableGroup }: IBankContractGroupEdit) => {

    const validateTextCharater = (maxLen: number) => (_: any, value: string) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'groupCode', label: 'Mã nhóm khế ước', disabled: code ? true : false,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { required: true, message: "Vui lòng nhập mã nhóm khế ước" },
                { validator: validateTextCharater(16) }],
                placeholder: "Nhập mã nhóm khế ước"
            },
            {
                colSpan: 12, name: 'groupType', label: 'Loại nhóm',
                render: (
                    <InputNumber disabled={dataTab?.mode === "detail" || disableGroup} defaultValue={0} maxLength={1} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập loại nhóm' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 12, name: 'groupName', label: 'Tên nhóm khế ước',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập tên nhóm khế ước" },
                { validator: validateTextCharater(128) }],
                placeholder: "Nhập tên nhóm khế ước", disabled: dataTab?.mode === "detail"
            },
            {
                colSpan: 12, name: 'groupName2', label: 'Tên nhóm khế ước 2',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(128) }],
                placeholder: "Nhập tên nhóm khế ước 2", disabled: dataTab?.mode === "detail"
            },
        ]
    }, [dataTab?.mode])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={24}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, []);

    return (
        <>
            {renderColumns(session1Cols)}

            <Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 130 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={dataTab?.mode === "detail"}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item>
        </>
    );
};

export default BankContractGroupMainInfomation;
