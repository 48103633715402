import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Utils } from '@app/shared/helpers/utils';
import { Checkbox, InputNumber, Row, message } from 'antd';
import Form, { Rule } from 'antd/lib/form';
import React, { useMemo } from 'react';

interface IAssetGroupEdit {
    code?: string;
    currentMode: "add" | "edit" | "detail";
}

const AssetGroupMainInfomation: React.FC<IAssetGroupEdit> = ({ code, currentMode }: IAssetGroupEdit) => {

    const validateTextCharater = (maxLen: number) => (_: any, value: string) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const mainInforCols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'assetGroupCode', label: 'Mã nhóm tài sản', disabled: code ? true : false,
                placeholder: "Nhập mã nhóm tài sản",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập mã nhóm tài sản" },
                { validator: validateTextCharater(16) }] as Rule[]
            },
            {
                colSpan: 12, name: 'assetGroupType', label: 'Loại nhóm',
                render: (
                    <InputNumber disabled={currentMode === "detail"} defaultValue={0} maxLength={1} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập loại nhóm' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 12, name: 'assetGroupName', label: 'Tên nhóm tài sản',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập tên tài sản" },
                { validator: validateTextCharater(128) }],
                disabled: currentMode === "detail", placeholder: "Nhập tên nhóm tài sản"
            },
            {
                colSpan: 12, name: 'assetGroupName2', label: 'Tên nhóm tài sản 2',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(128) }],
                placeholder: "Nhập tên nhóm tài sản 2", disabled: currentMode === "detail"
            },
        ]
    }, [currentMode])

    const renderColumns = () => {
        return (
            <Row gutter={24}>
                {
                    mainInforCols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }

    return (
        <>
            {renderColumns()}
            <Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 130 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={currentMode === "detail"}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item>
        </>
    );
};

export default AssetGroupMainInfomation;
