import { Modal } from '@app/components/common/Modal/Modal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Checkbox, Col, Form, InputNumber, Row } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import apiServices from '@app/api/api.services';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Rule } from 'antd/lib/form';
import { antFormFinishErrorNotification } from '@app/shared/functions/common.function';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import InputSearchLookup from '@app/components/common/input-search-lookup/InputSearchLookup';
import { InputSearchApiService } from '@app/api/input-search-api.service';
import { InputSearchLookupColumns } from '@app/components/common/input-search-lookup/constants/input-lookup-entity-columns.constant';
import { Utils } from '@app/shared/helpers/utils';
import { CatalogAddModalType } from '@app/components/shared/modals/catalog-add-modal/catalog-add-modal';

interface IModalWarehouse {
    code?: string;
    visible: boolean;
    mode: "add" | "edit" | "detail";
    onClose: (reload?: boolean) => void;
}

export const WarehouseModal: React.FC<IModalWarehouse> = ({ code, visible, mode, onClose }: IModalWarehouse) => {
    const [form] = BaseForm.useForm();
    const [titleModal, setTileModal] = useState("Thêm mới kho");
    const [currentMode, setCurrentMode] = useState(mode);
    const [loading, setLoading] = useState<boolean>(false);
    const [changeBaseUnitValue, setChangeBaseUnitValue] = useState<{ text: string }>(null!);
    const [changeWarehouseValue, setChangeWarehouseValue] = useState<{ text: string }>(null!);

    const handleCancel = async () => {
        onClose();
    };

    const fetchData = useCallback(async () => {
        if (code) {
            if (code) {
                const dataEditWarehouse = await apiServices.v6Api.warehousesGetById(code);
                form.setFieldsValue({
                    ...dataEditWarehouse,
                    status: (dataEditWarehouse.status === '1' ? true : false) as any,
                    stT_NTXT: (dataEditWarehouse.stT_NTXT ? dataEditWarehouse.stT_NTXT : 0) as number,
                })
                setChangeBaseUnitValue({ text: dataEditWarehouse.maDVCS as string });
                setChangeWarehouseValue({ text: dataEditWarehouse.tkDaiLy as string });
            }
            setTileModal(`${mode === "detail" ? "Chi tiết" : "Chỉnh sửa"} kho - ${code}`);
        } else {
            setTileModal(`Thêm mới kho`);
        }
    }, [code, mode, form])

    useEffect(() => {
        form.resetFields();
        code ? setCurrentMode(mode) : setCurrentMode("add");
        fetchData();
    }, [fetchData, form, code, mode])

    const handleDisable = (() => {
        setCurrentMode("edit")
        setTileModal(`${currentMode !== "detail" ? "Chi tiết" : "Chỉnh sửa"} kho - ${code}`);
    });

    const validateTextCharater = (maxLen: number) => (_: any, value: string) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const mainInforCols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'maKho', label: 'Mã kho', disabled: code ? true : false,
                placeholder: "Nhập mã kho",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, {
                    required: true,
                    message: "Vui lòng nhập mã kho"
                }, { validator: validateTextCharater(8) }] as Rule[]
            },
            {
                colSpan: 12, name: 'tenKho', label: 'Tên kho',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập tên kho" },
                { validator: validateTextCharater(256) }] as Rule[],
                disabled: currentMode === "detail", placeholder: "Nhập tên kho"
            },
            {
                colSpan: 12, name: 'tenKho2', label: 'Tên kho 2',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(256) }] as Rule[],
                placeholder: "Nhập tên kho 2", disabled: currentMode === "detail"
            },
            {
                colSpan: 12, name: 'tKDaiLy', label: 'Tài khoản đại lý',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(16) }] as Rule[],
                render: (<>
                    <InputSearchLookup
                        fetchDataFunc={InputSearchApiService.getCatalogDymamic("TK0")}
                        placeHolder='Nhập tài khoản đại lý'
                        disabled={currentMode === "detail"}
                        keyField={'tk'}
                        columns={InputSearchLookupColumns.accountAgency}
                        onOutputChange={(value: string) => {
                            form.setFieldsValue({
                                tKDaiLy: value
                            })
                        }}
                        patchValue={currentMode !== "add" ? changeWarehouseValue : undefined}
                    />
                </>)
            },
            {
                colSpan: 12, name: 'maDVCS', label: 'Mã ĐVCS',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(8) }] as Rule[],
                render: (<>
                    <InputSearchLookup
                        fetchDataFunc={InputSearchApiService.getCatalogDymamic("MA_DVCS")}
                        placeHolder='Nhập mã đvcs'
                        disabled={currentMode === "detail"}
                        keyField={'ma_dvcs'}
                        columns={InputSearchLookupColumns.baseUnit}
                        lookupAdding={{
                            initValues: {
                            },
                            catalogAddModalType: CatalogAddModalType.BaseUnit
                        }}
                        onOutputChange={(value: string) => {
                            form.setFieldsValue({
                                maDVCS: value
                            })
                        }}
                        patchValue={currentMode !== "add" ? changeBaseUnitValue : undefined}
                    />
                </>)
            },
            {
                colSpan: 12, name: 'stT_NTXT', label: 'STT NTXT',
                rules: [{ required: true, message: "Nhập STT NTXT" }],
                render: (
                    <InputNumber disabled={currentMode === "detail"} min={0} max={9} defaultValue={0} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập STT NTXT' style={{ width: "100%" }} />
                )
            },
        ]
    }, [code, changeBaseUnitValue, changeWarehouseValue, currentMode])

    const renderColumns = () => {
        return (
            <Row gutter={24}>
                {
                    mainInforCols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }

    const onFinish = async () => {
        if (currentMode === "detail") {
            handleDisable();
            return;
        }
        const data = await form.validateFields();
        data.status = data.status ? '1' : '0';

        setLoading(true);
        const promise = code
            ? apiServices.v6Api.warehousesUpdateAlKho(code, data)
            : apiServices.v6Api.warehousesCreateAlKho(data);

        promise.then((response) => {
            if (response.isSuccess) {
                notificationController.success({ message: 'Thành công' });
                onClose(true);
                return;
            }

            notificationController.error({ message: response.message });
        }).catch(() => {
            notificationController.error({ message: 'Thất bại' });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal
            title={titleModal}
            confirmLoading={loading}
            open={visible}
            destroyOnClose={true}
            width={"1000px"}
            onOk={() => form.submit()}
            onCancel={() => handleCancel()}
            okText={currentMode === "detail" ? "Chỉnh sửa" : "Lưu"}
            cancelText="Hủy"
            okButtonProps={{ style: { padding: "5px", height: "36px" } }}
            cancelButtonProps={{ style: { padding: "5px", height: "36px" } }}
        >
            <div className='basic-form'>
                <Form
                    form={form}
                    style={{ width: "95%" }}
                    name="basic"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onFinish={onFinish}
                    onFinishFailed={antFormFinishErrorNotification}
                >
                    {renderColumns()}
                    <Form.Item
                        label="Trạng thái"
                        name="status"
                        labelCol={{ style: { width: 130 } }}
                        labelAlign='left'
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Checkbox
                            style={{
                                marginRight: "10px",
                            }}
                            disabled={currentMode === "detail"}
                        >
                            Hoạt động?
                        </Checkbox>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    )
}