import React, { useCallback, useEffect, useState } from 'react';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './LoginForm.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import apiServices, { axiosSetAccessTokenMiddleware } from '@app/api/api.services';
import { persistToken } from '@app/services/localStorage.service';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LoginRequest } from '@app/api/v6.api';
import { DefaultOptionType } from 'antd/lib/select';
import { BaseOptionType } from 'antd/lib/cascader';
import { Link } from 'react-router-dom';
import { Constant } from '@app/styles/themes/constants';

interface ILoginFormData {
    userName?: string;
    password?: string;
    baseUnitCode?: string;
}

interface ILoginFormDataGet {
    userName?: string;
    password?: string;
    baseUnitsCode?: any[];
    onLoginReturn: (value: boolean) => void;
}

const LoginBaseUnitRequest: React.FC<ILoginFormDataGet> = (data) => {
    const [form] = BaseForm.useForm();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [baseUnitList, setBaseUnitList] = useState<(DefaultOptionType | BaseOptionType)[]>([]);

    useEffect(() => {
        if (data.baseUnitsCode) {
            setBaseUnitList(data.baseUnitsCode.map((value) => ({
                value: value.key,
                label: value.value,
            })));
        }
        form.setFieldValue("userName", data.userName);
    }, [data]);

    const handleReturn = () => {
        data.onLoginReturn(true);
    }

    const handleSubmit = async (values: ILoginFormData) => {
        const model: LoginRequest = {
            userName: data.userName,
            password: data.password,
            baseUnitCode: values.baseUnitCode
        } as LoginRequest;
        setLoading(true);
        try {
            const res = await apiServices.v6Api.authsLogin(model);

            if (res && res.token) {
                persistToken(res.token as string);
                localStorage.setItem(Constant.BASE_UNIT, values.baseUnitCode!);
                axiosSetAccessTokenMiddleware();
                setLoading(false);
                navigate('/');
                return;
            }
        } catch {
            setLoading(false);
        }
        notificationController.error({
            message: 'Sai đơn vị cơ sở'
        });
    };

    return (
        <>
            <BaseForm layout="vertical" onFinish={handleSubmit} form={form}>
                <Auth.FormItem
                    name="userName"
                    label={'Tài khoản'}
                >
                    <Auth.FormInput disabled={true} />
                </Auth.FormItem>

                <Auth.FormItem
                    name="baseUnitCode"
                    label={'Đơn vị cơ sở'}
                    rules={[
                        { required: true, message: 'Chưa chọn đơn vị cở sở' }
                    ]}
                >
                    <Auth.FormSelect options={baseUnitList ?? []} placeholder={'Chọn DVCS'} />
                </Auth.FormItem>
                <BaseForm.Item style={{ margin: "16px 0" }}>
                    <Auth.SubmitButton htmlType="button" onClick={handleReturn}>
                        {t('Trở lại')}
                    </Auth.SubmitButton>
                </BaseForm.Item>
                <BaseForm.Item style={{ marginBottom: "16px" }}>
                    <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
                        {t('Đăng nhập')}
                    </Auth.SubmitButton>
                </BaseForm.Item>
                <Auth.ActionsWrapper>
                    <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
                        <Auth.FormCheckbox>
                            <S.RememberMeText>{t('Ghi nhớ lúc đăng nhập')}</S.RememberMeText>
                        </Auth.FormCheckbox>
                    </BaseForm.Item>
                    <Link to="/auth/forgot-password">
                        <S.ForgotPasswordText>{t('Quên mật khẩu')}</S.ForgotPasswordText>
                    </Link>
                </Auth.ActionsWrapper>
            </BaseForm>        </>
    );
};

export default LoginBaseUnitRequest;
