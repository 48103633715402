import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Row } from 'antd';
import { Rule } from 'antd/lib/form';
import React, { useCallback, useMemo } from 'react';

const MaterialAccount: React.FC = ({
}) => {
    const validateTextCharater = (maxLen: number) => (_: any, value: any) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'materialsAccount', label: 'TK kho',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(16) }] as Rule[],
            },
            {
                colSpan: 12, name: '', label: '',
                render: <>Thành phẩm nhập kho</>
            },
            {
                colSpan: 12, name: 'revenueAccount', label: 'TK doanh thu',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(16) }]
            },
            {
                colSpan: 12, name: '', label: '',
                render: <> Doanh thu bán các thành phẩm</>
            },
            {
                colSpan: 12, name: 'costAccount', label: 'TK giá vốn',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(16) }]
            },
            {
                colSpan: 12, name: '', label: '',
                render: <>Giá vốn bán hàng</>
            },
            {
                colSpan: 12, name: 'discountAccount', label: 'TK chiết khấu',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(16) }],
            },
        ]
    }, [])

    const session2Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'returnAccount', label: 'TK hàng bán bị trả lại',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(16) }] as Rule[],
            },
            {
                colSpan: 12, name: '', label: '',
                render: <>Hàng bán bị trả lại</>
            },
            {
                colSpan: 12, name: 'unfinishedAccount', label: 'TK sản phẩm dở dang',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(16) }] as Rule[],
            },
            {
                colSpan: 12, name: '', label: '',
                render: <></>
            },
            {
                colSpan: 12, name: 'materialDifferenceAccount', label: 'TK chênh lệch vật tư',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(16) }] as Rule[],
            },
            {
                colSpan: 12, name: '', label: '',
                render: <></>
            }
        ]
    }, [])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={24}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, []);

    return (
        <>
            {renderColumns(session1Cols)}
            {renderColumns(session2Cols)}
        </>
    );
};

export default MaterialAccount;