import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { PaymentMethodListView } from '../views/PaymentMethodListView';

const PaymentMethodListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách hình thức thanh toán</PageTitle>
            <PaymentMethodListView />
        </>
    );
};

export default PaymentMethodListPage;
