import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { MaterialTypeListView } from '../material-type-views/MaterialTypeListView';

const MaterialTypeListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách loại vật tư</PageTitle>
            <MaterialTypeListView />
        </>
    );
};

export default MaterialTypeListPage;
