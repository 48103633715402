import { EditOutlined } from '@ant-design/icons';
import apiServices from '@app/api/api.services';
import { Pagination } from '@app/api/table.api';
import { AM91MobileDto } from '@app/api/v6.api';
import { PAGE_SIZE } from '@app/constants/page.constant';
import { commonFunc } from '@app/shared/functions/common.function';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'components/common/Table/Table';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import AM91Modal from '../modals/am-91-modal/am-91-modal';
import { AM91ViewFilter } from './am-91-view-filter';
import { KIEU_POST_OPTIONS } from '../../shared/constants/invoice.constant';

export interface IInvoice91ListViewPagingFilter {
    vvalue: string;
    pageNumber: number;
    pageSize: number;
    status?: boolean;
}

export const AM91ListView: React.FC = () => {
    const [am91Modal, setAM91Modal] = useState<{
        visible: boolean;
        mode?: 'add' | 'edit' | 'detail',
        sttRec?: string
    }>({
        visible: false
    });
    const [invoice91s, setInvoices] = useState<AM91MobileDto[]>([]);
    const [pagination, setPagination] = useState<Pagination>();
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<IInvoice91ListViewPagingFilter>({
        vvalue: '',
        pageNumber: 1,
        pageSize: PAGE_SIZE
    })

    const fetchData = useCallback(async () => {
        setLoading(true);
        const invoice91Paging = await apiServices.v6Api.mobileInvoicesMobileGetAM91(filter.vvalue, filter.status, filter.pageNumber, filter.pageSize);
        setLoading(false);

        setInvoices(invoice91Paging?.items || []);
        setPagination({
            current: invoice91Paging?.pageNumber,
            pageSize: filter.pageSize,
            total: invoice91Paging.totalCount
        })
    }, [filter])

    useEffect(() => {
        fetchData();
    }, [fetchData])


    const handleEdit = (sttRec: string) => {
        setAM91Modal({
            visible: true,
            mode: 'edit',
            sttRec: sttRec
        });
    };

    const handleCloseModal = async (reload?: boolean) => {
        setAM91Modal({
            visible: false
        });
        if (reload) {
            setFilter({
                ...filter,
                pageNumber: 1
            });
        }
    };

    const columns = useMemo(() => {
        const columns: ColumnsType<AM91MobileDto> = [
            {
                title: 'STT',
                width: 40,
                align: 'center',
                render: (text, record, index) => index + 1,
            },
            {
                title: 'Stt Rec',
                dataIndex: 'stt_rec',
                width: 50
            },
            {
                title: 'Tên KH',
                dataIndex: 'ten_kh',
                width: 100
            },
            {
                title: 'Mã kho',
                dataIndex: 'ma_kho_i',
                width: 40
            },
            {
                title: 'ĐVT',
                dataIndex: 'dvt',
                width: 40
            },
            {
                title: 'Ngày chứng từ',
                dataIndex: 'ngay_ct',
                width: 60,
                render: (date2: Date | null | undefined) => {
                    if (date2 !== null) {
                        return moment(date2).format('DD/MM/YYYY');
                    }
                    return "";
                }
            },
            {
                title: 'Trạng thái',
                width: 70,
                render: (invoice91: AM91MobileDto) => {
                    return KIEU_POST_OPTIONS.find(x => x.value === invoice91.kieu_post)?.label ?? '';
                }
            },
            {
                title: 'Thời gian sửa',
                dataIndex: 'time2',
                width: 50
            },
            {
                title: 'Người sửa',
                dataIndex: 'user_id2',
                width: 40
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'date0',
                width: 50,
                render: (date0: Date | null | undefined) => {
                    if (date0) {
                        return moment(date0).format('DD/MM/YYYY');
                    }
                    return "";
                }
            },
            {
                title: 'Thời gian tạo mới',
                dataIndex: 'time0',
                width: 65
            },
            {
                title: 'Người tạo mới',
                dataIndex: 'user_id0',
                width: 45
            },

            {
                title: 'Hành động',
                fixed: 'right',
                align: 'center',
                width: 45,
                render: (invoice91: AM91MobileDto) => {
                    return (
                        <>
                            <Button type="primary"
                                shape="circle"
                                icon={<EditOutlined />}
                                title='Chỉnh sửa'
                                style={{ marginRight: 3 }}
                                onClick={() => {
                                    handleEdit(invoice91.stt_rec!)
                                }}>
                            </Button>
                        </>
                    );
                },
            },
        ];

        return columns;
    }, [filter])

    function onPagingChange(pageNum: number, pageSize: number) {
        const params: IInvoice91ListViewPagingFilter = {
            ...filter,
            pageNumber: pageNum,
            pageSize: pageSize,
        };
        setFilter(params);
    }

    return (
        <div className='app-table-list'>
            <div className='app-table-list-header'>
                <h1 className='app-table-list-header-title'>
                    Danh sách đơn bán hàng
                </h1>

                <div className='app-table-list-header-actions'>
                    <Button type="primary" onClick={() => {
                        setAM91Modal({
                            visible: true,
                            mode: 'add'
                        })
                    }}>
                        Thêm mới
                    </Button>

                </div>
            </div>

            <AM91ViewFilter
                filter={filter}
                onFilter={(data: IInvoice91ListViewPagingFilter) => {
                    setFilter({
                        ...filter,
                        vvalue: data?.vvalue as string,
                        pageNumber: 1
                    } as any);
                }} />

            <Table
                columns={columns}
                dataSource={invoice91s}
                scroll={{ x: 1900 }}
                pagination={{
                    ...pagination,
                    onChange: onPagingChange
                }}
                loading={loading}
                bordered
            />
            {
                am91Modal.visible && (
                    <AM91Modal
                        visible={am91Modal.visible}
                        mode={am91Modal.mode!}
                        sttRec={am91Modal.sttRec}
                        onClose={(e) => handleCloseModal(e)}
                    />
                )
            }
        </div>
    )
}