import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Modal, Tabs, TabsProps, message } from 'antd';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import BaseUnitTabAdvance from './base-unit-tabs/BaseUnitTabAdvance';
import BaseUnitTabMainInfomation from './base-unit-tabs/BaseUnitTabMainInfomation';
import BaseUnitTabSelfDefinition from './base-unit-tabs/BaseUnitTabSelfDefinition';
import apiServices from '@app/api/api.services';
import { notificationController } from '@app/controllers/notificationController';
import { BaseUnitDto, BaseUnitUpdateDto, CatalogSPParams } from '@app/api/v6.api';
import { antFormFinishErrorNotification } from '@app/shared/functions/common.function';

interface IModalBaseUnit {
    code?: string;
    visible: boolean;
    mode: "add" | "edit" | "detail";
    onClose: (reload?: boolean) => void;
    onDispatchResponse?: (data: BaseUnitDto) => void;
}

interface ITab {
    dataEdit?: BaseUnitDto;
    mode: "add" | "edit" | "detail";
}

const BaseUnitModal: React.FC<IModalBaseUnit> = ({ code, visible, mode, onDispatchResponse, onClose }: IModalBaseUnit) => {
    const [form] = BaseForm.useForm();
    const [titleModal, setTileModal] = useState("Thêm mới đơn vị cơ sở");
    const [loading, setLoading] = useState<boolean>(false);
    const [dataTab, setDataTab] = useState<ITab>();

    const handleCancel = async () => {
        setDataTab(undefined);
        onClose();
    };

    const fetchData = useCallback(async () => {
        setDataTab(undefined);
        if (code) {
            if (code) {
                const dataEditBaseUnit = await apiServices.v6Api.baseUnitsGetById(code);
                form.setFieldsValue({
                    ...dataEditBaseUnit,
                    status: (dataEditBaseUnit.status === '1' ? true : false) as any
                })
                setDataTab({ dataEdit: dataEditBaseUnit, mode: mode })
            }
            setTileModal(`${mode === "detail" ? "Chi tiết" : "Chỉnh sửa"} đơn vị cơ sở - ${code}`);
        } else {
            setTileModal(`Thêm mới đơn vị cơ sở`);
        }
    }, [code, mode, form])

    useEffect(() => {
        form.resetFields();
        code ? setDataTab({ dataEdit: dataTab?.dataEdit, mode: mode }) : setDataTab({ dataEdit: dataTab?.dataEdit, mode: "add" })
        fetchData();
    }, [fetchData, form, code, mode])

    const checkInputLookup = async () => {
        const value = await form.getFieldValue(`taxCode`) as number;
        const filter = {
            vvar: 'MA_THUE',
            pageNumber: 1,
            pageSize: 20
        } as CatalogSPParams;

        if (value) {
            const checkExist = await apiServices.v6Api.catalogsGetCatalogDynamicPaging(filter);
            if (!checkExist.items?.find(item => item.ma_thue.trim() == value.toString().trim())) {
                return "Không tìm thấy mã thuế này";
            }
        }
        return ""
    }

    const handleDisable = (() => {
        setDataTab({ dataEdit: dataTab?.dataEdit, mode: "edit" })
        setTileModal(`${dataTab?.mode !== "detail" ? "Chi tiết" : "Chỉnh sửa"} đơn vị cơ sở - ${code}`);
    });

    const renderForm = useCallback(() => {
        return <>
            <Form
                form={form}
                name="basic"
                initialValues={{ remember: true }}
                autoComplete="off"
                onFinish={onFinish}
                onFinishFailed={antFormFinishErrorNotification}
            >
                <Tabs defaultActiveKey="1" items={items} />
            </Form>
        </>
    }, [dataTab, code])

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Thông tin chính',
            children: <BaseUnitTabMainInfomation
                code={code}
                form={form}
                dataTab={dataTab}
            />,
        },
        {
            key: '2',
            label: 'Tự định nghĩa',
            children: <BaseUnitTabSelfDefinition />,

        },
        {
            key: '3',
            label: 'Advance',
            children: <BaseUnitTabAdvance />,
        },
    ];

    const onFinish = async () => {
        if (dataTab?.mode === "detail") {
            handleDisable();
            return;
        }

        const data = await form.validateFields();
        data.status = data.status ? '1' : '0';

        const resultCheckInputLookup = await checkInputLookup();

        if (resultCheckInputLookup !== "") {
            message.error(resultCheckInputLookup);
            return;
        }

        setLoading(true);
        const promise = code
            ? apiServices.v6Api.baseUnitsUpdateBaseUnit(code, data)
            : apiServices.v6Api.baseUnitsCreateBaseUnit(data);

        promise.then((response) => {
            if (response.isSuccess) {
                notificationController.success({ message: 'Thành công' });
                onClose(true);

                if (onDispatchResponse) {
                    onDispatchResponse(response.data as BaseUnitUpdateDto);
                }
                return;
            }

            notificationController.error({ message: response.message });
        }).catch(() => {
            notificationController.error({ message: 'Thất bại' });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal
            title={titleModal}
            confirmLoading={loading}
            open={visible}
            destroyOnClose={true}
            width={"1000px"}
            onOk={() => form.submit()}
            onCancel={() => handleCancel()}
            okText={dataTab?.mode === "detail" ? "Chỉnh sửa" : "Lưu"}
            cancelText="Hủy"
            okButtonProps={{ style: { padding: "5px", height: "36px" } }}
            cancelButtonProps={{ style: { padding: "5px", height: "36px" } }}
        >
            <div className='basic-form'>
                {renderForm()}
            </div>
        </Modal>
    );
};

export default BaseUnitModal;
