import React, { useEffect, useState } from 'react';
import { AntMenuItem, menuFunctions } from '@app/shared/functions/menu.function';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import * as S from './SiderMenu.styles';
import { sidebarNavigation, SidebarNavigationItem } from '../sidebarNavigation';
import { Input, Menu } from 'antd';
import apiServices from '@app/api/api.services';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { setActiveMenuKeys } from '@app/store/slices/nightModeSlice';
import { MenuDto } from '@app/api/v6.api';
import { debounce } from 'lodash';
import removeAccents from 'remove-accents';

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const sidebarNavFlat = sidebarNavigation.reduce(
  (result: SidebarNavigationItem[], current) =>
    result.concat(current.children && current.children.length > 0 ? current.children : current),
  [],
);

const SiderMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [menuItems, setMenuItems] = useState<AntMenuItem[]>([]);
  const [allMenus, setAllMenus] = useState<MenuDto[]>([]);
  const [menuSidebarNavigation, setMenuSidebarNavigation] = useState<SidebarNavigationItem[] | []>([]);

  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state);

  const currentMenuItem = sidebarNavFlat.find(({ url }) => url === location.pathname);
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];

  const openedSubmenu = menuSidebarNavigation.find(({ children }) =>
    children?.some(({ url }) => url === location.pathname),
  );
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  useEffect(() => {
    fetchMenus();
  }, []);

  async function fetchMenus() {
    const menus = await apiServices.v6Api.menusGetMenu();
    setAllMenus(menus);
    const antdMenuItems = menuFunctions.buildAntMenuItems(menus);
    setMenuItems(antdMenuItems);
    dispatch(setActiveMenuKeys([]));
    setMenuSidebarNavigation(sidebarNavigation);
  }

  const onFilterMenuChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = removeAccents(event.target.value?.trim() ?? '');

    const filteredMenus = menuFunctions.filterMenus(allMenus, searchText);
    const antdMenuItems = menuFunctions.buildAntMenuItems(filteredMenus);
    setMenuItems(antdMenuItems);

    const updatedSidebarNavigation = sidebarNavigation.filter(item => {
      const menuLowerCase = item.title.toLocaleLowerCase();
      return menuLowerCase && menuLowerCase.includes(searchText.toLocaleLowerCase());
    });
    setMenuSidebarNavigation(updatedSidebarNavigation.length > 0 ? updatedSidebarNavigation : []);
  }

  const debouncedOnFilterMenuChange = debounce(onFilterMenuChange, 400);

  return (
    <>
      <Input style={{ width: "85%", margin: "10px 0 0 16px" }}
        placeholder='Tìm kiếm menu'
        onChange={(event) => debouncedOnFilterMenuChange(event)} />

      <S.Menu
        mode="vertical"
        items={menuItems}
        // defaultSelectedKeys={['A001-B001-C0101007']}
        // defaultOpenKeys={['A001-B001-A0000000']}
        onClick={() => setCollapsed(true)}
      />

      <S.Menu
        mode="inline"
        defaultSelectedKeys={defaultSelectedKeys}
        defaultOpenKeys={defaultOpenKeys}
        onClick={() => setCollapsed(true)}
      >
        {menuSidebarNavigation.map((nav) =>
          nav.children && nav.children.length > 0 ? (
            <Menu.SubMenu
              key={nav.key}
              title={t(nav.title)}
              icon={nav.icon}
              onTitleClick={() => setCollapsed(false)}
              popupClassName="d-none"
            >
              {nav.children.map((childNav) => (
                <Menu.Item key={childNav.key} title="">
                  <Link to={childNav.url || ''}>{t(childNav.title)}</Link>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ) : (
            <Menu.Item key={nav.key} title="" icon={nav.icon}>
              <Link to={nav.url || ''}>{t(nav.title)}</Link>
            </Menu.Item>
          ),
        )}
      </S.Menu>
    </>
  );
};

export default SiderMenu;
