import apiServices from '@app/api/api.services';
import { Pagination } from '@app/api/table.api';
import { MaterialUnitConversionDto } from '@app/api/v6.api';
import { PAGE_SIZE } from '@app/constants/page.constant';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'components/common/Table/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { UnitConversionListViewFilter } from './MaterialUnitConversionViewFilter';
import { Button } from 'antd';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { UnitConversionModal } from '../modals/MaterialUnitConversionModal';

interface BaseUnitConversion {
    ma_vt: string;
    dvt: string;
    dvtqd: string;
}

export interface IMaterialUnitConversionPagingFilter {
    searchText: string;
    pageNumber: number;
    pageSize: number;
    status?: boolean;
}

export const UnitConversionListView: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [chosenMode, setChosenMode] = useState<"add" | "edit" | "detail">("add");
    const [dataEdit, setDataEdit] = useState<BaseUnitConversion>();
    const [unitConversions, setUnitConversions] = useState<MaterialUnitConversionDto[]>([]);
    const [pagination, setPagination] = useState<Pagination>();
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<IMaterialUnitConversionPagingFilter>({
        searchText: '',
        status: true,
        pageNumber: 1,
        pageSize: PAGE_SIZE
    })

    const fetchData = useCallback(async () => {
        setLoading(true);

        const unitConversionsPaging = await apiServices.v6Api.materialUnitConversionsGet(filter.searchText, filter.status, undefined, filter.pageNumber, filter.pageSize);

        setUnitConversions(unitConversionsPaging?.items || []);
        setPagination({
            current: unitConversionsPaging?.pageNumber,
            pageSize: filter.pageSize,
            total: unitConversionsPaging.totalCount
        })
        setLoading(false);
    }, [filter])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleEdit = async (code: any, dViTinh: any, dViQuyDoi: any) => {
        setDataEdit({
            ma_vt: code,
            dvt: dViTinh,
            dvtqd: dViQuyDoi
        });
        setChosenMode("edit");
        setIsModalOpen(true);
    };

    const handleDetail = async (code: any, dViTinh: any, dViQuyDoi: any) => {
        setDataEdit({
            ma_vt: code,
            dvt: dViTinh,
            dvtqd: dViQuyDoi
        }); setChosenMode("detail");
        setIsModalOpen(true);
    };

    const handleCloseModal = async (reload?: boolean) => {
        setDataEdit(undefined);
        setIsModalOpen(false);
        if (reload) {
            setFilter({
                ...filter,
                pageNumber: 1,
                status: true
            });
        }
    };

    const columns = useMemo(() => {
        const columns: ColumnsType<MaterialUnitConversionDto> = [
            {
                title: 'STT',
                width: 50,
                align: 'center',
                render: (text, record, index) => index + 1,
            },
            {
                title: 'Mã VT',
                dataIndex: 'ma_vt',
                width: 80
            },
            {
                title: 'Đvt',
                dataIndex: 'dvt',
                width: 70
            },
            {
                title: 'Đvt tồn kho',
                dataIndex: 'dvtqd',
                width: 80
            },
            {
                title: 'Hệ số tử',
                dataIndex: 'he_sot',
                width: 80
            },
            {
                title: 'Hệ số mẫu',
                dataIndex: 'he_som',
                width: 80
            },
            {
                title: 'Hệ số',
                dataIndex: 'he_so',
                width: 80
            },
            {
                title: 'Hành động',
                fixed: 'right',
                align: 'center',
                width: 100,
                render: (unitConversion: MaterialUnitConversionDto) => {
                    return (
                        <>
                            <Button type="primary"
                                shape="circle"
                                icon={<EditOutlined />}
                                title='Chỉnh sửa'
                                style={{ marginRight: 3 }}
                                onClick={() => {
                                    handleEdit(unitConversion.ma_vt, unitConversion.dvt, unitConversion.dvtqd)
                                }}>
                            </Button>
                            <Button type="primary"
                                shape="circle"
                                icon={<InfoCircleOutlined />}
                                title='Chi tiết'
                                style={{ marginRight: 3 }}
                                onClick={() => {
                                    handleDetail(unitConversion.ma_vt, unitConversion.dvt, unitConversion.dvtqd)
                                }}>
                            </Button>

                        </>
                    );
                },
            },
        ];
        return columns;
    }, [])

    function onPagingChange(pageNum: number, pageSize: number) {
        const params: IMaterialUnitConversionPagingFilter = {
            ...filter,
            pageNumber: pageNum,
            pageSize: pageSize,
        };
        setFilter(params);
    }

    return (
        <div className='app-table-list'>
            <div className='app-table-list-header'>
                <h1 className='app-table-list-header-title'>
                    Danh sách quy đổi đơn vị tính
                </h1>
                <div className='app-table-list-header-actions'>
                    <Button type="primary" onClick={() => { setIsModalOpen(true); setDataEdit(undefined) }}>
                        Thêm mới
                    </Button>

                </div>
            </div>
            <UnitConversionListViewFilter
                filter={filter}
                onFilter={(data: IMaterialUnitConversionPagingFilter) => {
                    setFilter({
                        ...filter,
                        searchText: data.searchText as string,
                        pageNumber: 1
                    });
                }} />
            <Table
                columns={columns}
                dataSource={unitConversions}
                pagination={{
                    ...pagination,
                    onChange: onPagingChange
                }}
                loading={loading}
                bordered
            />
            <UnitConversionModal
                visible={isModalOpen}
                mode={chosenMode}
                data={dataEdit}
                onClose={(e) => handleCloseModal(e)}
            />
        </div>
    )
}