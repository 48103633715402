import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Checkbox, Form, InputNumber, Row } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { Rule } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import { Utils } from '@app/shared/helpers/utils';

interface IPaymentMethodEdit {
    mode: "add" | "edit" | "detail";
}
const PaymentMethodKeyInfomation: React.FC<IPaymentMethodEdit> = ({ mode
}: IPaymentMethodEdit) => {
    const validateTextCharater = (maxLen: number) => (_: any, value: any) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 10, name: 'han_tt', label: 'Hạn TT', disabled: mode === "detail", width: 170,
                render: (
                    <InputNumber disabled={mode === "detail"} min={0} maxLength={5} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập hạn thanh toán' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 24, name: 'tk_no', label: 'Tài khoản ghi nợ', disabled: mode === "detail", width: 170,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(16) }] as Rule[],
            },
            {
                colSpan: 24, name: 'ghi_chu', label: 'Ghi chú', width: 170,
                render: (
                    <TextArea
                        disabled={mode === "detail"}
                        rows={4}
                    />
                )
            },
        ]
    }, [mode])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={10}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, [mode]);

    return (
        <>
            {renderColumns(session1Cols)}

            <Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 170 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={mode === "detail"}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item>
        </>
    );
};

export default PaymentMethodKeyInfomation;