import { InputSearchApiService } from '@app/api/input-search-api.service';
import { BankContractDto } from '@app/api/v6.api';
import InputSearchLookup from '@app/components/common/input-search-lookup/InputSearchLookup';
import { InputSearchLookupColumns } from '@app/components/common/input-search-lookup/constants/input-lookup-entity-columns.constant';
import { IInputSearchLookupApiRequest } from '@app/components/common/input-search-lookup/models/input-search-lookup-column';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { CatalogAddModalType } from '@app/components/shared/modals/catalog-add-modal/catalog-add-modal';
import { Utils } from '@app/shared/helpers/utils';
import { PaginatedList } from '@app/shared/models/paginated-list.model';
import { Checkbox, Col, DatePicker, InputNumber, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import Form from 'antd/lib/form';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';

interface IBankContractEdit {
    code?: string;
    form: FormInstance<BankContractDto>;
    dataTab: ITab | undefined
}

interface ITab {
    dataEdit?: BankContractDto;
    mode: "add" | "edit" | "detail";
}

const BankContractMainInfomation: React.FC<IBankContractEdit> = ({ form, dataTab }: IBankContractEdit) => {

    const validateTextCharater = (maxLen: number) => (_: any, value: string) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const baseDatePickerForm = useCallback((name: string, lable: string) => {
        const valueForm = form ? form.getFieldValue(name) : null;
        return (
            <Col span={12}>
                <Form.Item
                    label={lable}
                    name={name}
                    labelAlign='left'
                    labelCol={{ style: { width: 130 } }}
                >
                    <DatePicker
                        disabled={dataTab?.mode === "detail"}
                        size='small'
                        placeholder={valueForm ? moment(valueForm, "YYYY-MM-DD").toString() : `${lable}`}
                        defaultValue={valueForm ? moment(valueForm, "YYYY-MM-DD") : undefined}
                        style={{ width: "100%", borderRadius: "0px" }}
                    />

                </Form.Item>
            </Col>
        )
    }, [dataTab, form]);

    const baseInputLookUp = useCallback((name: string, label: string, alnhValue: string | undefined | null, bankContractGroup: number) => {
        const setChangeAlnhkuValue: { text: string } = { text: alnhValue ? alnhValue : "" };
        const fetchDataFunc: (request: IInputSearchLookupApiRequest) => Promise<PaginatedList<any>> = InputSearchApiService.getBankContractGroups(bankContractGroup);

        return (
            <Col span={12}>
                <Form.Item
                    name={name}
                    label={label}
                    labelCol={{ style: { width: 130 } }}
                    labelAlign='left'
                    className="w-100"
                    rules={[{ validator: validateTextCharater(16) }]}
                >
                    <InputSearchLookup
                        fetchDataFunc={fetchDataFunc}
                        disabled={dataTab?.mode === "detail"}
                        placeHolder={`Nhập ${label}`}
                        keyField={'groupCode'}
                        columns={InputSearchLookupColumns.accontContractGroup}
                        lookupAdding={{
                            initValues: {
                                groupType: bankContractGroup
                            },
                            catalogAddModalType: CatalogAddModalType.BankContractGroup
                        }}
                        onOutputChange={(value: string) => {
                            form.setFieldsValue({ [name]: value });
                        }}
                        patchValue={setChangeAlnhkuValue}
                    />
                </Form.Item>
            </Col>
        )
    }, [dataTab, form]);


    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'soKu', label: 'Số khế ước', disabled: dataTab?.mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(20) }],
                placeholder: "Nhập số khế ước"
            }
        ]
    }, [dataTab?.mode])

    const session2Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'tienNT', label: 'Tiền nguyên tệ',
                render: (
                    <InputNumber disabled={dataTab?.mode === "detail"} defaultValue={0} maxLength={16} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập tiền nguyên tệ' style={{ width: "100%" }} />
                ), disabled: dataTab?.mode === "detail"
            },
            {
                colSpan: 4, name: 'maNT', label: '', width: 0,
                render: (
                    <InputSearchLookup
                        fetchDataFunc={InputSearchApiService.getCatalogDymamic("MA_NT")}
                        disabled={dataTab?.mode === "detail"}
                        placeHolder={`Nhập mã ngoại tệ`}
                        keyField={'ma_nt'}
                        columns={InputSearchLookupColumns.foreignCurrency}
                        onOutputChange={(value: string) => {
                            form.setFieldsValue({ maNT: value });
                        }}
                        patchValue={{ text: dataTab?.dataEdit?.maNT ?? "" as string }}
                    />
                ), disabled: dataTab?.mode === "detail"
            },
            {
                colSpan: 12, name: 'tien0', label: 'Tiền VND',
                render: (
                    <InputNumber disabled={dataTab?.mode === "detail"} defaultValue={0} maxLength={16} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập tiền VND' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 12, name: 'snVay', label: 'Tài khoản vay',
                render: (
                    <InputNumber disabled={dataTab?.mode === "detail"} defaultValue={0} maxLength={16} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập tài khoản vay' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 12, name: 'maVV', label: 'Mã vụ việc',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(16) }],
                placeholder: "Nhập mã vụ việc", disabled: dataTab?.mode === "detail"
            },
            {
                colSpan: 12, name: 'laiSuat', label: 'Lãi suất 1',
                placeholder: "Nhập lãi suất 1", disabled: dataTab?.mode === "detail",
                render: (
                    <InputNumber disabled={dataTab?.mode === "detail"} defaultValue={0} min={0} max={999} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập lãi suất' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 12, name: 'laiSuat2', label: 'Lãi suất 2',
                disabled: dataTab?.mode === "detail",
                render: (
                    <InputNumber disabled={dataTab?.mode === "detail"} defaultValue={0} min={0} max={999} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập lãi suất 2' style={{ width: "100%" }} />
                )
            }
        ]
    }, [dataTab?.mode])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={24}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, []);

    return (
        <>
            {renderColumns(session1Cols)}

            <Row gutter={24}>
                {baseDatePickerForm("ngayKU1", "Ngày bắt đầu")}
                {baseDatePickerForm("ngayKU2", "Ngày kết thúc")}
            </Row>

            {renderColumns(session2Cols)}

            <Row gutter={24}>
                {baseInputLookUp("nhKU1", "Phân nhóm kiểu 1", dataTab?.dataEdit?.nhKU1, 1)}
                {baseInputLookUp("nhKU2", "Phân nhóm kiểu 2", dataTab?.dataEdit?.nhKU2, 2)}
                {baseInputLookUp("nhKU3", "Phân nhóm kiểu 3", dataTab?.dataEdit?.nhKU3, 3)}
            </Row>

            <Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 130 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={dataTab?.mode === "detail"}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item>
        </>
    );
};

export default BankContractMainInfomation;
