import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { CustomerListView } from '../customer-views/CustomerListView';

const CustomerListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách khách hàng</PageTitle>
            <CustomerListView />
        </>
    );
};

export default CustomerListPage;
