import { InputSearchApiService } from '@app/api/input-search-api.service';
import InputSearchLookup from '@app/components/common/input-search-lookup/InputSearchLookup';
import { InputSearchLookupColumns } from '@app/components/common/input-search-lookup/constants/input-lookup-entity-columns.constant';
import { IMTYPE_OPTIONS, InvoiceAMColumnConstant, KIEU_POST_OPTIONS } from '@app/pages/Invoices/shared/constants/invoice.constant';
import { CatalogVvar } from '@app/shared/constants/catalog.constant';
import { Utils } from '@app/shared/helpers/utils';
import { ICatalogTax } from '@app/shared/models/catalog-tax.model';
import { IAM91 } from '@app/shared/models/invoices/am91.model';
import { Checkbox, Col, Form, InputNumber, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import React, { useEffect, useMemo, useState } from 'react';
import './am-91-modal-summary.less';
import * as S from './am-91-modal-summary.style';
import { V6optionDto } from '@app/api/v6.api';

interface IAM91ModalSummaryProps {
    loaiCK: boolean;
    code?: string;
    form: FormInstance<IAM91>;
    dataTab: ITab | undefined;
    tinhTongThanhToan: () => void;
    changesSummarySuaTien: (suaTien: boolean) => void;
}

interface ITab {
    dataEdit?: any;
    mode: "add" | "edit" | "detail";
}

const LABEL_WIDTH = 115;

const AM91ModalSummary: React.FC<IAM91ModalSummaryProps> = ({
    loaiCK,
    form,
    dataTab,
    tinhTongThanhToan,
    changesSummarySuaTien
}: IAM91ModalSummaryProps) => {
    const [suaCK, setSuaCK] = useState<boolean>(false);
    const [suaTKThue, setSuaTKThue] = useState<boolean>(false);
    const [tinhCK, setTinhCK] = useState<boolean>(false);

    const [onChangeFormTKCo, setOnChangeFormTKCo] = useState<{ text: string }>(null!);

    const isAdd = useMemo(() => { return dataTab?.mode === 'add' }, [dataTab])
    const isDetail = useMemo(() => { return dataTab?.mode === 'detail' }, [dataTab])


    const [isDisableTienCK, setIsDisableTienCK] = useState(true);
    const [isDisableTienThue, setIsDisableTienCThue] = useState(true);

    const handleCheckTienCK = () => {
        setIsDisableTienCK((prev) => !prev);
    };

    const handleCheckTienThue = () => {
        setIsDisableTienCThue((prev) => !prev);
    };

    const onSuaTKThueChange = () => {
        setSuaTKThue(form.getFieldValue(InvoiceAMColumnConstant.SUA_TKTHUE));
    }

    // #region useEffect
    useEffect(() => {
        if (!loaiCK) {
            const data = {} as any;
            data[InvoiceAMColumnConstant.TINH_CK] = false;
            data[InvoiceAMColumnConstant.PT_CK] = 0;

            form.setFieldsValue(data)
            setTinhCK(false);
        }
    }, [loaiCK])

    // useEffect(() => {
    //     if (!loaiCK) {
    //         const data = {} as any;
    //         data[InvoiceAMColumnConstant.TINH_CK] = false;
    //         data[InvoiceAMColumnConstant.PT_CK] = 0;

    //         form.setFieldsValue(data)
    //         setTinhCK(tinhCK);
    //     }
    // }, [tinhCK])
    // #endregion useEffect

    // #region Form Item Change
    function onMaThueChange(record: ICatalogTax) {
        const data = {} as any;
        data[InvoiceAMColumnConstant.MA_THUE] = record.ma_thue;
        data[InvoiceAMColumnConstant.THUE_SUAT] = record.thue_suat;
        data[InvoiceAMColumnConstant.TK_THUE_CO] = record.tk_thue_co;
        data[InvoiceAMColumnConstant.TK_THUE_NO] = form.getFieldValue(InvoiceAMColumnConstant.MA_NX);
        form.setFieldsValue(data);

        setOnChangeFormTKCo({ text: record.tk_thue_co });
        tinhTongThanhToan();
    }
    // #endregion Form Item Change

    return (
        <div className='am-91-modal-summary-container'>
            <Row gutter={4} >
                <Col span={16}>
                    <Row gutter={4} >
                        <Col span={2}>
                            <Form.Item
                                label="0"
                                labelAlign='left'
                                className="w-100"
                            >
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                label="Chưa thực hiện"
                                labelAlign='left'
                                className="w-100"
                            >
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                name={InvoiceAMColumnConstant.T_SO_LUONG1}
                                label=""
                                labelCol={{ style: { width: 105 } }}
                                labelAlign='left'
                                className="w-100"
                            >
                                <InputNumber className='w-100 antd-input-number-text-align-right ' formatter={Utils.numberFormatter} size="small" disabled={true} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={4} >
                        <Col span={6}>
                            <Form.Item
                                label=""
                                name="status"
                                labelCol={{ style: { width: 130 } }
                                }
                                labelAlign='left'
                                valuePropName="checked"
                                initialValue={false}
                            >
                                <Checkbox
                                    style={{
                                        marginRight: "10px",
                                    }}
                                >
                                    Sửa chi tiết
                                </Checkbox>
                            </Form.Item >
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label=""

                                labelCol={{ style: { width: 130 } }
                                }
                                labelAlign='left'
                                valuePropName="checked"
                                initialValue={true}
                            >
                                <Select
                                    size='small'
                                    defaultValue="0"
                                    style={{ width: "100%" }}
                                    options={[{ value: '0', label: 'Chức năng' }]}
                                />
                            </Form.Item >

                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label=""

                                labelCol={{ style: { width: 130 } }
                                }
                                labelAlign='left'
                                valuePropName="checked"
                                initialValue={true}
                            >
                                <Select
                                    size='small'
                                    defaultValue="0"
                                    style={{ width: "100%" }}
                                    options={[{ value: '0', label: 'Chức năng 2' }]}
                                />
                            </Form.Item >
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name={InvoiceAMColumnConstant.T_SO_LUONG}
                                label=""
                                labelCol={{ style: { width: 105 } }}
                                labelAlign='left'
                                className="w-100"
                            >
                                <InputNumber className='w-100 antd-input-number-text-align-right ' formatter={Utils.numberFormatter} size="small" disabled={true} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={4} >
                        <Col span={6}>
                            <Form.Item
                                name={InvoiceAMColumnConstant.SUA_TIEN}
                                label=""

                                labelCol={{ style: { width: 130 } }
                                }
                                labelAlign='left'
                                valuePropName="checked"
                                initialValue={false}
                            >
                                <Checkbox
                                    style={{
                                        marginRight: "10px",
                                    }}
                                    onChange={() => {
                                        changesSummarySuaTien(form.getFieldValue(InvoiceAMColumnConstant.SUA_TIEN));
                                    }}
                                >
                                    Sửa tiền
                                </Checkbox>
                            </Form.Item >
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label=""

                                labelCol={{ style: { width: 130 } }
                                }
                                labelAlign='left'
                                valuePropName="checked"
                                initialValue={true}
                            >
                                <Checkbox
                                    style={{
                                        marginRight: "10px",
                                    }}
                                >
                                    Sửa liên tục
                                </Checkbox>
                            </Form.Item >
                        </Col>
                        <Col span={6}>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name={InvoiceAMColumnConstant.T_TT_NT}
                                label=""
                                labelCol={{ style: { width: 105 } }}
                                labelAlign='left'
                                className="w-100"
                            >
                                <InputNumber className='w-100 antd-input-number-text-align-right ' formatter={Utils.numberFormatter} size="small" disabled={true} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={4} >
                        <Col span={5}>
                            <Form.Item
                                label=""
                                name={InvoiceAMColumnConstant.TINH_CK}
                                labelCol={
                                    { style: { width: 130 } }
                                }
                                labelAlign='left'
                                valuePropName="checked"
                                initialValue={false}
                            >
                                <Checkbox
                                    disabled={!loaiCK}
                                    style={{
                                        marginRight: "10px",
                                    }}
                                    onChange={() => {
                                        const tinh_ck = form.getFieldValue(InvoiceAMColumnConstant.TINH_CK);
                                        setTinhCK(tinh_ck);
                                        if (!tinh_ck) {
                                            form.setFieldValue(InvoiceAMColumnConstant.PT_CK, 0);
                                        }
                                    }}
                                >
                                    Chiết khấu
                                </Checkbox>
                            </Form.Item >
                        </Col>
                        <Col span={2}>
                            <Form.Item
                                name={InvoiceAMColumnConstant.PT_CK}
                                label=""
                                labelCol={{ style: { width: 105 } }}
                                labelAlign='left'
                                className="w-100"
                                initialValue={0}
                            >
                                <InputNumber className='w-100 antd-input-number-text-align-right' formatter={Utils.numberFormatter} size="small" disabled={!tinhCK}
                                    onChange={() => {
                                        tinhTongThanhToan();
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item
                                label=""
                                name={InvoiceAMColumnConstant.AUTO_CK}
                                labelCol={{ style: { width: 130 } }
                                }
                                labelAlign='left'
                                valuePropName="checked"
                                initialValue={true}
                            >
                                <Checkbox
                                    style={{
                                        marginRight: "10px",
                                    }}
                                >
                                    Auto CK
                                </Checkbox>
                            </Form.Item >
                        </Col>
                        <Col span={6}>
                            <Form.Item

                                label="Tk ck"
                                labelCol={{ style: { width: 35 } }}
                                labelAlign='left'
                                className="w-100"
                            >
                                <S.Input size="small" disabled={true}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item

                                label="Tk gt"
                                labelCol={{ style: { width: 35 } }}
                                labelAlign='left'
                                className="w-100"
                            >
                                <S.Input size="small" disabled={true}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={4} >
                        <Col span={5}>
                            <Form.Item
                                name={InvoiceAMColumnConstant.MA_THUE}
                                label="Mã thuế"
                                labelCol={{ style: { width: 60 } }}
                                labelAlign='left'
                                className="w-100"
                            >
                                <InputSearchLookup
                                    disabled={isDetail}
                                    fetchDataFunc={InputSearchApiService.getCatalogDymamic(InvoiceAMColumnConstant.MA_THUE)}
                                    placeHolder='Mã thuế'
                                    keyField={InvoiceAMColumnConstant.MA_THUE}
                                    columns={InputSearchLookupColumns.catalogColumns[InvoiceAMColumnConstant.MA_THUE]}
                                    onOutputSelectRecord={(record: ICatalogTax) => {
                                        onMaThueChange(record);
                                    }}
                                    patchValue={{ text: form.getFieldValue('ma_thue') }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item
                                name={InvoiceAMColumnConstant.THUE_SUAT}
                                label=""
                                labelCol={{ style: { width: 60 } }}
                                labelAlign='left'
                                className="w-100 ant-form-item-input-number-height-26"
                            >
                                <InputNumber className='w-100' formatter={Utils.numberFormatter} size="small" disabled={true} />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item

                                label=""
                                labelCol={{ style: { width: 105 } }}
                                labelAlign='left'
                                className="w-100"
                            >
                                <S.Input size="small" disabled={true}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={9} className='d-flex'>
                            <Form.Item
                                label="Tk thuế"
                                name={InvoiceAMColumnConstant.SUA_TKTHUE}
                                labelCol={{ style: { width: 60 } }}
                                labelAlign='left'
                                valuePropName="checked"
                                initialValue={false}
                                className='margin-vertial-auto'
                                style={{ width: 120 }}
                            >
                                <Checkbox
                                    style={{
                                        marginRight: "10px",
                                    }}
                                    onChange={() => onSuaTKThueChange()}
                                >
                                </Checkbox>
                            </Form.Item >

                            <Form.Item
                                label=""
                                labelAlign='left'
                                className="w-100 margin-vertial-auto"
                            >
                                <InputSearchLookup
                                    disabled={isDetail || !suaTKThue}
                                    fetchDataFunc={InputSearchApiService.getCatalogDymamic(CatalogVvar.tk)}
                                    placeHolder='Tk nợ'
                                    keyField={CatalogVvar.tk}
                                    columns={InputSearchLookupColumns.catalogColumns[CatalogVvar.tk]}
                                    onOutputSelectRecord={(record: any) => {
                                        const data = {} as any;
                                        data[InvoiceAMColumnConstant.TK_THUE_NO] = record[CatalogVvar.tk];
                                        form.setFieldsValue(data);

                                    }}
                                    patchValue={{ text: form.getFieldValue(InvoiceAMColumnConstant.TK_THUE_NO) }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label=""
                                labelAlign='left'
                                className="w-100"
                            >
                                <InputSearchLookup
                                    disabled={isDetail || !suaTKThue}
                                    fetchDataFunc={InputSearchApiService.getCatalogDymamic(CatalogVvar.tk)}
                                    placeHolder='Tk có'
                                    keyField={CatalogVvar.tk}
                                    columns={InputSearchLookupColumns.catalogColumns[CatalogVvar.tk]}
                                    onOutputSelectRecord={(record: any) => {
                                        const data = {} as any;
                                        data[InvoiceAMColumnConstant.TK_THUE_CO] = record[CatalogVvar.tk];
                                        form.setFieldsValue(data);

                                    }}
                                    patchValue={{ text: form.getFieldValue(InvoiceAMColumnConstant.TK_THUE_CO) }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={4} >
                        <Col span={5}>
                            <Form.Item
                                name={''}
                                label="Trạng thái"
                                labelCol={{ style: { width: 130 } }}
                                labelAlign='left'
                                className="w-100"
                            >
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item
                                name={'kieu_post'}
                                label=""
                                labelCol={{ style: { width: 130 } }
                                }
                                labelAlign='left'
                                initialValue={KIEU_POST_OPTIONS[0].value}
                            >
                                <Select
                                    size='small'
                                    allowClear={false}
                                    style={{ width: "100%" }}
                                    options={KIEU_POST_OPTIONS}
                                />
                            </Form.Item >

                        </Col>
                        <Col span={9}>
                            <Form.Item
                                label="DATA"
                                name={'imtype'}
                                labelCol={{ style: { width: 50 } }
                                }
                                labelAlign='left'
                            >
                                <Select
                                    size='small'
                                    allowClear={false}
                                    style={{ width: "100%" }}
                                    options={IMTYPE_OPTIONS}
                                />
                            </Form.Item >

                        </Col>
                    </Row>

                </Col>

                <Col span={2}>
                </Col>

                <Col span={6}>
                    <Form.Item
                        name={InvoiceAMColumnConstant.T_TIEN_NT2}
                        label={'Tiền hàng'}
                        labelAlign='left'
                        labelCol={{ style: { minWidth: LABEL_WIDTH } }}
                        className="w-100"
                    >
                        <InputNumber className='w-100 antd-input-number-text-align-right ' formatter={Utils.numberFormatter} size="small" disabled={true} />
                    </Form.Item>

                    <Form.Item
                        name={InvoiceAMColumnConstant.T_GG_NT}
                        label={'Tiền giảm'}
                        labelAlign='left'
                        labelCol={{ style: { minWidth: LABEL_WIDTH } }}
                        className="w-100"
                    >
                        <InputNumber className='w-100 antd-input-number-text-align-right ' formatter={Utils.numberFormatter} size="small" disabled={true} />
                    </Form.Item>

                    <div className='d-flex'>
                        <Form.Item
                            label={<span style={{ marginRight: 10 }}>Tiền CK</span>}
                            name={InvoiceAMColumnConstant.SUA_CK}
                            labelAlign='left'
                            valuePropName="checked"
                            initialValue={false}
                            style={{ minWidth: LABEL_WIDTH }}
                        >
                            <Checkbox
                                disabled={!loaiCK}
                                onChange={() => {
                                    setSuaCK(form.getFieldValue(InvoiceAMColumnConstant.SUA_CK));
                                }}>
                                Sửa
                            </Checkbox>
                        </Form.Item>

                        <Form.Item
                            name={InvoiceAMColumnConstant.T_CK_NT}
                            className="w-100"
                        >
                            <InputNumber formatter={Utils.numberFormatter} className='w-100 antd-input-number-text-align-right ' size="small" disabled={!suaCK}
                                onBlur={() => {
                                    tinhTongThanhToan();
                                }}
                            />
                        </Form.Item>
                    </div>

                    <div className='d-flex'>
                        <Form.Item
                            label="Tiền thuế"
                            name={InvoiceAMColumnConstant.SUA_THUE}
                            labelAlign='left'
                            valuePropName="checked"
                            initialValue={false}
                            style={{ minWidth: LABEL_WIDTH }}
                        >
                            <Checkbox onChange={() => {
                                setSuaTKThue(form.getFieldValue(InvoiceAMColumnConstant.SUA_THUE));
                                tinhTongThanhToan();
                            }}>
                                Sửa
                            </Checkbox>
                        </Form.Item>

                        <Form.Item
                            name={InvoiceAMColumnConstant.T_THUE_NT}
                            className="w-100"
                        >
                            <InputNumber formatter={Utils.numberFormatter} className='w-100 antd-input-number-text-align-right ' size="small" disabled={!suaTKThue}
                                onBlur={() => {
                                    tinhTongThanhToan();
                                }}
                            />
                        </Form.Item>
                    </div>

                    <Form.Item
                        name={InvoiceAMColumnConstant.T_TT_NT}
                        label={'Tổng TT'}
                        labelAlign='left'
                        labelCol={{ style: { minWidth: LABEL_WIDTH } }}
                        className="w-100"
                    >
                        <InputNumber className='w-100 antd-input-number-text-align-right ' formatter={Utils.numberFormatter} size="small" disabled={true} />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

export default AM91ModalSummary;
