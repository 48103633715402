import apiServices from '@app/api/api.services';
import { Pagination } from '@app/api/table.api';
import { PAGE_SIZE } from '@app/constants/page.constant';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'components/common/Table/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ARS82ListViewFilter } from './ARS82ListViewFilter';
import { ARS82Dto } from '@app/api/v6.api';
import { Button } from 'antd';
import { CodeQRModal } from '../modals/QRModal';

export interface IARS82PagingFilter {
    searchText: string;
    pageNumber: number;
    pageSize: number;
}

export const ARS82ListView: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [codeQR, setCodeQR] = useState<string | undefined>();
    const [aRS82s, setARS82s] = useState<ARS82Dto[]>([]);
    const [pagination, setPagination] = useState<Pagination>();
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<IARS82PagingFilter>({
        searchText: '',
        pageNumber: 1,
        pageSize: PAGE_SIZE,
    });

    const fetchData = useCallback(async () => {
        setLoading(true);

        const aRS82esPaging = await apiServices.v6Api.aRS82sGet(filter.searchText, undefined, filter.pageNumber, filter.pageSize);

        setARS82s(aRS82esPaging?.items || []);
        setPagination({
            current: aRS82esPaging?.pageNumber,
            pageSize: filter.pageSize,
            total: aRS82esPaging.totalCount
        })
        setLoading(false);
    }, [filter])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleShowQR = async (codeQR: any) => {
        setCodeQR(codeQR ? codeQR : undefined)
        setIsModalOpen(true);
    };

    const handleCloseModal = async (reload?: boolean) => {
        setCodeQR("");
        setIsModalOpen(false);
    };

    const columns = useMemo(() => {
        const columns: ColumnsType<ARS82Dto> = [
            {
                title: 'Mã CT',
                dataIndex: 'ma_ct',
                width: 30,
                align: 'center'
            },
            {
                title: 'Số CT',
                dataIndex: 'so_ct',
                width: 30
            },
            {
                title: 'stt_rec',
                dataIndex: 'stt_rec',
                width: 30
            },
            {
                title: 'stt_rec0',
                dataIndex: 'stt_rec0',
                width: 30
            },
            {
                title: 'Số lượng sản phẩm',
                dataIndex: 'sl_sp',
                width: 30
            },
            {
                title: 'QR code',
                dataIndex: 'qr_code0',
                width: 30,
                align: 'center',
                render: (qrCode: string) => {
                    return (
                        <Button type="link" block
                            title='Mã QR'
                            style={{ margin: "4px" }}
                            onClick={() => {
                                handleShowQR(qrCode)
                            }}>Mã QR
                        </Button>)
                }
            },
        ];
        return columns;
    }, [])

    function onPagingChange(pageNum: number, pageSize: number) {
        const params: IARS82PagingFilter = {
            ...filter,
            pageNumber: pageNum,
            pageSize: pageSize,
        };
        setFilter(params);
    }

    return (
        <div className='app-table-list'>
            <div className='app-table-list-header'>
                <h1 className='app-table-list-header-title'>
                    Danh sách ARS82
                </h1>
            </div>
            <ARS82ListViewFilter
                filter={filter}
                onFilter={(data: IARS82PagingFilter) => {
                    setFilter({
                        ...filter,
                        searchText: data.searchText as string,
                        pageNumber: 1
                    } as IARS82PagingFilter);
                }} />
            <Table
                columns={columns}
                dataSource={aRS82s}
                pagination={{
                    ...pagination,
                    onChange: onPagingChange
                }}
                loading={loading}
                bordered
            />
            <CodeQRModal
                visible={isModalOpen}
                codeQR={codeQR}
                onClose={(e) => handleCloseModal(e)}
            />
        </div>
    )
}