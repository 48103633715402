import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { CountryListView } from '../views/CountryListView';

const CountryListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách quốc gia</PageTitle>
            <CountryListView />
        </>
    );
};

export default CountryListPage;
