import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { FeeListView } from '../views/FeeListView';

const FeeListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách khoản phí</PageTitle>
            <FeeListView />
        </>
    );
};

export default FeeListPage;
