import apiServices from '@app/api/api.services';
import { Pagination } from '@app/api/table.api';
import { CatalogSPParams, Tax30Dto } from '@app/api/v6.api';
import { PAGE_SIZE } from '@app/constants/page.constant';
import { commonFunc } from '@app/shared/functions/common.function';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'components/common/Table/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Thue30ListViewFilter } from './Tax30ListViewFilter';
import { Button } from 'antd';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Thue30Modal } from '../modals/Tax30Modal';

export const Thue30ListView: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [codeEdit, setCodeEdit] = useState<string | undefined>();
    const [chosenMode, setChosenMode] = useState<"add" | "edit" | "detail">("add");
    const [tax30s, setTax30s] = useState<Tax30Dto[]>([]);
    const [pagination, setPagination] = useState<Pagination>();
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<CatalogSPParams>({
        vvar: 'MA_THUE30',
        pageNumber: 1,
        pageSize: PAGE_SIZE
    } as CatalogSPParams)

    const fetchData = useCallback(async () => {
        setLoading(true);
        const tax30sPaging = await apiServices.v6Api.catalogsGetCatalogDynamicPaging(filter);

        setTax30s(tax30sPaging?.items || []);
        setPagination({
            current: tax30sPaging?.pageNumber,
            pageSize: filter.pageSize,
            total: tax30sPaging.totalCount
        })
        setLoading(false);
    }, [filter])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleCloseModal = async (reload?: boolean) => {
        setCodeEdit("");
        setIsModalOpen(false);
        if (reload) {
            const updatedFilter = {
                vvar: 'MA_THUE30',
                pageNumber: 1,
                pageSize: PAGE_SIZE
            } as CatalogSPParams;
            setFilter(updatedFilter);
        }
    };

    const handleEdit = async (code?: string | null) => {
        setCodeEdit(code ? code : undefined)
        setChosenMode("edit");
        setIsModalOpen(true);
    };

    const handleDetail = async (code?: string | null) => {
        setCodeEdit(code ? code : undefined)
        setChosenMode("detail");
        setIsModalOpen(true);
    };

    const columns = useMemo(() => {
        const columns: ColumnsType<Tax30Dto> = [
            {
                title: 'STT',
                dataIndex: 'rownum',
                width: 30,
                align: 'center'
            },
            {
                title: 'Mã thuế',
                dataIndex: 'ma_thue',
                width: 50
            },
            {
                title: 'Tên thuế',
                dataIndex: 'ten_thue',
                width: 150
            },
            {
                title: 'Tên thuế 2',
                dataIndex: 'ten_thue2',
                width: 130
            },
            {
                title: 'Thuế suất',
                dataIndex: 'thue_suat',
                width: 70
            },
            {
                title: 'Tài khoản thuế có',
                dataIndex: 'tk_thue_co',
                width: 100
            },
            {
                title: 'Tài khoản thuế nợ',
                dataIndex: 'tk_thue_no',
                width: 100
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'date0',
                width: 50,
                render: (date0: Date | null | undefined) => {
                    if (date0) {
                        return moment(date0).format('DD/MM/YYYY');
                    }
                    return "";
                }
            },
            {
                title: 'Thời gian tạo mới',
                dataIndex: 'time0',
                width: 70
            },
            {
                title: 'Người tạo mới',
                dataIndex: 'user_id0',
                width: 60
            },
            {
                title: 'Trạng thái',
                dataIndex: "status",
                width: 80,
                render: (status: any) => commonFunc.statusRender(status as string),
            },
            {
                title: 'Ngày sửa sau cùng',
                dataIndex: 'date2',
                width: 60,
                render: (date2: Date | null | undefined) => {
                    if (date2) {
                        return moment(date2).format('DD/MM/YYYY');
                    }
                    return "";
                }
            },
            {
                title: 'Thời gian sửa',
                dataIndex: 'time2',
                width: 60
            },
            {
                title: 'Người sửa',
                dataIndex: 'user_id2',
                width: 60
            },
            {
                title: 'POST_GT',
                dataIndex: 'post_gt',
                width: 50
            },
            {
                title: 'Hành động',
                fixed: 'right',
                align: 'center',
                dataIndex: 'ma_thue',
                width: 50,
                render: (maThue: string) => {
                    return (
                        <>
                            <Button type="primary"
                                shape="circle"
                                icon={<EditOutlined />}
                                title='Chỉnh sửa'
                                style={{ marginRight: 3 }}
                                onClick={() => {
                                    handleEdit(maThue)
                                }}>
                            </Button>
                            <Button type="primary"
                                shape="circle"
                                icon={<InfoCircleOutlined />}
                                title='Chi tiết'
                                style={{ marginRight: 3 }}
                                onClick={() => {
                                    handleDetail(maThue)
                                }}>
                            </Button>

                        </>
                    );
                },
            },
        ];

        return columns;
    }, [])

    function onPagingChange(pageNum: number, pageSize: number) {
        const params: CatalogSPParams = {
            ...filter,
            pageNumber: pageNum,
            pageSize: pageSize,
        } as CatalogSPParams;
        setFilter(params);
    }

    return (
        <div className='app-table-list'>
            <div className='app-table-list-header'>
                <h1 className='app-table-list-header-title'>
                    Mua hàng - Danh sách thuế suất
                </h1>
                <div className='app-table-list-header-actions'>
                    <Button type="primary" onClick={() => { setIsModalOpen(true); setCodeEdit(undefined) }}>
                        Thêm mới
                    </Button>

                </div>
            </div>
            <Thue30ListViewFilter
                filter={filter}
                onFilter={(data: CatalogSPParams) => {
                    setFilter({
                        ...filter,
                        vvalue: data.vvalue as string,
                        pageNumber: 1
                    } as CatalogSPParams);
                }} />
            <Table
                columns={columns}
                dataSource={tax30s}
                scroll={{ x: 2400 }}
                pagination={{
                    ...pagination,
                    onChange: onPagingChange
                }}
                loading={loading}
                bordered
            />
            <Thue30Modal
                visible={isModalOpen}
                mode={chosenMode}
                code={codeEdit}
                onClose={(e) => handleCloseModal(e)}
            />
        </div>
    )
}