import { TaxDto } from '@app/api/v6.api';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Utils } from '@app/shared/helpers/utils';
import { Checkbox, InputNumber, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import Form, { Rule } from 'antd/lib/form';
import React, { useCallback, useMemo, } from 'react';

interface ITaxEdit {
    code?: string;
    form: FormInstance<any>;
    dataTab: ITab | undefined
}

interface ITab {
    dataEdit?: TaxDto;
    mode: "add" | "edit" | "detail";
}

const TaxMainInfomation: React.FC<ITaxEdit> = ({ code, form, dataTab }: ITaxEdit) => {

    const validateTextCharater = (maxLen: number) => (_: any, value: string) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 13, name: 'taxCode', label: 'Mã thuế', disabled: code ? true : false,
                placeholder: "Nhập mã thuế", width: 200,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập mã thuế" },
                { validator: validateTextCharater(8) }] as Rule[]
            },
            {
                colSpan: 11, name: 'postGT', label: 'Post GT', disabled: dataTab?.mode === "detail", width: 100,
                rules: [{ required: true, message: "Vui lòng nhập Post GT" }],
                render: (
                    <InputNumber disabled={dataTab?.mode === "detail"} max={9} min={0} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập post GT' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 24, name: 'taxName', label: 'Tên thuế', disabled: dataTab?.mode === "detail",
                placeholder: "Nhập tên thuế", width: 200,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập tên thuế" },
                { validator: validateTextCharater(48) }] as Rule[]
            },
            {
                colSpan: 24, name: 'taxName2', label: 'Tên thuế 2', disabled: dataTab?.mode === "detail",
                placeholder: "Nhập tên thuế 2", width: 200,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(48) }] as Rule[]
            },
            {
                colSpan: 13, name: 'taxRate', label: 'Thuế suất', disabled: dataTab?.mode === "detail",
                placeholder: "Nhập thuế suất", width: 200,
                render: (
                    <InputNumber disabled={dataTab?.mode === "detail"} max={9999999} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập thuế suất' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 11, name: 'sttIn', label: 'Số TT In', disabled: dataTab?.mode === "detail",
                placeholder: "Nhập số TT In", width: 100,
                render: (
                    <InputNumber disabled={dataTab?.mode === "detail"} max={99999} min={1} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập số stt in' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 13, name: 'taxAccountCredit', label: 'Tài khoản GTGT đầu ra', disabled: dataTab?.mode === "detail",
                placeholder: "Nhập tài khoản GTGT đầu ra", width: 200,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(16) }] as Rule[]
            },
            {
                colSpan: 11, name: 'maNhom', label: 'Nhóm thuế', disabled: dataTab?.mode === "detail",
                placeholder: "Nhập nhóm thuế", width: 100,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(16) }] as Rule[]
            },
            {
                colSpan: 13, name: 'taxAccountDebit', label: 'Tk GTGT đầu ra được giảm', disabled: dataTab?.mode === "detail",
                placeholder: "Nhập Tk GTGT đầu ra được giảm", width: 200,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(16) }] as Rule[]
            }
        ]
    }, [dataTab?.mode])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={24}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, []);

    return (
        <>
            {renderColumns(session1Cols)}

            <Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 200 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={dataTab?.mode === "detail"}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item>
        </>
    );
};

export default TaxMainInfomation;
