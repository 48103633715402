import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { UnitConversionListView } from '../views/MaterialUnitConversionListView';

const UnitConversionListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách quy đổi đơn vị tính</PageTitle>
            <UnitConversionListView />
        </>
    );
};

export default UnitConversionListPage;
