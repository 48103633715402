import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { EmployeeListView } from '../views/EmployeeListView';

const EmployeeListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách nhân viên</PageTitle>
            <EmployeeListView />
        </>
    );
};

export default EmployeeListPage;
