import { InvoiceAMColumnConstant } from "@app/pages/Invoices/shared/constants/invoice.constant";
import { Form, InputNumber } from "antd";

const AM91FormHiddenItem: React.FC = () => {
    return (
        <div hidden>
            <Form.Item name={InvoiceAMColumnConstant.T_CK_NT} initialValue={0}>
                <InputNumber />
            </Form.Item>
            <Form.Item name={InvoiceAMColumnConstant.T_TT_NT} initialValue={0}>
                <InputNumber />
            </Form.Item>
            <Form.Item name={InvoiceAMColumnConstant.T_TIEN2} initialValue={0}>
                <InputNumber />
            </Form.Item>
            <Form.Item name={InvoiceAMColumnConstant.T_CK} initialValue={0}>
                <InputNumber />
            </Form.Item>
            <Form.Item name={InvoiceAMColumnConstant.T_TT} initialValue={0}>
                <InputNumber />
            </Form.Item>
        </div>
    )
}

export default AM91FormHiddenItem;