import apiServices from '@app/api/api.services';
import { Pagination } from '@app/api/table.api';
import { DisableIPCommandRequest, IPBlockDto, IPBlockType } from '@app/api/v6.api';
import { PAGE_SIZE } from '@app/constants/page.constant';
import { notificationController } from '@app/controllers/notificationController';
import { Button, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'components/common/Table/Table';
import { useCallback, useEffect, useState } from 'react';
import { IPBlockListViewFilter } from './IPBlockListViewFilter';
import { IPBlockModal } from '../modals/IPBlockModal';

export interface IIPBlockPagingFilter {
    searchText: string;
    pageNumber: number;
    pageSize: number;
    type: IPBlockType;
}

interface IIPBlockListView {
    type: IPBlockType
}

export const IPBlockListView: React.FC<IIPBlockListView> = ({
    type
}: IIPBlockListView) => {
    const [IPBlocks, setIPBlocks] = useState<IPBlockDto[]>([]);
    const [pagination, setPagination] = useState<Pagination>();
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<IIPBlockPagingFilter>({
        searchText: '',
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        type: type
    })
    const [ipBlockModal, setIPBlockModal] = useState<boolean>(false);

    const fetchData = useCallback(async () => {
        setLoading(true);
        const dataPaging = await apiServices.v6Api.iPBlocksGet(filter.searchText, filter.type, filter.pageNumber, filter.pageSize);

        setIPBlocks(dataPaging?.items || []);
        setPagination({
            current: dataPaging?.pageNumber,
            pageSize: filter.pageSize,
            total: dataPaging.totalCount
        })
        setLoading(false);
    }, [filter])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleDisabled = async (record: IPBlockDto) => {
        const res = await apiServices.v6Api.iPBlocksDisableIP({
            id: record.id,
            disabled: !record.disabled
        } as DisableIPCommandRequest);

        if (res) {
            fetchData();
            notificationController.success({ message: 'Thành công' })
        }
    };

    const columns: ColumnsType<IPBlockDto> = [
        {
            title: 'Thiết bị',
            dataIndex: 'deviceAddress',
            align: 'center'
        },
        {
            title: 'Trạng thái',
            render: (record: IPBlockDto) => {
                return (
                    <Tag color={record.disabled ? 'volcano' : 'green'}>
                        {record.disabled ? 'Đã khóa' : 'Chưa khóa'}
                    </Tag>
                )
            }
        },
        {
            title: 'Hành động',
            fixed: 'right',
            align: 'center',
            width: 150,
            render: (record: IPBlockDto) => {
                if (record.disabled) {
                    return (
                        <Button onClick={() => {
                            handleDisabled(record)
                        }}>Mở khóa</Button>
                    )
                }

                return (
                    <Button onClick={() => {
                        handleDisabled(record)
                    }}>Khóa</Button>
                )
            },
        },
    ];

    function onPagingChange(pageNum: number, pageSize: number) {
        const params: IIPBlockPagingFilter = {
            ...filter,
            pageNumber: pageNum,
            pageSize: pageSize,
        };
        setFilter(params);
    }

    const handleCloseModal = async (reload?: boolean) => {
        setIPBlockModal(false);
        if (reload) {
            setFilter({
                ...filter,
                pageNumber: 1
            });
        }
    };

    return (
        <div className='app-table-list'>
            <div className='app-table-list-header'>
                <h1 className='app-table-list-header-title'>
                    {
                        type === IPBlockType.DeviceCode
                            ? 'Danh sách thiết bị'
                            : 'Danh sách IP'
                    }
                </h1>

                <div className='app-table-list-header-actions'>
                    <Button type="primary" onClick={() => { setIPBlockModal(true); }}>
                        Thêm mới
                    </Button>
                </div>
            </div>

            <IPBlockListViewFilter
                filter={filter}
                onFilter={(data) => {
                    setFilter({
                        ...filter,
                        searchText: data.searchText,
                        pageNumber: 1
                    });
                }} />

            <Table
                columns={columns}
                dataSource={IPBlocks}
                scroll={{ x: 1700, scrollToFirstRowOnChange: true }}
                loading={loading}
                pagination={{
                    ...pagination,
                    onChange: onPagingChange
                }}
                bordered
            />

            {
                ipBlockModal && (
                    <IPBlockModal
                        type={type}
                        visible={ipBlockModal}
                        mode={'add'}
                        onClose={(e) => handleCloseModal(e)}
                    />
                )
            }
        </div>
    )
}