import apiServices from '@app/api/api.services';
import { CatalogDiscountUpdateDto } from '@app/api/v6.api';
import { Modal } from '@app/components/common/Modal/Modal';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { notificationController } from '@app/controllers/notificationController';
import { antFormFinishErrorNotification } from '@app/shared/functions/common.function';
import { Form, Row, Tabs, TabsProps } from 'antd';
import { Rule } from 'antd/lib/form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import './CatalogDiscountModal.less'
import CatalogDiscountKeyInfomation from './tabs/CatalogDiscountKeyInfomation';

interface IModalCatalogDiscount {
    code?: string;
    visible: boolean;
    mode: "add" | "edit" | "detail";
    onClose: (reload?: boolean) => void;
}

export const CatalogDiscountModal: React.FC<IModalCatalogDiscount> = ({
    code, visible, mode,
    onClose,
}: IModalCatalogDiscount) => {
    const [form] = BaseForm.useForm<CatalogDiscountUpdateDto>();
    const [currentMode, setCurrentMode] = useState(mode);
    const [loading, setLoading] = useState<boolean>(false);

    const isAdd = useMemo(() => { return currentMode === 'add' }, [currentMode]);
    const isDetail = useMemo(() => { return currentMode === 'detail' }, [currentMode]);

    const titleModal = useMemo(() => {
        if (isAdd) {
            return 'Thêm mới chiết khấu';
        }

        const prefixTitle = isDetail ? "Chi tiết" : "Chỉnh sửa";
        return `${prefixTitle} ${code}`;
    }, [currentMode, code]);

    const handleCancel = async () => {
        onClose();
    };

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Thông tin chính',
            children: <CatalogDiscountKeyInfomation
                mode={currentMode}
            />,
        },
    ];

    const validateTextCharater = (maxLen: number) => (_: any, value: string) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const fetchData = useCallback(async () => {
        if (isAdd) {
            return;
        }

        const catalogDiscount = await apiServices.v6Api.catalogDiscountsGetById(code as string);
        if (catalogDiscount) {
            form.setFieldsValue({
                ...catalogDiscount,
                status: (catalogDiscount.status === '1') as any,
                tien_yn: (catalogDiscount.tien_yn === '1') as any,
                tienh_yn: (catalogDiscount.tienh_yn === '1') as any,
                cong_yn: (catalogDiscount.cong_yn === '1') as any,
                con_lai_yn: (catalogDiscount.con_lai_yn === '1') as any,

            })
        }
    }, [code, currentMode])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleDisable = (() => {
        setCurrentMode("edit")
    });

    const mainInforCols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'ma_ck', label: 'Mã chiết khấu', disabled: code ? true : false,
                placeholder: "Nhập mã chiết khấu",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập mã chiết khấu" },
                { validator: validateTextCharater(8) }] as Rule[]
            },
            {
                colSpan: 12, name: 'ten_ck', label: 'Tên chiết khấu', width: 100,
                disabled: isDetail,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập tên chiết khấu" },
                { validator: validateTextCharater(48) }] as Rule[]
            },
            {
                colSpan: 12, name: 'ten_ck2', label: 'Tên 2',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(48) }],
                disabled: isDetail, placeholder: "Nhập tên 2"
            },
        ]
    }, [code, currentMode])

    const renderColumns = () => {
        return (
            <Row gutter={10}>
                {
                    mainInforCols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }

    const onFinish = async () => {
        if (isDetail) {
            handleDisable();
            return;
        }
        const data = await form.validateFields();
        data.status = data.status ? '1' : '0';
        data.tien_yn = data.tien_yn ? '1' : '0';
        data.tienh_yn = data.tienh_yn ? '1' : '0';
        data.cong_yn = data.cong_yn ? '1' : '0';
        data.con_lai_yn = data.con_lai_yn ? '1' : '0';

        setLoading(true);
        const promise = code
            ? apiServices.v6Api.catalogDiscountsUpdateCatalogDiscount(code, data)
            : apiServices.v6Api.catalogDiscountsCreateCatalogDiscount(data);

        promise.then((response) => {
            if (response.isSuccess) {
                notificationController.success({ message: 'Thành công' });
                onClose(true);
                return;
            }

            notificationController.error({ message: response.message });
        }).catch(() => {
            notificationController.error({ message: 'Thất bại' });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal
            title={titleModal}
            confirmLoading={loading}
            open={visible}
            destroyOnClose={true}
            width={"1000px"}
            onOk={() => form.submit()}
            onCancel={() => handleCancel()}
            okText={isDetail ? "Chỉnh sửa" : "Lưu"}
            cancelText="Hủy"
            okButtonProps={{ style: { padding: "5px", height: "36px" } }}
            cancelButtonProps={{ style: { padding: "5px", height: "36px" } }}
        >
            <div className='basic-form'>
                <Form
                    form={form}
                    style={{ width: "95%" }}
                    name="basic"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onFinish={onFinish}
                    onFinishFailed={antFormFinishErrorNotification}
                >
                    {renderColumns()}
                    <Tabs defaultActiveKey="1" items={items} />

                </Form>
            </div>
        </Modal>
    )
}