import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { AM91ListView } from '../views/am-91-list-view';

const AM91ListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách đơn bán hàng</PageTitle>
            <AM91ListView />
        </>
    );
};

export default AM91ListPage;
