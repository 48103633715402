import { KeyOutlined } from '@ant-design/icons';
import apiServices from '@app/api/api.services';
import { Pagination } from '@app/api/table.api';
import { UserDto } from '@app/api/v6.api';
import { UserPasswordChangeModal } from '@app/components/apps/user-password-change-modal/user-password-change-modal.component';
import { PAGE_SIZE } from '@app/constants/page.constant';
import { Button, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'components/common/Table/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface IUserPagingFilter {
  searchText: string;
  pageNumber: number;
  pageSize: number;
}

export const UserListView: React.FC = () => {
  const [users, setUsers] = useState<UserDto[]>([]);
  const [pagination, setPagination] = useState<Pagination>();
  const [loading, setLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<IUserPagingFilter>({
    searchText: '',
    pageNumber: 1,
    pageSize: PAGE_SIZE,
  });

  const [userPasswordChangeModal, setUserPasswordChangeModal] = useState<{
    visible: boolean;
    userName?: string;
  }>({
    visible: false,
  });

  const fetchData = useCallback(async () => {
    setLoading(true);
    const usersPaging = await apiServices.v6Api.usersGet(filter.searchText, filter.pageNumber, filter.pageSize);
    setUsers(usersPaging?.items || []);
    setPagination({
      current: usersPaging?.pageNumber,
      pageSize: filter.pageSize,
      total: usersPaging.totalCount,
    });
    setLoading(false);
  }, [filter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = useMemo(() => {
    const columns: ColumnsType<UserDto> = [
      {
        title: 'UserName',
        dataIndex: 'userName',
        width: 100,
      },
      {
        title: 'Admin',
        width: 100,
        render: (user: UserDto) => {
          return <Tag color={user.isAdmin ? 'green' : 'volcano'}>{user.isAdmin ? 'Admin' : 'Thành viên'}</Tag>;
        },
      },
      {
        title: 'V Admin',
        width: 100,
        render: (user: UserDto) => {
          return <Tag color={user.isVAdmin ? 'green' : 'volcano'}>{user.isVAdmin ? 'V Admin' : 'Thành viên'}</Tag>;
        },
      },
      {
        title: 'Hành động',
        fixed: 'right',
        align: 'center',
        width: 35,
        render: (user: UserDto) => {
          return (
            <Button
              type="primary"
              danger
              shape="circle"
              icon={<KeyOutlined />}
              title="Thay đổi mật khẩu"
              onClick={() => {
                setUserPasswordChangeModal({
                  visible: true,
                  userName: user.userName as string,
                });
              }}
            ></Button>
          );
        },
      },
    ];

    return columns;
  }, []);

  function onPagingChange(pageNum: number, pageSize: number) {
    const params: IUserPagingFilter = {
      ...filter,
      pageNumber: pageNum,
      pageSize: pageSize,
    };
    setFilter(params);
  }

  return (
    <div className="app-table-list">
      <div className="app-table-list-header">
        <h1 className="app-table-list-header-title">Danh sách Users</h1>
      </div>

      <Table
        columns={columns}
        dataSource={users}
        pagination={{
          ...pagination,
          onChange: onPagingChange,
        }}
        loading={loading}
        bordered
      />

      {userPasswordChangeModal.visible && (
        <UserPasswordChangeModal
          visible={userPasswordChangeModal.visible}
          userName={userPasswordChangeModal.userName}
          onClose={() => setUserPasswordChangeModal({ visible: false })}
        />
      )}
    </div>
  );
};
