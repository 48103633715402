import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { CustomerGroupListView } from '../views/CustomerGroupListView';

const CustomerGroupListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách nhóm khách hàng</PageTitle>
            <CustomerGroupListView />
        </>
    );
};

export default CustomerGroupListPage;
