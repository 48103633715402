import React, { useMemo } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useParams } from 'react-router-dom';
import CustomerAddOrUpdateView from '@app/pages/customers/customer-views/CustomerAddOrUpdateView';

const CustomerAddOrUpdatePage: React.FC = () => {
    const { code } = useParams<{ code: string }>();

    const title = useMemo(() => {
        return code
            ? `Chỉnh sửa khách hàng ${code}`
            : 'Tạo khách hàng'
    }, [code])

    return (
        <>
            <PageTitle>{title}</PageTitle>
            <CustomerAddOrUpdateView code={code?.trim() as string} />
        </>
    );
};

export default CustomerAddOrUpdatePage;
