import React from 'react';

const BaseUnitAdvanceInfomation: React.FC = () => {

    return (
        <>
            <h1>Null</h1>
        </>
    );
};

export default BaseUnitAdvanceInfomation;
