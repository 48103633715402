import apiServices from '@app/api/api.services';
import { Pagination } from '@app/api/table.api';
import { Account0Dto, CatalogSPParams } from '@app/api/v6.api';
import { PAGE_SIZE } from '@app/constants/page.constant';
import { commonFunc } from '@app/shared/functions/common.function';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'components/common/Table/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AccountListViewFilter } from './AccountListViewFilter';
import { Button, Tooltip } from 'antd';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { AccountModal } from '../modals/AccountModal';
import moment from 'moment';

export const AccountListView: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [chosenMode, setChosenMode] = useState<"add" | "edit" | "detail">("add");
    const [codeEdit, setCodeEdit] = useState<string | undefined>();
    const [accounts, setAccounts] = useState<Account0Dto[]>([]);
    const [pagination, setPagination] = useState<Pagination>();
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<CatalogSPParams>({
        vvar: 'TK0',
        pageNumber: 1,
        pageSize: PAGE_SIZE
    } as CatalogSPParams)

    const fetchData = useCallback(async () => {
        setLoading(true);

        const accountsPaging = await apiServices.v6Api.catalogsGetCatalogDynamicPaging(filter);

        setAccounts(accountsPaging?.items || []);
        setPagination({
            current: accountsPaging?.pageNumber,
            pageSize: filter.pageSize,
            total: accountsPaging.totalCount
        })
        setLoading(false);
    }, [filter])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleEdit = async (code: any) => {
        setCodeEdit(code ? code : undefined)
        setChosenMode("edit");
        setIsModalOpen(true);
    };

    const handleDetail = async (code: any) => {
        setCodeEdit(code ? code : undefined)
        setChosenMode("detail");
        setIsModalOpen(true);
    };

    const handleCloseModal = async (reload?: boolean) => {
        setCodeEdit("");
        setIsModalOpen(false);
        if (reload) {
            const updatedFilter = {
                vvar: 'TK0',
                pageNumber: 1,
                pageSize: PAGE_SIZE
            } as CatalogSPParams;
            setFilter(updatedFilter);
        }
    };

    const columns = useMemo(() => {
        const columns: ColumnsType<Account0Dto> = [
            {
                title: 'STT',
                dataIndex: 'rownum',
                width: 30,
                align: 'center'
            },
            {
                title: 'Mã tài khoản',
                dataIndex: 'tk',
                width: 80
            },
            {
                title: 'Tên tài khoản',
                width: 100,
                dataIndex: 'ten_tk',
                render: (tenTK: string) => (
                    <Tooltip
                        placement="topLeft"
                        title={tenTK}
                    >
                        <div className="textOverflowTwoLines">
                            {tenTK}
                        </div>
                    </Tooltip>
                ),
            },
            {
                title: 'Tên tài khoản 2',
                dataIndex: 'ten_tk2',
                width: 100
            },
            {
                title: 'Mã ngoại tệ',
                dataIndex: 'ma_nt',
                width: 60
            },
            {
                title: 'Loại TK',
                dataIndex: 'loai_tk',
                width: 60
            },
            {
                title: 'Tài khoản mẹ',
                dataIndex: 'tk_me',
                width: 60
            },
            {
                title: 'Bậc',
                dataIndex: 'bac_tk',
                width: 60
            },
            {
                title: 'Tài khoản sổ cái',
                dataIndex: 'tk_sc',
                width: 60
            },
            {
                title: 'Tài khoản công nợ',
                dataIndex: 'tk_cn',
                width: 60
            },
            {
                title: 'Nhóm TK 0',
                dataIndex: 'nh_tk0',
                width: 60
            },
            {
                title: 'Nhóm TK2',
                dataIndex: 'nh_tk2',
                width: 60
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'date0',
                width: 50,
                render: (date0: Date | null | undefined) => {
                    if (date0) {
                        return moment(date0).format('DD/MM/YYYY');
                    }
                    return "";
                }
            },
            {
                title: 'Thời gian tạo mới',
                dataIndex: 'time0',
                width: 70
            },
            {
                title: 'Người tạo mới',
                dataIndex: 'user_id0',
                width: 45
            },
            {
                title: 'Trạng thái',
                width: 80,
                dataIndex: 'status',
                render: (status: any) => commonFunc.statusRender(status as string)
            },
            {
                title: 'Ngày sửa sau cùng',
                dataIndex: 'date2',
                width: 60,
                render: (date2: Date | null | undefined) => {
                    if (date2) {
                        return moment(date2).format('DD/MM/YYYY');
                    }
                    return "";
                }
            },
            {
                title: 'Thời gian sửa',
                dataIndex: 'time2',
                width: 50
            },
            {
                title: 'Người sửa',
                dataIndex: 'user_id2',
                width: 40
            },
            {
                title: 'Loại chênh loại có',
                dataIndex: 'loai_cl_co',
                width: 50
            },
            {
                title: 'Loại chênh lệch nợ',
                dataIndex: 'loai_cl_no',
                width: 40
            },
            {
                title: 'Hành động',
                fixed: 'right',
                dataIndex: 'tk',
                align: 'center',
                width: 60,
                render: (maTk: string) => {
                    return (
                        <>
                            <Button type="primary"
                                shape="circle"
                                icon={<EditOutlined />}
                                title='Chỉnh sửa'
                                style={{ marginRight: 3 }}
                                onClick={() => {
                                    handleEdit(maTk)
                                }}>
                            </Button>
                            <Button type="primary"
                                shape="circle"
                                icon={<InfoCircleOutlined />}
                                title='Chi tiết'
                                style={{ marginRight: 3 }}
                                onClick={() => {
                                    handleDetail(maTk)
                                }}>
                            </Button>

                        </>
                    );
                },
            },
        ];
        return columns;
    }, [])

    function onPagingChange(pageNum: number, pageSize: number) {
        const params: CatalogSPParams = {
            ...filter,
            pageNumber: pageNum,
            pageSize: pageSize,
        } as CatalogSPParams;
        setFilter(params);
    }

    return (
        <div className='app-table-list'>
            <div className='app-table-list-header'>
                <h1 className='app-table-list-header-title'>
                    Danh sách tài khoản
                </h1>
                <div className='app-table-list-header-actions'>
                    <Button type="primary" onClick={() => { setIsModalOpen(true); setCodeEdit(undefined) }}>
                        Thêm mới
                    </Button>

                </div>
            </div>
            <AccountListViewFilter
                filter={filter}
                onFilter={(data: CatalogSPParams) => {
                    setFilter({
                        ...filter,
                        vvalue: data.vvalue as string,
                        pageNumber: 1
                    } as CatalogSPParams);
                }} />
            <Table
                columns={columns}
                dataSource={accounts}
                scroll={{ x: 2300, scrollToFirstRowOnChange: true }}
                pagination={{
                    ...pagination,
                    onChange: onPagingChange
                }}
                loading={loading}
                bordered
            />
            <AccountModal
                visible={isModalOpen}
                mode={chosenMode}
                code={codeEdit}
                onClose={(e) => handleCloseModal(e)}
            />
        </div>
    )
}