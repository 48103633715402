import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { BankContractGroupListView } from '../views/BankContractGroupListView';

const BankContractGroupListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách nhóm khế ước</PageTitle>
            <BankContractGroupListView />
        </>
    );
};

export default BankContractGroupListPage;
