export enum InvoiceADColumnFtype {
    Catalog = 'C0',
    N0 = 'N0', // Tiền
    N1 = 'N1', // Tiền NT
    N2 = 'N2', // Số lượng
    N3 = 'N3', // GIA
    N4 = 'N4', // GIA NT
}

export const supportedColumnFType: string[] = [
    InvoiceADColumnFtype.Catalog,
    InvoiceADColumnFtype.N0,
    InvoiceADColumnFtype.N1,
    InvoiceADColumnFtype.N2,
    InvoiceADColumnFtype.N3,
    InvoiceADColumnFtype.N4,
]

export const IS_AD_NUMBER_COLUMN = (ftype: string): boolean => {
    const types = [
        InvoiceADColumnFtype.N0,
        InvoiceADColumnFtype.N1,
        InvoiceADColumnFtype.N2,
        InvoiceADColumnFtype.N3,
        InvoiceADColumnFtype.N4
    ] as string[];

    return types.includes(ftype);
}