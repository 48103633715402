import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import apiServices from '@app/api/api.services';
import { Pagination } from '@app/api/table.api';
import { CatalogSPParams, CustomerGroupDto } from '@app/api/v6.api';
import { PAGE_SIZE } from '@app/constants/page.constant';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'components/common/Table/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CustomerGroupListViewFilter } from './CustomerGroupListViewFilter';
import { commonFunc } from '@app/shared/functions/common.function';
import { CustomerGroupModal } from '../modals/CustomerGroupModal';

export const CustomerGroupListView: React.FC = () => {
    const [customerGroupModal, setCustomerGroupModal] = useState<{
        visible: boolean;
        mode?: "add" | "edit" | "detail";
        code?: string
    }>({
        visible: false
    });
    const [customerGroups, setCustomerGroups] = useState<CustomerGroupDto[]>([]);
    const [pagination, setPagination] = useState<Pagination>();
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<CatalogSPParams>({
        vvar: 'NH_KH',
        pageNumber: 1,
        pageSize: PAGE_SIZE
    } as CatalogSPParams)

    const fetchData = useCallback(async () => {
        setLoading(true);
        const customerGroupsPaging = await apiServices.v6Api.catalogsGetCatalogDynamicPaging(filter);
        setCustomerGroups(customerGroupsPaging?.items || []);
        setPagination({
            current: customerGroupsPaging?.pageNumber,
            pageSize: filter.pageSize,
            total: customerGroupsPaging.totalCount
        })
        setLoading(false);
    }, [filter])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleEdit = async (code: string) => {
        setCustomerGroupModal({
            visible: true,
            mode: 'edit',
            code: code
        })
    };

    const handleDetail = async (code: string) => {
        setCustomerGroupModal({
            visible: true,
            mode: 'detail',
            code: code
        })
    };

    const handleCloseModal = async (reload?: boolean) => {
        setCustomerGroupModal({
            visible: false
        })

        if (reload) {
            const updatedFilter = {
                vvar: 'NH_KH',
                pageNumber: 1,
                pageSize: PAGE_SIZE
            } as CatalogSPParams;
            setFilter(updatedFilter);
        }
    };

    const columns = useMemo(() => {
        const columns: ColumnsType<CustomerGroupDto> = [
            {
                title: 'Mã nhóm KH',
                dataIndex: 'ma_nh',
                width: 70
            },
            {
                title: 'Tên nhóm KH',
                dataIndex: 'ten_nh',
                width: 130
            },
            {
                title: 'Loại nhóm',
                dataIndex: 'loai_nh',
                width: 50
            },
            {
                title: 'Trạng thái',
                dataIndex: "status",
                width: 80,
                render: (status: any) => commonFunc.statusRender(status as string),
            },
            {
                title: 'Hành động',
                fixed: 'right',
                align: 'center',
                dataIndex: "ma_nh",
                width: 35,
                render: (groupCode: string) => {
                    return (
                        <>
                            <Button type="primary"
                                shape="circle"
                                icon={<EditOutlined />}
                                title='Chỉnh sửa'
                                style={{ marginRight: 3 }}
                                onClick={() => {
                                    handleEdit(groupCode!)
                                }}>
                            </Button>
                            <Button type="primary"
                                shape="circle"
                                icon={<InfoCircleOutlined />}
                                title='Chi tiết'
                                style={{ marginRight: 3 }}
                                onClick={() => {
                                    handleDetail(groupCode!)
                                }}>
                            </Button>

                        </>
                    );
                },
            },
        ];

        return columns;
    }, [])

    function onPagingChange(pageNum: number, pageSize: number) {
        const params: CatalogSPParams = {
            ...filter,
            pageNumber: pageNum,
            pageSize: pageSize,
        } as CatalogSPParams;
        setFilter(params);
    }

    return (
        <div className='app-table-list'>
            <div className='app-table-list-header'>
                <h1 className='app-table-list-header-title'>
                    Danh sách nhóm khách hàng
                </h1>

                <div className='app-table-list-header-actions'>
                    <Button type="primary" onClick={() => {
                        setCustomerGroupModal({
                            visible: true,
                            mode: 'add'
                        })
                    }}>
                        Thêm mới
                    </Button>

                </div>
            </div>
            <CustomerGroupListViewFilter
                filter={filter}
                onFilter={(data: CatalogSPParams) => {
                    setFilter({
                        ...filter,
                        vvalue: data.vvalue as string,
                        pageNumber: 1
                    } as CatalogSPParams);
                }} />
            <Table
                columns={columns}
                dataSource={customerGroups}
                pagination={{
                    ...pagination,
                    onChange: onPagingChange
                }}
                loading={loading}
                bordered
            />

            {
                customerGroupModal && customerGroupModal.visible && (
                    <CustomerGroupModal
                        visible={customerGroupModal.visible}
                        mode={customerGroupModal.mode!}
                        code={customerGroupModal.code}
                        onClose={(e) => handleCloseModal(e)}
                    />
                )
            }
        </div>
    )
}