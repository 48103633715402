import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { LoginSelectBaseUnit } from './LoginSelectBaseUnit';
import LoginBaseUnitRequest from './LoginBaseUnitRequest';
import './LoginAnimation.css';

interface LoginFormData {
  userName: string;
  password: string;
  baseUnitCode?: any[];
}

interface LoginFormInitValues {
  userName: string;
  password: string;
  baseUnitCode: string;
}

export const initValues: LoginFormInitValues = {
  userName: '',
  password: '',
  baseUnitCode: ''
};

export const LoginForm: React.FC = () => {
  const { t } = useTranslation();

  enum LoginFormStep {
    BaseUnitRequest = 1,
    BaseUnitSelection = 2
  }

  const [formStep, setFormStep] = useState<LoginFormStep>(LoginFormStep.BaseUnitRequest);
  const [dataForm, setDataForm] = useState<LoginFormData>();

  const handleChangeStep = (baseUnits: any[] | undefined, value: any) => {
    setDataForm({
      userName: value.userName as string,
      password: value.password as string,
      baseUnitCode: baseUnits
    });
    setFormStep(LoginFormStep.BaseUnitSelection);
  }

  const handleCheckReturn = (value: boolean) => {
    if (value) {
      setFormStep(LoginFormStep.BaseUnitRequest);
    }
  }

  const renderFormLogin = useCallback((valueStep: any) => {
    return (
      <>
        <Auth.FormWrapper>
          <Auth.FormTitle>{t('Đăng nhập')}</Auth.FormTitle>
          {LoginFormStep.BaseUnitRequest === valueStep && (
            <LoginSelectBaseUnit
              userName={dataForm?.userName}
              password={dataForm?.password}
              onFishLoginSelectBaseUnit={handleChangeStep}
            />
          )}
          {LoginFormStep.BaseUnitSelection === valueStep && (
            <LoginBaseUnitRequest
              baseUnitsCode={dataForm?.baseUnitCode}
              userName={dataForm?.userName}
              password={dataForm?.password}
              onLoginReturn={handleCheckReturn}
            />
          )}
          <Auth.FooterWrapper>
            <Auth.Text>
              {t('Nếu bạn không có tài khoản? Tạo tài khoản')}{' '}
              <Link to="/auth/sign-up">
                <Auth.LinkText>{t('Ở đây')}</Auth.LinkText>
              </Link>
            </Auth.Text>
          </Auth.FooterWrapper>
        </Auth.FormWrapper>
      </>
    )
  }, [formStep])

  return (
    <>
      {LoginFormStep.BaseUnitRequest === formStep ?
        <div key={formStep} id='animated-example' className={`animated lightSpeedIn`}>
          {renderFormLogin(LoginFormStep.BaseUnitRequest)}
        </div>
        :
        <div key={formStep} id='animated-example' className={`animated lightSpeedIn`}>
          {renderFormLogin(LoginFormStep.BaseUnitSelection)}
        </div>
      }
    </>
  );
};
