import apiServices from '@app/api/api.services';
import { CustomerGroupDto, CustomerGroupUpdateDto } from '@app/api/v6.api';
import { Modal } from '@app/components/common/Modal/Modal';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { notificationController } from '@app/controllers/notificationController';
import { antFormFinishErrorNotification } from '@app/shared/functions/common.function';
import { Utils } from '@app/shared/helpers/utils';
import { Checkbox, Form, InputNumber, Row } from 'antd';
import { Rule } from 'antd/lib/form';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface IModalCustomerGroup {
    code?: string;
    visible: boolean;
    mode: "add" | "edit" | "detail";
    disableGroupType?: boolean;
    initialValues?: CustomerGroupUpdateDto;
    onClose: (reload?: boolean) => void;
    onDispatchResponse?: (data: CustomerGroupDto) => void;
}

export const CustomerGroupModal: React.FC<IModalCustomerGroup> = ({
    code, visible, mode,
    disableGroupType,
    initialValues,
    onClose,
    onDispatchResponse
}: IModalCustomerGroup) => {
    const [form] = BaseForm.useForm<CustomerGroupUpdateDto>();
    const [currentMode, setCurrentMode] = useState(mode);
    const [loading, setLoading] = useState<boolean>(false);

    const isAdd = useMemo(() => { return currentMode === 'add' }, [currentMode]);
    const isDetail = useMemo(() => { return currentMode === 'detail' }, [currentMode]);

    const titleModal = useMemo(() => {
        if (isAdd) {
            return 'Thêm mới nhóm khách hàng';
        }

        const prefixTitle = isDetail ? "Chi tiết" : "Chỉnh sửa";
        return `${prefixTitle} ${code}`;
    }, [currentMode, code]);

    const handleCancel = async () => {
        onClose();
    };

    const validateTextCharater = (maxLen: number) => (_: any, value: string) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const fetchData = useCallback(async () => {
        if (isAdd) {
            if (initialValues) {
                form.setFieldsValue(initialValues);
            }
            return;
        }

        const customerGroup = await apiServices.v6Api.customerGroupsGetById(code as string);
        if (customerGroup) {
            form.setFieldsValue({
                ...customerGroup,
                status: (customerGroup.status === '1') as any
            })
        }
    }, [code, currentMode])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleDisable = (() => {
        setCurrentMode("edit")
    });

    const mainInforCols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'groupCode', label: 'Mã nhóm KH', disabled: code ? true : false,
                placeholder: "Nhập mã nhóm KH",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập mã nhóm KH" },
                { validator: validateTextCharater(16) }] as Rule[]
            },
            {
                colSpan: 12, name: 'groupType', label: 'Loại nhóm',
                render: (
                    <InputNumber disabled={isDetail || disableGroupType} defaultValue={0} maxLength={1} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập loại nhóm' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 12, name: 'groupName', label: 'Tên nhóm KH',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập tên KH" }, { validator: validateTextCharater(128) }],
                disabled: isDetail, placeholder: "Nhập tên nhóm KH"
            },
            {
                colSpan: 12, name: 'groupName2', label: 'Tên nhóm KH 2',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(128) }],
                placeholder: "Nhập tên nhóm KH 2", disabled: isDetail
            },
        ]
    }, [code, currentMode])

    const renderColumns = () => {
        return (
            <Row gutter={24}>
                {
                    mainInforCols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }

    const onFinish = async () => {
        if (isDetail) {
            handleDisable();
            return;
        }
        const data = await form.validateFields();
        data.status = data.status ? '1' : '0';

        setLoading(true);
        const promise = code
            ? apiServices.v6Api.customerGroupsUpdateUser(code, data)
            : apiServices.v6Api.customerGroupsCreateUser(data);

        promise.then((response) => {
            if (response.isSuccess) {
                notificationController.success({ message: 'Thành công' });
                onClose(true);

                if (onDispatchResponse) {
                    onDispatchResponse(response.data as CustomerGroupDto);
                }
                return;
            }

            notificationController.error({ message: response.message });
        }).catch(() => {
            notificationController.error({ message: 'Thất bại' });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal
            title={titleModal}
            confirmLoading={loading}
            open={visible}
            destroyOnClose={true}
            width={"1000px"}
            onOk={() => form.submit()}
            onCancel={() => handleCancel()}
            okText={isDetail ? "Chỉnh sửa" : "Lưu"}
            cancelText="Hủy"
            okButtonProps={{ style: { padding: "5px", height: "36px" } }}
            cancelButtonProps={{ style: { padding: "5px", height: "36px" } }}
        >
            <div className='basic-form'>
                <Form
                    form={form}
                    style={{ width: "95%" }}
                    name="basic"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onFinish={onFinish}
                    onFinishFailed={antFormFinishErrorNotification}
                >
                    {renderColumns()}

                    <Form.Item
                        label="Trạng thái"
                        name="status"
                        labelCol={{ style: { width: 130 } }}
                        labelAlign='left'
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Checkbox
                            style={{
                                marginRight: "10px",
                            }}
                            disabled={isDetail}
                        >
                            Hoạt động?
                        </Checkbox>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    )
}