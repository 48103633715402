import { EditOutlined } from '@ant-design/icons';
import apiServices from '@app/api/api.services';
import { Pagination } from '@app/api/table.api';
import { CatalogSPParams, CustomerDto } from '@app/api/v6.api';
import { PAGE_SIZE } from '@app/constants/page.constant';
import { antFormColumnFilterStatus, commonFunc } from '@app/shared/functions/common.function';
import { Button, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'components/common/Table/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomerListViewFilter } from './CustomerListViewFilter';

export const CustomerListView: React.FC = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<CustomerDto[]>([]);
  const [pagination, setPagination] = useState<Pagination>();
  const [loading, setLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<CatalogSPParams>({
    vvar: 'MA_KH',
    pageNumber: 1,
    pageSize: PAGE_SIZE
  } as CatalogSPParams)

  const fetchData = useCallback(async () => {
    setLoading(true);
    const customersPaging = await apiServices.v6Api.catalogsGetCatalogDynamicPaging(filter);
    setUsers(customersPaging?.items || []);
    setPagination({
      current: customersPaging?.pageNumber,
      pageSize: filter.pageSize,
      total: customersPaging.totalCount
    })
    setLoading(false);
  }, [filter])

  useEffect(() => {
    fetchData();
  }, [fetchData])

  const columns = useMemo(() => {
    const columns: ColumnsType<CustomerDto> = [
      {
        title: 'Mã KH',
        dataIndex: 'ma_kh',
        width: 70
      },
      {
        title: 'Tên KH',
        dataIndex: 'ten_kh',
        width: 130,
        render: (ten_kh: string) => (
          <Tooltip
            placement="topLeft"
            title={ten_kh}
          >
            <div className="textOverflowTwoLines">
              {ten_kh}
            </div>
          </Tooltip>
        ),
      },
      {
        title: 'Mã đvcs',
        dataIndex: 'ma_dvcs',
        width: 50,
      },
      {
        title: 'Số điện thoại',
        dataIndex: 'dien_thoai',
        width: 80
      },
      {
        title: 'Mã số thuế',
        dataIndex: 'ma_so_thue',
        width: 50
      },
      {
        title: 'Địa chỉ',
        dataIndex: 'dia_chi',
        width: 100
      },
      {
        title: 'Trạng thái',
        dataIndex: 'status',
        width: 80,
        render: (status: string) => commonFunc.statusRender(status),
      },
      {
        title: 'Hành động',
        fixed: 'right',
        align: 'center',
        dataIndex: 'ma_kh',
        width: 35,
        render: (maKH: string) => {
          return (
            <>
              <Button type="primary"
                shape="circle"
                icon={<EditOutlined />}
                title='Chỉnh sửa'
                style={{ marginRight: 3 }}
                onClick={() => {
                  navigate(`${maKH}/edit`);
                }}></Button>
            </>
          );
        },
      },
    ];

    return columns;
  }, [filter, navigate])

  const handleCustomerAddOrUpDate = () => {
    navigate(`add`);
  }

  function onPagingChange(pageNum: number, pageSize: number) {
    const params: CatalogSPParams = {
      ...filter,
      pageNumber: pageNum,
      pageSize: pageSize,
    } as CatalogSPParams;
    setFilter(params);
  }

  return (
    <div className='app-table-list customer-list'>
      <div className='app-table-list-header'>
        <h1 className='app-table-list-header-title'>
          Danh sách khách hàng
        </h1>

        <div className='app-table-list-header-actions'>
          <Button type="primary" onClick={handleCustomerAddOrUpDate}>
            Thêm mới
          </Button>
        </div>
      </div>

      <CustomerListViewFilter
        filter={filter}
        onFilter={(data: CatalogSPParams) => {
          setFilter({
            ...filter,
            vvalue: data.vvalue,
            pageNumber: 1
          } as CatalogSPParams);
        }} />

      <Table
        columns={columns}
        dataSource={users}
        pagination={{
          ...pagination,
          onChange: onPagingChange
        }}
        loading={loading}
        bordered
        rowKey="customerCode"
      />
    </div>
  )
}