import { Modal } from '@app/components/common/Modal/Modal';
import { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, List } from 'antd';
import apiServices from '@app/api/api.services';
import { CatalogSPParams } from '@app/api/v6.api';

interface IModalVourcher {
    visible: boolean;
    dataCheckValue: string;
    onClose: () => void;
    onSubmit: (valueListVourcher?: string) => void;
}

export const VourcherModal: React.FC<IModalVourcher> = ({ onClose, visible, onSubmit, dataCheckValue }: IModalVourcher) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [vourchers, setVourchers] = useState<any[]>([]);
    const [checked, setChecked] = useState<any>([]);
    const [filter, setFilter] = useState<CatalogSPParams>({
        vvar: 'MA_CT',
        pageNumber: 1,
        pageSize: 99
    } as CatalogSPParams)

    const handleCancel = async () => {
        onClose();
        setChecked([]);
    };

    const handleSubmit = async () => {
        const dataReturn = checked.filter((value: any) => {
            return value !== undefined;
        });
        onSubmit(dataReturn.join(", "));
    };

    const fetchData = useCallback(async () => {
        setLoading(true);
        const vourchersPaging = await apiServices.v6Api.catalogsGetCatalogDynamicPaging(filter);
        setVourchers(vourchersPaging?.items || []);

        setLoading(false);
    }, [visible === true])

    const fetchDataCheck = useCallback(async () => {
        if (dataCheckValue) {
            const valueChecked = dataCheckValue.split(", ");
            setChecked(valueChecked);
        }
    }, [visible === true])

    useEffect(() => {
        fetchData();
        fetchDataCheck();
    }, [fetchData])

    const handleCheckAllChange = () => {
        setChecked(vourchers.map((item) => item.ma_ct));
    };

    const handleNoCheckAllChange = () => {
        setChecked([]);
    };

    return (
        <Modal
            title="Danh sách chứng từ"
            confirmLoading={loading}
            destroyOnClose={true}
            open={visible}
            width={"700px"}
            bodyStyle={{
                overflowY: 'scroll',
                height: 'calc(100vh - 300px)',
                display: "flex",
                flexDirection: "column"
            }}
            onCancel={() => handleCancel()}
            onOk={() => handleSubmit()}
            okText={"Lưu"}
            cancelText="Hủy"
            okButtonProps={{ style: { padding: "5px", height: "36px" } }}
            cancelButtonProps={{ style: { padding: "5px", height: "36px" } }}
        >
            <div className='basic-form'>
                <Checkbox.Group
                    style={{ width: "100%" }}
                    value={checked}
                    onChange={(checkedValues) => {
                        setChecked(checkedValues);
                    }}
                >
                    <List
                        itemLayout="horizontal"
                        dataSource={vourchers}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Checkbox value={item.ma_ct} >
                                        {item.ten_ct}
                                    </Checkbox>}
                                />
                            </List.Item>
                        )}
                    />
                </Checkbox.Group>
                <Button type='primary'
                    onClick={handleCheckAllChange}
                >
                    Chọn hết
                </Button>
                <Button type='primary'
                    onClick={handleNoCheckAllChange}
                    style={{ marginLeft: "10px" }}
                >
                    Bỏ chọn
                </Button>
            </div>
        </Modal>
    )
}