import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { ARS82ListView } from '../views/ARS82ListView';

const ARS82ListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách ARS82</PageTitle>
            <ARS82ListView />
        </>
    );
};

export default ARS82ListPage;
