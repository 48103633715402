import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { Thue30ListView } from '../views/Tax30ListView';

const Thue30ListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Mua hàng - Danh sách thuế suất</PageTitle>
            <Thue30ListView />
        </>
    );
};

export default Thue30ListPage;
