import apiServices from "@app/api/api.services";
import { UserPasswordUpdateDto } from "@app/api/v6.api";
import { notificationController } from "@app/controllers/notificationController";
import { Col, Form, Input, Modal, Row } from "antd";
import { useState } from "react";

interface UserPasswordChangeModalProps {
  visible: boolean;
  userName?: string;
  onClose: () => void;
}

interface IUserPasswordChangeModel {
  password: string;
  repeatPassword: string;
}

export const UserPasswordChangeModal: React.FC<UserPasswordChangeModalProps> = ({
  visible,
  userName,
  onClose
}: UserPasswordChangeModalProps) => {
  const [form] = Form.useForm<IUserPasswordChangeModel>();

  const [loading, setLoading] = useState(false);

  async function onFinish(data: IUserPasswordChangeModel) {
    setLoading(true);
    const res = await apiServices.v6Api.usersUpdateUserPassword({
      userName: userName,
      password: data.password
    } as UserPasswordUpdateDto);
    setLoading(false);

    if (res.isSuccess) {
      notificationController.success({
        message: 'Thành công'
      });
      onClose()
    } else {
      notificationController.error({
        message: res.message
      })
    }
  }

  return (
    <Modal
      className="v-scroll-modal"
      title={`Thay đổi mật khẩu ${userName}`}
      visible={visible}
      onCancel={onClose}
      onOk={() => form.submit()}
      cancelText={'Hủy'}
      okText={'Lưu'}
      confirmLoading={loading}
      style={{ top: 32 }}
      width={400}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="password"
              label="Mật khẩu"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập mật khẩu',
                },
              ]}
            >
              <Input placeholder={'Nhập mật khẩu'} type="password" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="repeatPassword"
              label="Xác nhận mật khẩu"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập mật khẩu',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Xác nhận mật khẩu chưa giống mật khẩu!');
                  },
                })
              ]}
            >
              <Input placeholder={'Nhập mật khẩu'} type="password" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
};
