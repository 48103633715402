import { InputSearchApiService } from '@app/api/input-search-api.service';
import { BaseUnitDto } from '@app/api/v6.api';
import InputSearchLookup from '@app/components/common/input-search-lookup/InputSearchLookup';
import { InputSearchLookupColumns } from '@app/components/common/input-search-lookup/constants/input-lookup-entity-columns.constant';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { CatalogAddModalType } from '@app/components/shared/modals/catalog-add-modal/catalog-add-modal';
import { Utils } from '@app/shared/helpers/utils';
import { Checkbox, Col, Form, InputNumber, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { Rule } from 'antd/lib/form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

interface IBaseUnitEdit {
    code?: string;
    form: FormInstance<BaseUnitDto>;
    dataTab: ITab | undefined
}

interface ITab {
    dataEdit?: BaseUnitDto;
    mode: "add" | "edit" | "detail";
}

const BaseUnitMainInfomation: React.FC<IBaseUnitEdit> = ({ code, form, dataTab }: IBaseUnitEdit) => {

    const [changeTax30Value, setChangeTax30Value] = useState<{
        text: string
    }>(null!);

    useEffect(() => {
        setChangeTax30Value({
            text: dataTab?.dataEdit?.taxCode?.toString() as string
        });
    }, [dataTab])


    const validateTextCharater = (maxLen: number) => (_: any, value: any) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        const colMainInfomations: IColFormItemModel[] = [
            {
                colSpan: 10, name: 'baseUnitCode', label: 'Mã dvcs',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, {
                    required: true,
                    message: "Vui lòng nhập mã dvcs"
                }, { validator: validateTextCharater(8) }] as Rule[], disabled: code ? true : false,
            },
            {
                colSpan: 6, name: 'ordOrder', label: 'Số thứ tự', width: 90,
                render: (
                    <InputNumber min={0} max={9999}
                        formatter={Utils.numberFormatter} size={"small"}
                        placeholder='Nhập số thứ tự' style={{ width: "100%" }}
                        disabled={dataTab?.mode === "detail"}
                    />
                )
            },
            {
                colSpan: 8, name: 'baseUnitGroup1', label: 'Group',
                disabled: dataTab?.mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(8) }]
            },
            {
                colSpan: 24, name: 'baseUnitName', label: 'Tên dvcs',
                disabled: dataTab?.mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, {
                    required: true,
                    message: "Vui lòng nhập tên dvcs"
                }, { validator: validateTextCharater(254) }] as Rule[]
            },
            {
                colSpan: 24, name: 'baseUnitName2', label: 'Tên dvcs X',
                disabled: dataTab?.mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(254) }]
            },
            {
                colSpan: 24, name: 'baseUnitxName', label: 'Tên 2',
                disabled: dataTab?.mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(254) }]
            },
            {
                colSpan: 24, name: 'baseUnitxName2', label: 'Tên 2 X',
                disabled: dataTab?.mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(254) }]
            },
            {
                colSpan: 24, name: 'address', label: 'địa chỉ',
                disabled: dataTab?.mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(254) }]
            },
            {
                colSpan: 24, name: 'address2', label: 'địa chỉ x',
                disabled: dataTab?.mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(254) }]
            },
            {
                colSpan: 24, name: 'addressx', label: 'địa chỉ 2',
                disabled: dataTab?.mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(254) }]
            },
            {
                colSpan: 24, name: 'address2x', label: 'địa chỉ 2 x',
                disabled: dataTab?.mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(254) }]
            },
        ];

        colMainInfomations.forEach(x => {
            if (x.colSpan >= 10) {
                x.width = 150
            }
        })

        return colMainInfomations;
    }, [code, dataTab])

    const session2Cols: IColFormItemModel[] = useMemo(() => {
        const colMainInfomations: IColFormItemModel[] = [
            {
                colSpan: 13, name: 'directorName', label: "Tên giám đốc",
                disabled: dataTab?.mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(64) }]
            },
            {
                colSpan: 11, name: 'directorName2', label: 'Tên giám đốc 2',
                disabled: dataTab?.mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(64) }]
            },

            {
                colSpan: 13, name: 'chiefAccountantName', label: 'Tên kế toán trưởng',
                disabled: dataTab?.mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(64) }]
            },
            {
                colSpan: 11, name: 'chiefAccountantName2', label: 'Tên kế toán trường 2',
                disabled: dataTab?.mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(64) }]
            },

            {
                colSpan: 13, name: 'scheduling', label: 'Tên người lập biểu',
                disabled: dataTab?.mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(64) }]
            },
            {
                colSpan: 11, name: 'scheduling2', label: 'Tên người lập biểu 2',
                disabled: dataTab?.mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(64) }]
            },

            {
                colSpan: 24, name: 'directorTitle', label: `Chức danh "giám đốc"`,
                disabled: dataTab?.mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(64) }]
            },
            {
                colSpan: 24, name: 'chiefAccountantTitle', label: `Chức danh "KT trưởng"`,
                disabled: dataTab?.mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(64) }]
            },
        ];

        colMainInfomations.forEach(x => {
            if (x.colSpan >= 10) {
                x.width = 150
            }
        })

        return colMainInfomations;
    }, [code, dataTab])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={24}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, []);

    return (
        <>
            {renderColumns(session1Cols)}
            <Row gutter={24}>
                <Col span={13}>
                    <Form.Item
                        name={"taxCode"}
                        label={"Mã thuế"}
                        labelCol={{ style: { width: 150 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <InputSearchLookup
                            fetchDataFunc={InputSearchApiService.getCatalogDymamic("MA_THUE")}
                            placeHolder={`Nhập mã thuế`}
                            keyField={'ma_thue'}
                            disabled={dataTab?.mode === "detail"}
                            lookupAdding={{
                                initValues: {
                                },
                                catalogAddModalType: CatalogAddModalType.Tax
                            }}
                            columns={InputSearchLookupColumns.tax}
                            onOutputChange={(value: string) => {
                                form.setFieldsValue({ taxCode: value });
                            }}
                            patchValue={changeTax30Value}
                        />
                    </Form.Item>
                </Col>
                {formItemInputWithColumn({
                    colSpan: 11, name: 'phoneNumber', label: "Số điện thoại", width: 150,
                    disabled: dataTab?.mode === "detail",
                    rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(128) }] as Rule[],
                }, 1)}
            </Row>
            {renderColumns(session2Cols)}
            <Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 150 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    disabled={dataTab?.mode === "detail"}
                    style={{
                        marginRight: "10px",
                    }}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item>
        </>
    );
};

export default BaseUnitMainInfomation;
