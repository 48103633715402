import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { BlockingPathTracingListView } from '../views/BlockingPathTracingListView';
import { Tabs, TabsProps } from 'antd';
import { IPBlockListView } from '../views/IPBlockListView';
import { IPBlockType } from '@app/api/v6.api';
import { IPBlockAppSetting } from '../views/IPBlockAppSetting';

const BlockingPathTracingListPage: React.FC = () => {
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'API Url',
            children: <BlockingPathTracingListView />,
        },
        {
            key: '2',
            label: 'Thiết bị',
            children: <IPBlockListView type={IPBlockType.DeviceCode} />,
        },
        {
            key: '3',
            label: 'IP',
            children: <IPBlockListView type={IPBlockType.IPAddress} />,
        },
        {
            key: '4',
            label: 'Cấu hình',
            children: <IPBlockAppSetting />,
        }
    ];

    return (
        <>
            <PageTitle>Danh sách API Tracking</PageTitle>

            <Tabs defaultActiveKey="1" items={items} />
        </>
    );
};

export default BlockingPathTracingListPage;
