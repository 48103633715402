import { DeleteOutlined } from '@ant-design/icons';
import apiServices from '@app/api/api.services';
import { Pagination } from '@app/api/table.api';
import { BlockingPathTracingDto, CatalogSPParams, V6Api } from '@app/api/v6.api';
import { PAGE_SIZE } from '@app/constants/page.constant';
import { Button, Dropdown, MenuProps, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'components/common/Table/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BlockingPathTracingListViewFilter } from './BlockingPathTracingListViewFilter';
import { BlockingPathTracingModal } from '../modals/BlockingPathTracingModal';
import { notificationController } from '@app/controllers/notificationController';

export interface IBlockingPathTracingPagingFilter {
    searchText: string;
    pageNumber: number;
    pageSize: number;
}

const items: MenuProps['items'] = [
    {
        key: '1',
        label: (
            <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
                1st menu item
            </a>
        ),
    },
    {
        key: '2',
        label: (
            <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
                2nd menu item
            </a>
        ),
    },
    {
        key: '3',
        label: (
            <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
                3rd menu item
            </a>
        ),
    },
];

export const BlockingPathTracingListView: React.FC = () => {
    const [blockingPathTracings, setBlockingPathTracings] = useState<BlockingPathTracingDto[]>([]);
    const [pagination, setPagination] = useState<Pagination>();
    const [loading, setLoading] = useState<boolean>(false);
    const [blockingPathTracingModal, setBlockingPathTracingModal] = useState<boolean>(false);
    const [filter, setFilter] = useState<IBlockingPathTracingPagingFilter>({
        searchText: '',
        pageNumber: 1,
        pageSize: PAGE_SIZE
    })

    const fetchData = useCallback(async () => {
        setLoading(true);

        const dataPaging = await apiServices.v6Api.blockingPathTracingsGet(filter.searchText, filter.pageNumber, filter.pageSize);

        setBlockingPathTracings(dataPaging?.items || []);
        setPagination({
            current: dataPaging?.pageNumber,
            pageSize: filter.pageSize,
            total: dataPaging.totalCount
        })
        setLoading(false);
    }, [filter])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleDelete = async (id: string) => {
        const res = await apiServices.v6Api.blockingPathTracingsDeleteBlockingPathTracing(id);

        if (res.isSuccess) {
            setFilter({
                ...filter,
                pageNumber: 1
            })
        }
    };

    const handleCloseModal = async (reload?: boolean) => {
        setBlockingPathTracingModal(false);
        if (reload) {
            setFilter({
                ...filter,
                pageNumber: 1
            });
        }
    };

    const columns = useMemo(() => {
        const columns: ColumnsType<BlockingPathTracingDto> = [
            {
                title: 'API',
                dataIndex: 'path',
                align: 'center'
            },
            {
                title: 'Hành động',
                fixed: 'right',
                align: 'center',
                width: 150,
                render: (record: BlockingPathTracingDto) => {
                    return (
                        <Popconfirm
                            title="Xóa"
                            onConfirm={() => {
                                handleDelete(record.id as string)
                            }}
                            okText="Xóa"
                            cancelText="Hủy"
                        >
                            <Button type="primary"
                                shape="circle"
                                icon={<DeleteOutlined />}
                                title='Xóa'
                                style={{ marginRight: 3 }}>
                            </Button>
                        </Popconfirm>
                    );
                },
            },
        ];
        return columns;
    }, [])

    function onPagingChange(pageNum: number, pageSize: number) {
        const params: IBlockingPathTracingPagingFilter = {
            ...filter,
            pageNumber: pageNum,
            pageSize: pageSize,
        };
        setFilter(params);
    }

    async function clearCache() {
        await apiServices.v6Api.blockingPathTracingsClearCache();
        notificationController.success({ message: 'Thành công' });
    }

    return (
        <div className='app-table-list'>
            <div className='app-table-list-header'>
                <h1 className='app-table-list-header-title'>
                    Quản lý API Url truy vết
                </h1>
                <div className='app-table-list-header-actions'>
                    <Button type="primary" onClick={() => { setBlockingPathTracingModal(true); }}>
                        Thêm mới
                    </Button>

                    <Button type="primary"
                        style={{ marginLeft: 6 }}
                        onClick={() => { clearCache(); }}>
                        Clear Cache
                    </Button>
                </div>
            </div>
            <BlockingPathTracingListViewFilter
                filter={filter}
                onFilter={(data) => {
                    setFilter({
                        ...filter,
                        searchText: data.searchText,
                        pageNumber: 1
                    });
                }} />
            <Table
                columns={columns}
                dataSource={blockingPathTracings}
                scroll={{ x: 1700, scrollToFirstRowOnChange: true }}
                pagination={{
                    ...pagination,
                    onChange: onPagingChange
                }}
                loading={loading}
                bordered
            />

            {
                blockingPathTracingModal && (
                    <BlockingPathTracingModal
                        visible={blockingPathTracingModal}
                        mode={'add'}
                        onClose={(e) => handleCloseModal(e)}
                    />
                )
            }
        </div>
    )
}