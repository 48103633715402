import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { AccountListView } from '../views/AccountListView';

const AccountListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách tài khoản</PageTitle>
            <AccountListView />
        </>
    );
};

export default AccountListPage;
