import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import apiServices from '@app/api/api.services';
import { Pagination } from '@app/api/table.api';
import { BaseUnitDto, CatalogSPParams } from '@app/api/v6.api';
import { PAGE_SIZE } from '@app/constants/page.constant';
import { commonFunc } from '@app/shared/functions/common.function';
import { Button, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'components/common/Table/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import BaseUnitModal from '../modals/BaseUnitModal';
import { BaseUnitListViewFilter } from './BaseUnitListViewFilter';

export const BaseUnitListView: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [chosenMode, setChosenMode] = useState<"add" | "edit" | "detail">("add");
    const [codeEdit, setCodeEdit] = useState<string | undefined>();
    const [baseUnits, setBaseUnits] = useState<BaseUnitDto[]>([]);
    const [pagination, setPagination] = useState<Pagination>();
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<CatalogSPParams>({
        vvar: 'MA_DVCS',
        pageNumber: 1,
        pageSize: PAGE_SIZE
    } as CatalogSPParams)

    const fetchData = useCallback(async () => {
        setLoading(true);

        const baseUnitPaging = await apiServices.v6Api.catalogsGetCatalogDynamicPaging(filter);

        setBaseUnits(baseUnitPaging?.items || []);
        setPagination({
            current: baseUnitPaging?.pageNumber,
            pageSize: filter.pageSize,
            total: baseUnitPaging.totalCount
        })
        setLoading(false);
    }, [filter])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleEdit = async (code: any) => {
        setCodeEdit(code ? code : undefined)
        setChosenMode("edit");
        setIsModalOpen(true);
    };

    const handleDetail = async (code: any) => {
        setCodeEdit(code ? code : undefined)
        setChosenMode("detail");
        setIsModalOpen(true);
    };

    const handleCloseModal = async (reload?: boolean) => {
        setCodeEdit("");
        setIsModalOpen(false);
        if (reload) {
            const updatedFilter = {
                vvar: 'MA_DVCS',
                pageNumber: 1,
                pageSize: PAGE_SIZE
            } as CatalogSPParams;
            setFilter(updatedFilter);
        }
    };

    const columns = useMemo(() => {
        const columns: ColumnsType<BaseUnitDto> = [
            {
                title: 'Thứ tự',
                dataIndex: 'rownum',
                width: 40,
                align: 'center'
            },
            {
                title: 'Mã đơn vị',
                dataIndex: 'ma_dvcs',
                width: 50
            },
            {
                title: 'Tên đơn vị cở sở',
                width: 90,
                dataIndex: 'ten_dvcs',
                render: (tenDvcs: string) => (
                    <Tooltip
                        placement="topLeft"
                        title={tenDvcs}
                    >
                        <div className="textOverflowTwoLines">
                            {tenDvcs}
                        </div>
                    </Tooltip>
                ),
            },
            {
                title: 'Tên đơn vị cở sở 2',
                width: 90,
                dataIndex: 'ten_dvcs2',
                render: (tenDvcs2: string) => (
                    <Tooltip
                        placement="topLeft"
                        title={tenDvcs2}
                    >
                        <div className="textOverflowTwoLines">
                            {tenDvcs2}
                        </div>
                    </Tooltip>
                ),
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'date0',
                width: 50,
                render: (date0: Date | null | undefined) => {
                    if (date0) {
                        return moment(date0).format('DD/MM/YYYY');
                    }
                    return "";
                }
            },
            {
                title: 'Thời gian tạo mới',
                dataIndex: 'time0',
                width: 70
            },
            {
                title: 'Người tạo mới',
                dataIndex: 'user_id0',
                width: 45
            },
            {
                title: 'Ngày sửa sau cùng',
                dataIndex: 'date2',
                width: 60,
                render: (date2: Date | null | undefined) => {
                    if (date2) {
                        return moment(date2).format('DD/MM/YYYY');
                    }
                    return "";
                }
            },
            {
                title: 'Thời gian sửa',
                dataIndex: 'time2',
                width: 50
            },
            {
                title: 'Người sửa',
                dataIndex: 'user_id2',
                width: 40
            },
            {
                title: 'Trạng thái',
                dataIndex: "status",
                width: 80,
                render: (status: any) => commonFunc.statusRender(status as string),
            },
            {
                title: 'Địa chỉ',
                width: 100,
                dataIndex: 'dia_chi'
            },
            {
                title: 'Địa chỉ 2',
                width: 100,
                dataIndex: 'dia_chi2'
            },
            {
                title: 'Số điện thoại',
                width: 50,
                dataIndex: 'dien_thoai'
            },
            {
                title: 'Mã số thuế',
                width: 50,
                dataIndex: 'ma_so_thue'
            },
            {
                title: 'NH_DVCS1',
                width: 50,
                dataIndex: 'nh_dvcs1'
            },
            {
                title: 'NH_DVCS2',
                width: 50,
                dataIndex: 'nh_dvcs2'
            },
            {
                title: 'NH_DVCS3',
                width: 50,
                dataIndex: 'nh_dvcs3'
            },
            {
                title: 'ten_dvcsx',
                width: 50,
                dataIndex: 'ten_dvcsx',
                render: (ten_dvcsx: string) => (
                    <Tooltip
                        placement="topLeft"
                        title={ten_dvcsx}
                    >
                        <div className="textOverflowTwoLines">
                            {ten_dvcsx}
                        </div>
                    </Tooltip>
                ),
            },
            {
                title: 'ten_dvcs2x',
                dataIndex: 'ten_dvcs2x',
                width: 50,
                render: (tendvcs2x: string) => (
                    <Tooltip
                        placement="topLeft"
                        title={tendvcs2x}
                    >
                        <div className="textOverflowTwoLines">
                            {tendvcs2x}
                        </div>
                    </Tooltip>
                ),
            },
            {
                title: 'DIA_CHIX',
                width: 50,
                dataIndex: 'dia_chix',
                render: (diaChiX: string) => (
                    <Tooltip
                        placement="topLeft"
                        title={diaChiX}
                    >
                        <div className="textOverflowTwoLines">
                            {diaChiX}
                        </div>
                    </Tooltip>
                ),
            },
            {
                title: 'DIA_CHI2X',
                width: 50,
                dataIndex: 'dia_chi2x'
            },
            {
                title: 'TEN_KTT',
                width: 50,
                dataIndex: 'ten_ktt'
            },
            {
                title: 'TEN_KTT2',
                width: 50,
                dataIndex: 'ten_ktt2'
            },
            {
                title: 'TEN_GD',
                width: 50,
                dataIndex: 'ten_gd'
            },
            {
                title: 'TEN_GD2',
                width: 50,
                dataIndex: 'ten_gd2'
            },
            {
                title: 'TEN_NLB',
                width: 50,
                dataIndex: 'ten_nlb'
            },
            {
                title: 'TEN_NLB2',
                width: 50,
                dataIndex: 'ten_nlb2'
            },
            {
                title: 'TEN_GOI_GD',
                width: 50,
                dataIndex: 'ten_goi_gd'
            },
            {
                title: 'TEN_GOI_KTT',
                width: 50,
                dataIndex: 'ten_goi_ktt'
            },
            {
                title: 'Hành động',
                fixed: 'right',
                align: 'center',
                dataIndex: 'ma_dvcs',
                width: 50,
                render: (maDVCS: string) => {
                    return (
                        <>
                            <Button type="primary"
                                shape="circle"
                                icon={<EditOutlined />}
                                title='Chỉnh sửa'
                                style={{ marginRight: 3 }}
                                onClick={() => {
                                    handleEdit(maDVCS)
                                }}>
                            </Button>
                            <Button type="primary"
                                shape="circle"
                                icon={<InfoCircleOutlined />}
                                title='Chi tiết'
                                style={{ marginRight: 3 }}
                                onClick={() => {
                                    handleDetail(maDVCS)
                                }}>
                            </Button>
                        </>
                    );
                },
            },
        ];

        return columns;
    }, [filter])

    function onPagingChange(pageNum: number, pageSize: number) {
        const params: CatalogSPParams = {
            ...filter,
            pageNumber: pageNum,
            pageSize: pageSize,
        } as CatalogSPParams;
        setFilter(params);
    }

    return (
        <div className='app-table-list'>
            <div className='app-table-list-header'>
                <h1 className='app-table-list-header-title'>
                    Danh sách đơn vị cơ sở
                </h1>

                <div className='app-table-list-header-actions'>
                    <Button type="primary" onClick={() => { setIsModalOpen(true); setCodeEdit(undefined) }}>
                        Thêm mới
                    </Button>

                </div>
            </div>

            <BaseUnitListViewFilter
                filter={filter}
                onFilter={(data: CatalogSPParams) => {
                    setFilter({
                        ...filter,
                        vvalue: data.vvalue as string,
                        pageNumber: 1
                    } as CatalogSPParams);
                }} />

            <Table
                columns={columns}
                dataSource={baseUnits}
                scroll={{ x: 3400 }}
                pagination={{
                    ...pagination,
                    onChange: onPagingChange
                }}
                loading={loading}
                bordered
            />
            <BaseUnitModal
                visible={isModalOpen}
                mode={chosenMode}
                code={codeEdit}
                onClose={(e) => handleCloseModal(e)}
            />
        </div>
    )
}