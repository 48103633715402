import { UploadFile } from "antd/lib/upload/interface";
import _, { first, isArray, isEmpty, isEqual, isNil } from "lodash";
import moment, { Moment } from "moment";

export class Utils {
  static clone<T>(object: T): T {
    return JSON.parse(JSON.stringify(object));
  }

  static handleImgError(e: any) {
    e.target.onerror = null;
    e.target.src = "https://i.imgur.com/T4ENOCj.png";
  }

  static extracUTCMomentDate(moment: Moment): {
    year: number;
    month: number;
    date: number;
  } {
    if (!moment) return null!;

    return {
      year: moment.year(),
      month: moment.month(),
      date: moment.date(),
    };
  }

  static createMomentDate(year: number, month: number, date: number): Moment {
    if (!moment) return null!;
    if (year === undefined || month === undefined || year === undefined)
      return null!;

    const utcDate = moment(`${date}/${month + 1}/${year}`, "DD/MM/YYYY");

    return utcDate.local();
  }

  static convertIsoToVNLocalTime(isoDateString: string): string {
    if (!isoDateString) return "---";

    return (
      moment(isoDateString).add(7, "hours").format("DD/MM/YYYY HH:mm") || "---"
    );
  }

  static convertIsoDateTime(isoDateString: string): string {
    if (!isoDateString) return "---";

    return moment(isoDateString).format("DD/MM/YYYY HH:mm") || "---";
  }

  static convertIsoOnlyDate(isoDateString: string): string {
    if (!isoDateString) return "---";

    return moment(isoDateString).format("DD/MM/YYYY") || "---";
  }

  static convertIsoOnlyTime(isoDateString: string): string {
    if (!isoDateString) return "---";

    return moment(isoDateString).format("HH:mm") || "---";
  }

  static convertIsoWithFormat(isoDateString: string, format: string): string {
    if (!isoDateString) return "---";

    return moment(isoDateString).format(format) || "---";
  }

  static convertMomentDateTime(date: Moment): string {
    if (!date) return "---";

    return date.format("DD/MM/YYYY HH:mm A") || "---";
  }

  static convertMomentOnlyDate(date: Moment): string {
    if (!date) return "---";

    return date.format("DD/MM/YYYY") || "---";
  }

  static compareDateOnlyString(date1: string, date2: string): number {
    const momentA = moment(date1, "DD/MM/YYYY");
    const momentB = moment(date2, "DD/MM/YYYY");
    if (momentA > momentB) return 1;
    else if (momentA < momentB) return -1;
    else return 0;
  }

  static convertDateOnlyStringToDateDaily(date: string): string {
    if (!date) return "---";

    return (
      moment(date, "DD/MM/YYYY").calendar(null, {
        lastWeek: "DD/MM/YYYY",
        lastDay: "[Hôm qua]",
        sameDay: "[Hôm nay]",
        nextDay: "[Ngày mai]",
        nextWeek: "DD/MM/YYYY",
        sameElse: "DD/MM/YYYY",
      }) || "---"
    );
  }

  static convertIsoDateToDateDailyHaveTime(isoDateString: string): string {
    if (!isoDateString) return "---";

    const date = new Date(isoDateString);
    if (date.getFullYear() < 1970) {
      return null!;
    }

    return (
      moment(isoDateString).calendar(null, {
        lastWeek: "DD/MM/YYYY HH:mm A",
        lastDay: "[Hôm qua]",
        sameDay: "[Hôm nay]",
        nextDay: "[Ngày mai]",
        nextWeek: "DD/MM/YYYY HH:mm A",
        sameElse: "DD/MM/YYYY HH:mm A",
      }) || "---"
    );
  }

  static convertIsoDateToDateDaily(isoDateString: string): string {
    if (!isoDateString) return "---";

    return (
      moment(isoDateString).calendar(null, {
        lastWeek: "DD/MM/YYYY",
        lastDay: "[Hôm qua]",
        sameDay: "[Hôm nay]",
        nextDay: "[Ngày mai]",
        nextWeek: "DD/MM/YYYY",
        sameElse: "DD/MM/YYYY",
      }) || "---"
    );
  }

  static isToday(isoDateString: string) {
    if (!isoDateString) return false;
    const date = new Date(isoDateString);
    const today = new Date();

    return (
      date.getDate() == today.getDate() &&
      date.getMonth() == today.getMonth() &&
      date.getFullYear() == today.getFullYear()
    );
  }

  static convertIsoDateToFromStr(
    isoDateString: string,
    checkToday = true
  ): string {
    if (!isoDateString) return "---";

    if (!Utils.isToday(isoDateString) && checkToday)
      return Utils.convertIsoDateTime(isoDateString);

    return moment(isoDateString).add(-1, "second").fromNow() || "---";
  }

  static convertIsoDateToFromStrShort(isoDateString: string): string {
    if (!isoDateString) return "---";

    return moment(isoDateString).add(-1, "second").fromNow(true);
  }

  static delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static getMediaUrl(file: UploadFile<any>): string {
    const response = file.response;
    if (response?.length > 0) {
      return response[0];
    }

    return null!;
  }

  static replaceAt(target: string, index: number, replacement: string): string {
    return (
      target.substr(0, index) +
      replacement +
      target.substr(index + replacement.length)
    );
  }

  static getXorUserIdOnConversationId(conversationId: string, userId: string) {
    if (!conversationId || !userId) return null;
    const splitableStr = Utils.replaceAt(conversationId, 36, "/");
    const arr = splitableStr.split("/");

    if (isEmpty(arr)) return null;

    return arr.find((i) => i !== userId);
  }

  static addClass(root: string, className: string, condition: boolean): string {
    if (!condition || isEmpty(className)) return root;
    return `${root} ${className}`;
  }

  static popupwindow(url: string, title: string, w: number, h: number) {
    const y = window.outerHeight / 2 + window.screenY - h / 2;
    const x = window.outerWidth / 2 + window.screenX - w / 2;
    return window.open(
      url,
      title,
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        y +
        ", left=" +
        x
    );
  }

  static processAntRangeToIsoString(dates: Moment[]) {
    if (dates?.length !== 2)
      return {
        startDate: null,
        endDate: null,
      };

    return {
      startDate: dates[0].toISOString(),
      endDate: dates[1].toISOString(),
    };
  }

  static datePickerDisableFutureDay(current: Moment) {
    return current > moment().endOf("day");
  }

  static datePickerDisableFutureAndCurrentDate(current: Moment) {
    return current >= moment().startOf("day");
  }

  static processPrice(value: number) {
    if (value === undefined || value === null || isNaN(value)) return "---";

    return new Intl.NumberFormat("vi-VN", {
      style: "currency",
      currency: "VND",
    }).format(value);
  }

  static readonly vnNumberFormatter = new Intl.NumberFormat("vi-VN");

  static formatVNNumber(value: number): string {
    if (value === undefined || value === null || isNaN(value)) return "---";

    return Utils.vnNumberFormatter.format(value);
  }

  static removeAccentsAndWhiteSpace(str: string) {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/đ/g, "d")
      .replace(/Đ/g, "D")
      .replace(/ /g, ""); // All remove white space
  }

  static compareSearchText(searchText: string, value: string): boolean {
    const trimmedSearchText = searchText?.trim()?.toLocaleLowerCase();
    const trimmedValue = value?.trim().toLocaleLowerCase();

    if (isEmpty(trimmedSearchText)) return true;
    if (isEmpty(trimmedValue)) return false;

    const searchTextProcessed =
      Utils.removeAccentsAndWhiteSpace(trimmedSearchText);
    const valueProcessed = Utils.removeAccentsAndWhiteSpace(trimmedValue);

    return valueProcessed.includes(searchTextProcessed);
  }

  static sortTrueOnTop(x: boolean, y: boolean): number {
    return x === y ? 0 : x ? -1 : 1;
  }

  static getDataByNamePath(
    namePath: string | number | readonly (string | number)[],
    obj: any
  ): any {
    if (isNil(namePath) || isNil(obj)) return undefined;

    if (["number", "string"].includes(typeof namePath)) {
      return obj[namePath as any];
    }

    if (Array.isArray(namePath)) {
      let result = obj;

      for (const path of namePath) {
        if (["number", "string"].includes(typeof path) && !isNil(result)) {
          result = result[path];
        } else {
          return undefined;
        }
      }

      return result;
    }

    return undefined;
  }

  static getFullName(firstName: string, lastName: string): string {
    return `${firstName ? firstName : ""} ${lastName ? lastName : ""}`;
  }

  static numberFormatter(value: any) {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  static getRandomColor(): string {
    const letters = "0123456789ABCDEF";
    let color = "#";

    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }

    return color;
  }
}
