import apiServices from '@app/api/api.services';
import { IPBlockType, IPBlockUpdateDto } from '@app/api/v6.api';
import { Modal } from '@app/components/common/Modal/Modal';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import { antFormFinishErrorNotification } from '@app/shared/functions/common.function';
import { Col, Form, Input, Row } from 'antd';
import { useState } from 'react';

interface IIPBlockModal {
    visible: boolean;
    type: IPBlockType;
    mode: "add";
    onClose: (reload?: boolean) => void;
}

export const IPBlockModal: React.FC<IIPBlockModal> = ({
    visible,
    type,
    onClose
}: IIPBlockModal) => {
    const [form] = BaseForm.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const handleCancel = async () => {
        onClose();
    };

    const onFinish = async (data: IPBlockUpdateDto) => {
        setLoading(true);
        const promise = apiServices.v6Api.iPBlocksCreateBlockingPathTracing({
            type: type,
            deviceAddress: data.deviceAddress
        } as IPBlockUpdateDto);

        promise.then((response) => {
            if (response.isSuccess) {
                notificationController.success({ message: 'Thành công' });
                onClose(true);
                return;
            }

            notificationController.error({ message: response.message });
        }).catch(() => {
            notificationController.error({ message: 'Thất bại' });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal
            title={'Thêm mới'}
            confirmLoading={loading}
            open={visible}
            destroyOnClose={true}
            width={500}
            onOk={() => form.submit()}
            onCancel={() => handleCancel()}
            okText={"Lưu"}
            cancelText="Hủy"
            okButtonProps={{ style: { padding: "5px", height: "36px" } }}
            cancelButtonProps={{ style: { padding: "5px", height: "36px" } }}
        >
            <div className='basic-form'>
                <Form
                    form={form}
                    style={{ width: "95%" }}
                    name="basic"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onFinish={onFinish}
                    onFinishFailed={antFormFinishErrorNotification}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name={'deviceAddress'}
                                label={'Thiết bị'}
                                rules={[
                                    {
                                        required: true,
                                        message: `Vui lòng nhập mã thiết bị`,
                                    }
                                ]}
                                className="w-100"
                            >
                                <Input size="small" placeholder={'Nhập'}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    )
}