import apiServices from '@app/api/api.services';
import create from 'zustand';
import { IMaterialUnitsPairInforModel } from '../models/material-units-pair-infor.model';
import { AD91Dto } from '@app/api/v6.api';

interface IAM91ModalState {
    materials_units: IMaterialUnitsPairInforModel[];
}

interface IAM91ModalAction {
    updateMaterial_Unit: (ma_vt: string, materialUnitsPairInfor: IMaterialUnitsPairInforModel) => void;
    fetchUnitConverionsByMaterial: (ma_vt: string, nhieu_dvt: boolean) => Promise<void>;
    initMaterials_UnitsFromADs: (ad91s: AD91Dto[]) => void;
    reset: () => void;
}

const initData: IAM91ModalState = {
    materials_units: []
}

export interface IAM91ModalStore extends IAM91ModalState, IAM91ModalAction { }

const useAM91ModalStore = create<IAM91ModalStore>((set, get, _) => ({
    ...initData,

    updateMaterial_Unit: (ma_vt: string, materialUnitsPairInfor: IMaterialUnitsPairInforModel) => {
        const materials_units = get().materials_units;

        const index = materials_units.findIndex(x => x.ma_vt === ma_vt);

        if (index > -1) {
            materials_units.push(materialUnitsPairInfor);
        } else {
            materials_units[index] = materialUnitsPairInfor;
        }

        set({
            materials_units: [...materials_units]
        })
    },

    initMaterials_UnitsFromADs: (ad91s: AD91Dto[]) => {
        if (!ad91s?.length) {
            return;
        }

        const materials_units: IMaterialUnitsPairInforModel[] = ad91s.map(ad91 => ({
            ma_vt: ad91.ma_vt,
            nhieu_dvt: (ad91.nhieu_dvt as any) === true || ad91.nhieu_dvt === '1',
            units: ad91.materialUnits
        } as IMaterialUnitsPairInforModel));

        set({
            materials_units: materials_units
        })
    },

    fetchUnitConverionsByMaterial: async (ma_vt: string, nhieu_dvt: boolean) => {
        const materials_units = get().materials_units;
        const material_units = materials_units.find(x => x.ma_vt === ma_vt);

        if (material_units) {
            return;
        }

        const units = await apiServices.v6Api.materialsGetMaterialUnitConversionsByMaterialCode(ma_vt);
        materials_units.push({
            ma_vt: ma_vt,
            nhieu_dvt: nhieu_dvt,
            units: units
        })

        set({
            materials_units: [...materials_units]
        });
    },

    reset: () => {
        set({
            ...initData
        })
    }
}))

export default useAM91ModalStore;