import { DownSquareOutlined, FileFilled, PlusOutlined, UpSquareOutlined } from "@ant-design/icons";
import CatalogAddModal from "@app/components/shared/modals/catalog-add-modal/catalog-add-modal";
import { PaginatedList } from "@app/shared/models/paginated-list.model";
import { Input, InputRef, List, Popover, Skeleton } from "antd";
import debounce from 'lodash/debounce';
import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import './InputSearchLookup.less';
import { IInputSearchLookupAddingModel } from "./models/input-search-lookup-adding.model";
import {
    IInputSearchLookupApiRequest, IInputSearchLookupColumn
} from "./models/input-search-lookup-column";

interface IInputSearchLookup {
    id?: string;
    autoFocus?: boolean;
    className?: string;
    style?: CSSProperties;
    inputStyle?: CSSProperties;
    keyField: string;
    columns: IInputSearchLookupColumn[]
    disabled?: boolean;
    lookupHeight?: number;
    placeHolder?: string;
    patchValue?: {
        text: string;
        selectedRecord?: any;
        fetchSelectedRecordByText?: boolean;
    }
    lookupAdding?: IInputSearchLookupAddingModel;
    notEmpty?: boolean;
    fetchDataFunc: (request: IInputSearchLookupApiRequest) => Promise<PaginatedList<any>>;
    onOutputSelectRecord?: (record: any) => void;
    onOutputChange?: (value: string) => void;
    onBlur?: () => void;
}

const InputSearchLookup: React.FC<IInputSearchLookup> = ({
    id,
    autoFocus,
    className,
    style,
    inputStyle,
    keyField,
    disabled,
    columns,
    lookupHeight,
    placeHolder,
    patchValue,
    lookupAdding,
    notEmpty,
    fetchDataFunc,
    onOutputSelectRecord,
    onOutputChange,
    onBlur
}: IInputSearchLookup) => {
    // #region useRef
    const inputRef = useRef<InputRef>(null)
    const scrollableDivRef = useRef<any>(null);
    const popoverContentContainerRef = useRef<any>();
    // #endregion useRef

    // #region useState
    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
    const [displayLookupToolBar, setDisplayLookupToolBar] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>('');
    const [searchText, setSearchText] = useState<string>('');
    const [boxIsDown, setBoxIsDown] = useState<boolean>(true);
    const [currentPagingData, setCurrentPagingData] = useState<PaginatedList<any>>(null!);
    const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [inputErrorBorder, setInputErrorBorder] = useState<boolean>(false);
    const [focused, setFocused] = useState<boolean>(false);
    const [selectedRecord, setSelectedRecord] = useState<any>([]);
    const [tempSelectedRecord, setTempSelectedRecord] = useState<any>(null);
    // #endregion useState

    const onSelectRecord = (record: any) => {
        setSelectedRecord(record);
        moveItemOnTop(record);

        if (onOutputSelectRecord) {
            onOutputSelectRecord(record);
        }

        const valueOfKey = getValueOfKey(record);
        setInputValue(valueOfKey);
        if (onOutputChange) {
            onOutputChange(valueOfKey);
        }

        closePopoverAndLookupToolbar();
        scrollableDivRef.current.scrollTop = 0;
    }

    // #region Keyboard - Actions
    const onKeyArrowUp = useCallback(function () {
        if (dataSource.length === 0) {
            return;
        }

        if (!tempSelectedRecord) {
            return;
        }

        const index = dataSource.findIndex(x => getValueOfKey(x) === getValueOfKey(tempSelectedRecord));
        if (index > 0) {
            setTempSelectedRecord(dataSource[index - 1]);
        }
    }, [dataSource, tempSelectedRecord, setTempSelectedRecord])

    const onKeyArrowDown = useCallback(function () {
        if (boxIsDown) {
            setBoxIsDown(false);
            return;
        }

        if (dataSource.length === 0) {
            return;
        }

        if (!tempSelectedRecord) {
            setTempSelectedRecord(dataSource[0]);
        }

        const index = dataSource.findIndex(x => getValueOfKey(x) === getValueOfKey(tempSelectedRecord));
        if (index < dataSource.length - 1) {
            setTempSelectedRecord(dataSource[index + 1]);
        }
    }, [
        dataSource,
        tempSelectedRecord, setTempSelectedRecord,
        boxIsDown, setBoxIsDown
    ])

    const onKeyEnter = useCallback(function () {
        if (tempSelectedRecord) {
            onSelectRecord(tempSelectedRecord);
            setTempSelectedRecord(null);
        }
    }, [tempSelectedRecord, onSelectRecord, setTempSelectedRecord])
    // #endregion Keyboard - Actions

    // #region useEffect
    useEffect(() => {
        const keydownListener = (event: KeyboardEvent) => {
            if (!focused) {
                return;
            }

            if (event.key === 'ArrowUp') {
                onKeyArrowUp();
            }

            if (event.key === 'ArrowDown') {
                onKeyArrowDown();
            }

            if (event.key === 'Enter') {
                onKeyEnter();
            }
        };

        document.addEventListener(`keydown`, keydownListener);

        return () => {
            document.removeEventListener(`keydown`, keydownListener);
        };
    }, [focused, onKeyArrowUp, onKeyArrowDown, onKeyEnter]);

    useEffect(() => {
        if (autoFocus && inputRef) {
            inputRef.current?.focus();
        }
    }, [autoFocus, inputRef])

    useEffect(() => {
        if (onOutputChange) {
            onOutputChange(searchText);
        }

        setCurrentPagingData(null!);
        setDataSource([]);
        setSelectedRecord(null!);

        fetchData(1);
    }, [searchText])

    useEffect(() => {
        if (!popoverOpen && !displayLookupToolBar) {
            setBoxIsDown(true);
        }
    }, [popoverOpen, displayLookupToolBar])

    useEffect(() => {
        if (boxIsDown) {
            return;
        }

        if (!searchText || dataSource.length === 0) {
            fetchData(1);
        }
    }, [boxIsDown])

    useEffect(() => {
        if (patchValue) {
            setInputValue(patchValue.text);
        }
    }, [patchValue])

    useEffect(() => {
        setInputErrorBorder(false);
    }, [inputValue])

    useEffect(() => {
        document.removeEventListener("mousedown", handleClickOutside);
        if (popoverContentContainerRef && (popoverOpen || displayLookupToolBar)) {
            document.addEventListener("mousedown", handleClickOutside);
        }
    }, [popoverContentContainerRef, popoverOpen, displayLookupToolBar])

    useEffect(() => {
        if (dataSource.length === 0) {
            setTempSelectedRecord(null);
        }
    }, [dataSource])

    useEffect(() => {
        if (dataSource.length === 0) {
            setTempSelectedRecord(null);
        }
    }, [inputValue])
    // #endregion useEffect

    // #region actions
    const closePopoverAndLookupToolbar = useCallback(() => {
        setPopoverOpen(false);
        setDisplayLookupToolBar(false);
        setBoxIsDown(true);
    }, [])

    const openPopoverAndLookupToolbar = useCallback(() => {
        setPopoverOpen(true);
        setDisplayLookupToolBar(true);
    }, [])

    const onFocusSearchTextField = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setFocused(true);

        openPopoverAndLookupToolbar();
    }

    const onBlurSearchTextField = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setFocused(false);

        if (notEmpty && !inputValue) {
            inputRef.current?.focus();
            setInputErrorBorder(true);
            setBoxIsDown(false);// -> fetch data and show popup selection
            return;
        }

        setPopoverOpen(false);
        // setBoxIsDown(true);
        // setDisplayLookupToolBar(false);

        setTempSelectedRecord(null);
        if (onBlur) {
            onBlur();
        }
    }

    const onClickAdd = () => {
        setAddModalOpen(true);
    }
    // #endregion actions

    const getValueOfKey = useCallback((record: any) => {
        if (!record) {
            return null!;
        }

        return record[keyField];
    }, [keyField])

    const componentWidth = useMemo(() => {
        return columns?.map(x => x.width).reduce((a, b) => a + b, 0) ?? 0;
    }, [columns]);

    const componentHeight = useMemo(() => {
        return lookupHeight ?? 300;
    }, [lookupHeight]);

    const changeSearchTextHandler = (text: string) => {
        setSearchText(text);
    };

    const searchTextDebouncedChangeHandler = useCallback(debounce(changeSearchTextHandler, 300), [setSearchText]);

    const onChangeInputValue = (e: any) => {
        const text = e.target.value;

        setInputValue(text);

        searchTextDebouncedChangeHandler(text);
    }

    const fetchData = async (pageNumber?: number) => {
        if (!searchText && boxIsDown) {
            setDataSource([]);
            return;
        }

        const requestPageNumber = pageNumber ? pageNumber : (currentPagingData?.pageNumber ?? 0) + 1;

        const pagingDataRes = await fetchDataFunc({
            searchText: searchText,
            pageNumber: requestPageNumber,
            pageSize: 20
        });
        setCurrentPagingData(pagingDataRes);
        const resourceData = (pagingDataRes.items || []);
        if (pagingDataRes.pageNumber === 1) {
            setDataSource(resourceData);
            return;
        }

        const newResourceData = resourceData.filter(x => !dataSource.some(y => getValueOfKey(x) === getValueOfKey(y)));
        setDataSource([...dataSource.concat(newResourceData)]);
    };

    const loadMoreData = () => {
        fetchData();
    }

    const moveItemOnTop = (record: any) => {
        const newLists = dataSource.filter(x => x !== record);
        newLists.unshift(record);
        setDataSource(newLists);
    }

    function handleClickOutside(event: any) {
        if (popoverContentContainerRef && popoverContentContainerRef.current && (popoverContentContainerRef.current as any).contains(event.target)) {
            return;
        }

        setDisplayLookupToolBar(false);
    }

    const renderColumnContent = (record: any, column: IInputSearchLookupColumn) => {
        const value = record[column.dataIndex];

        if (column.hover) {
            return (
                <Popover
                    title={value}
                    trigger="hover"
                >
                    <span className="text-overflow-ellipsis common-font-family-size">
                        {value}
                    </span>
                </Popover>
            );
        }

        return value;
    }

    const renderContent = () => {
        return (
            <div ref={popoverContentContainerRef}
                style={{ width: componentWidth, maxHeight: componentHeight }}
                className="input-search-lookup-popover-overlay">
                <div className="input-search-lookup-popover-overlay-headers">
                    {
                        columns?.map((column, index) => (
                            <span key={index}
                                style={{
                                    width: column.width ?? 100,
                                    padding: 5,
                                    paddingTop: 0,
                                    paddingBottom: 0
                                }}
                                className="font-weight-700">
                                {column.title}
                            </span>
                        ))
                    }
                </div>

                <div id="scrollableDiv"
                    className="scrollableDiv"
                    style={{ width: componentWidth, maxHeight: componentHeight - 30, overflow: 'auto' }}
                    ref={scrollableDivRef}>
                    <InfiniteScroll
                        dataLength={dataSource.length}
                        next={loadMoreData}
                        hasMore={currentPagingData ? currentPagingData.hasMoreData as boolean : true}
                        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                        scrollableTarget="scrollableDiv"
                    >
                        <List
                            itemLayout="horizontal"
                            dataSource={dataSource}
                            renderItem={
                                (renderItemRecord, dataSourceIndex) => {
                                    const renderItemKey = getValueOfKey(renderItemRecord);
                                    const selectedRecordKey = getValueOfKey(selectedRecord);
                                    const tempSelectedRecordKey = getValueOfKey(tempSelectedRecord);

                                    let className = renderItemKey === selectedRecordKey
                                        ? 'selected'
                                        : '';

                                    if (!className) {
                                        className = renderItemKey === tempSelectedRecordKey
                                            ? 'temp-selected'
                                            : '';
                                    }

                                    return (
                                        <List.Item key={dataSourceIndex}
                                            className={className}
                                            onClick={() => onSelectRecord(renderItemRecord)}>
                                            {
                                                columns?.map((column, index) => (
                                                    <List.Item.Meta key={index}
                                                        title={renderColumnContent(renderItemRecord, column)}
                                                    />
                                                ))
                                            }
                                        </List.Item>
                                    )
                                }
                            }
                        />
                    </InfiniteScroll>
                </div>

                <div className="input-search-lookup-popover-overlay-footer">
                    {/* <MonitorOutlined /> <span>Tìm nhanh (F3)</span> */}
                </div>
            </div>
        )
    }

    return (
        <div className={'input-search-lookup-container' + ` ${className}` + ` input-look-container-${keyField}`}
            style={style}>
            <Input
                ref={inputRef}
                autoFocus={autoFocus}
                style={inputStyle}
                value={inputValue}
                placeholder={placeHolder ?? 'Nhập'
                }
                className={
                    "input-search-lookup-input"
                    + (inputErrorBorder ? ' lookup-input-error' : '')
                }
                onFocus={onFocusSearchTextField}
                onBlur={onBlurSearchTextField}
                onChange={onChangeInputValue}
                disabled={disabled}
                suffix={
                    disabled
                        ? <></>
                        :
                        <div style={{ marginRight: 2 }}>
                            {
                                lookupAdding && (
                                    <PlusOutlined style={{ marginRight: 6, cursor: 'pointer' }} onClick={(e) => {
                                        e.stopPropagation();
                                        onClickAdd();
                                    }} />
                                )
                            }

                            {
                                boxIsDown && (
                                    <DownSquareOutlined onClick={() => {
                                        setBoxIsDown(false);
                                    }} />
                                )
                            }

                            {
                                !boxIsDown && (
                                    <UpSquareOutlined onClick={() => { setBoxIsDown(true) }} />
                                )
                            }
                        </div>
                }
            />

            {
                (
                    !boxIsDown
                    || (
                        (popoverOpen || displayLookupToolBar)
                        && Boolean(searchText)
                    )
                )
                && (
                    <div style={{
                        position: 'absolute',
                        backgroundColor: '#fff',
                        zIndex: 9999,
                        marginTop: 2
                    }}>
                        {renderContent()}
                    </div>
                )
            }

            {/* <Popover placement="bottomLeft"
                className="input-search-lookup-popover"
                overlayClassName="input-search-lookup-popover-overlay"
                title={null}
                content={renderContent()}
                visible={
                    !boxIsDown
                    || (
                        (popoverOpen || displayLookupToolBar)
                        && Boolean(searchText)
                    )
                }
                trigger="click">


            </Popover> */}

            {
                addModalOpen && lookupAdding && (
                    <CatalogAddModal
                        catalogAddModalType={lookupAdding.catalogAddModalType!}
                        lookupAdding={lookupAdding!}
                        onClose={() => setAddModalOpen(false)}
                        onSelect={(data: any) => {
                            try {
                                onSelectRecord(data);
                            } catch { }
                            setAddModalOpen(false)
                        }}
                    />
                )
            }
        </div >
    )
}

export default InputSearchLookup;