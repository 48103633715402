import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { MaterialGroupPriceListView } from '../views/MaterialGroupPriceListView';

const MaterialGroupPriceListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách nhóm giá vật tư</PageTitle>
            <MaterialGroupPriceListView />
        </>
    );
};

export default MaterialGroupPriceListPage;
