import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Checkbox, Form, Row } from 'antd';
import React, { useCallback, useMemo } from 'react';
import TextArea from 'antd/lib/input/TextArea';

interface ICustomerGroupPriceEdit {
    mode: "add" | "edit" | "detail";
}
const CustomerGroupPriceKeyInfomation: React.FC<ICustomerGroupPriceEdit> = ({ mode
}: ICustomerGroupPriceEdit) => {

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 24, name: 'gc_td1', label: 'Ghi chú',
                render: (
                    <TextArea
                        disabled={mode === "detail"}
                        rows={4}
                    />
                )
            },
        ]
    }, [mode])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={10}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, [mode]);

    return (
        <>
            {renderColumns(session1Cols)}

            <Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 130 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={mode === "detail"}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item>
        </>
    );
};

export default CustomerGroupPriceKeyInfomation;