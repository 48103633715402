import apiServices from '@app/api/api.services';
import { Pagination } from '@app/api/table.api';
import { CatalogSPParams } from '@app/api/v6.api';
import { PAGE_SIZE } from '@app/constants/page.constant';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'components/common/Table/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MaterialGroupPriceListViewFilter } from './MaterialGroupPriceViewFilter';
import { commonFunc } from '@app/shared/functions/common.function';
import moment from 'moment';

export const MaterialGroupPriceListView: React.FC = () => {
    const [materialGroupPrices, setMaterialGroupPrices] = useState<any[]>([]);
    const [pagination, setPagination] = useState<Pagination>();
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<CatalogSPParams>({
        vvar: 'MA_VT9',
        pageNumber: 1,
        pageSize: PAGE_SIZE
    } as CatalogSPParams)

    const fetchData = useCallback(async () => {
        setLoading(true);
        const materialGroupPricesPaging = await apiServices.v6Api.catalogsGetCatalogDynamicPaging(filter);
        setMaterialGroupPrices(materialGroupPricesPaging?.items || []);
        setPagination({
            current: materialGroupPricesPaging?.pageNumber,
            pageSize: filter.pageSize,
            total: materialGroupPricesPaging.totalCount
        })
        setLoading(false);
    }, [filter])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const columns = useMemo(() => {
        const columns: ColumnsType<any> = [
            {
                title: 'STT',
                align: 'center',
                dataIndex: 'rownum',
                width: 20
            },
            {
                title: 'Mã nhóm',
                dataIndex: 'ma_nh',
                width: 45
            },
            {
                title: 'Tên loại',
                dataIndex: 'ten_nh',
                width: 100
            },
            {
                title: 'Tên loại 2',
                dataIndex: 'ten_nh2',
                width: 100
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'date0',
                width: 45,
                render: (date0: Date | null | undefined) => {
                    if (date0) {
                        return moment(date0).format('DD/MM/YYYY');
                    }
                    return "";
                }
            },
            {
                title: 'Thời gian tạo mới',
                dataIndex: 'time0',
                width: 70
            },
            {
                title: 'Người tạo mới',
                dataIndex: 'user_id0',
                width: 60
            },
            {
                title: 'Trạng thái',
                width: 55,
                render: (data: any) => commonFunc.statusRender(data.status as string)
            },
            {
                title: 'Ngày sửa sau cùng',
                dataIndex: 'date2',
                width: 65,
                render: (date2: Date | null | undefined) => {
                    if (date2) {
                        return moment(date2).format('DD/MM/YYYY');
                    }
                    return "";
                }
            },
            {
                title: 'Thời gian sửa',
                dataIndex: 'time2',
                width: 50
            },
            {
                title: 'Người sửa',
                dataIndex: 'user_id2',
                width: 40
            },
            {
                title: 'Mã tự định nghĩa 1',
                dataIndex: 'gc_td1',
                width: 40
            }
        ];

        return columns;
    }, [])

    function onPagingChange(pageNum: number, pageSize: number) {
        const params: CatalogSPParams = {
            ...filter,
            pageNumber: pageNum,
            pageSize: pageSize,
        } as CatalogSPParams;
        setFilter(params);
    }
    return (
        <div className='app-table-list'>
            <div className='app-table-list-header'>
                <h1 className='app-table-list-header-title'>
                    Danh sách nhóm giá vật tư
                </h1>
            </div>
            <MaterialGroupPriceListViewFilter
                filter={filter}
                onFilter={(data: CatalogSPParams) => {
                    setFilter({
                        ...filter,
                        vvalue: data.vvalue as string,
                        pageNumber: 1
                    } as CatalogSPParams);
                }} />
            <Table
                columns={columns}
                scroll={{ x: 1800 }}
                dataSource={materialGroupPrices}
                pagination={{
                    ...pagination,
                    onChange: onPagingChange
                }}
                loading={loading}
                bordered
            />
        </div>
    )
}