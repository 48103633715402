import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { CatalogDiscountTypeListView } from '../views/CatalogDiscountTypeListView';

const CatalogDiscountTypeListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách loại chiết khấu</PageTitle>
            <CatalogDiscountTypeListView />
        </>
    );
};

export default CatalogDiscountTypeListPage;
