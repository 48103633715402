import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { AffairGroupListView } from '../views/AffairGroupListView';

const AffairGroupListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách nhóm vụ việc</PageTitle>
            <AffairGroupListView />
        </>
    );
};

export default AffairGroupListPage;
