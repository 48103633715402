import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { AlSonBListView } from '../views/AlSonBListView';

const AlSonBListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách danh mục nội bộ</PageTitle>
            <AlSonBListView />
        </>
    );
};

export default AlSonBListPage;
