import { Modal } from '@app/components/common/Modal/Modal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Form, Row, Tabs, TabsProps } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import apiServices from '@app/api/api.services';
import { antFormFinishErrorNotification } from '@app/shared/functions/common.function';
import MaterialMainInfomation from './material-tabs/MaterialTabMainInfomation';
import { MaterialDto, MaterialUpdateDto } from '@app/api/v6.api';
import MaterialAccount from './material-tabs/MaterialAccount';
import MaterialOtherInfomation from './material-tabs/MaterialOtherInfomation';
import MaterialInventory from './material-tabs/MaterialInventory';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Rule } from 'antd/lib/form';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';

interface IModalMaterial {
    visible: boolean;
    onClose: (reload?: boolean) => void;
    onDispatchResponse?: (data: MaterialDto) => void;
}

export const MaterialModal: React.FC<IModalMaterial> = ({ visible, onClose, onDispatchResponse }: IModalMaterial) => {
    const [form] = BaseForm.useForm<MaterialUpdateDto>();
    const [titleModal, setTileModal] = useState("Thêm mới vật tư");
    const [loading, setLoading] = useState<boolean>(false);

    const handleCancel = async () => {
        onClose();
    };

    const fetchData = useCallback(async () => {
        setTileModal(`Thêm mới vật tư`);
    }, [form])

    useEffect(() => {
        form.resetFields();
        fetchData();
    }, [fetchData, form])

    const renderForm = useCallback(() => {
        return <>
            <Form
                form={form}
                name="basic"
                initialValues={{ remember: true }}
                autoComplete="off"
                onFinish={onFinish}
                onFinishFailed={antFormFinishErrorNotification}
            >
                {renderCustomerInformation()}
                <Tabs defaultActiveKey="1" items={items} />
            </Form>
        </>
    }, [])

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Thông tin chính',
            children: <MaterialMainInfomation
                form={form} />,
        },
        {
            key: '2',
            label: 'Tài khoản',
            children: <MaterialAccount />,
        },
        {
            key: '3',
            label: 'Tồn kho',
            children: <MaterialInventory />,
        },
        {
            key: '4',
            label: 'Thông tin khác',
            children: <MaterialOtherInfomation />,
        }
    ];

    const validateTextCharater = (maxLen: number) => (_: any, value: any) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const masterCustomerInformations: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'ma_vt', label: 'Mã vật tư',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, {
                    required: true,
                    message: "Vui lòng nhập mã vật tư"
                }, { validator: validateTextCharater(25) }] as Rule[],
            },
            {
                colSpan: 12, name: 'shortName', label: 'Tên ngắn',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(30) }] as Rule[],
            },
            {
                colSpan: 24, name: 'materialName', label: 'Tên vật tư',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập tên vật tư" },
                { validator: validateTextCharater(254) }] as Rule[],
            },
            {
                colSpan: 24, name: 'materialName2', label: 'Tên khác',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(254) }] as Rule[],
            },
            {
                colSpan: 12, name: 'countryCode', label: 'Nước sản xuất',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(8) }] as Rule[],
            }
        ]
    }, [])

    const renderCustomerInformation = () => (
        <div>
            <Row gutter={24}>
                {
                    masterCustomerInformations.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        </div>
    );

    const onFinish = async () => {
        const data = await form.validateFields();
        data.status = data.status ? '1' : '0';
        data.units = data.status ? '1' : '0';

        setLoading(true);
        const promise = apiServices.v6Api.materialsCreateUser(data);
        promise.then((response) => {
            if (response.isSuccess) {
                notificationController.success({ message: 'Thành công' });
                onClose(true);
                if (onDispatchResponse) {
                    onDispatchResponse(response.data as MaterialDto);
                }
                return;
            }

            notificationController.error({ message: response.message });
        }).catch(() => {
            notificationController.error({ message: 'Thất bại' });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal
            title={titleModal}
            confirmLoading={loading}
            open={visible}
            destroyOnClose={true}
            width={"1000px"}
            onOk={() => form.submit()}
            onCancel={() => handleCancel()}
            okText={"Lưu"}
            cancelText="Hủy"
            okButtonProps={{ style: { padding: "5px", height: "36px" } }}
            cancelButtonProps={{ style: { padding: "5px", height: "36px" } }}
        >
            <div className='basic-form'>
                {renderForm()}
            </div>
        </Modal>
    )
}