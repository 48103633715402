import { Modal } from '@app/components/common/Modal/Modal';
import { useCallback, useEffect, useState } from 'react';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Form, Tabs, TabsProps } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import apiServices from '@app/api/api.services';
import TaxMainInfomation from './tax-tabs/TaxTabMainInfomation';
import { TaxDto } from '@app/api/v6.api';
import { antFormFinishErrorNotification } from '@app/shared/functions/common.function';

interface IModalTax {
    code?: string;
    visible: boolean;
    mode: "add" | "edit" | "detail";
    onClose: (reload?: boolean) => void;
    onDispatchResponse?: (data: TaxDto) => void;
}

interface ITab {
    dataEdit?: TaxDto;
    mode: "add" | "edit" | "detail";
}

export const TaxModal: React.FC<IModalTax> = ({ code, visible, mode, onDispatchResponse, onClose }: IModalTax) => {
    const [form] = BaseForm.useForm();
    const [titleModal, setTileModal] = useState("Thêm mới - Danh mục thuế suất");
    const [loading, setLoading] = useState<boolean>(false);
    const [dataTab, setDataTab] = useState<ITab>();

    const handleCancel = async () => {
        setDataTab(undefined);
        onClose();
    };

    const fetchData = useCallback(async () => {
        setDataTab(undefined);
        if (code) {
            if (code) {
                const dataEditTax = await apiServices.v6Api.taxesGetById(code);
                form.setFieldsValue({
                    ...dataEditTax,
                    status: (dataEditTax.status === '1' ? true : false) as any,
                })
                setDataTab({ dataEdit: dataEditTax, mode: mode })
            }
            setTileModal(`${mode === "detail" ? "Chi tiết" : "Chỉnh sửa"} - Danh mục thuế suất - ${code}`);
        } else {
            setTileModal(`Thêm mới - Danh mục thuế suất`);
        }
    }, [code, mode, form])

    useEffect(() => {
        form.resetFields();
        code ? setDataTab({ dataEdit: dataTab?.dataEdit, mode: mode }) : setDataTab({ dataEdit: dataTab?.dataEdit, mode: "add" })
        fetchData();
    }, [fetchData, form, code, mode])

    const handleDisable = (() => {
        setDataTab({ dataEdit: dataTab?.dataEdit, mode: "edit" })
        setTileModal(`${dataTab?.mode !== "detail" ? "Chi tiết" : "Chỉnh sửa"} - Danh mục thuế suất - ${code}`);
    });

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Thông tin chính',
            children: <TaxMainInfomation
                code={code}
                form={form}
                dataTab={dataTab}
            />,
        }
    ];

    const renderForm = useCallback(() => {
        return <>
            <Tabs defaultActiveKey="1" items={items} />
        </>
    }, [dataTab, code])

    const onFinish = async () => {
        if (dataTab?.mode === "detail") {
            handleDisable();
            return;
        }

        const data = await form.validateFields();
        data.status = data.status ? '1' : '0';

        setLoading(true);
        const promise = code
            ? apiServices.v6Api.taxesUpdateTax(code, data)
            : apiServices.v6Api.taxesCreateTax(data);

        promise.then((response) => {
            if (response.isSuccess) {
                notificationController.success({ message: 'Thành công' });
                onClose(true);

                if (onDispatchResponse) {
                    onDispatchResponse(response.data as TaxDto);
                }
                return;
            }

            notificationController.error({ message: response.message });
        }).catch(() => {
            notificationController.error({ message: 'Thất bại' });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal
            title={titleModal}
            confirmLoading={loading}
            open={visible}
            destroyOnClose={true}
            width={"900px"}
            onOk={() => form.submit()}
            onCancel={() => handleCancel()}
            okText={dataTab?.mode === "detail" ? "Chỉnh sửa" : "Lưu"}
            cancelText="Hủy"
            okButtonProps={{ style: { padding: "5px", height: "36px" } }}
            cancelButtonProps={{ style: { padding: "5px", height: "36px" } }}
        >
            <div className='basic-form'>
                <Form
                    form={form}
                    name="basic"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onFinish={onFinish}
                    onFinishFailed={antFormFinishErrorNotification}
                >
                    {renderForm()}
                </Form>
            </div>
        </Modal>
    )
}