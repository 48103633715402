import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { WarehouseListView } from '../views/WarehouseListView';

const WarehouseListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách Kho</PageTitle>
            <WarehouseListView />
        </>
    );
};

export default WarehouseListPage;
