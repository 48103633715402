import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { BaseUnitListView } from '../views/BaseUnitListView';

const BaseUnitListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách đơn vị cơ sở</PageTitle>
            <BaseUnitListView />
        </>
    );
};

export default BaseUnitListPage;
