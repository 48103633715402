import { InputSearchApiService } from '@app/api/input-search-api.service';
import { Account0Dto } from '@app/api/v6.api';
import InputSearchLookup from '@app/components/common/input-search-lookup/InputSearchLookup';
import { InputSearchLookupColumns } from '@app/components/common/input-search-lookup/constants/input-lookup-entity-columns.constant';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { CatalogAddModalType } from '@app/components/shared/modals/catalog-add-modal/catalog-add-modal';
import { Checkbox, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import Form, { Rule } from 'antd/lib/form';
import React, { useCallback, useMemo } from 'react';

interface IAccountEdit {
    form: FormInstance<any>;
    dataTab?: ITab
}

interface ITab {
    dataEdit?: Account0Dto;
    mode: "add" | "edit" | "detail";
}

const AccountTabAdvance: React.FC<IAccountEdit> = ({ form, dataTab }: IAccountEdit) => {

    const validateTextCharater = (maxLen: number) => (_: any, value: string) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 13, name: 'accountContribute', label: 'TK gộp TH',
                placeholder: "Nhập TK gộp TH", disabled: dataTab?.mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(16) }] as Rule[]
            },
            {
                colSpan: 13, name: 'accountContribute0', label: 'TK gộp TH 1',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(16) }],
                disabled: dataTab?.mode === "detail", placeholder: "Nhập TK gộp TH 1"
            },
            {
                colSpan: 13, name: 'accountContribute1', label: 'TK thay thế 00CTGS',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(16) }],
                placeholder: "Nhập TK thay thế 00CTGS", disabled: dataTab?.mode === "detail"
            },
        ]
    }, [dataTab?.mode])

    const session2Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 13, name: 'contributeType', label: 'Loại gộp',
                placeholder: "Nhập loại gộp",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(1) }] as Rule[],
                render: (< Select
                    disabled={dataTab?.mode === "detail"}
                    style={{ borderRadius: "0px" }}
                    allowClear
                    options={[{ value: '2', label: '2' }, { value: '1', label: '1' }, { value: '0', label: '0' }]}
                />)
            },
            {
                colSpan: 11, name: '', label: '0 - Không, 1 - Chi tiết , 2 - Đơn vị',
                render: (<></>), width: 500
            },
            {
                colSpan: 13, name: 'baseUnitCode', label: 'Đơn vị',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(8) }],
                disabled: dataTab?.mode === "detail", placeholder: "Nhập đơn vị",
                render: (<>
                    <InputSearchLookup
                        disabled={dataTab?.mode === "detail"}
                        fetchDataFunc={InputSearchApiService.getCatalogDymamic("MA_DVCS")}
                        placeHolder='Nhập mã đvcs'
                        keyField={'ma_dvcs'}
                        columns={InputSearchLookupColumns.baseUnit}
                        lookupAdding={{
                            initValues: {
                            },
                            catalogAddModalType: CatalogAddModalType.BaseUnit
                        }}
                        onOutputChange={(value: string) => {
                            form.setFieldsValue({
                                baseUnitCode: value
                            })
                        }}
                        patchValue={{ text: dataTab?.dataEdit?.baseUnitCode as string }}

                    />
                </>)
            },
        ]
    }, [dataTab?.mode])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={24}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, []);

    return (
        <>
            {renderColumns(session1Cols)}
            <Form.Item
                label="TK in cân đối PS"
                name="balancedPrinting"
                labelCol={{ style: { width: 130 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={dataTab?.mode === "detail"}
                >
                </Checkbox>
            </Form.Item>
            <Form.Item
                label="Không có số dư"
                name="printBalance0"
                labelCol={{ style: { width: 130 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={dataTab?.mode === "detail"}
                >
                </Checkbox>
            </Form.Item>
            {renderColumns(session2Cols)}
        </>
    );
};

export default AccountTabAdvance;
