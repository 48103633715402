import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { Rule } from 'antd/lib/form';
import React, { useMemo } from 'react';

interface IMaterialTypeEdit {
    isEditting: boolean;
}

const MaterialTypeKeyInfomation: React.FC<IMaterialTypeEdit> = ({ isEditting }: IMaterialTypeEdit) => {

    const validateTextCharater = (maxLen: number) => (_: any, value: string) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const mainInforCols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'materialTypeName', label: 'Tên loại vật tư',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập tên vật tư" },
                { validator: validateTextCharater(128) }
                ] as Rule[]
            },
            {
                colSpan: 12, name: 'materialTypeName2', label: 'Tên loại vật tư 2',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(128) }
                ] as Rule[]
            },
        ]
    }, [])

    const renderColumns = () => {
        return (
            <Row gutter={24}>
                {
                    mainInforCols.map((col) => (
                        formItemInputWithColumn(col)
                    ))
                }
            </Row>
        )
    }

    return (
        <>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label="Mã loại vật tư"
                        name="materialTypeCode"
                        labelCol={{ style: { width: 130 } }}
                        labelAlign='left'
                        rules={[{ required: true, message: "Vui lòng nhập mã loại vật tư" },
                        { validator: validateTextCharater(2) }
                        ]}
                    >
                        <Input
                            placeholder={`Nhập Giá Tồn`}
                            disabled={isEditting ? true : false}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Ghi chú"
                        name="note"
                        labelCol={{ style: { width: 130 } }}
                        labelAlign='left'
                        rules={[
                            { validator: validateTextCharater(128) }
                        ]}
                    >
                        <Input
                            placeholder={`Nhập ghi chú`}
                        />
                    </Form.Item>
                </Col>
            </Row>
            {renderColumns()}
            <Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 130 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item>
        </>
    );
};

export default MaterialTypeKeyInfomation;