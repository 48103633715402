import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { ForeignCurrencyListView } from '../views/ForeignCurrencyListView';

const ForeignCurrencyListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách ngoại tệ</PageTitle>
            <ForeignCurrencyListView />
        </>
    );
};

export default ForeignCurrencyListPage;
