import { InputSearchApiService } from '@app/api/input-search-api.service';
import { MaterialUpdateDto } from '@app/api/v6.api';
import InputSearchLookup from '@app/components/common/input-search-lookup/InputSearchLookup';
import { InputSearchLookupColumns } from '@app/components/common/input-search-lookup/constants/input-lookup-entity-columns.constant';
import { IInputSearchLookupApiRequest } from '@app/components/common/input-search-lookup/models/input-search-lookup-column';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { CatalogAddModalType } from '@app/components/shared/modals/catalog-add-modal/catalog-add-modal';
import { PaginatedList } from '@app/shared/models/paginated-list.model';
import { Checkbox, Col, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import Form, { Rule } from 'antd/lib/form';
import React, { useCallback, useMemo } from 'react';

interface IMaterialEdit {
    form: FormInstance<MaterialUpdateDto>;
}

const MaterialMainInfomation: React.FC<IMaterialEdit> = ({
    form,
}: IMaterialEdit) => {
    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 9, name: 'materialInventory', label: 'Theo dõi tồn kho',
                render: (< Select
                    style={{ width: "100%", height: 30 }}
                    defaultValue={0}
                    options={[{ value: '1', label: '1' }, { value: '0', label: '0' }]}
                />)
            },
            { colSpan: 15, name: "", label: '1 - Theo dõi tồn kh, 0 - Không theo dõi tồn kho', width: 300, render: (<></>) },
            {
                colSpan: 9, name: 'inventoryPrice', label: 'Cách tính giá tồn kho',
                render: (< Select
                    style={{ width: "100%", height: 30 }}
                    defaultValue={1}
                    options={[
                        { value: '1', label: '1' },
                        { value: '2', label: '2' },
                        { value: '3', label: '3' },
                        { value: '4', label: '4' }
                    ]}
                />)
            },
            { colSpan: 15, name: "", label: '1 - Giá trung bình, 2 - Đích danh, 3 - Nhập trước xuất trước, 4 - Trung bình di động', width: 500, render: (<></>) },

        ]
    }, [])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={24}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, []);

    const validateTextCharater = (maxLen: number) => (_: any, value: any) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const baseInputLookUp = useCallback((name: string, label: string, groupType: number) => {
        const fetchDataFunc: (request: IInputSearchLookupApiRequest) => Promise<PaginatedList<any>> = InputSearchApiService.getCatalogDymamic("NH_VT");
        return (
            <Col span={12}>
                <Form.Item
                    name={name}
                    label={label}
                    labelCol={{ style: { width: 130 } }}
                    labelAlign='left'
                    className="w-100"
                    rules={name === "materialGroup7" || name === "materialGroup8" ? [
                        { validator: validateTextCharater(8) }
                    ] : [
                        { validator: validateTextCharater(16) }
                    ]}
                >
                    <InputSearchLookup
                        fetchDataFunc={fetchDataFunc}
                        placeHolder={`Nhập ${label}`}
                        keyField={'groupCode'}
                        columns={InputSearchLookupColumns.materialGroup}
                        lookupAdding={{
                            initValues: {
                                groupType: groupType
                            },
                            catalogAddModalType: CatalogAddModalType.MaterialGroup
                        }}
                        onOutputChange={(value: string) => {
                            form.setFieldsValue({ [name]: value });
                        }}
                    />
                </Form.Item>
            </Col>
        )
    }, []);

    return (
        <>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name="unit"
                        label="Đơn vị tính"
                        labelCol={{ style: { width: 130 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <InputSearchLookup
                            fetchDataFunc={InputSearchApiService.getCatalogDymamic("DVT")}
                            placeHolder={`Nhập đơn vị tính`}
                            keyField={'dvt'}
                            columns={InputSearchLookupColumns.measurementUnit}
                            lookupAdding={{
                                initValues: {
                                },
                                catalogAddModalType: CatalogAddModalType.MeasurementUnit
                            }}
                            onOutputChange={(value: string) => {
                                form.setFieldsValue({ unit: value });
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    < Form.Item
                        label=""
                        name="units"
                        labelCol={{ style: { width: 130 } }}
                        labelAlign='left'
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Checkbox
                            style={{
                                marginRight: "10px",
                            }}
                        >
                            Nhiều đơn vị tính?
                        </Checkbox>
                    </Form.Item >
                </Col>
            </Row>
            {renderColumns(session1Cols)}
            <Row gutter={24}>
                {baseInputLookUp("materialGroup1", "Nhóm vật tư 1", 1)}
                {baseInputLookUp("materialGroup4", "Nhóm vật tư 4", 4)}
                {baseInputLookUp("materialGroup2", "Nhóm vật tư 2", 2)}
                {baseInputLookUp("materialGroup5", "Nhóm vật tư 5", 5)}
                {baseInputLookUp("materialGroup3", "Nhóm vật tư 3", 3)}
                {baseInputLookUp("materialGroup6", "Nhóm vật tư 6", 6)}
                {formItemInputWithColumn({
                    colSpan: 12, name: 'barCode', label: 'Mã vạch',
                    rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(13) }] as Rule[],
                }, 1)}
                {baseInputLookUp("materialGroup7", "Nhóm vật tư 7", 7)}
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name={'materialType'}
                        label={'Loại vật tư'}
                        labelCol={{ style: { width: 130 } }}
                        labelAlign='left'
                        className="w-100"
                        rules={[
                            { validator: validateTextCharater(2) }
                        ]}
                    >
                        <InputSearchLookup
                            fetchDataFunc={InputSearchApiService.getCatalogDymamic("LOAI_VT")}
                            placeHolder='Nhập loại vật tư'
                            keyField={'loai_vt'}
                            columns={InputSearchLookupColumns.materialType}
                            onOutputChange={(value: string) => {
                                form.setFieldsValue({
                                    materialType: value
                                })
                            }}
                        />
                    </Form.Item>
                </Col>
                {baseInputLookUp("materialGroup8", "Nhóm vật tư 8", 8)}
            </Row >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name={"tax"}
                        label={"Mã thuế"}
                        labelCol={{ style: { width: 130 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <InputSearchLookup
                            fetchDataFunc={InputSearchApiService.getCatalogDymamic("MA_THUE")}
                            placeHolder={`Nhập mã thuế`}
                            keyField={'ma_thue'}
                            columns={InputSearchLookupColumns.tax}
                            lookupAdding={{
                                initValues: {
                                },
                                catalogAddModalType: CatalogAddModalType.Tax
                            }}
                            onOutputChange={(value: string) => {
                                form.setFieldsValue({ tax: value });
                            }}
                        />
                    </Form.Item>
                </Col>
                {formItemInputWithColumn({
                    colSpan: 12, label: "Thuế xuất VAT", name: "exportTax",
                    rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(9) }] as Rule[],
                }, 1)}
            </Row>
            < Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 130 } }
                }
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item >
        </>
    );
};

export default MaterialMainInfomation;
