import apiServices from '@app/api/api.services';
import { CatalogPriceIdUpdateDto } from '@app/api/v6.api';
import { Modal } from '@app/components/common/Modal/Modal';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import { antFormFinishErrorNotification } from '@app/shared/functions/common.function';
import { Form, Row, Tabs, TabsProps } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import CatalogPriceIdKeyInfomation from './tabs/CatalogPriceIdKeyInfomation';
import './CatalogPriceIdModal.less'

interface CatalogPriceIdModalProps {
    code?: string;
    visible: boolean;
    mode: "add" | "edit" | "detail";
    onClose: (reload?: boolean) => void;
}

export const CatalogPriceIdModal: React.FC<CatalogPriceIdModalProps> = ({
    code, visible, mode,
    onClose,
}: CatalogPriceIdModalProps) => {
    const [form] = BaseForm.useForm<CatalogPriceIdUpdateDto>();
    const [currentMode, setCurrentMode] = useState(mode);
    const [loading, setLoading] = useState<boolean>(false);

    const isAdd = useMemo(() => { return currentMode === 'add' }, [currentMode]);
    const isDetail = useMemo(() => { return currentMode === 'detail' }, [currentMode]);

    const titleModal = useMemo(() => {
        if (isAdd) {
            return 'Thêm mới mã giá';
        }

        const prefixTitle = isDetail ? "Chi tiết" : "Chỉnh sửa";
        return `${prefixTitle} ${code}`;
    }, [currentMode, code]);

    const handleCancel = async () => {
        onClose();
    };

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Thông tin chính',
            children: <CatalogPriceIdKeyInfomation
                mode={currentMode}
            />,
        },
    ];

    const fetchData = useCallback(async () => {
        if (isAdd) {
            return;
        }

        const catalogPriceId = await apiServices.v6Api.catalogPriceIdsGetByCatalogPriceId(code as string);
        if (catalogPriceId) {
            form.setFieldsValue({
                ...catalogPriceId,
                status: (catalogPriceId.status === '1') as any,
            })
        }
    }, [code, currentMode])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleDisable = (() => {
        setCurrentMode("edit")
    });

    const onFinish = async () => {
        if (isDetail) {
            handleDisable();
            return;
        }
        const data = await form.validateFields();
        data.status = data.status ? '1' : '0';

        setLoading(true);
        const promise = code
            ? apiServices.v6Api.catalogPriceIdsUpdateByCode(code, data)
            : apiServices.v6Api.catalogPriceIdsCreate(data);

        promise.then((response) => {
            if (response.isSuccess) {
                notificationController.success({ message: 'Thành công' });
                onClose(true);
                return;
            }

            notificationController.error({ message: response.message });
        }).catch(() => {
            notificationController.error({ message: 'Thất bại' });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal
            title={titleModal}
            confirmLoading={loading}
            open={visible}
            destroyOnClose={true}
            width={"1000px"}
            onOk={() => form.submit()}
            onCancel={() => handleCancel()}
            okText={isDetail ? "Chỉnh sửa" : "Lưu"}
            cancelText="Hủy"
            okButtonProps={{ style: { padding: "5px", height: "36px" } }}
            cancelButtonProps={{ style: { padding: "5px", height: "36px" } }}
        >
            <div className='basic-form'>
                <Form
                    form={form}
                    style={{ width: "95%" }}
                    name="basic"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onFinish={onFinish}
                    onFinishFailed={antFormFinishErrorNotification}
                >
                    <Tabs defaultActiveKey="1" items={items} />

                </Form>
            </div>
        </Modal>
    )
}