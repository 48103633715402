import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Checkbox, Form, InputNumber, Row, Select } from 'antd';
import React, { useCallback, useMemo } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { Utils } from '@app/shared/helpers/utils';

interface IEmployeeEdit {
    mode: "add" | "edit" | "detail";
}
const EmployeeKeyInfomation: React.FC<IEmployeeEdit> = ({ mode,
}: IEmployeeEdit) => {
    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 10, name: 'han_tt', label: 'Hạn TT', disabled: mode === "detail",
                render: (
                    <InputNumber disabled={mode === "detail"} min={0} maxLength={16} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập hạn thanh toán' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 10, name: '', label: '',
                render: (<>
                </>)
            },
            {
                colSpan: 10, name: 'loai', label: 'Loại', disabled: mode === "detail",
                render: (< Select
                    disabled={mode === "detail"}
                    style={{ width: "100%", height: 30 }}
                    defaultValue={0}
                    options={[
                        { value: '0', label: '0' },
                        { value: '1', label: '1' },
                        { value: '2', label: '2' },
                        { value: '3', label: '3' }
                    ]}
                />)
            },
            {
                colSpan: 10, name: '', label: '0: BP khác, 1 : BP bán hàng, 2: BP mua hàng, 3:BP kế hoạch', width: 500,
                render: (<>
                </>)
            },
            {
                colSpan: 24, name: 'ghi_chu', label: 'Ghi chú',
                render: (
                    <TextArea
                        disabled={mode === "detail"}
                        rows={4}
                    />
                )
            },
        ]
    }, [mode])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={10}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, [mode]);

    return (
        <>
            {renderColumns(session1Cols)}

            <Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 130 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={mode === "detail"}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item>
        </>
    );
};

export default EmployeeKeyInfomation;