import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { CatalogPriceIdListView } from '../views/CatalogPriceIdListView';

const CatalogPriceIdListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách mã giá</PageTitle>
            <CatalogPriceIdListView />
        </>
    );
};

export default CatalogPriceIdListPage;
