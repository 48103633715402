import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { BankAccountListView } from '../views/BankAccountListView';

const BankAccountListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách tài khoản ngân hàng</PageTitle>
            <BankAccountListView />
        </>
    );
};

export default BankAccountListPage;
