import { Modal } from '@app/components/common/Modal/Modal';
import { useCallback, useEffect, useState } from 'react';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Form, Tabs, TabsProps } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import apiServices from '@app/api/api.services';
import { antFormFinishErrorNotification } from '@app/shared/functions/common.function';
import AccountMainInfomation from './account-tabs/AccountTabMainInfomation';
import AccountTabAdvance from './account-tabs/AccountTabAdvance';
import { Account0Dto } from '@app/api/v6.api';

interface IModalAccount {
    code?: string;
    visible: boolean;
    mode: "add" | "edit" | "detail";
    onClose: (reload?: boolean) => void;
}

interface ITab {
    dataEdit?: Account0Dto;
    mode: "add" | "edit" | "detail";
}

export const AccountModal: React.FC<IModalAccount> = ({ code, visible, mode, onClose }: IModalAccount) => {
    const [form] = BaseForm.useForm();
    const [titleModal, setTileModal] = useState("Thêm mới tài khoản");
    const [loading, setLoading] = useState<boolean>(false);
    const [dataTab, setDataTab] = useState<ITab>();

    const handleCancel = async () => {
        setDataTab(undefined);
        onClose();
    };

    const fetchData = useCallback(async () => {
        setDataTab(undefined);
        if (code) {
            if (code) {
                const dataEditAccount = await apiServices.v6Api.account0sGetById(code);
                form.setFieldsValue({
                    ...dataEditAccount,
                    status: (dataEditAccount.status === '1' ? true : false) as any,
                    balancedPrinting: (dataEditAccount.balancedPrinting === '1' ? true : false) as any,
                    printBalance0: (dataEditAccount.printBalance0 === '1' ? true : false) as any,
                })
                setDataTab({ dataEdit: dataEditAccount, mode: mode })
            }
            setTileModal(`${mode === "detail" ? "Chi tiết" : "Chỉnh sửa"} tài khoản - ${code}`);
        } else {
            setTileModal(`Thêm mới tài khoản`);
        }
    }, [code, mode, form])

    useEffect(() => {
        form.resetFields();
        code ? setDataTab({ dataEdit: dataTab?.dataEdit, mode: mode }) : setDataTab({ dataEdit: dataTab?.dataEdit, mode: "add" })
        fetchData();
    }, [fetchData, form, code, mode])

    const handleDisable = (() => {
        setDataTab({ dataEdit: dataTab?.dataEdit, mode: "edit" })
        setTileModal(`${dataTab?.mode !== "detail" ? "Chi tiết" : "Chỉnh sửa"} tài khoản - ${code}`);
    });

    const renderForm = useCallback(() => {
        return <>
            <Form
                form={form}
                name="basic"
                initialValues={{ remember: true }}
                autoComplete="off"
                onFinish={onFinish}
                onFinishFailed={antFormFinishErrorNotification}
            >
                <Tabs defaultActiveKey="1" items={items} />
            </Form>
        </>
    }, [dataTab, code])

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Thông tin chính',
            children: <AccountMainInfomation
                code={code}
                form={form}
                dataTab={dataTab}
            />,
        }, {
            key: '2',
            label: 'Advance',
            children: <AccountTabAdvance
                form={form}
                dataTab={dataTab}
            />,
        }
    ];

    const onFinish = async () => {
        if (dataTab?.mode === "detail") {
            handleDisable();
            return;
        }

        const data = await form.validateFields();
        data.status = data.status ? '1' : '0';
        data.balancedPrinting = data.balancedPrinting ? '1' : '0';
        data.printBalance0 = data.printBalance0 ? '1' : '0';

        setLoading(true);
        const promise = code
            ? apiServices.v6Api.account0sUpdateAccount0(code, data)
            : apiServices.v6Api.account0sCreateAccount0(data);

        promise.then((response) => {
            if (response.isSuccess) {
                notificationController.success({ message: 'Thành công' });
                onClose(true);
                return;
            }

            notificationController.error({ message: response.message });
        }).catch(() => {
            notificationController.error({ message: 'Thất bại' });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal
            title={titleModal}
            confirmLoading={loading}
            open={visible}
            destroyOnClose={true}
            width={"1000px"}
            onOk={() => form.submit()}
            onCancel={() => handleCancel()}
            okText={dataTab?.mode === "detail" ? "Chỉnh sửa" : "Lưu"}
            cancelText="Hủy"
            okButtonProps={{ style: { padding: "5px", height: "36px" } }}
            cancelButtonProps={{ style: { padding: "5px", height: "36px" } }}
        >
            <div className='basic-form'>
                {renderForm()}
            </div>
        </Modal>
    )
}