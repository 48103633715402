import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { DepartmentListView } from '../views/DepartmentListView';

const DepartmentListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách bộ phận</PageTitle>
            <DepartmentListView />
        </>
    );
};

export default DepartmentListPage;
