import apiServices from '@app/api/api.services';
import { InputSearchApiService } from '@app/api/input-search-api.service';
import { InvoinceAutoColumnDto, MaterialUnitConversionDto } from '@app/api/v6.api';
import InputSearchLookup from '@app/components/common/input-search-lookup/InputSearchLookup';
import { InputSearchLookupColumns } from '@app/components/common/input-search-lookup/constants/input-lookup-entity-columns.constant';
import { IS_AD_NUMBER_COLUMN, InvoiceADColumnFtype } from '@app/pages/Invoices/shared/enums/invoice.enum';
import { Utils } from '@app/shared/helpers/utils';
import { ICatalogMaterial } from '@app/shared/models/catalog-material.model';
import { Form, FormInstance, Input, InputNumber, Select } from 'antd';
import { useState } from 'react';
import { ColumnsDisabledByLoaiCKFunc, ColumnsDisabledByThanhTienFunc, Invoice91ColumnsDisabled, Invoice91_FColumnConstant, MoneyColumnsSetDefaultByGiaNT21 } from '../../invoice-91/constants/invoice-91.contant';
import { InvoiceFunc } from '../functions/invoice.func';

const FColumnConstant = Invoice91_FColumnConstant;

export interface IUMaterial91ListViewListViewFilter {
    loaiCK: boolean;
    suaTien: boolean;
    addForm: FormInstance<any>;
    adAutoColumns: InvoinceAutoColumnDto[];
}

export const ADAutoColumnsForm: React.FC<IUMaterial91ListViewListViewFilter> = ({
    loaiCK,
    suaTien,
    addForm,
    adAutoColumns
}: IUMaterial91ListViewListViewFilter) => {
    const [addFormMaterialNhieuDVT, setAddFormMaterialNhieuDVT] = useState<boolean>(false);
    const [addFormMaterialUnits, setAddFormMaterialUnits] = useState<MaterialUnitConversionDto[]>([]);

    async function fetchUnitConverionsByMaterial(materialCode: string) {
        const units = await apiServices.v6Api.materialsGetMaterialUnitConversionsByMaterialCode(materialCode);

        setAddFormMaterialUnits(units || []);
    }

    // #region Handle Money, Discount Change
    function calculateTotalMoney() {
        const soLuong1 = addForm.getFieldValue(FColumnConstant.SO_LUONG1) ?? 0;
        const giaNT21 = addForm.getFieldValue(FColumnConstant.GIA_NT21) ?? 0;
        const totalMoney = soLuong1 * giaNT21;

        const data = {} as any;
        data[FColumnConstant.TIEN2] = data[FColumnConstant.TIEN_NT2] = totalMoney;

        addForm.setFieldsValue(data);
    }

    function calculateGiaFromTienNT2() {
        const tienNT2 = addForm.getFieldValue(FColumnConstant.TIEN_NT2) ?? 0;
        const soLuong1 = addForm.getFieldValue(FColumnConstant.SO_LUONG1) ?? 0;
        const giaNT21 = soLuong1 > 0 ? tienNT2 / soLuong1 : 0;

        const data = {} as any;
        data[FColumnConstant.GIA_NT21] = giaNT21;

        addForm.setFieldsValue(data);
    }

    function onDiscountPercentChange() {
        let ck_nt = addForm.getFieldValue(FColumnConstant.CK_NT) ?? 0;
        const ptCKI = addForm.getFieldValue(FColumnConstant.PT_CKI) ?? 0;

        const data = {} as any;
        if (ptCKI > 0) {
            const soLuong1 = addForm.getFieldValue(FColumnConstant.SO_LUONG1) ?? 0;
            const giaNT21 = addForm.getFieldValue(FColumnConstant.GIA_NT21) ?? 0;
            const totalMoney = soLuong1 * giaNT21;

            ck_nt = +(totalMoney * ptCKI / 100).toFixed(0);
        } else {
            ck_nt = 0;
        }

        data[FColumnConstant.CK_NT] = data[FColumnConstant.CK] = ck_nt;
        addForm.setFieldsValue(data);
    }

    function onDiscountChange() {
        const ptCKI = addForm.getFieldValue(FColumnConstant.PT_CKI) ?? 0;

        if (ptCKI > 0) {
            onDiscountPercentChange();
        }
    }
    // #endregion Handle Money, Discount Change

    // #region Handle AD Form Change

    function onADFieldChanged(fcolumn: string, value: any) {
        switch (fcolumn) {
            case FColumnConstant.ma_vt:
                handleMaterialChanged(value);
                break;
            case FColumnConstant.MAVV_I:
                // addForm.setFieldValue
                break;

            case FColumnConstant.SO_LUONG1:
                const soLuong1 = addForm.getFieldValue(FColumnConstant.SO_LUONG1);
                addForm.setFieldValue(FColumnConstant.SO_LUONG, soLuong1);

                calculateTotalMoney();
                break

            case FColumnConstant.GIA_NT21:
                InvoiceFunc.formCopyValues(addForm, FColumnConstant.GIA_NT21, MoneyColumnsSetDefaultByGiaNT21);

                calculateTotalMoney();
                break;

            case FColumnConstant.TIEN_NT2:
                InvoiceFunc.formCopyValues(addForm, FColumnConstant.TIEN_NT2, [FColumnConstant.TIEN2]);
                calculateGiaFromTienNT2();
                InvoiceFunc.formCopyValues(addForm, FColumnConstant.GIA_NT21, MoneyColumnsSetDefaultByGiaNT21);
                break;

            case FColumnConstant.PT_CKI:
                onDiscountPercentChange();
                break;
            case FColumnConstant.CK_NT:
                onDiscountChange();
                break;
            default:
                break;
        }
    }

    // #region Handle Material Change
    const handleMaterialChanged = (value: ICatalogMaterial) => {
        const materialCode = addForm.getFieldValue(FColumnConstant.ma_vt);
        fetchUnitConverionsByMaterial(materialCode);

        setAddFormMaterialNhieuDVT(value.nhieu_dvt === '1');

        addForm.setFieldsValue({
            ten_vt: value.ten_vt,
            dvt1: value.dvt,
            dvt: value.dvt
        })
    }
    // #endregion Handle Material Change

    // #endregion Handle AD Form Change

    return (
        <Form form={addForm}
            layout="vertical"
            className='add-form form-auto-columns d-flex'>
            {
                (adAutoColumns || []).map((column, index) => {
                    const columnWidth = (column.width ?? 100);
                    const fcolumn = column.fcolumn?.toLowerCase() ?? '';
                    const requiredVar = column.checkvvar; // check value before leaving // Todo: Phi
                    const notEmpty = column.notempty ?? false;
                    const formItemDisplay = column.fstatus ? '' : 'none';
                    const disabledByLoaiCK = ColumnsDisabledByLoaiCKFunc(fcolumn, loaiCK);
                    const disabledByThanhTien = ColumnsDisabledByThanhTienFunc(fcolumn, suaTien);
                    const numberColumnForceDisabled = Invoice91ColumnsDisabled.includes(fcolumn);

                    if (column.ftype === InvoiceADColumnFtype.Catalog && fcolumn === FColumnConstant.DVT1) {
                        return (
                            <Form.Item
                                key={index}
                                name={fcolumn}
                                label={column.caption}
                                style={{
                                    marginLeft: 3,
                                    minWidth: columnWidth,
                                    maxWidth: columnWidth,
                                    marginBottom: 0,
                                    display: formItemDisplay
                                }}
                                className='add-form-item-dvt'
                                rules={
                                    notEmpty
                                        ? [
                                            { required: true }
                                        ] : undefined
                                }
                            >
                                <Select
                                    disabled={!addFormMaterialNhieuDVT || disabledByLoaiCK}
                                    allowClear={false}
                                    showSearch
                                    placeholder={column.caption!}
                                >
                                    {addFormMaterialUnits?.map((i, index) => (
                                        <Select.Option key={index} value={i.dvt}>
                                            {i.dvt}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )
                    }

                    if (column.ftype === InvoiceADColumnFtype.Catalog) {
                        const fvvar = column.fvvar?.toLowerCase() ?? '';
                        const columns = InputSearchLookupColumns.catalogColumns[fvvar];
                        const catalogCodeKeyField = columns?.length > 0 ? columns[0].dataIndex : fcolumn;
                        const disabled = !fvvar;

                        return (<Form.Item
                            key={index}
                            name={fcolumn}
                            className='add-form-item'
                            label={column.caption}
                            style={{
                                marginLeft: 3,
                                zIndex: 99999,
                                display: formItemDisplay
                            }}>
                            <InputSearchLookup
                                notEmpty={notEmpty}
                                disabled={disabled || disabledByLoaiCK}
                                key={index}
                                style={{
                                    marginLeft: 3,
                                    minWidth: columnWidth,
                                    maxWidth: columnWidth
                                }}
                                inputStyle={{
                                    padding: 0,
                                    paddingLeft: 2
                                }}
                                fetchDataFunc={InputSearchApiService.getCatalogDymamic(fvvar)}
                                placeHolder={column.caption!}
                                keyField={catalogCodeKeyField}
                                columns={columns}
                                onOutputSelectRecord={(record: any) => {
                                    const data = {} as any;
                                    data[fcolumn] = record[catalogCodeKeyField];
                                    addForm.setFieldsValue(data)

                                    onADFieldChanged(fcolumn, record);
                                }}
                            />
                        </Form.Item>
                        )
                    }

                    if (IS_AD_NUMBER_COLUMN(column.ftype ?? '')) {
                        return <Form.Item
                            key={index}
                            name={fcolumn}
                            className='add-form-item auto-column-number'
                            label={column.caption}
                            style={{
                                marginLeft: 3,
                                minWidth: columnWidth,
                                maxWidth: columnWidth,
                                marginBottom: 0,
                                display: formItemDisplay
                            }}
                            rules={
                                notEmpty
                                    ? [
                                        { required: true }
                                    ] : undefined
                            }
                        >
                            <InputNumber formatter={Utils.numberFormatter}
                                disabled={disabledByThanhTien || disabledByLoaiCK || numberColumnForceDisabled}
                                className='auto-column-number-input w-100'
                                style={{ padding: 0 }}
                                placeholder={column.caption ?? ''}
                                autoComplete='off'
                                onChange={() => {
                                    onADFieldChanged(fcolumn, null);
                                }}
                            />
                        </Form.Item>
                    }

                    return (
                        <Form.Item
                            key={index}
                            name={fcolumn}
                            className='add-form-item'
                            label={column.caption}
                            style={{
                                marginLeft: 3,
                                minWidth: columnWidth,
                                maxWidth: columnWidth,
                                marginBottom: 0,
                                display: formItemDisplay
                            }}
                            rules={
                                notEmpty
                                    ? [
                                        { required: true }
                                    ] : undefined
                            }
                        >
                            <Input style={{ padding: 0 }}
                                disabled={disabledByThanhTien || disabledByLoaiCK}
                                placeholder={column.caption ?? ''}
                            />
                        </Form.Item>
                    );
                })
            }

            <Form.Item name='ten_vt' style={{ visibility: 'hidden' }}>
                <Input />
            </Form.Item>
        </Form>
    )
}