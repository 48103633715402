import { Modal } from '@app/components/common/Modal/Modal';
import { useCallback, useEffect, useState } from 'react';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Form, Tabs, TabsProps } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import apiServices from '@app/api/api.services';
import { antFormFinishErrorNotification } from '@app/shared/functions/common.function';
import AssetGroupMainInfomation from './asset-group-tabs/AssetGroupTabMainInfomation';

interface IModalAssetGroup {
    code?: string;
    visible: boolean;
    mode: "add" | "edit" | "detail";
    onClose: (reload?: boolean) => void;
}

export const AssetGroupModal: React.FC<IModalAssetGroup> = ({ code, visible, mode, onClose }: IModalAssetGroup) => {
    const [form] = BaseForm.useForm();
    const [titleModal, setTileModal] = useState("Thêm mới nhóm tài sản");
    const [currentMode, setCurrentMode] = useState(mode);
    const [loading, setLoading] = useState<boolean>(false);

    const handleCancel = async () => {
        onClose();
    };

    const fetchData = useCallback(async () => {
        if (code) {
            if (code) {
                const dataEditAssetGroup = await apiServices.v6Api.assetGroupsGetById(code);
                form.setFieldsValue({
                    ...dataEditAssetGroup,
                    status: (dataEditAssetGroup.status === '1' ? true : false) as any
                })
            }
            setTileModal(`${mode === "detail" ? "Chi tiết" : "Chỉnh sửa"} nhóm tài sản - ${code}`);
        } else {
            setTileModal(`Thêm mới nhóm tài sản`);
        }
    }, [code, mode, form])

    useEffect(() => {
        form.resetFields();
        code ? setCurrentMode(mode) : setCurrentMode("add");
        fetchData();
    }, [fetchData, form, code, mode])

    const handleDisable = (() => {
        setCurrentMode("edit")
        setTileModal(`${currentMode !== "detail" ? "Chi tiết" : "Chỉnh sửa"} nhóm tài sản - ${code}`);
    });

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Thông tin chính',
            children: <AssetGroupMainInfomation
                code={code}
                currentMode={currentMode}
            />,
        },
    ];

    const onFinish = async () => {
        if (currentMode === "detail") {
            handleDisable();
            return;
        }

        const data = await form.validateFields();
        data.status = data.status ? '1' : '0';

        setLoading(true);
        const promise = code
            ? apiServices.v6Api.assetGroupsUpdateAssetGroup(code, data)
            : apiServices.v6Api.assetGroupsCreateAssetGroup(data);

        promise.then((response) => {
            if (response.isSuccess) {
                notificationController.success({ message: 'Thành công' });
                onClose(true);
                return;
            }

            notificationController.error({ message: response.message });
        }).catch(() => {
            notificationController.error({ message: 'Thất bại' });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal
            title={titleModal}
            confirmLoading={loading}
            open={visible}
            destroyOnClose={true}
            width={"1000px"}
            onOk={() => form.submit()}
            onCancel={() => handleCancel()}
            okText={currentMode === "detail" ? "Chỉnh sửa" : "Lưu"}
            cancelText="Hủy"
            okButtonProps={{ style: { padding: "5px", height: "36px" } }}
            cancelButtonProps={{ style: { padding: "5px", height: "36px" } }}
        >
            <div className='basic-form'>
                <Form
                    form={form}
                    style={{ width: "95%" }}
                    name="basic"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onFinish={onFinish}
                    onFinishFailed={antFormFinishErrorNotification}
                >
                    <Tabs defaultActiveKey="1" items={items} />
                </Form>
            </div>
        </Modal>
    )
}