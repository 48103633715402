import apiServices from '@app/api/api.services';
import { AD91Dto, AM91Dto, Invoice91PostDataDto, Invoice91PostDto, InvoinceAutoColumnDto, VpaGetAutoColumnRequestDto } from '@app/api/v6.api';
import { notificationController } from '@app/controllers/notificationController';
import { InvoiceFunc } from '@app/pages/Invoices/shared/functions/invoice.func';
import { antFormFinishErrorNotification } from '@app/shared/functions/common.function';
import { NumberHelpers } from '@app/shared/helpers/number.helper';
import { IAM91 } from '@app/shared/models/invoices/am91.model';
import { Constant } from '@app/styles/themes/constants';
import { Form } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InvoiceAMColumnConstant, InvoiceColumnConstant, InvoiceConstant } from "../../../shared/constants/invoice.constant";
import { AD91FieldFormatBoolToString, AM91FieldFormatBoolToNumber, AM91FieldFormatBoolToString, Invoice91_FColumnConstant } from '../../constants/invoice-91.contant';
import useAM91ModalStore from '../../stores/am-91-modal.store';
import AM91FormHiddenItem from './am-91-form-hidden-item';
import { AM91ModalDetails } from './am-91-modal-details/am-91-modal-details';
import AM91ModalMaster from './am-91-modal-master/am-91-modal-master';
import AM91ModalSummary from './am-91-modal-summary/am-91-modal-summary';
import './am-91-modal.less';
import * as S from './am-91-modal.style';
import { supportedColumnFType } from '@app/pages/Invoices/shared/enums/invoice.enum';

const DATA = 'data';

const M_ROUND = 0;
const M_ROUND_NT = 0;
const M_SOA_MULTI_VAT = false;

interface IAM91ModalProps {
    sttRec?: string;
    visible: boolean;
    mode: "add" | "edit" | "detail";
    onClose: (reload?: boolean) => void;
}

interface ITab {
    dataEdit?: any;
    mode: "add" | "edit" | "detail";
}

enum PostInvoiceParamType {
    CreateAndCheckExisted = '10',
    CreateAndReplace = '11',
}

export interface IADListForm {
    adDatas: AD91Dto[]
}

const AM91Modal: React.FC<IAM91ModalProps> = ({ sttRec, visible, mode, onClose }: IAM91ModalProps) => {
    // #region Store State
    const {
        initMaterials_UnitsFromADs,
        reset
    } = useAM91ModalStore();
    // #endregion Store State

    // #region UseMemo
    const isAdd = useMemo(() => mode === 'add', [mode]);
    const isEdit = useMemo(() => mode === 'edit', [mode]);
    const isDetail = useMemo(() => mode === 'detail', [mode]);

    const titleModal = useMemo(() => {
        if (isEdit) {
            return `Chỉnh sửa đơn bán hàng - ${sttRec}`;
        }

        if (isDetail) {
            return `Chi tiết đơn bán hàng - ${sttRec}`;
        }

        return "Thêm mới đơn bán hàng";
    }, [isAdd, isEdit, isDetail, sttRec]);
    // #endregion UseMemo

    // #region Form
    const [amForm] = Form.useForm<IAM91>();
    const [adListForm] = Form.useForm<any>();
    // #endregion Form

    // #region UseState
    const [loading, setLoading] = useState<boolean>(false);
    const [dataTab, setDataTab] = useState<ITab>();
    const [adAutoColumns, setADAutoColumns] = useState<InvoinceAutoColumnDto[]>([]);
    const [loaiCK, setLoaiCK] = useState<boolean>(true);
    const [summarySuaTien, setSummarySuaTien] = useState<boolean>(false);
    const [edittingAM91, setEdittingAM91] = useState<AM91Dto>(null!);

    // #region Dispatch Form/InputLookup Change
    const [dispatchADFormListChange, setDispatchADFormListChange] = useState<{
        adDetailsInForm: any[]
    }>(null!);
    // #endregion Dispatch Form/InputLookup Change

    // #endregion UseState

    // #region Init Form

    // #region Init Form - fetch master data
    function getDefaultMaDVCS() {
        const maDVCS = localStorage.getItem(Constant.BASE_UNIT);

        if (maDVCS) {
            amForm.setFieldsValue({
                ma_dvcs: maDVCS!
            })
        }
    }

    async function fetchVpaAutoColumn() {
        const data = await apiServices.v6Api.invoicesGetVPAAutoColumn({
            maCT: InvoiceConstant.Invoice91_Ma_CT
        } as VpaGetAutoColumnRequestDto);

        setADAutoColumns(data.filter(x => supportedColumnFType.includes(x.ftype!)));
    }

    useEffect(() => {
        fetchVpaAutoColumn();
    }, [])
    // #endregion Init Form - fetch master data

    // #region Init Form - Edit
    const formatDataForForm = useCallback((am91: AM91Dto) => {
        if (am91.ngay_ct) am91.ngay_ct = moment(am91.ngay_ct) as any;
        if (am91.ngay_lct) am91.ngay_lct = moment(am91.ngay_lct) as any;

        AM91FieldFormatBoolToString.forEach(fieldName => {
            const value = (am91 as any)[fieldName];
            (am91 as any)[fieldName] = value === '1';
        })

        AM91FieldFormatBoolToNumber.forEach(fieldName => {
            const value = (am91 as any)[fieldName];
            (am91 as any)[fieldName] = value === 1;
        })

        if (am91.aD91s?.length) {
            am91.aD91s.forEach(ad91 => {
                AD91FieldFormatBoolToString.forEach(fieldName => {
                    const value = (ad91 as any)[fieldName];
                    (ad91 as any)[fieldName] = value === '1';
                })
            })
        }
    }, []);

    const fetchAM91BySttRec = async () => {
        if (isAdd) {
            return;
        }

        const am91 = await apiServices.v6Api.invoicesGetAM91BySttRec(sttRec!);
        if (!am91) {
            return;
        }
        setEdittingAM91(am91);

        formatDataForForm(am91);

        amForm.setFieldsValue(am91 as any);

        const ad91s = am91.aD91s || [];

        initMaterials_UnitsFromADs(ad91s);

        setADListsIntoFormAndList(ad91s);

        loaiCKChange();

        // const datagetDonHang = new Invoice91GetDto();
        // datagetDonHang.sttRec = dataInvoice91s[0].sttRec
        // const dataInvoice91s12 = await apiServices.v6Api.invoicesGetAM91(datagetDonHang);

        // await amForm.setFieldValue(['data', 'amData'], dataInvoice91s12.amData)
        // const convertDateTime = dataInvoice91s12.amData?.ngayCT;
        // await amForm.setFieldValue(['data', 'amData', 'ngayCT'], convertDateTime ? moment(convertDateTime, "YYYY-MM-DD") : undefined)
        // const data = JSON.parse(JSON.stringify(dataInvoice91s)) as Invoice91ADDataDto[]
        // // setADDetails(data);
        // setDataTab({ dataEdit: dataInvoice91s12, mode: mode })
    }
    // #endregion Init Form - Edit

    useEffect(() => {
        reset();

        if (isAdd) {
            getDefaultMaDVCS();
        }

        if (isEdit || isDetail) {
            fetchAM91BySttRec();
        }
    }, [])
    // #endregion Init Form

    const handleCancel = async () => {
        setDataTab(undefined);
        onClose();
    };

    // useEffect(() => {
    //     // amForm.resetFields();
    //     if (sttRec) {
    //         setDataTab({ dataEdit: dataTab?.dataEdit, mode: mode })
    //     } else {
    //         setDataTab({ dataEdit: dataTab?.dataEdit, mode: "add" });
    //     }

    //     fetchData();
    // }, [fetchData, amForm, sttRec, mode])

    // const fetchVpaAutoColumn = useCallback(async () => {
    //     const data = await apiServices.v6Api.vpaAutoColumnGet({
    //         maCT: 'SOH'
    //     } as VpaGetAutoColumnRequestDto);

    //     setADAutoColumns(data);
    // }, [])

    const handleDisable = (() => {
        setDataTab({ dataEdit: dataTab?.dataEdit, mode: "edit" })
    });

    function validateAMData(amData: IAM91): boolean {
        if (!amData?.ma_sonb) {
            notificationController.warning({ message: 'Chưa nhập mã số nội bộ' });
            return false;
        }

        if (!amData?.ma_kh) {
            notificationController.warning({ message: 'Chưa nhập mã khách hàng' });
            return false;
        }

        return true;
    }

    const onFinish = async () => {
        if (isDetail) {
            handleDisable();
            return;
        }

        const amData = await amForm.validateFields();

        AM91FieldFormatBoolToString.forEach(fieldName => {
            const value = Boolean((amData as any)[fieldName]);
            (amData as any)[fieldName] = value ? '1' : '0';
        })

        AM91FieldFormatBoolToNumber.forEach(fieldName => {
            const value = Number((amData as any)[fieldName]);
            (amData as any)[fieldName] = Number.isNaN(value) ? 0 : value;
        })

        const amDataIsValid = validateAMData(amData!);
        if (!amDataIsValid) {
            return;
        }

        const adDetails = adListForm.getFieldValue(DATA);

        if (!adDetails || adDetails.length === 0) {
            notificationController.warning({ message: 'Chưa nhập chi tiết vật tư' });
            return;
        }

        InvoiceFunc.formatDefaultFieldsForADDatas(adDetails);

        amData.status = Boolean(amData.status) ? '1' : '0';

        removeUnexpectedADFields(adDetails);
        const dataPost: Invoice91PostDto = {
            type: PostInvoiceParamType.CreateAndCheckExisted,
            data: {
                adDatas: adDetails as any,
                amData: amData as any
            } as Invoice91PostDataDto
        } as Invoice91PostDto;

        if (isEdit) {
            dataPost.type = PostInvoiceParamType.CreateAndReplace;
            dataPost!.data!.amData!.stt_rec = sttRec!;
        }

        const promise = apiServices.v6Api.invoicesPostDonDatHang(dataPost);

        promise.then((response) => {
            if (response.status) {
                notificationController.success({ message: 'Thành công' });
                onClose(true);
                return;
            }
            notificationController.error({ message: response.message });
        }).catch(() => {
            notificationController.error({ message: 'Thất bại' });
        }).finally(() => {
            setLoading(false);
        });
    }

    const removeUnexpectedADFields = (data: any[]) => {
        const unexpectedFields = [
            'materialUnits'
        ];

        data.forEach(record => {
            unexpectedFields.forEach(field => {
                delete record[field];
            })
        })
    }

    // useEffect(() => {
    //     const t_tt = adDetails.map(x => (x.tienNT2 ?? 0) - (x.ck ?? 0)).reduce((a, b) => a + b, 0);

    //     formSummary.setFieldsValue({
    //         tongTT: t_tt,
    //         tongTTNT: t_tt,
    //         tongTien2: t_tt,
    //         tongTienNT2: t_tt,
    //     })
    // }, [adDetails])

    // #region Handle AM Master change
    function loaiCKChange() {
        const loaiCK = amForm.getFieldValue(InvoiceAMColumnConstant.LOAI_CK);
        setLoaiCK(loaiCK);
        if (!loaiCK) {
            amForm.setFieldValue(InvoiceAMColumnConstant.SUA_CK, false);
        }
    }
    // #endregion Handle AM Master change

    // #region Tính summary
    function tinhTongValues(): {
        t_tien_2: number;
        t_tien_nt2: number;
    } {
        const adDetails = adListForm.getFieldValue(DATA) || [];

        const tongSoLuong1 = InvoiceFunc.ADDatasTinhTong(adDetails, InvoiceColumnConstant.SO_LUONG1);
        const tongSoLuong = InvoiceFunc.ADDatasTinhTong(adDetails, InvoiceColumnConstant.SO_LUONG);

        const tongTien2 = InvoiceFunc.ADDatasTinhTong(adDetails, InvoiceColumnConstant.TIEN2);
        const tongTienNT2 = InvoiceFunc.ADDatasTinhTong(adDetails, InvoiceColumnConstant.TIEN_NT2);

        const data = {} as any
        data[InvoiceAMColumnConstant.T_SO_LUONG1] = tongSoLuong1;
        data[InvoiceAMColumnConstant.T_SO_LUONG] = tongSoLuong;
        data[InvoiceAMColumnConstant.T_TIEN2] = tongTien2;
        data[InvoiceAMColumnConstant.T_TIEN_NT2] = tongTienNT2;

        amForm.setFieldsValue(data);

        // if (V6Options.GetValue("M_GIAVC_GIAGIAM_CT") == "2" ||
        //     V6Options.GetValue("M_GIAVC_GIAGIAM_CT") == "3") {
        //     var t_gg_nt = TinhTong(AD, "GG_NT");
        //     var t_gg = TinhTong(AD, "GG");
        //     txtTongGiamNt.Value = NumberHelpers.round(t_gg_nt, M_ROUND_NT);
        //     txtTongGiam.Value = NumberHelpers.round(t_gg, M_ROUND);
        // }

        // if (V6Options.GetValue("M_GIAVC_GIAGIAM_CT") == "1" ||
        //     V6Options.GetValue("M_GIAVC_GIAGIAM_CT") == "3") {
        //     var t_vc_nt = TinhTong(AD, "TIEN_VC_NT");
        //     var t_vc = TinhTong(AD, "TIEN_VC");
        //     TxtT_TIENVCNT.Value = NumberHelpers.round(t_vc_nt, M_ROUND_NT);
        //     TxtT_TIENVC.Value = NumberHelpers.round(t_vc, M_ROUND);
        // }

        return {
            t_tien_2: tongTien2,
            t_tien_nt2: tongTienNT2
        }
    }

    function tinhChietKhau(): {
        t_ck: number;
        t_ck_nt: number;
    } {
        const adDetailsInForm = adListForm.getFieldValue(DATA) || [];
        const amFormData = amForm.getFieldsValue();

        const tTienNt2 = InvoiceFunc.ADDatasTinhTong(adDetailsInForm, InvoiceColumnConstant.TIEN_NT2);
        const tyGia = amFormData.ty_gia;
        const t_tien_nt2 = amFormData.t_tien_nt2;
        let t_ck_nt = 0
        let t_ck = 0

        const loaiCk = amForm.getFieldValue(InvoiceAMColumnConstant.LOAI_CK); // Chiết khấu chung

        const updatedData = {} as any;
        if (loaiCk) {
            //Chiết khấu chung, chia theo phần trăm
            //Tính phần trăm chiết khấu. Nếu check sua_ptck thì lấy luôn
            //Nếu nhập tiền chiết khấu

            // Đơn AM91 không có phần trăm ck
            const suaPTCK = amFormData.tinh_ck ?? false;
            const pt_ck = amFormData.pt_ck ?? 0;
            // if (chkSuaPtck.Checked || (!chkT_CK_NT.Checked && txtPtCk.Value > 0)) {
            if (suaPTCK || (!amFormData.sua_ck && pt_ck > 0)) {
                // txtTongCkNt.ReadOnly = false;
                //
                t_ck_nt = NumberHelpers.round(pt_ck * tTienNt2 / 100, M_ROUND_NT);
                t_ck = NumberHelpers.round(t_ck_nt * tyGia, M_ROUND);

                if (true) // (_maNt == _mMaNt0)
                    t_ck = t_ck_nt;

                updatedData[InvoiceAMColumnConstant.T_CK_NT] = t_ck_nt;
                updatedData[InvoiceAMColumnConstant.T_CK] = t_ck;
            }
            else if (amFormData.sua_ck) {
                t_ck_nt = amFormData.t_ck_nt;
                t_ck = Math.round(t_ck_nt * tyGia); // tyGia luôn = 1 -> việt nam đồng

                if (true) // (_maNt == _mMaNt0)
                    t_ck = t_ck_nt;

                updatedData[InvoiceAMColumnConstant.T_CK] = t_ck;
            }

            //tính chiết khấu cho mỗi chi tiết
            for (let index = 0; index < adDetailsInForm.length; index++) {
                const adDetail = adDetailsInForm[index];

                if (t_tien_nt2 !== 0) {
                    const tien_nt2 = adDetail[InvoiceColumnConstant.TIEN_NT2] ?? 0;
                    const ck_nt = Math.round(tien_nt2 * t_ck_nt / t_tien_nt2);
                    let ck = ck_nt * tyGia;

                    if (true) // (_maNt == _mMaNt0)
                        ck = ck_nt;

                    //gán lại ck_nt
                    adDetail[Invoice91_FColumnConstant.CK_NT] = ck_nt;
                    adDetail[Invoice91_FColumnConstant.CK] = ck;
                    adDetail[Invoice91_FColumnConstant.PT_CKI] = pt_ck;
                }
                else {
                    const ck_nt = 0;
                    let ck = 0;

                    if (true) //(_maNt == _mMaNt0)
                        ck = ck_nt;

                    //gán lại ck_nt
                    adDetail[Invoice91_FColumnConstant.CK_NT] = ck_nt;
                    adDetail[Invoice91_FColumnConstant.CK] = ck;
                    adDetail[Invoice91_FColumnConstant.PT_CKI] = pt_ck;
                }
            }
        } else {
            //Chiết khấu RIÊNG, CỘNG DỒN
            t_ck_nt = InvoiceFunc.ADDatasTinhTong(adDetailsInForm, Invoice91_FColumnConstant.CK_NT);
            t_ck = InvoiceFunc.ADDatasTinhTong(adDetailsInForm, Invoice91_FColumnConstant.CK);

            updatedData[InvoiceAMColumnConstant.T_CK] = t_ck;
            updatedData[InvoiceAMColumnConstant.T_CK_NT] = t_ck_nt;

            // txtPtCk.ReadOnly = true;
            // txtTongCkNt.ReadOnly = true;
            // txtTongCk.ReadOnly = true;
        }

        amForm.setFieldsValue(updatedData);

        if (adDetailsInForm.length > 0) {
            setADListsIntoFormAndList(adDetailsInForm);
        }

        return {
            t_ck: t_ck,
            t_ck_nt: t_ck_nt
        }
    }

    function setADListsIntoFormAndList(adDetails: AD91Dto[]) {
        adListForm.setFieldValue(DATA, [...adDetails]);
        dispatchADListFormChange(adDetails);
    }

    function tinhGiamGia() {
        return;
    }

    function tinhThue(): {
        t_thue: number;
        t_thue_nt: number;
    } {
        //Tính tiền thuế theo thuế suất
        let thue_suat = 0;
        let t_thue_nt = 0;
        let t_thue = 0;

        const formData = amForm.getFieldsValue();

        const ty_gia = formData.ty_gia;
        const t_tien_nt2 = formData.t_tien_nt2;
        const t_gg_nt = 0 //formData.t_gg txtTongGiamNt.Value;  Phi Todo
        const t_vc_nt = 0 // TxtT_TIENVCNT.Value;
        const t_ck_nt = formData.t_ck_nt;

        const t_tien_truocthue = t_tien_nt2 - t_gg_nt - t_ck_nt + t_vc_nt;

        if (formData.sua_thue)//Tiền thuế gõ tự do
        {
            t_thue_nt = formData.t_thue_nt;
            t_thue = NumberHelpers.round(t_thue_nt * ty_gia, M_ROUND);

            if (true) // (_maNt == _mMaNt0)
                t_thue = t_thue_nt;
        }
        else {
            thue_suat = amForm.getFieldValue(InvoiceAMColumnConstant.THUE_SUAT) ?? 0;
            //tiền thuế = (tiền hàng - tiền giảm - chiết khấu) * thuế suất
            t_thue_nt = t_tien_truocthue * thue_suat / 100;
            t_thue_nt = NumberHelpers.round(t_thue_nt, M_ROUND_NT);
            //sV("T_THUE_NT", t_thue_nt);

            t_thue = NumberHelpers.round(t_thue_nt * ty_gia, M_ROUND);
            if (true) // (_maNt == _mMaNt0)
                t_thue = t_thue_nt;

        }

        const data = {} as any;
        data[InvoiceAMColumnConstant.T_THUE_NT] = t_thue_nt;
        data[InvoiceAMColumnConstant.T_THUE] = t_thue;

        //tính thuế riêng cho từng chi tiết
        //tính phần trăm giá trị của từng chi tiết trên tổng tiền hàng rồi nhân với tổng thuế sẽ ra thuế 
        let t_thue_nt_check = 0;
        let t_thue_check = 0;
        let index = -1;

        const adDetailsInForm = adListForm.getFieldValue(DATA) || [];
        for (let i = 0; i < adDetailsInForm.Count; i++) {
            const adDetail = adDetailsInForm[i];

            if (t_tien_nt2 != 0) {
                const tien_nt2 = adDetail[Invoice91_FColumnConstant.TIEN_NT2] ?? 0;
                const thue_nt = NumberHelpers.round(tien_nt2 * t_thue_nt / t_tien_nt2, M_ROUND_NT);
                t_thue_nt_check = t_thue_nt_check + thue_nt;

                let thue = NumberHelpers.round(thue_nt * ty_gia, M_ROUND);

                if (true) // (_maNt == _mMaNt0)
                    thue = thue_nt;
                t_thue_check += thue;

                if (thue_nt != 0 && index == -1)
                    index = i;

                adDetail[Invoice91_FColumnConstant.THUE_NT] = thue_nt;
                adDetail[Invoice91_FColumnConstant.THUE] = thue;
            }
            else {
                const thue_nt = 0;
                let thue = 0;

                t_thue_nt_check = t_thue_nt_check + thue_nt;


                if (true) // (_maNt == _mMaNt0)
                    thue = thue_nt;
                t_thue_check += thue;

                if (thue_nt != 0 && index == -1)
                    index = i;

                adDetail[Invoice91_FColumnConstant.THUE_NT] = thue_nt;
                adDetail[Invoice91_FColumnConstant.THUE] = thue;
            }
        }

        // Xu ly chenh lech
        // Tìm dòng có số tiền
        if (index != -1) {
            const _thue_nt = (adDetailsInForm[index][Invoice91_FColumnConstant.THUE_NT] ?? 0) + (t_thue_nt - t_thue_nt_check);
            adDetailsInForm[index][Invoice91_FColumnConstant.THUE_NT] = _thue_nt;

            const _thue = (adDetailsInForm[index][Invoice91_FColumnConstant.THUE] ?? 0) + (t_thue - t_thue_check);
            adDetailsInForm[index][Invoice91_FColumnConstant.THUE] = _thue;
        }

        amForm.setFieldsValue(data);
        setADListsIntoFormAndList(adDetailsInForm);

        return {
            t_thue,
            t_thue_nt
        };
    }

    function tinhTongThue_ct(): {
        t_thue: number;
        t_thue_nt: number;
    } {
        const adDetails = adListForm.getFieldValue(DATA);

        const t_thue_nt = InvoiceFunc.ADDatasTinhTong(adDetails, Invoice91_FColumnConstant.THUE_NT);
        const t_thue = InvoiceFunc.ADDatasTinhTong(adDetails, Invoice91_FColumnConstant.THUE);

        const data = {} as any;
        data[InvoiceAMColumnConstant.T_THUE_NT] = NumberHelpers.round(t_thue_nt, M_ROUND_NT);
        data[InvoiceAMColumnConstant.T_THUE] = NumberHelpers.round(t_thue, M_ROUND_NT);

        amForm.setFieldsValue(data);

        return {
            t_thue,
            t_thue_nt
        };

        // txtMa_thue.ReadOnly = true;
        // txtTongThueNt.ReadOnly = true;
        // txtTongThue.ReadOnly = true;
    }

    function dispatchADListFormChange(adDetailsInForm: any[]) {
        setDispatchADFormListChange({
            adDetailsInForm: adDetailsInForm
        });
    }

    function tinhTongThanhToan() {
        const tinhTongRes = tinhTongValues();
        const tinhCKRes = tinhChietKhau();
        tinhGiamGia();

        let t_thue = 0;
        let t_thue_nt = 0;

        if (M_SOA_MULTI_VAT) {
            const tinhTongThue_ctRes = tinhTongThue_ct();
            t_thue = tinhTongThue_ctRes.t_thue;
            t_thue_nt = tinhTongThue_ctRes.t_thue_nt;
        }
        else {
            const tinhThueRes = tinhThue();
            t_thue = tinhThueRes.t_thue;
            t_thue_nt = tinhThueRes.t_thue_nt;
        }

        const t_tien_nt2 = tinhTongRes.t_tien_nt2;
        const t_gg_nt = 0;
        const t_ck_nt = tinhCKRes.t_ck_nt;
        const t_vc_nt = 0; //TxtT_TIENVCNT.Value;

        const data = {} as any;

        const t_tt_nt = t_tien_nt2 - t_gg_nt - t_ck_nt + t_thue_nt + t_vc_nt;
        data[InvoiceAMColumnConstant.T_TT_NT] = t_tt_nt;

        const t_tt = tinhTongRes.t_tien_2 - tinhCKRes.t_ck + t_thue;
        // const t_tt = tinhTongRes.t_tien_2 - txtTongGiam.Value - tinhCKRes.t_ck + txtTongThue.Value;
        data[InvoiceAMColumnConstant.T_TT] = t_tt;

        amForm.setFieldsValue(data);


        // txtConLai.Value = t_tt_nt - txtSL_UD1.Value;
    }
    // #endregion Tính summary

    // #region Behaviors
    function changesSummarySuaTien(suaTien: boolean) {
        setSummarySuaTien(suaTien);
    }
    // #endregion Behaviors

    return (
        <S.ModalFullScreen
            keyboard={false}
            maskClosable={false}
            title={titleModal}
            confirmLoading={loading}
            open={visible}
            width={"100vw"}
            destroyOnClose={true}
            bodyStyle={{
                overflowY: 'scroll',
                height: 'calc(100vh - 142px)'
            }}
            onOk={() => amForm.submit()}
            onCancel={() => handleCancel()}
            okText={isDetail ? "Chỉnh sửa" : "Lưu"}
            cancelText="Hủy"
            okButtonProps={{ style: { padding: "5px", height: "36px" } }}
            cancelButtonProps={{ style: { padding: "5px", height: "36px" } }}
        >
            <div className='am-91-modal-container w-100'>
                <Form
                    form={amForm}
                    name="basic"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onFinish={onFinish}
                    onFinishFailed={antFormFinishErrorNotification}
                >
                    <AM91ModalMaster
                        form={amForm}
                        edittingAM91={edittingAM91}
                        mode={mode}
                        onLoaiCKChange={loaiCKChange}
                    />

                    <AM91FormHiddenItem />
                </Form>

                <div className='ad-data w-100'>
                    <AM91ModalDetails
                        loaiCK={loaiCK}
                        summarySuaTien={summarySuaTien}
                        adListForm={adListForm}
                        adAutoColumns={adAutoColumns}
                        disabled={isDetail}
                        dispatchADFormListChange={dispatchADFormListChange}
                        onADDatasChange={() => {
                            tinhTongThanhToan();
                        }}
                    />
                </div>

                <Form
                    form={amForm}
                    name="basic"
                    initialValues={{
                        remember: true
                    }}
                    style={{ marginTop: "20px" }}
                    autoComplete="off"
                    onFinish={onFinish}
                    onFinishFailed={antFormFinishErrorNotification}
                >
                    <AM91ModalSummary
                        loaiCK={loaiCK}
                        code={sttRec}
                        form={amForm}
                        dataTab={dataTab}
                        tinhTongThanhToan={tinhTongThanhToan}
                        changesSummarySuaTien={changesSummarySuaTien}
                    />
                </Form>
            </div>
        </S.ModalFullScreen>
    );
};

export default AM91Modal;
