import apiServices from '@app/api/api.services';
import { InputSearchApiService } from '@app/api/input-search-api.service';
import InputSearchLookup from '@app/components/common/input-search-lookup/InputSearchLookup';
import { InputSearchLookupColumns } from '@app/components/common/input-search-lookup/constants/input-lookup-entity-columns.constant';
import { CatalogVvar } from '@app/shared/constants/catalog.constant';
import { Utils } from '@app/shared/helpers/utils';
import { ICatalogCustomer } from '@app/shared/models/catalog-customer.model';
import { IAM91 } from '@app/shared/models/invoices/am91.model';
import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from 'react';
import './am-91-modal-master.less';
import { InvoiceAMColumnConstant, InvoiceConstant } from '@app/pages/Invoices/shared/constants/invoice.constant';
import { Constant } from '@app/styles/themes/constants';
import { AM91Dto } from '@app/api/v6.api';
const dateFormat = 'DD/MM/YYYY';

interface IAM91ModalMasterProps {
    edittingAM91?: AM91Dto;
    form: FormInstance<IAM91>;
    mode: "add" | "edit" | "detail";
    onLoaiCKChange: () => void;
}

const AM91ModalMaster: React.FC<IAM91ModalMasterProps> = ({
    form,
    mode,
    edittingAM91,
    onLoaiCKChange
}: IAM91ModalMasterProps) => {
    const isAdd = useMemo(() => { return mode === 'add' }, [mode])
    const isEdit = useMemo(() => { return mode === 'edit' }, [mode])
    const isDetail = useMemo(() => { return mode === 'detail' }, [mode])
    const [onChangeFormBaseUnit, setOnChangeFormBaseUnit] = useState<{ text: string }>(null!);
    const [onChangeFormTKNo, setOnChangeFormTKNo] = useState<{ text: string }>(null!);

    async function initBaseUnitCode() {
        const maDVCS = localStorage.getItem(Constant.BASE_UNIT);

        if (!maDVCS) {
            return;
        }

        const baseUnit = await apiServices.v6Api.baseUnitsGetById(maDVCS);

        form.setFieldsValue({
            ma_dvcs: maDVCS,
            ten_dvcs: baseUnit?.baseUnitName
        } as any)

        setOnChangeFormBaseUnit({
            text: maDVCS!
        })
    }

    async function initMaCTInformation() {
        const data = await apiServices.v6Api.vouchersGetByCode('SOH');
        if (data) {
            form.setFieldValue(InvoiceAMColumnConstant.MA_NX, data.tk_no);
            setOnChangeFormTKNo({ text: data.tk_no! });
        }
    }

    useEffect(() => {
        if (isAdd) {
            initBaseUnitCode();
            initMaCTInformation();
        }
    }, [isAdd])

    useEffect(() => {
        if ((isEdit || isDetail) && edittingAM91) {
            const baseUnit = edittingAM91?.ma_dvcs;
            setOnChangeFormBaseUnit({ text: baseUnit ?? '' });
        }
    }, [isEdit, isDetail, edittingAM91])

    async function fetchSoCT() {
        const ma_sonb = form.getFieldValue(InvoiceAMColumnConstant.MA_SONB);
        const ngay_lct = form.getFieldValue(InvoiceAMColumnConstant.NGAY_CT);

        if (!ma_sonb || !ngay_lct) {
            return;
        }

        const so_ct = await apiServices.v6Api.invoicesGetTransactionNumber(ma_sonb, ngay_lct);
        form.setFieldValue(InvoiceAMColumnConstant.SO_CT, so_ct);
    }

    return (
        <div className='am-91-modal-master-container'>
            <Row gutter={24}>
                <Col span={5}>
                    <Form.Item
                        name={InvoiceAMColumnConstant.MA_SONB}
                        label="Số nội bộ"
                        labelCol={{ style: { width: 105 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <InputSearchLookup
                            id='am-ma-sonb'
                            notEmpty={true}
                            autoFocus={true}
                            disabled={isDetail}
                            fetchDataFunc={InputSearchApiService.getCatalogSoNBsByMaCTNB(InvoiceConstant.Invoice91_Ma_CT)}
                            placeHolder='Nhập số nội bộ'
                            keyField={'maSonB'}
                            columns={InputSearchLookupColumns.batchNo}
                            onOutputChange={(value: any) => {
                                form.setFieldValue('ma_sonb', value)
                            }}
                            onBlur={() => {
                                fetchSoCT();
                            }}
                            patchValue={{ text: form.getFieldValue('ma_sonb') }}
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name={'ngay_ct'}
                        label="Ngày hạch toán"
                        labelCol={{ style: { width: 95 } }}
                        labelAlign='left'
                        className="w-100"
                        initialValue={dayjs()}
                    >
                        <DatePicker
                            disabled={isDetail}
                            size='small'
                            style={{ width: "100%", borderRadius: "0px" }}
                            onChange={(value) => {
                                form.setFieldsValue({
                                    ngay_lct: value ? value! : undefined
                                })

                                fetchSoCT();
                            }}
                            allowClear={false}
                            format={dateFormat}
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name={InvoiceAMColumnConstant.MA_NX}
                        label="Mã nx(tk nợ)"
                        labelCol={{ style: { width: 90 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <InputSearchLookup
                            disabled={isDetail}
                            fetchDataFunc={InputSearchApiService.getCatalogDymamic(CatalogVvar.ma_nx)}
                            placeHolder='Mã nx'
                            keyField={CatalogVvar.ma_nx}
                            columns={InputSearchLookupColumns.catalogColumns[CatalogVvar.ma_nx]}
                            onOutputChange={(value: any) => {
                                form.setFieldValue(CatalogVvar.ma_nx, value)
                            }}
                            patchValue={onChangeFormTKNo}
                        />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item
                        name={'so_ctx'}
                        label="Số ct xuất"
                        labelCol={{ style: { width: 80 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <Input placeholder="Nhập số ct xuất" disabled={isDetail}
                        />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item
                        name={InvoiceAMColumnConstant.MA_BP}
                        label="BP/NV"
                        labelCol={{ style: { width: 50 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <InputSearchLookup
                            disabled={isDetail}
                            fetchDataFunc={InputSearchApiService.getCatalogDymamic(CatalogVvar.ma_bp)}
                            placeHolder='Mã BP'
                            keyField={CatalogVvar.ma_bp}
                            columns={InputSearchLookupColumns.catalogColumns[CatalogVvar.ma_bp]}
                            onOutputChange={(value: any) => {
                                form.setFieldValue(CatalogVvar.ma_bp, value)
                            }}
                            patchValue={{ text: form.getFieldValue(CatalogVvar.ma_bp) }}
                        />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item
                        name={InvoiceAMColumnConstant.MA_NVIEN}
                        labelAlign='left'
                        className="w-100"
                    >
                        <InputSearchLookup
                            disabled={isDetail}
                            fetchDataFunc={InputSearchApiService.getCatalogDymamic(CatalogVvar.ma_nvien)}
                            placeHolder='Mã NV'
                            keyField={CatalogVvar.ma_nvien}
                            columns={InputSearchLookupColumns.catalogColumns[CatalogVvar.ma_nvien]}
                            onOutputChange={(value: any) => {
                                form.setFieldValue(CatalogVvar.ma_nvien, value)
                            }}
                            patchValue={{ text: form.getFieldValue(CatalogVvar.ma_nvien) }}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24} >
                <Col span={5}>
                    <Form.Item
                        name={InvoiceAMColumnConstant.SO_CT}
                        label="Số phiếu"
                        labelCol={{ style: { width: 105 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <Input size="small" placeholder=""
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name={'ngay_lct'}
                        label="Ngày lập"
                        labelCol={{ style: { width: 95 } }}
                        labelAlign='left'
                        className="w-100"
                        initialValue={dayjs()}
                    >
                        <DatePicker
                            size='small' disabled={true}
                            style={{ width: "100%", borderRadius: "0px" }}
                            format={dateFormat}
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name={InvoiceAMColumnConstant.MA_LNX_PH}
                        label="Loại NX"
                        labelCol={{ style: { width: 90 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <InputSearchLookup
                            disabled={isDetail}
                            fetchDataFunc={InputSearchApiService.getCatalogDymamic(CatalogVvar.ma_lnx)}
                            placeHolder='Loại NX'
                            keyField={CatalogVvar.ma_lnx}
                            columns={InputSearchLookupColumns.catalogColumns[CatalogVvar.ma_lnx]}
                            onOutputChange={(value: any) => {
                                form.setFieldValue(InvoiceAMColumnConstant.MA_LNX_PH, value)
                            }}
                            patchValue={{ text: form.getFieldValue(InvoiceAMColumnConstant.MA_LNX_PH) }}
                        />
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item
                        label="Người mua"
                        labelCol={{ style: { width: 80 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <Input size="small" disabled={isDetail}
                            placeholder="Nhập người mua"
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name={'so_seri'}
                        label="Số seri"
                        labelCol={{ style: { width: 60 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <Input size="small" disabled={isDetail}
                            placeholder="Nhập số seri"
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24} >
                <Col span={5}>
                    <Form.Item
                        name={CatalogVvar.ma_dvcs}
                        label="Mã đơn vị"
                        labelCol={{ style: { width: 105 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <InputSearchLookup
                            disabled={isDetail}
                            fetchDataFunc={InputSearchApiService.getCatalogDymamic(CatalogVvar.ma_dvcs)}
                            placeHolder='Nhập mã đơn vị'
                            keyField={CatalogVvar.ma_dvcs}
                            columns={InputSearchLookupColumns.baseUnit}
                            onOutputChange={(value: any) => {
                                form.setFieldValue(CatalogVvar.ma_dvcs, value)
                            }}
                            patchValue={onChangeFormBaseUnit}
                        />
                    </Form.Item>
                </Col>
                <Col span={11}>
                    <Form.Item
                        name="ten_dvcs"
                        label=""
                        labelCol={{ style: { width: 90 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <Input size="small" disabled={true} placeholder="Tên đơn vị"
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name={'so_dh'}
                        label="Số ĐH"
                        labelCol={{ style: { width: 65 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <Input size="small" disabled={isDetail}
                            placeholder="Nhập số ĐH"
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        label="Chiết khấu"
                        name={InvoiceAMColumnConstant.LOAI_CK}
                        labelCol={{ style: { width: 80 } }}
                        labelAlign='left'
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Checkbox
                            disabled={isDetail}
                            style={{
                                marginRight: "10px",
                            }}
                            onChange={() => onLoaiCKChange()}
                        >
                            Chung
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24} >
                <Col span={5}>
                    <Form.Item
                        name={CatalogVvar.ma_kh}
                        label="Mã khách hàng"
                        labelCol={{ style: { width: 105 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <InputSearchLookup
                            notEmpty={true}
                            disabled={isDetail}
                            fetchDataFunc={InputSearchApiService.getCatalogDymamic(CatalogVvar.ma_kh)}
                            columns={InputSearchLookupColumns.catalogColumns[CatalogVvar.ma_kh]}
                            placeHolder='Nhập mã khách hàng'
                            keyField={CatalogVvar.ma_kh}
                            onOutputSelectRecord={async (value: ICatalogCustomer) => {
                                await form.setFieldValue('ten_kh', value.ten_kh);
                                await form.setFieldValue('ma_kh', value.ma_kh);
                                await form.setFieldValue('dia_chi', value.dia_chi);
                                await form.setFieldValue('ma_so_thue', value.ma_so_thue);

                            }}
                            onOutputChange={(value: any) => {
                                form.setFieldValue(CatalogVvar.ma_kh, value)
                            }}
                            patchValue={{ text: form.getFieldValue(CatalogVvar.ma_kh) }}
                        />
                    </Form.Item>
                </Col>

                <Col span={11}>
                    <Form.Item
                        name={'ten_kh'}
                        label=""
                        labelCol={{ style: { width: 105 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <Input size="small" disabled={true}
                            value={form.getFieldValue('ten_kh')}
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        label="Tỷ giá"
                        name="ma_nt"
                        labelCol={{ style: { width: 65 } }}
                        labelAlign='left'
                        className="w-100"
                        initialValue={'VND'}
                    >
                        <Select
                            disabled={true}
                            allowClear={false}
                            defaultValue={"Đồng VN"}
                            options={[
                                { value: 'VND', label: 'Đồng VN' },
                            ]}
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name={InvoiceAMColumnConstant.TY_GIA}
                        labelCol={{ style: { width: 70 } }}
                        labelAlign='left'
                        className="w-100"
                        initialValue={1}
                    >
                        <InputNumber min={0} maxLength={8}
                            required={true}
                            disabled={true}
                            formatter={Utils.numberFormatter} size={"small"}
                            placeholder='Nhập tỷ giá' style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24} >
                <Col span={13}>
                    <Form.Item
                        name={'dia_chi'}
                        label="Địa chỉ"
                        labelCol={{ style: { width: 105 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <Input size="small" disabled={isDetail}
                            placeholder="Nhập địa chỉ"
                        />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item
                        name={'ma_so_thue'}
                        label=""
                        labelCol={{ style: { width: 105 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <Input size="small" disabled={isDetail}
                            placeholder="Nhập mã số thuế"
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item

                        label="HTTT"
                        labelCol={{ style: { width: 65 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <Input size="small" disabled={isDetail}
                            placeholder="Nhập HTTT"
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name={'han_tt'}
                        label="Hạn HTT"
                        labelCol={{ style: { width: 70 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <Input size="small" disabled={isDetail}
                            placeholder="Nhập hạn HTTT"
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24} >
                <Col span={16}>
                    <Form.Item

                        label="Diễn giải"
                        labelCol={{ style: { width: 105 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <Input size="small" disabled={isDetail}
                            placeholder="Nhập diễn giải"
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item

                        label="ACB"
                        labelCol={{ style: { width: 65 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <Input size="small" disabled={isDetail}
                            placeholder="Nhập ACB"
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item

                        label={<Button style={{ padding: "4px" }}>Mã giá</Button>}
                        labelCol={{ style: { width: 70 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <Input size="small" disabled={isDetail}
                            placeholder="Nhập mã giá"
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24} >
                <Col span={18}>
                    <Form.Item
                        name={'ten_vtthue'}
                        label="Tên hàng VAT"
                        labelCol={{ style: { width: 105 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <Input size="small" disabled={isDetail}
                            placeholder="Nhập Tên hàng VAT"
                        />
                    </Form.Item>
                </Col>
            </Row>

        </div>
    );
};

export default AM91ModalMaster;
