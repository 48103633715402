import React, { useMemo } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useParams } from 'react-router-dom';
import MaterialAddOrUpdateView from '../material-views/MaterialAddOrUpdateView';

const MaterialAddOrUpdatePage: React.FC = () => {
    const { code } = useParams<{ code: string }>();

    const title = useMemo(() => {
        return code
            ? `Chỉnh sửa ${code} - Vật tư`
            : 'Tạo vật tư'
    }, [code])
    return (
        <>
            <PageTitle>{title}</PageTitle>
            <MaterialAddOrUpdateView code={code?.trim() as string} />
        </>
    );
};

export default MaterialAddOrUpdatePage;
