import { Modal } from '@app/components/common/Modal/Modal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Checkbox, Col, Form, Input, InputNumber, Row } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import apiServices from '@app/api/api.services';
import { antFormFinishErrorNotification } from '@app/shared/functions/common.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import InputSearchLookup from '@app/components/common/input-search-lookup/InputSearchLookup';
import { InputSearchApiService } from '@app/api/input-search-api.service';
import { InputSearchLookupColumns } from '@app/components/common/input-search-lookup/constants/input-lookup-entity-columns.constant';
import { Utils } from '@app/shared/helpers/utils';
import { CatalogAddModalType } from '@app/components/shared/modals/catalog-add-modal/catalog-add-modal';
import { MaterialUnitConversionAddDto } from '@app/api/v6.api';

interface IModalUnitConversion {
    data?: BaseUnitConversion;
    visible: boolean;
    mode: "add" | "edit" | "detail";
    onClose: (reload?: boolean) => void;
}

interface BaseUnitConversion {
    ma_vt: string;
    dvt: string;
    dvtqd: string;
}

export const UnitConversionModal: React.FC<IModalUnitConversion> = ({ data, visible, mode, onClose }: IModalUnitConversion) => {
    const [form] = BaseForm.useForm<MaterialUnitConversionAddDto>();
    const [titleModal, setTileModal] = useState("Thêm mới quy đổi đơn vị tính");
    const [currentMode, setCurrentMode] = useState(mode);
    const [valueNameMaterial, setValueNameMaterial] = useState("");
    const [loading, setLoading] = useState<boolean>(false);
    const [infomationConversion, setInfomationConversion] = useState("");

    const handleCancel = async () => {
        handleResetValue();
        onClose();
    };

    const handleResetValue = () => {
        form.resetFields();
        setInfomationConversion("");
        setValueNameMaterial("");
    };

    const fetchData = useCallback(async () => {
        if (data) {
            if (data) {
                const dataEditUnitConversion = await apiServices.v6Api.materialUnitConversionsGetMaterialUnitConversion(data.ma_vt, data.dvt, data.dvtqd);
                form.setFieldsValue({
                    ...dataEditUnitConversion,
                    status: (dataEditUnitConversion.status === '1' ? true : false) as any,
                })
                const dataMaterial = await apiServices.v6Api.materialsGetByCode(data.ma_vt);
                handleInfomationConversion();
                setValueNameMaterial(dataMaterial?.materialName ? dataMaterial?.materialName : "");
            }
            setTileModal(`${mode === "detail" ? "Chi tiết" : "Chỉnh sửa"} quy đổi đơn vị tính - ${data?.ma_vt}`);
        } else {
            setTileModal(`Thêm mới quy đổi đơn vị tính`);
        }
    }, [data, mode, form])

    useEffect(() => {
        form.resetFields();
        data ? setCurrentMode(mode) : setCurrentMode("add");
        fetchData();
    }, [fetchData, form, data, mode])

    const handleDisable = (() => {
        setCurrentMode("edit")
        setTileModal(`${currentMode !== "detail" ? "Chi tiết" : "Chỉnh sửa"} quy đổi đơn vị tính - ${data?.ma_vt}`);
    });

    const handleInfomationConversion = (() => {
        const valueFormDVT = form.getFieldValue("dvt");
        const valueFormhe_sot = form.getFieldValue("he_sot") ?? 1;
        const valueFormhe_som = form.getFieldValue("he_som") ?? 1;
        const value = `1 ${valueFormDVT ?? ""} = ${valueFormhe_sot} / ${valueFormhe_som} = ${(valueFormhe_sot / valueFormhe_som).toFixed(3)} Cai`
        setInfomationConversion(value)
    });

    const validateTextCharater = (maxLen: number) => (_: any, value: string) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 13, name: 'ma_vt', label: 'Mã vật tư',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng chọn mã vật tư" },
                { validator: validateTextCharater(25) }],
                placeholder: "Nhập mã vật tư",
                render: (<>
                    <InputSearchLookup
                        disabled={currentMode !== "add"}
                        fetchDataFunc={InputSearchApiService.getCatalogDymamic("MA_VT")}
                        placeHolder='Nhập mã đvcs'
                        keyField={'ma_vt'}
                        lookupAdding={{
                            initValues: {
                            },
                            catalogAddModalType: CatalogAddModalType.Material
                        }}
                        columns={InputSearchLookupColumns.material}
                        onOutputSelectRecord={(value: any) => {
                            setValueNameMaterial(value.ten_vt)
                            form.setFieldValue(
                                "ma_vt", value.ma_vt
                            )
                        }}
                        patchValue={{ text: form.getFieldValue("ma_vt") ?? data?.ma_vt }}

                    />
                </>)
            },
            {
                colSpan: 11, name: '', label: '',
                disabled: true,
                render: (<>
                    <Input disabled={true} value={valueNameMaterial ? valueNameMaterial : ""}></Input>
                </>)
            }
        ]
    }, [mode, currentMode, valueNameMaterial])


    const session2Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 13, name: 'dvt', label: 'Quy đổi từ ĐVT',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng quy đổi ĐVT" },
                { validator: validateTextCharater(10) }],
                disabled: currentMode !== "add",
                render: (<>
                    <InputSearchLookup
                        disabled={currentMode !== "add"}
                        fetchDataFunc={InputSearchApiService.getCatalogDymamic("DVT")}
                        placeHolder='Nhập đơn vị tính'
                        keyField={'dvt'}
                        columns={InputSearchLookupColumns.measurementUnit}
                        lookupAdding={{
                            initValues: {
                            },
                            catalogAddModalType: CatalogAddModalType.MeasurementUnit
                        }}
                        onOutputSelectRecord={(value: any) => {
                            form.setFieldValue(
                                "dvt", value.dvt,
                            )
                            handleInfomationConversion();
                        }}
                        patchValue={{ text: form.getFieldValue("dvt") ?? data?.dvt }}
                    />
                </>)
            },
            {
                colSpan: 11, name: '', label: '',
                render: (<>
                </>)
            },
            {
                colSpan: 8, name: 'dvtqd', label: 'Ra ĐVT tồn kho',
                render: (<>
                    <Input disabled={true} value={1}></Input>
                </>)
            },
            {
                colSpan: 5, name: '', label: '',
                render: (<>
                    <Input disabled={true} value={"Cái"} ></Input>
                </>)
            },
            {
                colSpan: 13, name: '', label: 'Ra ĐVT',
                render: (<>
                    <Input disabled={true} value={"Cái"} ></Input>
                </>)
            },
            {
                colSpan: 11, name: '', label: '',
                render: (<>
                </>)
            },
        ]
    }, [mode, currentMode])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={24}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, []);

    const onFinish = async () => {
        if (currentMode === "detail") {
            handleDisable();
            return;
        }

        const dataForm = await form.validateFields();
        dataForm.status = dataForm.status ? '1' : '0';
        dataForm.dvtqd = "CAI";
        dataForm.dvt = await form.getFieldValue("dvt");
        dataForm.dvtqd = dataForm.dvt;
        if (dataForm.he_sot) {
            dataForm.he_sot < 0 ?? 1;
        }

        if (dataForm.he_som) {
            dataForm.he_som <= 0 ?? 1;
        }

        setLoading(true);
        if (!dataForm.ma_vt || !dataForm.dvt) {
            return;
        }
        const promise = data
            ? apiServices.v6Api.materialUnitConversionsUpdateUnitConversion(dataForm.ma_vt, dataForm.dvt, "CAI", dataForm)
            : apiServices.v6Api.materialUnitConversionsCreateUnitConversion(dataForm);

        promise.then((response) => {
            if (response.isSuccess) {
                notificationController.success({ message: 'Thành công' });
                handleResetValue();
                onClose(true);
                return;
            }

            notificationController.error({ message: response.message });
        }).catch(() => {
            notificationController.error({ message: 'Thất bại' });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal
            title={titleModal}
            confirmLoading={loading}
            open={visible}
            destroyOnClose={true}
            width={"1000px"}
            onOk={() => form.submit()}
            onCancel={() => handleCancel()}
            okText={currentMode === "detail" ? "Chỉnh sửa" : "Lưu"}
            cancelText="Hủy"
            okButtonProps={{ style: { padding: "5px", height: "36px" } }}
            cancelButtonProps={{ style: { padding: "5px", height: "36px" } }}
        >
            <div className='basic-form'>
                <Form
                    form={form}
                    style={{ width: "95%" }}
                    name="basic"
                    autoComplete="off"
                    onFinish={onFinish}
                    onFinishFailed={antFormFinishErrorNotification}
                >
                    {renderColumns(session1Cols)}
                    {renderColumns(session2Cols)}
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Hệ số"
                                name="he_sot"
                                labelAlign='left'
                                labelCol={{ style: { width: 130 } }}
                            >
                                <InputNumber onChange={(handleInfomationConversion)} disabled={currentMode === "detail"} min={1} defaultValue={1} maxLength={11} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập đến số' style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <h1>/</h1>
                        <Col span={5} style={{ paddingLeft: "8px", paddingRight: "16px" }}>
                            <Form.Item
                                name="he_som"
                            >
                                <InputNumber onChange={(handleInfomationConversion)} disabled={currentMode === "detail"} min={1} defaultValue={1} maxLength={11} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập đến số' style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <span>{infomationConversion}</span>
                        </Col>
                    </Row>
                    <Form.Item
                        label="Trạng thái"
                        name="status"
                        labelCol={{ style: { width: 130 } }}
                        labelAlign='left'
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Checkbox
                            style={{
                                marginRight: "10px",
                            }}
                            disabled={currentMode === "detail"}
                        >
                            Hoạt động?
                        </Checkbox>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    )
}