import { Checkbox } from 'antd';
import Form from 'antd/lib/form';
import React from 'react';

interface IDictionaryEdit {
    code?: string;
    currentMode: "add" | "edit" | "detail";
}

const DictionaryMainInfomation: React.FC<IDictionaryEdit> = ({ code, currentMode }: IDictionaryEdit) => {

    return (
        <>
            <Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 130 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={currentMode === "detail"}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item>
        </>
    );
};

export default DictionaryMainInfomation;
