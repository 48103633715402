import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Checkbox, Row } from 'antd';
import Form, { Rule } from 'antd/lib/form';
import React, { useMemo } from 'react';

interface IMeasurementUnitEdit {
    code?: string;
    currentMode: "add" | "edit" | "detail";
}

const MeasurementUnitMainInfomation: React.FC<IMeasurementUnitEdit> = ({ code, currentMode }: IMeasurementUnitEdit) => {

    const validateTextCharater = (maxLen: number) => (_: any, value: string) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const mainInforCols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'measurementUnitCode', label: 'Mã đơn vị tính', disabled: code ? true : false,
                placeholder: "Nhập mã đơn vị tính",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập mã đơn vị tính" },
                { validator: validateTextCharater(10) }] as Rule[]
            },
            {
                colSpan: 12, name: 'measurementUnitName', label: 'Tên đơn vị tính',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập tên đơn vị tính" },
                { validator: validateTextCharater(24) }],
                disabled: currentMode === "detail", placeholder: "Nhập tên đơn vị tính"
            },
            {
                colSpan: 12, name: 'measurementUnitName2', label: 'Tên đơn vị tính 2',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(24) }],
                placeholder: "Nhập tên đơn vị tính 2", disabled: currentMode === "detail"
            },
        ]
    }, [currentMode])

    const renderColumns = () => {
        return (
            <Row gutter={24}>
                {
                    mainInforCols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }

    return (
        <>
            {renderColumns()}
            <Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 130 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={currentMode === "detail"}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item>
        </>
    );
};

export default MeasurementUnitMainInfomation;
