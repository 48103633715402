import { InputSearchApiService } from '@app/api/input-search-api.service';
import { MaterialDto, MaterialUpdateDto } from '@app/api/v6.api';
import InputSearchLookup from '@app/components/common/input-search-lookup/InputSearchLookup';
import { InputSearchLookupColumns } from '@app/components/common/input-search-lookup/constants/input-lookup-entity-columns.constant';
import { IInputSearchLookupApiRequest } from '@app/components/common/input-search-lookup/models/input-search-lookup-column';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { CatalogAddModalType } from '@app/components/shared/modals/catalog-add-modal/catalog-add-modal';
import { Utils } from '@app/shared/helpers/utils';
import { PaginatedList } from '@app/shared/models/paginated-list.model';
import { Checkbox, Col, Form, FormInstance, Input, InputNumber, Row, Select } from 'antd';
import { Rule } from 'antd/lib/form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

interface IMaterialEdit {
    isEditting: boolean;
    form: FormInstance<MaterialUpdateDto>;
    editMaterial: MaterialDto;
}

const MaterialMainInfomation: React.FC<IMaterialEdit> = ({
    isEditting,
    form,
    editMaterial
}: IMaterialEdit) => {
    const [changeMaterialTypeValue, setChangeMaterialTypeValue] = useState<{
        text: string
    }>(null!);

    const [changeMeasurementUnitValue, setChangeMeasurementUnitValue] = useState<{
        text: string
    }>(null!);

    const [changeTaxValue, setChangeTaxValue] = useState<{
        text: string
    }>(null!);

    const [changeTax30Value, setChangeTax30Value] = useState<{
        text: string
    }>(null!);

    const [changeMaterialGroupValue, setChangeMaterialGroupValue] = useState<
        { text: string }[]
    >([]);

    useEffect(() => {
        if (editMaterial) {

            setChangeMaterialTypeValue({
                text: editMaterial.materialType as string
            });

            setChangeMeasurementUnitValue({
                text: editMaterial.unit as string
            });

            setChangeTaxValue({
                text: editMaterial.tax as string
            });

            setChangeTax30Value({
                text: editMaterial.exportTax?.toString() as string
            });

            setChangeMaterialGroupValue([
                { text: editMaterial.materialGroup1 as string },
                { text: editMaterial.materialGroup2 as string },
                { text: editMaterial.materialGroup3 as string },
                { text: editMaterial.materialGroup4 as string },
                { text: editMaterial.materialGroup5 as string },
                { text: editMaterial.materialGroup6 as string },
                { text: editMaterial.materialGroup7 as string },
                { text: editMaterial.materialGroup8 as string }
            ]);
        }
    }, [editMaterial])

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 9, name: 'materialInventory', label: 'Theo dõi tồn kho',
                render: (< Select
                    style={{ width: "100%", height: 30 }}
                    defaultValue={0}
                    options={[{ value: '1', label: '1' }, { value: '0', label: '0' }]}
                />)
            },
            { colSpan: 15, name: "", label: '1 - Theo dõi tồn kh, 0 - Không theo dõi tồn kho', width: 300, render: (<></>) },
            {
                colSpan: 9, name: 'inventoryPrice', label: 'Cách tính giá tồn kho',
                render: (< Select
                    style={{ width: "100%", height: 30 }}
                    defaultValue={1}
                    options={[
                        { value: '1', label: '1' },
                        { value: '2', label: '2' },
                        { value: '3', label: '3' },
                        { value: '4', label: '4' }
                    ]}
                />)
            },
            { colSpan: 15, name: "", label: '1 - Giá trung bình, 2 - Đích danh, 3 - Nhập trước xuất trước, 4 - Trung bình di động', width: 500, render: (<></>) },

        ]
    }, [isEditting])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={24}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, []);

    const validateTextCharater = (maxLen: number) => (_: any, value: any) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const baseInputLookUp = useCallback((name: string, label: string, patchValue: string, groupType: number) => {
        const setPatchValue: { text: string } = { text: patchValue };
        const fetchDataFunc: (request: IInputSearchLookupApiRequest) => Promise<PaginatedList<any>> = InputSearchApiService.getMaterialGroups(groupType);
        return (
            <Col span={12}>
                <Form.Item
                    name={name}
                    label={label}
                    labelCol={{ style: { width: 130 } }}
                    labelAlign='left'
                    className="w-100"
                    rules={name === "materialGroup7" || name === "materialGroup8" ? [
                        { validator: validateTextCharater(8) }
                    ] : [
                        { validator: validateTextCharater(16) }
                    ]}
                >
                    <InputSearchLookup
                        fetchDataFunc={fetchDataFunc}
                        placeHolder={`Nhập ${label}`}
                        keyField={'groupCode'}
                        columns={InputSearchLookupColumns.materialGroup}
                        lookupAdding={{
                            initValues: {
                                groupType: groupType
                            },
                            catalogAddModalType: CatalogAddModalType.MaterialGroup
                        }}
                        onOutputChange={(value: string) => {
                            form.setFieldsValue({ [name]: value });
                        }}
                        patchValue={setPatchValue}
                    />
                </Form.Item>
            </Col>
        )
    }, []);

    return (
        <>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name="unit"
                        label="Đơn vị tính"
                        labelCol={{ style: { width: 130 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <InputSearchLookup
                            fetchDataFunc={InputSearchApiService.getCatalogDymamic("DVT")}
                            disabled={changeMeasurementUnitValue ? true : false}
                            placeHolder={`Nhập đơn vị tính`}
                            keyField={'dvt'}
                            columns={InputSearchLookupColumns.measurementUnit}
                            lookupAdding={{
                                initValues: {
                                },
                                catalogAddModalType: CatalogAddModalType.MeasurementUnit
                            }}
                            onOutputChange={(value: string) => {
                                form.setFieldsValue({ unit: value });
                            }}
                            patchValue={changeMeasurementUnitValue}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    < Form.Item
                        label=""
                        name="units"
                        labelCol={{ style: { width: 130 } }}
                        labelAlign='left'
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Checkbox
                            style={{
                                marginRight: "10px",
                            }}
                        >
                            Nhiều đơn vị tính?
                        </Checkbox>
                    </Form.Item >
                </Col>
            </Row>
            {renderColumns(session1Cols)}
            <Row gutter={24}>
                {baseInputLookUp("materialGroup1", "Nhóm vật tư 1", changeMaterialGroupValue[0]?.text, 1)}
                {baseInputLookUp("materialGroup4", "Nhóm vật tư 4", changeMaterialGroupValue[3]?.text, 4)}
                {baseInputLookUp("materialGroup2", "Nhóm vật tư 2", changeMaterialGroupValue[1]?.text, 2)}
                {baseInputLookUp("materialGroup5", "Nhóm vật tư 5", changeMaterialGroupValue[4]?.text, 5)}
                {baseInputLookUp("materialGroup3", "Nhóm vật tư 3", changeMaterialGroupValue[2]?.text, 3)}
                {baseInputLookUp("materialGroup6", "Nhóm vật tư 6", changeMaterialGroupValue[5]?.text, 6)}
                {formItemInputWithColumn({
                    colSpan: 12, name: 'barCode', label: 'Mã vạch',
                    rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(13) }] as Rule[],
                }, 1)}
                {baseInputLookUp("materialGroup7", "Nhóm vật tư 7", changeMaterialGroupValue[6]?.text, 7)}
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name={'materialType'}
                        label={'Loại vật tư'}
                        labelCol={{ style: { width: 130 } }}
                        labelAlign='left'
                        className="w-100"
                        rules={[
                            { validator: validateTextCharater(2) }
                        ]}
                    >
                        <InputSearchLookup
                            fetchDataFunc={InputSearchApiService.getCatalogDymamic("LOAI_VT")}
                            placeHolder='Nhập loại vật tư'
                            keyField={'loai_vt'}
                            columns={InputSearchLookupColumns.materialType}
                            onOutputChange={(value: string) => {
                                form.setFieldsValue({
                                    materialType: value
                                })
                            }}
                            patchValue={changeMaterialTypeValue}
                        />
                    </Form.Item>
                </Col>
                {baseInputLookUp("materialGroup8", "Nhóm vật tư 8", changeMaterialGroupValue[7]?.text, 8)}
            </Row >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name={"tax"}
                        label={"Mã thuế"}
                        labelCol={{ style: { width: 130 } }}
                        labelAlign='left'
                        className="w-100"
                    >
                        <InputSearchLookup
                            fetchDataFunc={InputSearchApiService.getCatalogDymamic("MA_THUE")}
                            placeHolder={`Nhập mã thuế`}
                            keyField={'ma_thue'}
                            columns={InputSearchLookupColumns.tax}
                            lookupAdding={{
                                initValues: {
                                },
                                catalogAddModalType: CatalogAddModalType.Tax
                            }}
                            onOutputChange={(value: string) => {
                                form.setFieldsValue({ tax: value });
                            }}
                            patchValue={changeTaxValue}
                        />
                    </Form.Item>
                </Col>
                {formItemInputWithColumn({
                    colSpan: 12, label: "Thuế xuất VAT", name: "exportTax",
                    render: (
                        <InputNumber defaultValue={0} maxLength={9} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập thuế xuất VAT' style={{ width: "100%" }} />
                    )
                }, 1)}
            </Row>
            < Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 130 } }
                }
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item >
        </>
    );
};

export default MaterialMainInfomation;