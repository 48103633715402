import { EditOutlined } from '@ant-design/icons';
import apiServices from '@app/api/api.services';
import { Pagination } from '@app/api/table.api';
import { CatalogSPParams, MaterialTypeDto } from '@app/api/v6.api';
import { PAGE_SIZE } from '@app/constants/page.constant';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'components/common/Table/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MaterialTypeListViewFilter } from './MaterialTypeListViewFilter';
import { commonFunc } from '@app/shared/functions/common.function';

export const MaterialTypeListView: React.FC = () => {
    const navigate = useNavigate();
    const [materialTypes, setMaterialTypes] = useState<MaterialTypeDto[]>([]);
    const [pagination, setPagination] = useState<Pagination>();
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<CatalogSPParams>({
        vvar: 'LOAI_VT',
        pageNumber: 1,
        pageSize: PAGE_SIZE
    } as CatalogSPParams)

    const fetchData = useCallback(async () => {
        setLoading(true);
        const materialTypesPaging = await apiServices.v6Api.catalogsGetCatalogDynamicPaging(filter);
        setMaterialTypes(materialTypesPaging?.items || []);
        setPagination({
            current: materialTypesPaging?.pageNumber,
            pageSize: filter.pageSize,
            total: materialTypesPaging.totalCount
        })
        setLoading(false);
    }, [filter])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const columns = useMemo(() => {
        const columns: ColumnsType<MaterialTypeDto> = [
            {
                title: 'Mã Loại VT',
                dataIndex: 'loai_vt',
                width: 70
            },
            {
                title: 'Tên Loại VT',
                dataIndex: 'ten_loai',
                width: 130
            },
            {
                title: 'Ghi chú',
                width: 50,
                dataIndex: 'ghi_chu'
            },
            {
                title: 'Trạng thái',
                dataIndex: "status",
                width: 80,
                render: (status: any) => commonFunc.statusRender(status as string),
            },
            {
                title: 'Hành động',
                fixed: 'right',
                align: 'center',
                dataIndex: 'loai_vt',
                width: 35,
                render: (loaiVt: string) => {
                    return (
                        <>
                            <Button type="primary"
                                shape="circle"
                                icon={<EditOutlined />}
                                title='Chỉnh sửa'
                                style={{ marginRight: 3 }}
                                onClick={() => {
                                    navigate(`${loaiVt}/edit`);
                                }}></Button>
                        </>
                    );
                },
            },
        ];

        return columns;
    }, [navigate])

    const handleMaterialTypeAddOrUpDate = () => {
        navigate(`add`);
    }

    function onPagingChange(pageNum: number, pageSize: number) {
        const params: CatalogSPParams = {
            ...filter,
            pageNumber: pageNum,
            pageSize: pageSize,
        } as CatalogSPParams;
        setFilter(params);
    }

    return (
        <div className='app-table-list'>
            <div className='app-table-list-header'>
                <h1 className='app-table-list-header-title'>
                    Danh sách loại vật tư
                </h1>

                <div className='app-table-list-header-actions'>
                    <Button type="primary" onClick={handleMaterialTypeAddOrUpDate}>
                        Thêm mới
                    </Button>
                </div>
            </div>
            <MaterialTypeListViewFilter
                filter={filter}
                onFilter={(data: CatalogSPParams) => {
                    setFilter({
                        ...filter,
                        vvalue: data.vvalue as string,
                        pageNumber: 1
                    } as CatalogSPParams);
                }} />
            <Table
                columns={columns}
                dataSource={materialTypes}
                pagination={{
                    ...pagination,
                    onChange: onPagingChange
                }}
                loading={loading}
                bordered
            />
        </div>
    )
}