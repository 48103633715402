import apiServices from '@app/api/api.services';
import { AppSettingDto, IPBlockType } from '@app/api/v6.api';
import { IPBlockAppSettingConstant } from '@app/constants/ip-block-app-setting.constant';
import { notificationController } from '@app/controllers/notificationController';
import { Button, Col, Form, Input, Row } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';

export interface IIPBlockPagingFilter {
    searchText: string;
    pageNumber: number;
    pageSize: number;
    type: IPBlockType;
}

export const IPBlockAppSetting: React.FC = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const fields = useMemo(() => {
        return [
            { fieldName: IPBlockAppSettingConstant.ClientRateLimit_CountRequest, displayName: 'Số lượng request' },
            { fieldName: IPBlockAppSettingConstant.ClientRateLimit_Minute, displayName: 'Thời gian giới hạn (phút)' }
        ]
    }, []);

    const fetchData = useCallback(async () => {
        setLoading(true);
        const keys = [
            IPBlockAppSettingConstant.ClientRateLimit_CountRequest,
            IPBlockAppSettingConstant.ClientRateLimit_Minute
        ]
        const appSettings = await apiServices.v6Api.appSettingsGetByKeys(keys)

        const values = {} as any;
        fields.forEach(field => {
            const appSetting = appSettings.find(x => x.key === field.fieldName);

            values[field.fieldName] = appSetting ? appSetting.value : '';
        });

        form.setFieldsValue(values);

        setLoading(false);
    }, [])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    async function saveAppSetting(key: string) {
        const formValues = form.getFieldsValue();

        const value = formValues[key];

        setLoading(true);

        const response = await apiServices.v6Api.appSettingsCreateOrUpdateAppSetting({
            key: key,
            value: value
        } as AppSettingDto);

        if (response) {
            notificationController.success({ message: 'Thành công' });
        } else {
            notificationController.error({ message: 'Thành công' });
        }

        setLoading(false);
    }

    return (
        <div className='ip-block-app-settings'>
            <Form form={form} layout="vertical">
                {
                    fields.map(field => (
                        <Row gutter={24} key={field.fieldName}>
                            <Col span={12}>
                                <Form.Item name={field.fieldName} label={field.displayName}>
                                    <Input placeholder={field.displayName} disabled={loading} />
                                </Form.Item>
                            </Col>

                            <Col span={4} style={{
                                alignItems: 'center',
                                display: 'flex'
                            }}>
                                <Button type="primary" onClick={() => {
                                    saveAppSetting(field.fieldName);
                                }}>
                                    Lưu
                                </Button>
                            </Col>
                        </Row>
                    ))
                }
            </Form>
        </div>
    )
}