import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { DictionaryListView } from '../views/DictionaryListView';

const DictionaryListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách từ điển</PageTitle>
            <DictionaryListView />
        </>
    );
};

export default DictionaryListPage;
