import { Col, Form, Input } from "antd"
import { IColFormItemModel, IFormItemModel } from "./form-item.model"

export const formItemInput = (model: IFormItemModel) => {
    const rules = model.rules || [];

    if (model.isRequired) {
        rules.push({
            required: true,
            message: `Vui lòng nhập ${model.label?.toLocaleLowerCase()}`,
        });
    }

    return (
        <Form.Item
            name={model.name}
            label={model.label}
            labelCol={{ style: { width: model.width ?? 130 } }}
            labelAlign='left'
            rules={rules.length > 0 ? rules : undefined}
            className="w-100"
            initialValue={model.initialValue}
        >
            {model.render ? (
                model.render
            ) : (
                <Input size="small" disabled={model.disabled}
                    placeholder={model.placeholder ? model.placeholder : `Nhập ${model.label?.toLocaleLowerCase()}`}
                />
            )}
        </Form.Item>
    );
}

export const formItemInputWithColumn = (model: IColFormItemModel, key?: number | string) => (
    <Col span={model.colSpan} key={key}>
        {formItemInput(model)}
    </Col>
)