import { Modal } from 'antd';
import styled from 'styled-components';

export const ModalFullScreen = styled(Modal)`
    padding: 1rem;
    display: contents;

    .ant-modal-mask{
        padding: 0.9rem;
    }
`;