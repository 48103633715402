import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { MaterialListView } from '../material-views/MaterialListView';

const MaterialListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách vật tư</PageTitle>
            <MaterialListView />
        </>
    );
};

export default MaterialListPage;
