import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import apiServices from '@app/api/api.services';
import { Pagination } from '@app/api/table.api';
import { CatalogSPParams, PaymentMethodDto } from '@app/api/v6.api';
import { PAGE_SIZE } from '@app/constants/page.constant';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'components/common/Table/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PaymentMethodListViewFilter } from './PaymentMethodListViewFilter';
import { commonFunc } from '@app/shared/functions/common.function';
import { PaymentMethodModal } from '../modals/PaymentMethodModal';
import moment from 'moment';

export const PaymentMethodListView: React.FC = () => {
    const [paymentMethodModal, setPaymentMethodModal] = useState<{
        visible: boolean;
        mode?: "add" | "edit" | "detail";
        code?: string
    }>({
        visible: false
    });
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethodDto[]>([]);
    const [pagination, setPagination] = useState<Pagination>();
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<CatalogSPParams>({
        vvar: 'MA_HTTT',
        pageNumber: 1,
        pageSize: PAGE_SIZE
    } as CatalogSPParams)

    const fetchData = useCallback(async () => {
        setLoading(true);
        const paymentMethodsPaging = await apiServices.v6Api.catalogsGetCatalogDynamicPaging(filter);
        setPaymentMethods(paymentMethodsPaging?.items || []);
        setPagination({
            current: paymentMethodsPaging?.pageNumber,
            pageSize: filter.pageSize,
            total: paymentMethodsPaging.totalCount
        })
        setLoading(false);
    }, [filter])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleEdit = async (code: string) => {
        setPaymentMethodModal({
            visible: true,
            mode: 'edit',
            code: code
        })
    };

    const handleDetail = async (code: string) => {
        setPaymentMethodModal({
            visible: true,
            mode: 'detail',
            code: code
        })
    };

    const handleCloseModal = async (reload?: boolean) => {
        setPaymentMethodModal({
            visible: false
        })

        if (reload) {
            const updatedFilter = {
                vvar: 'MA_HTTT',
                pageNumber: 1,
                pageSize: PAGE_SIZE
            } as CatalogSPParams;
            setFilter(updatedFilter);
        }
    };

    const columns = useMemo(() => {
        const columns: ColumnsType<PaymentMethodDto> = [
            {
                title: 'Mã HTTT',
                dataIndex: 'ma_httt',
                width: 45
            },
            {
                title: 'Tên HTTT',
                dataIndex: 'ten_httt',
                width: 100
            },
            {
                title: 'Tên HTTT2',
                dataIndex: 'ten_httt2',
                width: 100
            },
            {
                title: 'Hạn thanh toán',
                dataIndex: 'han_tt',
                width: 70
            },
            {
                title: 'Ghi chú',
                dataIndex: 'ghi_chu',
                width: 70
            },
            {
                title: 'Ngày tạo mới',
                dataIndex: 'date0',
                width: 60,
                render: (date0: Date | null | undefined) => {
                    if (date0) {
                        return moment(date0).format('DD/MM/YYYY');
                    }
                    return "";
                }
            },
            {
                title: 'Thời gian tạo mới',
                dataIndex: 'time0',
                width: 70
            },
            {
                title: 'Người tạo mới',
                dataIndex: 'user_id0',
                width: 60
            },
            {
                title: 'Trạng thái',
                dataIndex: "status",
                width: 60,
                render: (status: any) => commonFunc.statusRender(status as string),
            },
            {
                title: 'Ngày sửa sau cùng',
                dataIndex: 'date2',
                width: 65,
                render: (date2: Date | null | undefined) => {
                    if (date2) {
                        return moment(date2).format('DD/MM/YYYY');
                    }
                    return "";
                }
            },
            {
                title: 'Thời gian sửa',
                dataIndex: 'time2',
                width: 60
            },
            {
                title: 'Người sửa',
                dataIndex: 'user_id2',
                width: 50
            },
            {
                title: 'Hành động',
                fixed: 'right',
                align: 'center',
                dataIndex: 'ma_httt',
                width: 50,
                render: (ma_httt: string) => {
                    return (
                        <>
                            <Button type="primary"
                                shape="circle"
                                icon={<EditOutlined />}
                                title='Chỉnh sửa'
                                style={{ marginRight: 3 }}
                                onClick={() => {
                                    handleEdit(ma_httt)
                                }}>
                            </Button>
                            <Button type="primary"
                                shape="circle"
                                icon={<InfoCircleOutlined />}
                                title='Chi tiết'
                                style={{ marginRight: 3 }}
                                onClick={() => {
                                    handleDetail(ma_httt)
                                }}>
                            </Button>

                        </>
                    );
                },
            },
        ];

        return columns;
    }, [])

    function onPagingChange(pageNum: number, pageSize: number) {
        const params: CatalogSPParams = {
            ...filter,
            pageNumber: pageNum,
            pageSize: pageSize,
        } as CatalogSPParams;
        setFilter(params);
    }

    return (
        <div className='app-table-list'>
            <div className='app-table-list-header'>
                <h1 className='app-table-list-header-title'>
                    Danh sách hình thức thanh toán
                </h1>

                <div className='app-table-list-header-actions'>
                    <Button type="primary" onClick={() => {
                        setPaymentMethodModal({
                            visible: true,
                            mode: 'add'
                        })
                    }}>
                        Thêm mới
                    </Button>

                </div>
            </div>
            <PaymentMethodListViewFilter
                filter={filter}
                onFilter={(data: CatalogSPParams) => {
                    setFilter({
                        ...filter,
                        vvalue: data.vvalue as string,
                        pageNumber: 1
                    } as CatalogSPParams);
                }} />
            <Table
                columns={columns}
                dataSource={paymentMethods}
                scroll={{ x: 2000 }}
                pagination={{
                    ...pagination,
                    onChange: onPagingChange
                }}
                loading={loading}
                bordered
            />

            {
                paymentMethodModal && paymentMethodModal.visible && (
                    <PaymentMethodModal
                        visible={paymentMethodModal.visible}
                        mode={paymentMethodModal.mode!}
                        code={paymentMethodModal.code}
                        onClose={(e) => handleCloseModal(e)}
                    />
                )
            }
        </div>
    )
}