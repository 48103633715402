import { SearchOutlined } from '@ant-design/icons';
import { CatalogSPParams } from '@app/api/v6.api';
import { Form, Input, Space } from 'antd';
import debounce from 'lodash/debounce';
import { useEffect } from 'react';
import { IARS82PagingFilter } from './ARS82ListView';

interface IARS82ListViewFilter {
    filter: IARS82PagingFilter;
    onFilter: (data: IARS82PagingFilter) => void;
}

export const ARS82ListViewFilter: React.FC<IARS82ListViewFilter> = ({
    filter,
    onFilter
}: IARS82ListViewFilter) => {
    const [form] = Form.useForm<IARS82PagingFilter>();

    useEffect(() => {
        form.setFieldsValue(filter);
    }, [filter, form])

    const onFinish = async (data: IARS82PagingFilter) => {
        onFilter(data);
    }

    const onFilterChange = () => {
        const data = form.getFieldsValue();
        onFilter(data);
    }

    const changeSearchTextHandler = (event: any) => {
        onFilterChange();
    };

    const searchTextDebouncedChangeHandler = debounce(changeSearchTextHandler, 300);

    return (
        <div className='table-list-filter-form'>
            <Form form={form}
                layout="inline"
                onFinish={onFinish}>
                <Space size={'small'} direction="horizontal" style={{ width: '100%', justifyContent: 'end' }}>
                    <Form.Item
                        name={'searchText'}
                        className="w-100"
                    >
                        <Input size="small" prefix={<SearchOutlined />} autoComplete='off' placeholder='Tìm kiếm' onChange={searchTextDebouncedChangeHandler} />
                    </Form.Item>
                </Space>
            </Form>
        </div>
    )
}