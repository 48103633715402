import apiServices from '@app/api/api.services';
import { UserProfileDto } from '@app/api/v6.api';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { useResponsive } from '@app/hooks/useResponsive';
import React, { useEffect, useState } from 'react';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import * as S from './ProfileDropdown.styles';

export const ProfileDropdown: React.FC = () => {
  const { isTablet } = useResponsive();
  const [userProfile, setUserProfile] = useState<UserProfileDto>(null!);

  useEffect(() => {
    fetchProfile();
  }, [])

  async function fetchProfile() {
    const profile = await apiServices.v6Api.authsProfile();
    setUserProfile(profile);
  }

  return userProfile
    ? (
      <BasePopover content={<ProfileOverlay />} trigger="click">
        <S.ProfileDropdownHeader as={BaseRow} gutter={[10, 10]} align="middle">
          <BaseCol>
            <span>{userProfile.userName}</span>
          </BaseCol>
        </S.ProfileDropdownHeader>
      </BasePopover>
    )
    : (
      <BasePopover content={<ProfileOverlay />} trigger="click">
        <S.ProfileDropdownHeader as={BaseRow} gutter={[10, 10]} align="middle">
          <BaseCol>
            <BaseAvatar src={'https://lightence-assets.s3.amazonaws.com/avatars/avatar5.webp'} alt="User" shape="circle" size={40} />
          </BaseCol>
          {isTablet && (
            <BaseCol>
              <span></span>
            </BaseCol>
          )}
        </S.ProfileDropdownHeader>
      </BasePopover>
    );
};
