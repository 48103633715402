export const InvoiceConstant = {
    Invoice91_Ma_CT: 'SOH'
}

export const InvoiceAMColumnConstant = {
    MA_SONB: 'ma_sonb',
    MA_DVCS: 'ma_dvcs',
    NGAY_CT: 'ngay_ct',
    SO_CT: 'so_ct',
    MA_NX: 'ma_nx',
    MA_BP: 'ma_bp',
    MA_LNX_PH: 'ma_lnx_ph',
    MA_NVIEN: 'ma_nvien',

    T_SO_LUONG1: 't_so_luong1',
    T_SO_LUONG: 't_so_luong',

    T_TIEN: 't_tien',
    T_TIEN_NT: 't_tien_nt',
    T_TIEN2: 't_tien2',
    T_TIEN_NT2: 't_tien_nt2',

    T_TT: 't_tt',
    T_TT_NT: 't_tt_nt',

    T_GG: 't_gg',
    T_GG_NT: 't_gg_nt',

    T_CK: 't_ck',
    T_CK_NT: 't_ck_nt',

    T_THUE: 't_thue',
    T_THUE_NT: 't_thue_nt',

    LOAI_CK: 'loai_ck',
    SUA_CK: 'sua_ck',
    SUA_THUE: 'sua_thue',
    SUA_TKTHUE: 'sua_tkthue',

    TK_THUE_NO: 'tk_thue_no',
    TK_THUE_CO: 'tk_thue_co',

    AUTO_CK: 'auto_ck',
    SUA_TIEN: 'sua_tien',
    PT_CK: 'pt_ck',
    TINH_CK: 'tinh_ck',

    TY_GIA: 'ty_gia',
    MA_THUE: 'ma_thue',
    THUE_SUAT: 'thue_suat',
}

export const InvoiceColumnConstant = {
    SO_LUONG1: 'so_luong1',
    SO_LUONG: 'so_luong',
    GIA_NT21: 'gia_nt21',
    GIA_BAN: 'gia_ban',
    GIA_BAN_NT: 'gia_ban_nt',
    GIA_NT: 'gia_nt',
    GIA: 'gia',
    GIA2: 'gia2',
    TIEN2: 'tien2',
    TIEN_NT2: 'tien_nt2',
}

export const InvoiceMoneyFieldsSetDefault = [
    InvoiceColumnConstant.SO_LUONG1,
    InvoiceColumnConstant.SO_LUONG,
    InvoiceColumnConstant.GIA_NT21,
    InvoiceColumnConstant.TIEN_NT2,
    'gia_nt2',
    InvoiceColumnConstant.TIEN2,
    InvoiceColumnConstant.GIA2,
]

export const KIEU_POST_OPTIONS = [
    { value: '0', label: 'Chưa thực hiện' },
    { value: '1', label: 'Thực hiện' },
    { value: '2', label: 'Hoàn thành' },
    { value: '3', label: 'Tạm dừng' },
    { value: '4', label: 'Hủy' },
    { value: '5', label: 'Khác' },
]

export const IMTYPE_OPTIONS = [
    { value: '0', label: 'Không chuyển' },
    { value: '1', label: 'Chuyển dữ liệu' },
    { value: '2', label: 'Không xử lý' }
]