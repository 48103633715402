import { InputSearchApiService } from '@app/api/input-search-api.service';
import { CustomerDto, CustomerUpdateDto } from '@app/api/v6.api';
import InputSearchLookup from '@app/components/common/input-search-lookup/InputSearchLookup';
import { InputSearchLookupColumns } from '@app/components/common/input-search-lookup/constants/input-lookup-entity-columns.constant';
import { IInputSearchLookupApiRequest } from '@app/components/common/input-search-lookup/models/input-search-lookup-column';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { PaginatedList } from '@app/shared/models/paginated-list.model';
import { FormInstance } from 'antd/es/form/Form';
import { Checkbox, Col, Form, Row } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Rule } from 'antd/lib/form';
import { CatalogAddModalType } from '@app/components/shared/modals/catalog-add-modal/catalog-add-modal';

interface ICustomerEdit {
    form: FormInstance<CustomerUpdateDto>;
}
const CustomerMainInfomation: React.FC<ICustomerEdit> = ({
    form,
}: ICustomerEdit) => {

    const validateText16Charater = (_: any, value: any) => {
        if (value && value.length > 16) {
            return Promise.reject('Tối đa 16 kí tự');
        }
        return Promise.resolve();
    };

    const customerGroupInputLookUp = useCallback((name: string, label: string, groupType: number) => {
        const fetchDataFunc: (request: IInputSearchLookupApiRequest) => Promise<PaginatedList<any>> = InputSearchApiService.getCatalogDymamic("NH_KH");

        return (
            <Col span={12}>
                <Form.Item
                    name={name}
                    label={label}
                    labelCol={{ style: { width: 130 } }}
                    labelAlign='left'
                    className="w-100"
                    rules={[{ validator: validateText16Charater }]}
                >
                    <InputSearchLookup
                        fetchDataFunc={fetchDataFunc}
                        placeHolder={`Nhập ${label}`}
                        keyField={'groupCode'}
                        lookupAdding={{
                            initValues: {
                                groupType: groupType
                            },
                            catalogAddModalType: CatalogAddModalType.CustomerGroup
                        }}
                        columns={InputSearchLookupColumns.customerGroup}
                        onOutputChange={(value: string) => {
                            form.setFieldsValue({ [name]: value });
                        }}
                    />
                </Form.Item>
            </Col>
        )
    }, []);

    const validateTextCharater = (maxLen: number) => (_: any, value: any) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 24, name: 'address', label: 'Địa chỉ',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(254) }] as Rule[],
            },
            {
                colSpan: 24, name: 'partner', label: 'Đối tác',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(128) }] as Rule[],
            },
        ]
    }, [])

    const session2Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 24, name: 'homePage', label: 'Home page',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(128) }] as Rule[],
            },
            {
                colSpan: 24, label: "Tài khoản ngân hàng", name: "tk",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(16) }] as Rule[],
            },
            {
                colSpan: 24, label: "Ngân hàng giao dịch", name: "bank",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(64) }] as Rule[],
            },
            {
                colSpan: 24, label: "Tỉnh thành", name: "province",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(100) }] as Rule[],
            },
        ]
    }, [])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={24}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, []);

    return (
        <>
            {renderColumns(session1Cols)}
            <Row gutter={24}>
                {
                    formItemInputWithColumn({
                        colSpan: 12, label: "Thuế xuất VAT", name: "taxCode",
                        rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(18) }] as Rule[],
                    }, 1)
                }
                {
                    formItemInputWithColumn({
                        colSpan: 12, label: "Theo nhóm mã giá", name: "priceCode",
                        rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(8) }] as Rule[],
                    }, 1)
                }
                {customerGroupInputLookUp("customerGroup1", "Nhóm khách hàng 1", 1)}
                {customerGroupInputLookUp("customerGroup4", "Nhóm khách hàng 4", 4)}
                {customerGroupInputLookUp("customerGroup2", "Nhóm khách hàng 2", 2)}
                {customerGroupInputLookUp("customerGroup5", "Nhóm khách hàng 5", 5)}
                {customerGroupInputLookUp("customerGroup3", "Nhóm khách hàng 3", 3)}
                {customerGroupInputLookUp("customerGroup6", "Nhóm khách hàng 6", 6)}
                {formItemInputWithColumn({
                    colSpan: 12, label: "Số điện thoại", name: "phoneNumber", rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(128) }] as Rule[],
                }, 1)}
                {customerGroupInputLookUp("customerGroup7", "Nhóm khách hàng 7", 7)}
                {formItemInputWithColumn({
                    colSpan: 12, label: "Số Fax", name: "Fax",
                    rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(128) }] as Rule[],
                }, 1)}
                {customerGroupInputLookUp("customerGroup7", "Nhóm khách hàng 8", 8)}
                {formItemInputWithColumn({
                    colSpan: 12, label: "Email", name: "email",
                    rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(128) }] as Rule[],
                }, 1)}
                {customerGroupInputLookUp("customerGroup7", "Nhóm khách hàng 9", 9)}
            </Row>
            {renderColumns(session2Cols)}

            <Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 130 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item>
        </>
    );
};

export default CustomerMainInfomation;