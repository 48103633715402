import { Modal } from '@app/components/common/Modal/Modal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Form, Row, Tabs, TabsProps } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import apiServices from '@app/api/api.services';
import { antFormFinishErrorNotification } from '@app/shared/functions/common.function';
import { BankContractDto } from '@app/api/v6.api';
import BankContractMainInfomation from './bank-contract-tabs/BankContractTabMainInfomation';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import InputSearchLookup from '@app/components/common/input-search-lookup/InputSearchLookup';
import { InputSearchApiService } from '@app/api/input-search-api.service';
import { InputSearchLookupColumns } from '@app/components/common/input-search-lookup/constants/input-lookup-entity-columns.constant';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import moment from 'moment';
import { CatalogAddModalType } from '@app/components/shared/modals/catalog-add-modal/catalog-add-modal';

interface IModalBankContract {
    code?: string;
    visible: boolean;
    mode: "add" | "edit" | "detail";
    onClose: (reload?: boolean) => void;
}

interface ITab {
    dataEdit?: BankContractDto;
    mode: "add" | "edit" | "detail";
}

export const BankContractModal: React.FC<IModalBankContract> = ({ code, visible, mode, onClose }: IModalBankContract) => {
    const [form] = BaseForm.useForm();
    const [titleModal, setTileModal] = useState("Thêm mới khế ước");
    const [loading, setLoading] = useState<boolean>(false);
    const [dataTab, setDataTab] = useState<ITab>();

    const handleCancel = async () => {
        setDataTab(undefined);
        onClose();
    };

    const [changeAlnhkuValue, setChangeAlnhkuValue] = useState<
        { text: string }[]
    >([]);

    useEffect(() => {
        if (code) {
            setChangeAlnhkuValue([
                { text: dataTab?.dataEdit?.nhKU1 as string },
                { text: dataTab?.dataEdit?.nhKU2 as string },
                { text: dataTab?.dataEdit?.nhKU3 as string },
            ]);
        }
    }, [code])

    const fetchData = useCallback(async () => {
        setDataTab(undefined);
        if (code) {
            if (code) {
                const dataEditBankContract = await apiServices.v6Api.bankContractsGetById(code);
                form.setFieldsValue({
                    ...dataEditBankContract,
                    status: (dataEditBankContract.status === '1' ? true : false) as any,
                    ngayKU1: dataEditBankContract.ngayKU1 ? moment(dataEditBankContract.ngayKU1, "YYYY-MM-DD") : undefined,
                    ngayKU2: dataEditBankContract.ngayKU2 ? moment(dataEditBankContract.ngayKU2, "YYYY-MM-DD") : undefined,
                    maNT: dataEditBankContract.maNT,
                })
                setDataTab({ dataEdit: dataEditBankContract, mode: mode })
            }
            setTileModal(`${mode === "detail" ? "Chi tiết" : "Chỉnh sửa"} khế ước - ${code}`);
        } else {
            setTileModal(`Thêm mới khế ước`);
        }
    }, [code, mode, form])

    useEffect(() => {
        form.resetFields();
        code ? setDataTab({ dataEdit: dataTab?.dataEdit, mode: mode }) : setDataTab({ dataEdit: dataTab?.dataEdit, mode: "add" })
        fetchData();
    }, [fetchData, form, code, mode])

    const handleDisable = (() => {
        setDataTab({ dataEdit: dataTab?.dataEdit, mode: "edit" })
        setTileModal(`${dataTab?.mode !== "detail" ? "Chi tiết" : "Chỉnh sửa"} khế ước - ${code}`);
    });

    const renderForm = useCallback(() => {
        return <>
            <Form
                form={form}
                name="basic"
                initialValues={{ remember: true }}
                autoComplete="off"
                onFinish={onFinish}
                onFinishFailed={antFormFinishErrorNotification}
            >
                {renderColumns(session1Cols)}
                <Tabs defaultActiveKey="1" items={items} />
            </Form>
        </>
    }, [dataTab, code])

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Thông tin chính',
            children: <BankContractMainInfomation
                code={code}
                form={form}
                dataTab={dataTab}
            />,
        }
    ];

    const onFinish = async () => {
        if (dataTab?.mode === "detail") {
            handleDisable();
            return;
        }

        const data = await form.validateFields();
        data.status = data.status ? '1' : '0';
        data.dinhdang = '00000';

        setLoading(true);
        const promise = code
            ? apiServices.v6Api.bankContractsUpdateBankContract(code, data)
            : apiServices.v6Api.bankContractsCreateBankContract(data);

        promise.then((response) => {
            if (response.isSuccess) {
                notificationController.success({ message: 'Thành công' });
                onClose(true);
                return;
            }

            notificationController.error({ message: response.message });
        }).catch(() => {
            notificationController.error({ message: 'Thất bại' });
        }).finally(() => {
            setLoading(false);
        });
    }

    const validateTextCharater = (maxLen: number) => (_: any, value: string) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'maKU', label: 'Mã khế ước', disabled: code ? true : false,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { required: true, message: "Vui lòng nhập mã khế ước" },
                { validator: validateTextCharater(16) }],
                placeholder: "Nhập mã khế ước"
            },
            {
                colSpan: 12, name: 'maDVCS', label: 'Mã DVCS',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(8) }],
                disabled: dataTab?.mode === "detail", placeholder: "Nhập đơn vị",
                render: (<>
                    <InputSearchLookup
                        disabled={dataTab?.mode === "detail"}
                        fetchDataFunc={InputSearchApiService.getCatalogDymamic("MA_DVCS")}
                        placeHolder='Nhập mã đvcs'
                        keyField={'ma_dvcs'}
                        columns={InputSearchLookupColumns.baseUnit}
                        lookupAdding={{
                            initValues: {
                            },
                            catalogAddModalType: CatalogAddModalType.BaseUnit
                        }}
                        onOutputChange={(value: string) => {
                            form.setFieldsValue({
                                maDVCS: value
                            })
                        }}
                        patchValue={{ text: dataTab?.dataEdit?.maDVCS as string }}

                    />
                </>)
            },
            {
                colSpan: 12, name: 'tenKU', label: 'Tên khế ước',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập tên khế ước" },
                { validator: validateTextCharater(18) }],
                disabled: dataTab?.mode === "detail", placeholder: "Nhập tên khế ước"
            },
            {
                colSpan: 12, name: 'tenKU2', label: 'Tên khế ước 2',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(18) }],
                placeholder: "Nhập tên khế ước 2", disabled: dataTab?.mode === "detail"
            },
        ]
    }, [dataTab?.mode])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={24}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, []);

    return (
        <Modal
            title={titleModal}
            confirmLoading={loading}
            open={visible}
            destroyOnClose={true}
            width={"1000px"}
            onOk={() => form.submit()}
            onCancel={() => handleCancel()}
            okText={dataTab?.mode === "detail" ? "Chỉnh sửa" : "Lưu"}
            cancelText="Hủy"
            okButtonProps={{ style: { padding: "5px", height: "36px" } }}
            cancelButtonProps={{ style: { padding: "5px", height: "36px" } }}
        >
            <div className='basic-form'>
                {renderForm()}
            </div>
        </Modal>
    )
}