import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Checkbox, Form, InputNumber, Row, Select } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { Rule } from 'antd/lib/form';
import { Utils } from '@app/shared/helpers/utils';

interface ICatalogPriceIdEdit {
    mode: "add" | "edit" | "detail";
}

const CatalogPriceIdKeyInfomation: React.FC<ICatalogPriceIdEdit> = ({ mode,
}: ICatalogPriceIdEdit) => {

    const validateTextCharater = (maxLen: number) => (_: any, value: string) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'ma_gia', label: 'Mã giá', disabled: mode !== "add",
                placeholder: "Nhập mã giá",
                rules: [{ whitespace: true, message: "không nhập khoảng trắng" }, { required: true, message: "Vui lòng nhập mã giá" },
                { validator: validateTextCharater(8) }] as Rule[]
            },
            {
                colSpan: 24, name: 'ten_gia', label: 'Diễn giải', disabled: mode === "detail",
                placeholder: "Nhập diễn giải",
                rules: [{ whitespace: true, message: "không nhập khoảng trắng" }, { required: true, message: "Vui lòng nhập diễn giải" },
                { validator: validateTextCharater(48) }] as Rule[]
            },
            {
                colSpan: 24, name: 'ten_gia2', label: 'Tên 2', disabled: mode === "detail",
                placeholder: "Nhập tên 2",
                rules: [{ whitespace: true, message: "không nhập khoảng trắng" },
                { validator: validateTextCharater(48) }] as Rule[]
            },
            {
                colSpan: 12, name: 'ma_gia0', label: 'Theo giá mã chuẩn', disabled: mode === "detail",
                placeholder: "Nhập theo giá mã chuẩn",
                rules: [{ whitespace: true, message: "không nhập khoảng trắng" },
                { validator: validateTextCharater(8) }] as Rule[]
            },
            {
                colSpan: 12, name: 'pt_gia', label: 'Giảm %', disabled: mode === "detail",
                render: (
                    <InputNumber disabled={mode === "detail"} min={0} maxLength={16} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập giảm %' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 10, name: 'loai', label: 'Loại', disabled: mode === "detail",
                render: (< Select
                    disabled={mode === "detail"}
                    style={{ width: "100%", height: 30 }}
                    defaultValue={0}
                    options={[
                        { value: '0', label: '0' },
                        { value: '1', label: '1' },
                        { value: '2', label: '2' },
                    ]}
                />)
            },
            {
                colSpan: 10, name: '', label: '0: Không chuẩn , 1 : Giá chuẩn, 2: Theo khách ', width: 500,
                render: (<>
                </>)
            },
        ]
    }, [mode])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={10}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, [mode]);

    return (
        <>
            {renderColumns(session1Cols)}

            <Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 130 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={mode === "detail"}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item>
        </>
    );
};

export default CatalogPriceIdKeyInfomation;