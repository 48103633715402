import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Utils } from '@app/shared/helpers/utils';
import { InputNumber, Row } from 'antd';
import React, { useCallback, useMemo } from 'react';

interface IMaterialEdit {
    isEditting: boolean;
}

const MaterialOtherInfomation: React.FC<IMaterialEdit> = ({ isEditting }: IMaterialEdit) => {

    const validateTextCharater = (maxLen: number) => (_: any, value: any) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 13, name: 'dvtQD', label: 'ĐVT QĐ',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(10) }],
            },
            {
                colSpan: 13, name: 'heSoQD1', label: 'Hệ số 1', render: (
                    <InputNumber formatter={Utils.numberFormatter} size={"small"} maxLength={11} placeholder='Nhập hệ số 1' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 13, name: 'heSoQD2', label: 'Hệ số 2', render: (
                    <InputNumber formatter={Utils.numberFormatter} size={"small"} maxLength={11} placeholder='Nhập hệ số 2' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 13, name: 'heSoQD3', label: 'Hệ số 3', render: (
                    <InputNumber formatter={Utils.numberFormatter} size={"small"} maxLength={11} placeholder='Nhập hệ số 3' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 13, name: 'heSoQD4', label: 'Hệ số 4', render: (
                    <InputNumber formatter={Utils.numberFormatter} size={"small"} maxLength={11} placeholder='Nhập hệ số 4' style={{ width: "100%" }} />
                )
            }

        ]
    }, [isEditting])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={24}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, []);

    return (
        <>
            {renderColumns(session1Cols)}
        </>
    );
};

export default MaterialOtherInfomation;