import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { CatalogDiscountListView } from '../views/CatalogDiscountListView';

const CatalogDiscountListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách chiết khấu</PageTitle>
            <CatalogDiscountListView />
        </>
    );
};

export default CatalogDiscountListPage;
