import { SearchOutlined } from '@ant-design/icons';
import { CatalogSPParams } from '@app/api/v6.api';
import { Form, Input, Space } from 'antd';
import debounce from 'lodash/debounce';
import { useEffect } from 'react';

interface ICustomerListViewFilter {
  filter: CatalogSPParams;
  onFilter: (data: CatalogSPParams) => void;
}

export const CustomerListViewFilter: React.FC<ICustomerListViewFilter> = ({
  filter,
  onFilter
}: ICustomerListViewFilter) => {
  const [form] = Form.useForm<CatalogSPParams>();

  useEffect(() => {
    form.setFieldsValue(filter);
  }, [filter, form])

  const onFinish = async (data: CatalogSPParams) => {
    onFilter(data);
  }

  const onFilterChange = () => {
    const data = form.getFieldsValue();
    onFilter(data);
  }

  const changeSearchTextHandler = (event: any) => {
    onFilterChange();
  };

  const searchTextDebouncedChangeHandler = debounce(changeSearchTextHandler, 300);

  return (
    <div className='table-list-filter-form'>
      <Form form={form}
        layout="inline"
        onFinish={onFinish}>
        <Space size={'small'} direction="horizontal" style={{ width: '100%', justifyContent: 'end' }}>
          <Form.Item
            name={'vvalue'}
            className="w-100"
          >
            <Input size="small" prefix={<SearchOutlined />} autoComplete='off' placeholder='Tìm kiếm' onChange={searchTextDebouncedChangeHandler} />
          </Form.Item>
        </Space>
      </Form>
    </div>
  )
}