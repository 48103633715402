import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Checkbox, Form, InputNumber, Row } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { Rule } from 'antd/lib/form';
import { Utils } from '@app/shared/helpers/utils';

interface ICatalogDiscountEdit {
    mode: "add" | "edit" | "detail";
}
const CatalogDiscountKeyInfomation: React.FC<ICatalogDiscountEdit> = ({ mode
}: ICatalogDiscountEdit) => {
    const validateTextCharater = (maxLen: number) => (_: any, value: any) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 13, name: 'loai_ck', label: 'Loại chiết khấu', disabled: mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(2) }] as Rule[],
            },
            {
                colSpan: 6, name: '', label: 'Chiết khấu theo kho',
                render: (
                    <></>
                )
            },
            {
                colSpan: 13, name: 'muc_do', label: 'Mức độ ưu tiên', disabled: mode === "detail",
                render: (
                    <InputNumber disabled={mode === "detail"} defaultValue={0} maxLength={3} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập từ số' style={{ width: "100%" }} />
                )
            }
        ]
    }, [mode])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={10}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, [mode]);

    return (
        <>
            {renderColumns(session1Cols)}
            <Form.Item
                label="Tính trên tiền hàng"
                name="tien_yn"
                labelCol={{ style: { width: 130 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={mode === "detail"}
                >
                    Có sử dụng
                </Checkbox>
            </Form.Item>
            <Form.Item
                label="Chiết khấu cộng thêm"
                name="cong_yn"
                labelCol={{ style: { width: 130 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={mode === "detail"}
                >
                    Có sử dụng
                </Checkbox>
            </Form.Item>
            <Form.Item
                label="Chiết khấu cộng thêm "
                name="tienh_yn"
                labelCol={{ style: { width: 130 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={mode === "detail"}
                >
                    Có sử dụng
                </Checkbox>
            </Form.Item>
            <Form.Item
                label="Tính trên giá trị còn lại"
                name="con_lai_yn"
                labelCol={{ style: { width: 130 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={mode === "detail"}
                >
                    Có sử dụng
                </Checkbox>
            </Form.Item>
            <Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 130 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={mode === "detail"}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item>

        </>
    );
};

export default CatalogDiscountKeyInfomation;