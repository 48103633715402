import { EditOutlined } from '@ant-design/icons';
import apiServices from '@app/api/api.services';
import { Pagination } from '@app/api/table.api';
import { CatalogSPParams, MaterialDto } from '@app/api/v6.api';
import { PAGE_SIZE } from '@app/constants/page.constant';
import { antFormColumnFilterStatus, commonFunc } from '@app/shared/functions/common.function';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'components/common/Table/Table';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MaterialListViewFilter } from './MaterialListViewFilter';

export const MaterialListView: React.FC = () => {
    const navigate = useNavigate();
    const [materials, setmaterials] = useState<MaterialDto[]>([]);
    const [pagination, setPagination] = useState<Pagination>();
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<CatalogSPParams>({
        vvar: 'MA_VT',
        pageNumber: 1,
        pageSize: PAGE_SIZE
    } as CatalogSPParams)

    const fetchData = useCallback(async () => {
        setLoading(true);
        const materialsPaging = await apiServices.v6Api.catalogsGetCatalogDynamicPaging(filter);
        setmaterials(materialsPaging?.items || []);
        setPagination({
            current: materialsPaging?.pageNumber,
            pageSize: filter.pageSize,
            total: materialsPaging.totalCount
        })
        setLoading(false);
    }, [filter])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const columns: ColumnsType<MaterialDto> = [
        {
            title: 'Mã VT',
            dataIndex: 'ma_vt',
            width: 70
        },
        {
            title: 'Tên VT',
            dataIndex: 'ten_vt',
            width: 130
        },
        {
            title: 'Tên nhóm',
            width: 60,
            dataIndex: 'ten_vt2'
        },
        {
            title: 'Mã loại vật tư',
            width: 60,
            dataIndex: 'loai_vt'
        },
        {
            title: 'ĐVT',
            width: 50,
            dataIndex: 'dvt'
        },
        {
            title: 'Giá tồn',
            width: 30,
            dataIndex: 'gia_ton'
        },
        {
            title: 'Trạng thái',
            width: 60,
            dataIndex: 'status',
            render: (status) => commonFunc.statusRender(status as string)
        },
        {
            title: 'Hành động',
            fixed: 'right',
            align: 'center',
            dataIndex: 'ma_vt',
            width: 35,
            render: (ma_vt: string) => {
                return (
                    <>
                        <Button type="primary"
                            shape="circle"
                            icon={<EditOutlined />}
                            title='Chỉnh sửa'
                            style={{ marginRight: 3 }}
                            onClick={() => {
                                navigate(`${ma_vt}/edit`);
                            }}></Button>
                    </>
                );
            },
        },
    ];

    const handleMaterialAddOrUpDate = () => {
        navigate(`add`);
    }

    function onPagingChange(pageNum: number, pageSize: number) {
        const params: CatalogSPParams = {
            ...filter,
            pageNumber: pageNum,
            pageSize: pageSize,
        } as CatalogSPParams;
        setFilter(params);
    }

    return (
        <div className='app-table-list'>
            <div className='app-table-list-header'>
                <h1 className='app-table-list-header-title'>
                    Danh sách vật tư
                </h1>

                <div className='app-table-list-header-actions'>
                    <Button type="primary" onClick={handleMaterialAddOrUpDate}>
                        Thêm mới
                    </Button>
                </div>
            </div>
            <MaterialListViewFilter
                filter={filter}
                onFilter={(data: CatalogSPParams) => {
                    setFilter({
                        ...filter,
                        vvalue: data.vvalue as string,
                        pageNumber: 1
                    } as CatalogSPParams);
                }} />
            <Table
                columns={columns}
                dataSource={materials}
                pagination={{
                    ...pagination,
                    onChange: onPagingChange
                }}
                loading={loading}
                bordered
            />
        </div>
    )
}