import { Modal } from '@app/components/common/Modal/Modal';
import QRCode from 'react-qr-code';

interface ICodeQRModal {
    codeQR?: string;
    visible?: boolean;
    onClose: (cancel?: boolean) => void;
}

export const CodeQRModal: React.FC<ICodeQRModal> = ({ codeQR, visible, onClose }: ICodeQRModal) => {

    const handleCancel = async () => {
        onClose();
    };

    return (
        <Modal
            style={{ textAlign: "center" }}
            title={"Mã QR"}
            open={visible}
            destroyOnClose={true}
            footer={false}
            onCancel={handleCancel}
            cancelButtonProps={{ style: { padding: "2px", height: "36px" } }}
        >
            <QRCode value={codeQR ?? ""} size={400} />
            <h2>{codeQR}</h2>
        </Modal>
    )
}