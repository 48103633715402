import { Modal } from '@app/components/common/Modal/Modal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Checkbox, Col, Form, InputNumber, Row } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import apiServices from '@app/api/api.services';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Rule } from 'antd/lib/form';
import { antFormFinishErrorNotification } from '@app/shared/functions/common.function';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import InputSearchLookup from '@app/components/common/input-search-lookup/InputSearchLookup';
import { InputSearchApiService } from '@app/api/input-search-api.service';
import { InputSearchLookupColumns } from '@app/components/common/input-search-lookup/constants/input-lookup-entity-columns.constant';
import { CatalogAddModalType } from '@app/components/shared/modals/catalog-add-modal/catalog-add-modal';
import { AccountGroupDto, AccountGroupUpdateDto } from '@app/api/v6.api';
import { Utils } from '@app/shared/helpers/utils';

interface IModalAccountGroup {
    code?: string;
    visible: boolean;
    mode: "add" | "edit" | "detail";
    disableGroup?: boolean;
    initialValues?: AccountGroupUpdateDto;
    onClose: (reload?: boolean) => void;
    onDispatchResponse?: (data: AccountGroupDto) => void;
}

export const AccountGroupModal: React.FC<IModalAccountGroup> = ({ code, visible, mode, initialValues, disableGroup, onClose, onDispatchResponse }: IModalAccountGroup) => {
    const [form] = BaseForm.useForm();
    const [titleModal, setTileModal] = useState("Thêm mới nhóm tài khoản");
    const [currentMode, setCurrentMode] = useState(mode);
    const [loading, setLoading] = useState<boolean>(false);
    const [changeBaseUnitValue, setChangeBaseUnitValue] = useState<{ text: string }>(null!);

    const handleCancel = async () => {
        onClose();
    };

    const fetchData = useCallback(async () => {
        if (code) {
            if (code) {
                const dataEditAccountGroup = await apiServices.v6Api.accountGroupsGetById(code);
                form.setFieldsValue({
                    ...dataEditAccountGroup,
                    status: (dataEditAccountGroup.status === '1' ? true : false) as any
                })
                setChangeBaseUnitValue({ text: dataEditAccountGroup.baseUnitCode as string });
            }
            setTileModal(`${mode === "detail" ? "Chi tiết" : "Chỉnh sửa"} nhóm tài khoản - ${code}`);
        } else {
            if (initialValues) {
                form.setFieldValue("groupType", initialValues.groupType);
            }
            setTileModal(`Thêm mới nhóm tài khoản`);
        }
    }, [code, mode, form])

    useEffect(() => {
        form.resetFields();
        code ? setCurrentMode(mode) : setCurrentMode("add");
        fetchData();
    }, [fetchData, form, code, mode])

    const handleDisable = (() => {
        setCurrentMode("edit")
        setTileModal(`${currentMode !== "detail" ? "Chi tiết" : "Chỉnh sửa"} nhóm tài khoản - ${code}`);
    });

    const validateTextCharater = (maxLen: number) => (_: any, value: string) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const mainInforCols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'groupCode', label: 'Mã nhóm TK', disabled: code ? true : false,
                placeholder: "Nhập mã nhóm TK",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, {
                    required: true,
                    message: "Vui lòng nhập mã nhóm TK"
                }, { validator: validateTextCharater(16) }] as Rule[]
            },
            {
                colSpan: 12, name: 'groupType', label: 'Loại nhóm',
                render: (
                    <InputNumber disabled={currentMode === "detail" || disableGroup} defaultValue={0} maxLength={1} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập loại nhóm' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 12, name: 'groupName', label: 'Tên nhóm TK',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập tên TK" },
                { validator: validateTextCharater(128) }] as Rule[],
                disabled: currentMode === "detail", placeholder: "Nhập tên nhóm TK"
            },
            {
                colSpan: 12, name: 'groupName2', label: 'Tên nhóm TK 2',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(128) }] as Rule[],
                placeholder: "Nhập tên nhóm TK 2", disabled: currentMode === "detail"
            },
        ]
    }, [code, currentMode])

    const renderColumns = () => {
        return (
            <Row gutter={24}>
                {
                    mainInforCols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }

    const onFinish = async () => {
        if (currentMode === "detail") {
            handleDisable();
            return;
        }
        const data = await form.validateFields();
        data.status = data.status ? '1' : '0';

        setLoading(true);
        const promise = code
            ? apiServices.v6Api.accountGroupsUpdateAccountGroup(code, data)
            : apiServices.v6Api.accountGroupsCreateAccountGroup(data);

        promise.then((response) => {
            if (response.isSuccess) {
                notificationController.success({ message: 'Thành công' });
                onClose(true);

                if (onDispatchResponse) {
                    onDispatchResponse(response.data as AccountGroupDto);
                }
                return;
            }

            notificationController.error({ message: response.message });
        }).catch(() => {
            notificationController.error({ message: 'Thất bại' });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal
            title={titleModal}
            confirmLoading={loading}
            open={visible}
            destroyOnClose={true}
            width={"1000px"}
            onOk={() => form.submit()}
            onCancel={() => handleCancel()}
            okText={currentMode === "detail" ? "Chỉnh sửa" : "Lưu"}
            cancelText="Hủy"
            okButtonProps={{ style: { padding: "5px", height: "36px" } }}
            cancelButtonProps={{ style: { padding: "5px", height: "36px" } }}
        >
            <div className='basic-form'>
                <Form
                    form={form}
                    style={{ width: "95%" }}
                    name="basic"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onFinish={onFinish}
                    onFinishFailed={antFormFinishErrorNotification}
                >
                    {renderColumns()}
                    <Row gutter={24} >
                        <Col span={12}>
                            <Form.Item
                                name={'baseUnitCode'}
                                label={'Mã đvcs'}
                                labelCol={{ style: { width: 130 } }}
                                labelAlign='left'
                                className="w-100"
                                rules={[
                                    { validator: validateTextCharater(8) }
                                ]}
                            >
                                <InputSearchLookup
                                    fetchDataFunc={InputSearchApiService.getCatalogDymamic("MA_DVCS")}
                                    placeHolder='Nhập mã đvcs'
                                    disabled={currentMode === "detail"}
                                    keyField={'ma_dvcs'}
                                    columns={InputSearchLookupColumns.baseUnit}
                                    lookupAdding={{
                                        initValues: {
                                        },
                                        catalogAddModalType: CatalogAddModalType.BaseUnit
                                    }}
                                    onOutputChange={(value: string) => {
                                        form.setFieldsValue({
                                            baseUnitCode: value
                                        })
                                    }}
                                    patchValue={currentMode !== "add" ? changeBaseUnitValue : undefined}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        label="Trạng thái"
                        name="status"
                        labelCol={{ style: { width: 130 } }}
                        labelAlign='left'
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Checkbox
                            style={{
                                marginRight: "10px",
                            }}
                            disabled={currentMode === "detail"}
                        >
                            Hoạt động?
                        </Checkbox>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    )
}