import { AlSonBDto } from '@app/api/v6.api';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Utils } from '@app/shared/helpers/utils';
import { Button, Checkbox, Col, DatePicker, Input, InputNumber, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import Form, { Rule } from 'antd/lib/form';
import moment from 'moment';
import React, { useCallback, useMemo, useState, } from 'react';
import { VourcherModal } from './AlsonBModalListVourcher';

interface IAlSonBEdit {
    code?: string;
    form: FormInstance<any>;
    dataTab: ITab | undefined
}

interface ITab {
    dataEdit?: AlSonBDto;
    mode: "add" | "edit" | "detail";
}

const AlSonBMainInfomation: React.FC<IAlSonBEdit> = ({ form, dataTab }: IAlSonBEdit) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const validateTextCharater = (maxLen: number) => (_: any, value: string) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const setValueChangeTransform = (maxLen: number) => (_: any, value: string) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
            const valuePhanDau = form.getFieldValue("phanDau") ? form.getFieldValue("phanDau") : "";
            const valueSoCT = form.getFieldValue("soCT") ? form.getFieldValue("soCT") : "";
            const valueSoCTString = `${valueSoCT}`
            const paddedString = valueSoCTString.padStart(5, '0');
            const valuePhanCuoi = form.getFieldValue("phanCuoi") ? form.getFieldValue("phanCuoi") : "";
            form.setFieldValue("transform", `${valuePhanDau}${paddedString}${valuePhanCuoi}`);
        }
        return Promise.resolve();
    };

    const handleChooseAlct = async () => {
        setIsModalOpen(true);
    };

    const handleSubmitAlct = async (e: string | undefined) => {
        await form.setFieldValue("maCTNB", e);
        setIsModalOpen(false)
    };

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 10, name: 'maMauHD', label: 'Ký hiệu mẫu hóa đơn', disabled: dataTab?.mode === "detail",
                placeholder: "Nhập ký hiệu mẫu hóa đơn", width: 200,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(24) }] as Rule[]
            },
            {
                colSpan: 14, name: '', label: '', render: (<></>)
            },
            {
                colSpan: 10, name: 'soSeri', label: 'Số Seri', width: 200,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(12) }],
                disabled: dataTab?.mode === "detail", placeholder: "Nhập số Seri"
            },
            {
                colSpan: 14, name: '', label: '', render: (<></>)
            },
            {
                colSpan: 14, name: 'maCTNB', label: 'Danh sách chứng tử',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(254) }], width: 200,
                placeholder: "Nhập danh sách chứng tử", disabled: true
            },
            {
                colSpan: 10, name: '', label: '', width: 0,
                render: (<>
                    <Button type="primary" disabled={dataTab?.mode === "detail"} onClick={() => { handleChooseAlct() }} >
                        Chọn danh sách chứng từ
                    </Button>
                </>)
            },
            {
                colSpan: 10, name: 'chkSoCT', label: 'Kiểm tra trùng số chứng từ', width: 200,
                render: (< Select
                    disabled={dataTab?.mode === "detail"}
                    style={{ width: "100%", height: 30 }}
                    defaultValue={0}
                    options={[
                        { value: '0', label: '0' },
                        { value: '1', label: '1' },
                        { value: '2', label: '2' },
                    ]}
                />)
            },
            {
                colSpan: 14, name: '', width: 600, label: '0 - Không kiểm tra , 1 - Cảnh cáo , 2 - Không cho lưu', render: (<></>)
            },
            {
                colSpan: 10, name: 'typeSoCT', label: 'Kiểu tăng số chứng từ', width: 200,
                render: (< Select
                    disabled={dataTab?.mode === "detail"}
                    style={{ width: "100%", height: 30 }}
                    defaultValue={0}
                    options={[
                        { value: '0', label: '0' },
                        { value: '1', label: '1' },
                        { value: '2', label: '2' },
                    ]}
                />)
            },
            {
                colSpan: 14, name: '', width: 600, label: '0 - Không tăng , 1 - Theo số hiện thời , 2 - Lấy lại số chưa chọn', render: (<></>)
            },
            {
                colSpan: 10, name: 'typeAuto', label: 'Kiểu tăng tự động', width: 200,
                render: (< Select
                    disabled={dataTab?.mode === "detail"}
                    style={{ width: "100%", height: 30 }}
                    defaultValue={0}
                    options={[
                        { value: '0', label: '0' },
                        { value: '1', label: '1' },
                        { value: '2', label: '2' },
                        { value: '3', label: '3' },
                        { value: '4', label: '4' }
                    ]}
                />)
            },
            {
                colSpan: 14, name: '', width: 600, label: '0 - Không tự động  , 1 - Theo ngày, 2 - Tháng , 3 - Năm , 4 - Khác', render: (<></>)
            },
            {
                colSpan: 10, name: 'soCT1', label: 'Từ số', width: 200,
                render: (
                    <InputNumber disabled={dataTab?.mode === "detail"} defaultValue={0} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập từ số' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 9, name: 'soCT2', label: 'Đến số', width: 100,
                render: (
                    <InputNumber disabled={dataTab?.mode === "detail"} defaultValue={0} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập đến số' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 10, name: 'soCT', label: 'Số ctừ hiển thời', width: 200,
                rules: [{ required: true, message: "Vui lòng nhập ctừ hiển thời" }, { validator: setValueChangeTransform(5) }] as Rule[],
                render: (
                    <InputNumber disabled={dataTab?.mode === "detail"} defaultValue={0} maxLength={6} formatter={Utils.numberFormatter} size={"small"} placeholder='Nhập ctừ hiển thời' style={{ width: "100%" }} />
                )
            },
            {
                colSpan: 11, name: '', label: '', render: (<></>)
            },
            {
                colSpan: 10, name: 'phanDau', label: 'Đầu ngữ', width: 200,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập đầu ngữ" },
                { validator: setValueChangeTransform(12) }] as Rule[],
                placeholder: "Nhập đầu ngữ", disabled: dataTab?.mode === "detail"
            },
            {
                colSpan: 7, name: 'phanCuoi', label: 'Vĩ ngữ', width: 100,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập vĩ ngữ" },
                { validator: setValueChangeTransform(12) }] as Rule[],
                placeholder: "Nhập vĩ ngữ", disabled: dataTab?.mode === "detail"
            },
            {
                colSpan: 7, name: 'dinhdang', label: 'Định dạng sô', width: 100,
                placeholder: "Nhập vĩ ngữ",
                render: (<>
                    <Input size="small" disabled={true} value={"000000"} />
                </>)
            },
            {
                colSpan: 10, name: 'transform', label: 'Mẫu', width: 200, disabled: true,
                placeholder: "Nhập mẫu"
            }
        ]
    }, [dataTab?.mode])

    const session2Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 10, name: 'maSo111', label: 'Mã tự động 111', width: 200,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập mã tự động 111" },
                { validator: validateTextCharater(16) }] as Rule[],
                placeholder: "Nhập mẫu", disabled: dataTab?.mode === "detail"
            },
            {
                colSpan: 14, name: '', label: 'Tự động tạo phiếu thu,phiếu chi tiền mặt', render: (<></>), width: 500
            },
            {
                colSpan: 10, name: 'maSo112', label: 'Mã tự động 112', width: 200,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập mã tự động 112" },
                { validator: validateTextCharater(16) }] as Rule[],
                placeholder: "Nhập mẫu", disabled: dataTab?.mode === "detail"
            },
            {
                colSpan: 14, name: '', label: 'Tự động báo nợ ,báo có ngân hàng', render: (<></>), width: 500
            }
        ]
    }, [dataTab?.mode])


    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={24}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, []);

    const baseDatePickerForm = useCallback((name: string, lable: string) => {
        const valueForm = form ? form.getFieldValue(name) : null;
        return (
            <Col span={10}>
                <Form.Item
                    label={lable}
                    name={name}
                    labelAlign='left'
                    labelCol={{ style: { width: 200 } }}
                >
                    <DatePicker
                        disabled={dataTab?.mode === "detail"}
                        size='small'
                        placeholder={valueForm ? moment(valueForm, "YYYY-MM-DD").toString() : `${lable}`}
                        defaultValue={valueForm ? moment(valueForm, "YYYY-MM-DD") : undefined}
                        style={{ width: "100%", borderRadius: "0px" }}
                    />

                </Form.Item>
            </Col>
        )
    }, [dataTab]);

    return (
        <>
            <VourcherModal
                visible={isModalOpen}
                dataCheckValue={form.getFieldValue("maCTNB")}
                onClose={() => setIsModalOpen(false)}
                onSubmit={(e: any) => { handleSubmitAlct(e) }}
            />
            {renderColumns(session1Cols)}
            <Row gutter={24}>
                {baseDatePickerForm("ngayNB1", "Nhập ngày bắt đầu")}
            </Row>
            {renderColumns(session2Cols)}
            <Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 200 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={dataTab?.mode === "detail"}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item>
        </>
    );
};

export default AlSonBMainInfomation;
