import { InputSearchApiService } from '@app/api/input-search-api.service';
import { Account0Dto } from '@app/api/v6.api';
import InputSearchLookup from '@app/components/common/input-search-lookup/InputSearchLookup';
import { InputSearchLookupColumns } from '@app/components/common/input-search-lookup/constants/input-lookup-entity-columns.constant';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { CatalogAddModalType } from '@app/components/shared/modals/catalog-add-modal/catalog-add-modal';
import { Checkbox, Col, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import Form, { Rule } from 'antd/lib/form';
import React, { useCallback, useMemo, } from 'react';

interface IAccountEdit {
    code?: string;
    form: FormInstance<any>;
    dataTab: ITab | undefined
}

interface ITab {
    dataEdit?: Account0Dto;
    mode: "add" | "edit" | "detail";
}

const AccountMainInfomation: React.FC<IAccountEdit> = ({ code, form, dataTab }: IAccountEdit) => {

    const validateTextCharater = (maxLen: number) => (_: any, value: string) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 13, name: 'account0Code', label: 'Số tài khoản', disabled: code ? true : false,
                placeholder: "Nhập mã tài khoản",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập mã tài khoản" },
                { validator: validateTextCharater(16) }] as Rule[]
            },
            {
                colSpan: 24, name: 'account0Name', label: 'Tên tài khoản',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập tên tài khoản" },
                { validator: validateTextCharater(128) }],
                disabled: dataTab?.mode === "detail", placeholder: "Nhập tên tài khoản"
            },
            {
                colSpan: 24, name: 'shortName', label: 'Tên ngắn',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(128) }],
                placeholder: "Nhập tên ngắn", disabled: dataTab?.mode === "detail"
            },
            {
                colSpan: 24, name: 'account0Name2', label: 'Tên 2',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(128) }],
                placeholder: "Nhập tên 2", disabled: dataTab?.mode === "detail"
            },
            {
                colSpan: 24, name: 'shortName2', label: 'Tên ngắn 2',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(128) }],
                placeholder: "Nhập tên ngắn 2", disabled: dataTab?.mode === "detail"
            },
            {
                colSpan: 13, name: 'foreignCurrencyCode', label: 'Mã ngoại tệ',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(3) }],
                placeholder: "Nhập mã ngoại tệ", disabled: dataTab?.mode === "detail",
                render: (<>
                    <InputSearchLookup
                        fetchDataFunc={InputSearchApiService.getCatalogDymamic("MA_NT")}
                        placeHolder={`Nhập mã ngoại tệ`}
                        keyField={'ma_nt'}
                        disabled={dataTab?.mode === "detail"}
                        columns={InputSearchLookupColumns.foreignCurrency}
                        lookupAdding={{
                            initValues: {
                            },
                            catalogAddModalType: CatalogAddModalType.ForeignCurencies
                        }}
                        onOutputChange={(value: string) => {
                            form.setFieldsValue({ ["foreignCurrencyCode"]: value });
                        }}
                        patchValue={{ text: dataTab?.dataEdit?.foreignCurrencyCode as string }}
                    />
                </>)
            },
            {
                colSpan: 13, name: 'account0Mother', label: 'Tài khoản mẹ',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(16) }],
                placeholder: "Nhập ài khoản mẹ", disabled: dataTab?.mode === "detail"
            },
            {
                colSpan: 13, name: 'account0ledger', label: 'TK theo dõi công nợ',
                placeholder: "Nhập TK theo dõi công nợ", disabled: dataTab?.mode === "detail",
                render: (< Select
                    disabled={dataTab?.mode === "detail"}
                    style={{ borderRadius: "0px" }}
                    allowClear
                    options={[{ value: '1', label: '1' }, { value: '0', label: '0' }]}
                />)
            },
            {
                colSpan: 11, name: '', label: '0 - Không theo dõi công nợ chi tiết , 1 - Theo dõi công nợ chi tiết', width: 500, render: (<></>)
            },
            {
                colSpan: 13, name: 'account0DebtTracking', label: 'Tài khoản sổ cái',
                placeholder: "Nhập tài khoản sổ cái", disabled: dataTab?.mode === "detail",
                render: (< Select
                    style={{ borderRadius: "0px" }}
                    disabled={dataTab?.mode === "detail"}
                    allowClear
                    options={[{ value: '1', label: '1' }, { value: '0', label: '0' }]}
                />)
            },
            {
                colSpan: 11, name: '', label: '0 - Tài khoản không phải là tài khoản sổ cái  , 1 - Tài khoản sổ cái ', width: 500, render: (<></>)
            },
            {
                colSpan: 13, name: '', label: '', render: (<></>)
            },
            {
                colSpan: 9, width: 100, name: 'account0Group01', label: 'Nhóm tk1',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(16) }],
                placeholder: "Nhập nhóm tk1", disabled: dataTab?.mode === "detail",
                render: (<>
                    <InputSearchLookup
                        fetchDataFunc={InputSearchApiService.getAccountGroups(1)}
                        placeHolder={`Nhập nhóm tài khoản 1`}
                        keyField={'groupCode'}
                        disabled={dataTab?.mode === "detail"}
                        columns={InputSearchLookupColumns.groupAccount}
                        lookupAdding={{
                            initValues: {
                                groupType: 1
                            },
                            catalogAddModalType: CatalogAddModalType.AccountGroup
                        }}
                        onOutputChange={(value: string) => {
                            form.setFieldsValue({ ["account0Group01"]: value });
                        }}
                        patchValue={{ text: dataTab?.dataEdit?.account0Group01 as string }}
                    />
                </>)
            },
            {
                colSpan: 13, name: '', label: '', render: (<></>)
            },
            {
                colSpan: 9, width: 100, name: 'account0Group02', label: 'Nhóm tk2',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(16) }],
                placeholder: "Nhập nhóm tk2", disabled: dataTab?.mode === "detail",
                render: (<>
                    <InputSearchLookup
                        disabled={dataTab?.mode === "detail"}
                        fetchDataFunc={InputSearchApiService.getAccountGroups(2)}
                        placeHolder={`Nhập nhóm tài khoản 2`}
                        keyField={'groupCode'}
                        columns={InputSearchLookupColumns.groupAccount}
                        lookupAdding={{
                            initValues: {
                                groupType: 2
                            },
                            catalogAddModalType: CatalogAddModalType.AccountGroup
                        }}
                        onOutputChange={(value: string) => {
                            form.setFieldsValue({ ["account0Group02"]: value });
                        }}
                        patchValue={{ text: dataTab?.dataEdit?.account0Group02 as string }}
                    />
                </>)
            },
            {
                colSpan: 13, name: 'group02', label: 'Nhóm tài khoản 2',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(8) }],
                placeholder: "Nhập nhóm tài khoản 2", disabled: dataTab?.mode === "detail",
            },
            {
                colSpan: 9, width: 100, name: 'account0Group03', label: 'Nhóm tk3',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(16) }],
                placeholder: "Nhập nhóm tk3", disabled: dataTab?.mode === "detail",
                render: (<>
                    <InputSearchLookup
                        fetchDataFunc={InputSearchApiService.getAccountGroups(3)}
                        placeHolder={`Nhập nhóm tk 3`}
                        disabled={dataTab?.mode === "detail"}
                        keyField={'groupCode'}
                        columns={InputSearchLookupColumns.groupAccount}
                        lookupAdding={{
                            initValues: {
                                groupType: 3
                            },
                            catalogAddModalType: CatalogAddModalType.AccountGroup
                        }}
                        onOutputChange={(value: string) => {
                            form.setFieldsValue({ ["account0Group03"]: value });
                        }}
                        patchValue={{ text: dataTab?.dataEdit?.account0Group02 as string }}
                    />
                </>)
            },
            {
                colSpan: 13, name: 'surplusGapType', label: 'PP tính TGGS có',
                rules: [{ validator: validateTextCharater(48) }],
                placeholder: "Nhập PP tính TGGS có", disabled: dataTab?.mode === "detail",
                render: (< Select
                    disabled={dataTab?.mode === "detail"}
                    style={{ borderRadius: "0px" }}
                    allowClear
                    options={[
                        { value: '4', label: '4' },
                        { value: '3', label: '3' },
                        { value: '2', label: '2' },
                        { value: '1', label: '1' },
                        { value: '0', label: '0' }
                    ]}
                />)
            },
            {
                colSpan: 11, name: '', label: '0 - Không, 1 - Theo PPTB , 2 - Đích danh , 3 - NTXT ,4 - TBDĐ',
                render: (<></>), width: 500
            },
            {
                colSpan: 13, name: 'debtGapType', label: 'PP tính TGGS nợ',
                rules: [{ validator: validateTextCharater(48) }],
                placeholder: "Nhập PP tính TGGS nợ", disabled: dataTab?.mode === "detail",
                render: (< Select
                    disabled={dataTab?.mode === "detail"}
                    style={{ borderRadius: "0px" }}
                    allowClear
                    options={[
                        { value: '4', label: '4' },
                        { value: '3', label: '3' },
                        { value: '2', label: '2' },
                        { value: '1', label: '1' },
                        { value: '0', label: '0' }
                    ]}
                />)
            },
            {
                colSpan: 11, name: '', label: '0 - Không, 1 - Theo PPTB , 2 - Đích danh , 3 - NTXT ,4 - TBDĐ',
                render: (<></>), width: 500
            },
        ]
    }, [dataTab?.mode])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={24}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, []);

    return (
        <>
            {renderColumns(session1Cols)}
            <Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 130 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={dataTab?.mode === "detail"}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item>
        </>
    );
};

export default AccountMainInfomation;
