import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import apiServices from '@app/api/api.services';
import { Pagination } from '@app/api/table.api';
import { WarehouseDto, CatalogSPParams } from '@app/api/v6.api';
import { PAGE_SIZE } from '@app/constants/page.constant';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'components/common/Table/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { WarehouseListViewFilter } from './WarehouseListViewFilter';
import { commonFunc } from '@app/shared/functions/common.function';
import { WarehouseModal } from '../modals/WarehouseModal';

export const WarehouseListView: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [chosenMode, setChosenMode] = useState<"add" | "edit" | "detail">("add");
    const [codeEdit, setCodeEdit] = useState<string | undefined>();
    const [warehouses, setWarehouses] = useState<WarehouseDto[]>([]);
    const [pagination, setPagination] = useState<Pagination>();
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<CatalogSPParams>({
        vvar: 'MA_KHO',
        pageNumber: 1,
        pageSize: PAGE_SIZE
    } as CatalogSPParams)

    const fetchData = useCallback(async () => {
        setLoading(true);
        const warehousesPaging = await apiServices.v6Api.catalogsGetCatalogDynamicPaging(filter);
        setWarehouses(warehousesPaging?.items || []);
        setPagination({
            current: warehousesPaging?.pageNumber,
            pageSize: filter.pageSize,
            total: warehousesPaging.totalCount
        })
        setLoading(false);
    }, [filter])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleEdit = async (code: any) => {
        setCodeEdit(code ? code : undefined)
        setChosenMode("edit");
        setIsModalOpen(true);
    };

    const handleDetail = async (code: any) => {
        setCodeEdit(code ? code : undefined)
        setChosenMode("detail");
        setIsModalOpen(true);
    };

    const handleCloseModal = async (reload?: boolean) => {
        setCodeEdit("");
        setIsModalOpen(false);
        if (reload) {
            const updatedFilter = {
                vvar: 'MA_KHO',
                pageNumber: 1,
                pageSize: PAGE_SIZE
            } as CatalogSPParams;
            setFilter(updatedFilter);
        }
    };

    const columns = useMemo(() => {
        const columns: ColumnsType<WarehouseDto> = [
            {
                title: 'Mã kho',
                dataIndex: 'ma_kho',
                width: 60
            },
            {
                title: 'Tên kho',
                dataIndex: 'ten_kho',
                width: 130
            },
            {
                title: 'Tên 2',
                dataIndex: 'ten_kho2',
                width: 130
            },
            {
                title: 'TK đại lý',
                width: 50,
                dataIndex: 'tk_dl'
            },
            {
                title: 'STT NTXT',
                width: 50,
                dataIndex: 'stt_ntxt'
            },
            {
                title: 'Mã ĐVCS',
                width: 50,
                dataIndex: 'ma_dvcs'
            },
            {
                title: 'Trạng thái',
                dataIndex: "status",
                width: 80,
                render: (status: any) => commonFunc.statusRender(status as string),
            },
            {
                title: 'Hành động',
                fixed: 'right',
                align: 'center',
                dataIndex: "ma_kho",
                width: 60,
                render: (maKho: string) => {
                    return (
                        <>
                            <Button type="primary"
                                shape="circle"
                                icon={<EditOutlined />}
                                title='Chỉnh sửa'
                                style={{ marginRight: 3 }}
                                onClick={() => {
                                    handleEdit(maKho)
                                }}>
                            </Button>
                            <Button type="primary"
                                shape="circle"
                                icon={<InfoCircleOutlined />}
                                title='Chi tiết'
                                style={{ marginRight: 3 }}
                                onClick={() => {
                                    handleDetail(maKho)
                                }}>
                            </Button>

                        </>
                    );
                },
            },
        ];

        return columns;
    }, [])

    function onPagingChange(pageNum: number, pageSize: number) {
        const params: CatalogSPParams = {
            ...filter,
            pageNumber: pageNum,
            pageSize: pageSize,
        } as CatalogSPParams;
        setFilter(params);
    }

    return (
        <div className='app-table-list'>
            <div className='app-table-list-header'>
                <h1 className='app-table-list-header-title'>
                    Danh sách Kho
                </h1>

                <div className='app-table-list-header-actions'>
                    <Button type="primary" onClick={() => { setIsModalOpen(true); setCodeEdit(undefined) }}>
                        Thêm mới
                    </Button>
                </div>
            </div>
            <WarehouseListViewFilter
                filter={filter}
                onFilter={(data: CatalogSPParams) => {
                    setFilter({
                        ...filter,
                        vvalue: data.vvalue as string,
                        pageNumber: 1
                    } as CatalogSPParams);
                }} />
            <Table
                columns={columns}
                dataSource={warehouses}
                pagination={{
                    ...pagination,
                    onChange: onPagingChange
                }}
                loading={loading}
                bordered
            />
            <WarehouseModal
                visible={isModalOpen}
                mode={chosenMode}
                code={codeEdit}
                onClose={(e) => handleCloseModal(e)}
            />
        </div>
    )
}