import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseDatePicker } from '@app/components/common/pickers/BaseDatePicker';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import styled from 'styled-components';

export const Button = styled(BaseButton)`
    font-size: 0.8rem;
`;

export const Input = styled(BaseInput)`
    font-size: 0.8rem;
`;

export const DatePicker = styled(BaseDatePicker)`
    .ant-picker{
        font-size: 0.8rem;
        height: 1.6rem;
    }

    .ant-picker-input>input{
        font-size: 0.8rem;
    }
`;
