import { DepartmentDto } from '@app/api/v6.api';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Checkbox, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import Form, { Rule } from 'antd/lib/form';
import React, { useCallback, useMemo } from 'react';

interface IDepartmentEdit {
    code?: string;
    form: FormInstance<any>;
    dataTab: ITab | undefined
}

interface ITab {
    dataEdit?: DepartmentDto;
    mode: "add" | "edit" | "detail";
}

const DepartmentMainInfomation: React.FC<IDepartmentEdit> = ({ dataTab }: IDepartmentEdit) => {

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 24, name: 'ghiChu', label: 'Ghi chú', disabled: dataTab?.mode === "detail",
                placeholder: "Nhập ghi chú"
            }
        ]
    }, [dataTab?.mode])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={24}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, []);

    return (
        <>
            {renderColumns(session1Cols)}

            <Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 130 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={dataTab?.mode === "detail"}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item>
        </>
    );
};

export default DepartmentMainInfomation;
