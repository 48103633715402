import { MenuDto } from "@app/api/v6.api";
import { PageConstant } from "@app/constants/page.constant";
import { MenuProps } from "antd";
import { Link } from "react-router-dom";
import removeAccents from 'remove-accents';

export type AntMenuItem = Required<MenuProps>['items'][number];

const MENU_KEYS_PAGE_URL: { menuKey: string; pageUrl: string }[] = [
    { menuKey: "A001-B003-C0103006", pageUrl: PageConstant.BASE_UNITS }, // Đơn vị cơ sở
    { menuKey: "A001-B004-C0104013", pageUrl: PageConstant.BASE_UNITS }, // Đơn vị cơ sở

    // { menuKey: "A001-B003-DEN00375", pageUrl: PageConstant. }, // Nhóm đơn vị cơ sở

    { menuKey: "A003-B003-C0303048", pageUrl: PageConstant.CUSTOMERS }, // Danh mục khách hàng
    { menuKey: "A003-B004-C0304052", pageUrl: PageConstant.CUSTOMERS }, // Danh mục khách hàng

    { menuKey: "A003-B003-C0303049", pageUrl: PageConstant.CUSTOMER_GROUPS }, // Danh mục phân nhóm khách hàng
    { menuKey: "A003-B004-C0304053", pageUrl: PageConstant.CUSTOMER_GROUPS }, // Danh mục phân nhóm khách hàng

    { menuKey: "A008-B003-C0803055", pageUrl: PageConstant.ACCOUNT_GROUPS }, // Danh mục phân nhóm tài khoản
    { menuKey: "A008-B003-C0803053", pageUrl: PageConstant.ACCOUNTS }, // Danh mục phân nhóm tài khoản

    { menuKey: "A006-B003-C0602013", pageUrl: PageConstant.AFFAIR_GROUPS }, // Danh mục phân nhóm vụ việc

    { menuKey: "A005-B003-DE000159", pageUrl: PageConstant.MATERIAL_TYPES }, // Danh mục loại vật tư
    { menuKey: "A005-B003-C0503046", pageUrl: PageConstant.MATERIALS }, // Danh mục vật tư

    { menuKey: "A007-B003-C0703023", pageUrl: PageConstant.ASSET_GROUPS }, // Danh mục phân nhóm tài sản
    { menuKey: "A007-B003-DE000002", pageUrl: PageConstant.FIXED_ASSET_TYPES }, // Danh mục phân loại tài sản

    { menuKey: "A001-B003-C0103004", pageUrl: PageConstant.FOREIGN_CURRENCIES }, // Danh mục ngoại tệ

    { menuKey: "A005-B003-DE000158", pageUrl: PageConstant.MEASUREMENT_UNITS }, // Danh mục đơn vị tính
    { menuKey: "A005-B003-DE000179", pageUrl: PageConstant.COUNTRIES }, // Danh mục quốc gia
    { menuKey: "A006-B003-C0603043", pageUrl: PageConstant.DICTIONARIES }, // Danh mục từ điển
    { menuKey: "A005-B003-C0503048", pageUrl: PageConstant.WAREHOUSES }, // Danh mục kho
    { menuKey: "A001-B001-C0101030", pageUrl: PageConstant.BATCH_NO }, // Danh mục số nội bộ
    { menuKey: "A003-B003-C0303051", pageUrl: PageConstant.DEPARTMENTS }, // Danh mục bộ phận
    { menuKey: "A003-B003-C0303050", pageUrl: PageConstant.TAXES }, // Danh mục thuế
    { menuKey: "A004-B003-DE000256", pageUrl: PageConstant.THUE30S }, // Danh mục thuế 30
    { menuKey: "A002-B003-DE000177", pageUrl: PageConstant.BANK_CONTRACT }, // Danh mục khế ước
    { menuKey: "A002-B003-DE000178", pageUrl: PageConstant.BANK_CONTRACT_GROUPS }, // Danh mục nhóm khế ước
    { menuKey: "A002-B003-C0203025", pageUrl: PageConstant.BANK_ACCOUNTS }, // Danh mục tài khoản ngân hàng
    { menuKey: "A005-B003-DE000163", pageUrl: PageConstant.UNIT_CONVERSIONS }, // Danh mục quy đổi đơn vị tính
    { menuKey: "A006-B003-DE000173", pageUrl: PageConstant.FEES }, // Danh mục khoản phí
    { menuKey: "A003-B003-DE000167", pageUrl: PageConstant.MATERIAL_GROUP_PRICES }, // Danh mục nhóm giá vật tư
    { menuKey: "A003-B003-DE000166", pageUrl: PageConstant.CUSTOMER_GROUP_PRICES }, // Danh mục nhóm giá khách hàng
    { menuKey: "A003-B003-DE000169", pageUrl: PageConstant.CATALOG_DISCOUNT_TYPES }, // Danh mục loại chiết khấu
    { menuKey: "A003-B003-DE000162", pageUrl: PageConstant.EMPLOYEES }, // Danh mục nhân viên
    { menuKey: "A003-B001-D0000063", pageUrl: PageConstant.AM_91 }, // Bán hàng => Cật nhật số liệu => Đơn đặt hàng
    { menuKey: "A003-B003-DE000168", pageUrl: PageConstant.CATALOG_DISCOUNTS }, // danh mục chiết khấu
    { menuKey: "A003-B003-DE000171", pageUrl: PageConstant.PAYMENT_METHODS }, // danh mục hình thức thanh toán
    { menuKey: "A003-B003-DE000257", pageUrl: PageConstant.CATALOG_PRICE_IDS }, // danh mục mã giá

];

function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: AntMenuItem[],
): AntMenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as AntMenuItem;
}

const buildAntMenuItems = (menuDtos: MenuDto[]): AntMenuItem[] => {
    if (!menuDtos?.length) {
        return [];
    }

    return menuDtos.map((menuDto, index) => {
        const pageUrl = MENU_KEYS_PAGE_URL.find(x => x.menuKey === menuDto.menuKey)?.pageUrl;

        const subMenu = (menuDto.subMenu?.length ?? 0) > 0
            ? buildAntMenuItems(menuDto.subMenu!)
            : undefined;

        const label = pageUrl
            ? <Link to={pageUrl}>{menuDto.vnTitle}</Link>
            : menuDto.vnTitle;
        return getItem(label,
            `${menuDto.menuKey}-${index}`,
            null,
            subMenu);
    });
}

const filterMenus = (menus: MenuDto[], searchName: string): MenuDto[] => {
    return menus.reduce((result, currentItem) => {
        const lowerCaseVnTitle = currentItem.vnTitle?.toLocaleLowerCase();

        if (lowerCaseVnTitle === searchName.toLowerCase() || removeAccents(lowerCaseVnTitle ?? "") === searchName.toLocaleLowerCase()) {
            result.push(currentItem);
            return result;
        }

        if (currentItem.subMenu && currentItem.subMenu.some(x => x.subMenu)) {
            const filteredSubMenu = filterMenus(currentItem.subMenu || [], searchName);

            if (filteredSubMenu.length > 0 || (lowerCaseVnTitle === searchName.toLowerCase() || removeAccents(lowerCaseVnTitle ?? "") === searchName.toLocaleLowerCase())) {
                result.push({
                    ...currentItem,
                    subMenu: filteredSubMenu,
                } as MenuDto);
            }

        }
        else {
            if (currentItem.subMenu) {
                const listSubMenu: MenuDto[] = [];

                currentItem.subMenu.forEach(item => {
                    const subMenuLowerCase = item.vnTitle?.toLocaleLowerCase();
                    if (subMenuLowerCase && (subMenuLowerCase.includes(searchName.toLowerCase()) || removeAccents(subMenuLowerCase ?? "").includes(searchName.toLocaleLowerCase()))) {
                        listSubMenu.push(item);
                    }
                });

                if (listSubMenu.length > 0) {
                    result.push({
                        ...currentItem,
                        subMenu: listSubMenu,
                    } as MenuDto);
                }
            }
        }

        return result;
    }, [] as MenuDto[]);
};

export const menuFunctions = {
    buildAntMenuItems,
    filterMenus
}