import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { AssetGroupListView } from '../views/AssetGroupListView';

const AssetGroupListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách nhóm tài sản</PageTitle>
            <AssetGroupListView />
        </>
    );
};

export default AssetGroupListPage;
