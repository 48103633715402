import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import LockPage from '@app/pages/LockPage';
import LoginPage from '@app/pages/LoginPage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import SignUpPage from '@app/pages/SignUpPage';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { PageConstant } from '@app/constants/page.constant';
import { withLoading } from '@app/hocs/withLoading.hoc';
import MedicalDashboardPage from '@app/pages/DashboardPages/MedicalDashboardPage';
import NftDashboardPage from '@app/pages/DashboardPages/NftDashboardPage';

import MaterialAddOrUpdatePage from '@app/pages/materials/pages/MaterialAddOrUpdatePage';
import MaterialListPage from '@app/pages/materials/pages/MaterialListPage';
import CustomerAddOrUpdatePage from '@app/pages/customers/pages/CustomerAddOrUpdatePage';
import CustomerListPage from '@app/pages/customers/pages/CustomerListPage';
import UserListPage from '@app/pages/users/pages/UserListPage';
import MaterialTypeAddOrUpdatePage from '@app/pages/material-types/pages/MaterialTypeAddOrUpdate';
import MaterialTypeListPage from '@app/pages/material-types/pages/MaterialTypeListPage';
import MaterialGroupListPage from '@app/pages/material-groups/pages/MaterialGroupListPage';
import CustomerGroupListPage from '@app/pages/customer-groups/pages/CustomerGroupListPage';
import AccountGroupListPage from '@app/pages/account-groups/pages/AccountGroupListPage';
import AffairGroupListPage from '@app/pages/affair-groups/pages/AffairGroupListPage';
import TaxListPage from '@app/pages/tax/pages/TaxListPage';
import AssetGroupListPage from '@app/pages/asset-groups/pages/AssetGroupListPage';
import ForeignCurrencyListPage from '@app/pages/foreign-curencies/pages/ForeignCurrencyListPage';
import MeasurementUnitListPage from '@app/pages/measurement-units/pages/MeasurementUnitListPage';
import CountryListPage from '@app/pages/countries/pages/CountryListPage';
import FixedAssetTypeListPage from '@app/pages/fixed-asset-type/pages/FixedAssetTypeListPage';
import DictionaryListPage from '@app/pages/dictionary/pages/DictionaryListPage';
import AccountListPage from '@app/pages/accounts/pages/AccountListPage';
import WarehouseListPage from '@app/pages/Warehouses/pages/WarehouseListPage';
import AlSonBListPage from '@app/pages/alsonbs/pages/AlSonBListPage';
import BaseUnitListPage from '@app/pages/base-units/pages/BaseUnitListPage';
import BankContractGroupListPage from '@app/pages/bank-contract-groups/pages/BankContractGroupListPage';
import BankContractListPage from '@app/pages/bank-contracts/pages/BankContractListPage';
import Thue30ListPage from '@app/pages/tax30s/pages/Tax30ListPage';
import DepartmentListPage from '@app/pages/departments/pages/DepartmentListPage';
import BankAccountListPage from '@app/pages/bank-accounts/pages/BankAccountListPage';
import BlockingPathTracingListPage from '@app/pages/blocking-path-tracing/pages/BlockingPathTracingListPage';
import UnitConversionListPage from '@app/pages/material-unit-conversions/pages/MaterialUnitConversionListPage';
import CustomerGroupPriceListPage from '@app/pages/customer-group-price/pages/CustomerGroupPriceListPage';
import FeeListPage from '@app/pages/fees/pages/FeeListPage';
import MaterialGroupPriceListPage from '@app/pages/material-group-prices/pages/MaterialGroupPriceListPage';
import AM91ListPage from '@app/pages/Invoices/invoice-91/pages/am91-list-page';
import CatalogDiscountTypeListPage from '@app/pages/catalog-discount-type/pages/CatalogDiscountTypeListPage';
import CatalogDiscountListPage from '@app/pages/catalog-discount/pages/CatalogDiscountListPage';
import EmployeeListPage from '@app/pages/employees/pages/EmployeeListPage';
import PaymentMethodListPage from '@app/pages/payment-methods/pages/PaymentMethodListPage';
import ARS82ListPage from '@app/pages/ars82s/pages/ARS82ListPage';
import PriceCodeListPage from '@app/pages/catalog-price-ids/pages/CatalogPriceIdListPage';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));

const NewsFeedPage = React.lazy(() => import('@app/pages/NewsFeedPage'));
/*const KanbanPage = React.lazy(() => import('@app/pages/KanbanPage'));
const DataTablesPage = React.lazy(() => import('@app/pages/DataTablesPage'));*/
const ChartsPage = React.lazy(() => import('@app/pages/ChartsPage'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const AdvancedFormsPage = React.lazy(() => import('@app/pages/AdvancedFormsPage'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
const NotificationsPage = React.lazy(() => import('@app/pages/NotificationsPage'));
const PaymentsPage = React.lazy(() => import('@app/pages/PaymentsPage'));
const ButtonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/ButtonsPage'));
const SpinnersPage = React.lazy(() => import('@app/pages/uiComponentsPages/SpinnersPage'));
const AvatarsPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/AvatarsPage'));
const BadgesPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/BadgesPage'));
const CollapsePage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/CollapsePage'));
const PaginationPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/PaginationPage'));
const ModalsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/ModalsPage'));
const PopoversPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopoversPage'));
const PopconfirmsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopconfirmsPage'));
const ProgressPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ProgressPage'));
const ResultsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ResultsPage'));
const AlertsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/AlertsPage'));
const SkeletonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/SkeletonsPage'));
const InputsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/InputsPage'));
const CheckboxesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/CheckboxesPage'));
const RadiosPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RadiosPage'));
const SelectsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SelectsPage'));
const SwitchesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SwitchesPage'));
const UploadsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/UploadsPage'));
const RatesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RatesPage'));
const AutoCompletesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/AutoCompletesPage'));
const StepsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/StepsPage'));
const DateTimePickersPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/DateTimePickersPage'));
const DropdownsPage = React.lazy(() => import('@app/pages/uiComponentsPages/DropdownsPage'));
const BreadcrumbsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/BreadcrumbsPage'));
const TabsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/TabsPage'));
const NotificationsUIPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/NotificationsPage'));
const GoogleMaps = React.lazy(() => import('@app/pages/maps/GoogleMapsPage/GoogleMapsPage'));
const LeafletMaps = React.lazy(() => import('@app/pages/maps/LeafletMapsPage/LeafletMapsPage'));
const ReactSimpleMaps = React.lazy(() => import('@app/pages/maps/ReactSimpleMapsPage/ReactSimpleMapsPage'));
const PigeonsMaps = React.lazy(() => import('@app/pages/maps/PigeonsMapsPage/PigeonsMapsPage'));
const Logout = React.lazy(() => import('./Logout'));

export const NFT_DASHBOARD_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';

const MedicalDashboard = withLoading(MedicalDashboardPage);
const NftDashboard = withLoading(NftDashboardPage);
const NewsFeed = withLoading(NewsFeedPage);
/*const Kanban = withLoading(KanbanPage);*/
const AdvancedForm = withLoading(AdvancedFormsPage);

// UI Components
const Buttons = withLoading(ButtonsPage);
const Spinners = withLoading(SpinnersPage);
const Inputs = withLoading(InputsPage);
const Checkboxes = withLoading(CheckboxesPage);
const Radios = withLoading(RadiosPage);
const Selects = withLoading(SelectsPage);
const Switches = withLoading(SwitchesPage);
const Uploads = withLoading(UploadsPage);
const Rates = withLoading(RatesPage);
const AutoCompletes = withLoading(AutoCompletesPage);
const Steps = withLoading(StepsPage);
const DateTimePickers = withLoading(DateTimePickersPage);
const Dropdowns = withLoading(DropdownsPage);
const Breadcrumbs = withLoading(BreadcrumbsPage);
const Tabs = withLoading(TabsPage);
const Avatars = withLoading(AvatarsPage);
const Badges = withLoading(BadgesPage);
const Collapse = withLoading(CollapsePage);
const Pagination = withLoading(PaginationPage);
const Modals = withLoading(ModalsPage);
const Popovers = withLoading(PopoversPage);
const Popconfirms = withLoading(PopconfirmsPage);
const Progress = withLoading(ProgressPage);
const Results = withLoading(ResultsPage);
const Alerts = withLoading(AlertsPage);
const NotificationsUI = withLoading(NotificationsUIPage);
const Skeletons = withLoading(SkeletonsPage);

/*const DataTables = withLoading(DataTablesPage);*/
const Charts = withLoading(ChartsPage);

// Maps
const Google = withLoading(GoogleMaps);
const Leaflet = withLoading(LeafletMaps);
const ReactSimple = withLoading(ReactSimpleMaps);
const Pigeons = withLoading(PigeonsMaps);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const Notifications = withLoading(NotificationsPage);
const Payments = withLoading(PaymentsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={NFT_DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={<NftDashboard />} />
          <Route path={MEDICAL_DASHBOARD_PATH} element={<MedicalDashboard />} />
          <Route path="apps">
            <Route path="feed" element={<NewsFeed />} />
            {/*<Route path="kanban" element={<Kanban />} />*/}
          </Route>
          <Route path="forms">
            <Route path="advanced-forms" element={<AdvancedForm />} />
          </Route>
          {/*<Route path="data-tables" element={<DataTables />} />*/}

          <Route path="charts" element={<Charts />} />
          <Route path="maps">
            <Route path="google-maps" element={<Google />} />
            <Route path="leaflet-maps" element={<Leaflet />} />
            <Route path="react-simple-maps" element={<ReactSimple />} />
            <Route path="pigeon-maps" element={<Pigeons />} />
          </Route>
          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="security-settings" element={<SecuritySettings />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="payments" element={<Payments />} />
          </Route>
          <Route path="ui-components">
            <Route path="button" element={<Buttons />} />
            <Route path="spinner" element={<Spinners />} />
            <Route path="input" element={<Inputs />} />
            <Route path="checkbox" element={<Checkboxes />} />
            <Route path="radio" element={<Radios />} />
            <Route path="select" element={<Selects />} />
            <Route path="switch" element={<Switches />} />
            <Route path="upload" element={<Uploads />} />
            <Route path="rate" element={<Rates />} />
            <Route path="auto-complete" element={<AutoCompletes />} />
            <Route path="steps" element={<Steps />} />
            <Route path="date-time-picker" element={<DateTimePickers />} />
            <Route path="dropdown" element={<Dropdowns />} />
            <Route path="breadcrumbs" element={<Breadcrumbs />} />
            <Route path="tabs" element={<Tabs />} />
            <Route path="avatar" element={<Avatars />} />
            <Route path="badge" element={<Badges />} />
            <Route path="collapse" element={<Collapse />} />
            <Route path="pagination" element={<Pagination />} />
            <Route path="modal" element={<Modals />} />
            <Route path="popover" element={<Popovers />} />
            <Route path="popconfirm" element={<Popconfirms />} />
            <Route path="progress" element={<Progress />} />
            <Route path="result" element={<Results />} />
            <Route path="alert" element={<Alerts />} />
            <Route path="notification" element={<NotificationsUI />} />
            <Route path="skeleton" element={<Skeletons />} />
          </Route>
        </Route>

        <Route path={PageConstant.BASE_UNITS} element={protectedLayout}>
          <Route path="" element={<BaseUnitListPage />} />
          <Route path="list" element={<BaseUnitListPage />} />
        </Route>

        <Route path={PageConstant.USERS} element={protectedLayout}>
          <Route path="" element={<UserListPage />} />
          <Route path="list" element={<UserListPage />} />
        </Route>

        <Route path={PageConstant.ACCOUNT_GROUPS} element={protectedLayout}>
          <Route path="" element={<AccountGroupListPage />} />
          <Route path="list" element={<AccountGroupListPage />} />
        </Route>

        <Route path={PageConstant.ACCOUNTS} element={protectedLayout}>
          <Route path="" element={<AccountListPage />} />
          <Route path="list" element={<AccountListPage />} />
        </Route>

        <Route path={PageConstant.AFFAIR_GROUPS} element={protectedLayout}>
          <Route path="" element={<AffairGroupListPage />} />
          <Route path="list" element={<AffairGroupListPage />} />
        </Route>

        <Route path={PageConstant.CUSTOMERS} element={protectedLayout}>
          <Route path="" element={<CustomerListPage />} />
          <Route path="list" element={<CustomerListPage />} />
          <Route path="add" element={<CustomerAddOrUpdatePage />} />
          <Route path=":code/edit" element={<CustomerAddOrUpdatePage />} />
        </Route>

        <Route path={PageConstant.CUSTOMER_GROUPS} element={protectedLayout}>
          <Route path="" element={<CustomerGroupListPage />} />
          <Route path="list" element={<CustomerGroupListPage />} />
        </Route>

        <Route path={PageConstant.ARS82S} element={protectedLayout}>
          <Route path="" element={<ARS82ListPage />} />
          <Route path="list" element={<ARS82ListPage />} />
        </Route>

        <Route path={PageConstant.MATERIALS} element={protectedLayout}>
          <Route path="" element={<MaterialListPage />} />
          <Route path="list" element={<MaterialListPage />} />
          <Route path="add" element={<MaterialAddOrUpdatePage />} />
          <Route path=":code/edit" element={<MaterialAddOrUpdatePage />} />
        </Route>

        <Route path={PageConstant.MATERIAL_TYPES} element={protectedLayout}>
          <Route path="" element={<MaterialTypeListPage />} />
          <Route path="list" element={<MaterialTypeListPage />} />
          <Route path="add" element={<MaterialTypeAddOrUpdatePage />} />
          <Route path=":code/edit" element={<MaterialTypeAddOrUpdatePage />} />
        </Route>

        <Route path={PageConstant.MATERIAL_GROUPS} element={protectedLayout}>
          <Route path="" element={<MaterialGroupListPage />} />
          <Route path="list" element={<MaterialGroupListPage />} />
        </Route>

        <Route path={PageConstant.PAYMENT_METHODS} element={protectedLayout}>
          <Route path="" element={<PaymentMethodListPage />} />
          <Route path="list" element={<PaymentMethodListPage />} />
        </Route>

        <Route path={PageConstant.TAXES} element={protectedLayout}>
          <Route path="" element={<TaxListPage />} />
          <Route path="list" element={<TaxListPage />} />
        </Route>

        <Route path={PageConstant.THUE30S} element={protectedLayout}>
          <Route path="" element={<Thue30ListPage />} />
          <Route path="list" element={<Thue30ListPage />} />
        </Route>

        <Route path={PageConstant.ASSET_GROUPS} element={protectedLayout}>
          <Route path="" element={<AssetGroupListPage />} />
          <Route path="list" element={<AssetGroupListPage />} />
        </Route>

        <Route path={PageConstant.BANK_ACCOUNTS} element={protectedLayout}>
          <Route path="" element={<BankAccountListPage />} />
          <Route path="list" element={<BankAccountListPage />} />
        </Route>

        <Route path={PageConstant.AM_91} element={protectedLayout}>
          <Route path="" element={<AM91ListPage />} />
          <Route path="list" element={<AM91ListPage />} />
        </Route>

        <Route path={PageConstant.MEASUREMENT_UNITS} element={protectedLayout}>
          <Route path="" element={<MeasurementUnitListPage />} />
          <Route path="list" element={<MeasurementUnitListPage />} />
        </Route>

        <Route path={PageConstant.FIXED_ASSET_TYPES} element={protectedLayout}>
          <Route path="" element={<FixedAssetTypeListPage />} />
          <Route path="list" element={<FixedAssetTypeListPage />} />
        </Route>

        <Route path={PageConstant.FOREIGN_CURRENCIES} element={protectedLayout}>
          <Route path="" element={<ForeignCurrencyListPage />} />
          <Route path="list" element={<ForeignCurrencyListPage />} />
        </Route>

        <Route path={PageConstant.COUNTRIES} element={protectedLayout}>
          <Route path="" element={<CountryListPage />} />
          <Route path="list" element={<CountryListPage />} />
        </Route>

        <Route path={PageConstant.FEES} element={protectedLayout}>
          <Route path="" element={<FeeListPage />} />
          <Route path="list" element={<FeeListPage />} />
        </Route>

        <Route path={PageConstant.CATALOG_DISCOUNTS} element={protectedLayout}>
          <Route path="" element={<CatalogDiscountListPage />} />
          <Route path="list" element={<CatalogDiscountListPage />} />
        </Route>

        <Route path={PageConstant.DICTIONARIES} element={protectedLayout}>
          <Route path="" element={<DictionaryListPage />} />
          <Route path="list" element={<DictionaryListPage />} />
        </Route>

        <Route path={PageConstant.BATCH_NO} element={protectedLayout}>
          <Route path="" element={<AlSonBListPage />} />
          <Route path="list" element={<AlSonBListPage />} />
        </Route>

        <Route path={PageConstant.BANK_CONTRACT_GROUPS} element={protectedLayout}>
          <Route path="" element={<BankContractGroupListPage />} />
          <Route path="list" element={<BankContractGroupListPage />} />
        </Route>

        <Route path={PageConstant.UNIT_CONVERSIONS} element={protectedLayout}>
          <Route path="" element={<UnitConversionListPage />} />
          <Route path="list" element={<UnitConversionListPage />} />
        </Route>

        <Route path={PageConstant.EMPLOYEES} element={protectedLayout}>
          <Route path="" element={<EmployeeListPage />} />
          <Route path="list" element={<EmployeeListPage />} />
        </Route>

        <Route path={PageConstant.WAREHOUSES} element={protectedLayout}>
          <Route path="" element={<WarehouseListPage />} />
          <Route path="list" element={<WarehouseListPage />} />
        </Route>

        <Route path={PageConstant.CATALOG_DISCOUNT_TYPES} element={protectedLayout}>
          <Route path="" element={<CatalogDiscountTypeListPage />} />
          <Route path="list" element={<CatalogDiscountTypeListPage />} />
        </Route>

        <Route path={PageConstant.BANK_CONTRACT} element={protectedLayout}>
          <Route path="" element={<BankContractListPage />} />
          <Route path="list" element={<BankContractListPage />} />
        </Route>

        <Route path={PageConstant.CATALOG_PRICE_IDS} element={protectedLayout}>
          <Route path="" element={<PriceCodeListPage />} />
          <Route path="list" element={<PriceCodeListPage />} />
        </Route>

        <Route path={PageConstant.DEPARTMENTS} element={protectedLayout}>
          <Route path="" element={<DepartmentListPage />} />
          <Route path="list" element={<DepartmentListPage />} />
        </Route>

        <Route path={PageConstant.MATERIAL_GROUP_PRICES} element={protectedLayout}>
          <Route path="" element={<MaterialGroupPriceListPage />} />
          <Route path="list" element={<MaterialGroupPriceListPage />} />
        </Route>

        <Route path={PageConstant.CUSTOMER_GROUP_PRICES} element={protectedLayout}>
          <Route path="" element={<CustomerGroupPriceListPage />} />
          <Route path="list" element={<CustomerGroupPriceListPage />} />
        </Route>

        <Route path={PageConstant.BLOCKING_PATH_TRACING} element={protectedLayout}>
          <Route path="" element={<BlockingPathTracingListPage />} />
          <Route path="list" element={<BlockingPathTracingListPage />} />
        </Route>

        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
