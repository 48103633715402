import { IInputSearchLookupColumn } from "../models/input-search-lookup-column"

const inputLookupCustomerColumns: IInputSearchLookupColumn[] = [
    { title: 'Mã khách hàng', width: 200, dataIndex: 'customerCode' },
    { title: 'Tên khách hàng', width: 200, dataIndex: 'customerName', hover: true }
]

const inputLookupCustomerGroupColumns: IInputSearchLookupColumn[] = [
    { title: 'Mã nhóm khách hàng', width: 200, dataIndex: 'groupCode' },
    { title: 'Tên nhóm khách hàng', width: 200, dataIndex: 'groupName', hover: true }
]

const inputLookupBaseUnitColumns: IInputSearchLookupColumn[] = [
    { title: 'Mã đvcs', width: 200, dataIndex: 'ma_dvcs' },
    { title: 'Tên đvcs', width: 200, dataIndex: 'ten_dvcs', hover: true }
]

const inputLookupMaterialTypeColumns: IInputSearchLookupColumn[] = [
    { title: 'Mã loại vật tư', width: 200, dataIndex: 'loai_vt' },
    { title: 'Tên loại vật tư', width: 200, dataIndex: 'ten_loai', hover: true }
]

const inputLookupMaterialGroupColumns: IInputSearchLookupColumn[] = [
    { title: 'Mã nhóm vật tư', width: 200, dataIndex: 'groupCode' },
    { title: 'Tên nhóm vật tư', width: 200, dataIndex: 'groupName', hover: true }
]

const inputLookupMaterialColumns: IInputSearchLookupColumn[] = [
    { title: 'Mã vật tư', width: 200, dataIndex: 'ma_vt' },
    { title: 'Tên vật tư', width: 200, dataIndex: 'ten_vt', hover: true }
]

const inputLookupWarehouseColumns: IInputSearchLookupColumn[] = [
    { title: 'Mã kho', width: 200, dataIndex: 'maKho' },
    { title: 'Tên kho', width: 200, dataIndex: 'tenKho', hover: true }
]

const inputLookupAccountGroupColumns: IInputSearchLookupColumn[] = [
    { title: 'Mã nhóm tài khoản', width: 200, dataIndex: 'groupCode' },
    { title: 'Tên nhóm tài khoản', width: 200, dataIndex: 'groupName', hover: true }
]

const inputLookupforeignCurrencyColumns: IInputSearchLookupColumn[] = [
    { title: 'Mã ngoại tệ', width: 200, dataIndex: 'ma_nt' },
    { title: 'Tên ngoại tệ', width: 200, dataIndex: 'ten_nt', hover: true }
]

const inputLookupTaxColumns: IInputSearchLookupColumn[] = [
    { title: 'Mã thuế', width: 200, dataIndex: 'ma_thue' },
    { title: 'Tên thuế', width: 200, dataIndex: 'ten_thue', hover: true }
]

const inputLookupAccountAgencyColumns: IInputSearchLookupColumn[] = [
    { title: 'Mã đại lý', width: 200, dataIndex: 'tk' },
    { title: 'Tên đại lý', width: 200, dataIndex: 'ten_tk', hover: true }
]

const inputLookupMeasurementUnitColumns: IInputSearchLookupColumn[] = [
    { title: 'Mã đơn vị tính', width: 200, dataIndex: 'dvt' },
    { title: 'Tên đơn vị tính', width: 200, dataIndex: 'ten_dvt', hover: true }
]

const inputLookupAccountContractGroupColumns: IInputSearchLookupColumn[] = [
    { title: 'Mã nhóm khế ước', width: 200, dataIndex: 'groupCode' },
    { title: 'Tên nhóm khế ước', width: 200, dataIndex: 'groupName', hover: true }
]

const inputLookupBatchNoColumns: IInputSearchLookupColumn[] = [
    { title: 'Mã nội bộ', width: 200, dataIndex: 'maSonB' },
    { title: 'Mã chứng từ', width: 200, dataIndex: 'maCTNB' },
    { title: 'Tên nội bộ', width: 200, dataIndex: 'tenSonB', hover: true }
]

const inputLookupTax30Columns: IInputSearchLookupColumn[] = [
    { title: 'Mã thuế', width: 200, dataIndex: 'taxCode' },
    { title: 'Tên thuế', width: 200, dataIndex: 'taxName', hover: true },
    { title: 'Thuế suất', width: 200, dataIndex: 'taxRate', hover: true }
]

const inputLookupProductColumns: IInputSearchLookupColumn[] = [
    { title: 'Mã sản phẩm', width: 200, dataIndex: 'ma_sp' },
    { title: 'Tên sản phẩm', width: 200, dataIndex: 'ten_sp' },
]

const inputLookupMaVVColumns: IInputSearchLookupColumn[] = [
    { title: 'Mã vụ việc', width: 200, dataIndex: 'ma_vv' },
    { title: 'Tên vụ việc', width: 200, dataIndex: 'ten_vv' },
]

const inputLookupFeeColumns: IInputSearchLookupColumn[] = [
    { title: 'Mã phí', width: 200, dataIndex: 'ma_phi' },
    { title: 'Tên phí', width: 200, dataIndex: 'ten_phi' },
]

const inputLookupBPHTColumns: IInputSearchLookupColumn[] = [
    { title: 'Mã BPHT', width: 200, dataIndex: 'ma_bpht' },
    { title: 'Tên BPHT', width: 200, dataIndex: 'ten_bpht' },
]

const catalogDynamicColumns: {
    [key: string]: IInputSearchLookupColumn[]
} = {
    "ma_vt": [
        { title: 'Mã vật tư', width: 200, dataIndex: 'ma_vt' },
        { title: 'Tên vật tư', width: 200, dataIndex: 'ten_vt', hover: true }
    ],
    "ma_kh": [
        { title: 'Mã khách hàng', width: 200, dataIndex: 'ma_kh' },
        { title: 'Tên khách hàng', width: 200, dataIndex: 'ten_kh', hover: true }
    ],
    "ma_sp": inputLookupProductColumns,
    "ma_vv": inputLookupMaVVColumns,
    "ma_kho": [
        { title: 'Mã kho', width: 200, dataIndex: 'ma_kho' },
        { title: 'Tên kho', width: 200, dataIndex: 'ten_kho', hover: true }
    ],
    "ma_phi": inputLookupFeeColumns,
    "ma_bpht": inputLookupBPHTColumns,
    "ma_sonb": [
        { title: 'Mã nội bộ', width: 200, dataIndex: 'ma_sonb' },
        { title: 'Mã chứng từ', width: 200, dataIndex: 'ma_ctnb' },
        { title: 'Tên nội bộ', width: 200, dataIndex: 'ten_sonb', hover: true }
    ],
    "ma_thue": [
        { title: 'Mã thuế', width: 200, dataIndex: 'ma_thue' },
        { title: 'Tên thuế', width: 200, dataIndex: 'ten_thue', hover: true }
    ],
    "ma_nx": [
        { title: 'Mã nx', width: 200, dataIndex: 'ma_nx' },
        { title: 'Tên nx', width: 200, dataIndex: 'ten_nx', hover: true }
    ],
    "ma_lnx": [
        { title: 'Mã lnx', width: 200, dataIndex: 'ma_lnx' },
        { title: 'Tên lnx', width: 200, dataIndex: 'ten_loai', hover: true }
    ],
    "ma_bp": [
        { title: 'Mã bp', width: 200, dataIndex: 'ma_bp' },
        { title: 'Tên bp', width: 200, dataIndex: 'ten_bp', hover: true }
    ],
    "ma_nvien": [
        { title: 'Mã nhân viên', width: 200, dataIndex: 'ma_nvien' },
        { title: 'Tên nhân viên', width: 200, dataIndex: 'ten_nvien', hover: true }
    ],
    "tk": [
        { title: 'Mã tk', width: 200, dataIndex: 'tk' },
        { title: 'Tên tk', width: 200, dataIndex: 'ten_tk', hover: true }
    ]
};

export const InputSearchLookupColumns = {
    customer: inputLookupCustomerColumns,
    customerGroup: inputLookupCustomerGroupColumns,
    baseUnit: inputLookupBaseUnitColumns,
    materialType: inputLookupMaterialTypeColumns,
    materialGroup: inputLookupMaterialGroupColumns,
    foreignCurrency: inputLookupforeignCurrencyColumns,
    tax: inputLookupTaxColumns,
    tax30: inputLookupTax30Columns,
    groupAccount: inputLookupAccountGroupColumns,
    accountAgency: inputLookupAccountAgencyColumns,
    measurementUnit: inputLookupMeasurementUnitColumns,
    accontContractGroup: inputLookupAccountContractGroupColumns,
    material: inputLookupMaterialColumns,
    wareHouses: inputLookupWarehouseColumns,
    batchNo: inputLookupBatchNoColumns,
    catalogColumns: catalogDynamicColumns
};