import { BankAccountDto } from '@app/api/v6.api';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Checkbox, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import Form, { Rule } from 'antd/lib/form';
import React, { useCallback, useMemo, } from 'react';

interface IBankAccountEdit {
    code?: string;
    form: FormInstance<any>;
    dataTab: ITab | undefined
}

interface ITab {
    dataEdit?: BankAccountDto;
    mode: "add" | "edit" | "detail";
}

const BankAccountMainInfomation: React.FC<IBankAccountEdit> = ({ code, form, dataTab }: IBankAccountEdit) => {

    const validateTextCharater = (maxLen: number) => (_: any, value: string) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 13, name: 'taiKhoan', label: 'Tài khoản', disabled: code ? true : false,
                placeholder: "Nhập tài khoản", width: 200,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập tài khoản" },
                { validator: validateTextCharater(16) }] as Rule[]
            },
            {
                colSpan: 24, name: 'taiKhoanNganHang', label: 'Tài khoản ngân hàng', width: 200,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập tài khoản ngân hàng" },
                { validator: validateTextCharater(50) }],
                disabled: dataTab?.mode === "detail", placeholder: "Nhập tài khoản ngân hàng"
            },
            {
                colSpan: 24, name: 'tenTkNganHang', label: 'Tại ngân hàng', width: 200,
                placeholder: "Nhập tại ngân hàng", disabled: dataTab?.mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(128) }],
            },
            {
                colSpan: 24, name: 'tenTkNganHang2', label: 'Chủ tài khoản', width: 200,
                placeholder: "Nhập chủ tài khoản", disabled: dataTab?.mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(128) }],
            },
            {
                colSpan: 24, name: 'diaChi', label: 'Địa chỉ (tỉnh/tp)', width: 200,
                placeholder: "Nhập địa chỉ", disabled: dataTab?.mode === "detail",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(254) }],
            }
        ]
    }, [dataTab?.mode])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={24}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, []);

    return (
        <>
            {renderColumns(session1Cols)}

            <Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 200 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={dataTab?.mode === "detail"}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item>
        </>
    );
};

export default BankAccountMainInfomation;
