import { InvoinceAutoColumnDto } from "@app/api/v6.api";
import { FormInstance } from "antd";
import { InvoiceMoneyFieldsSetDefault } from "../constants/invoice.constant";

const invoiceAutoColumnsSortedByForder = (a: InvoinceAutoColumnDto, b: InvoinceAutoColumnDto) => {
    const aForder = a.forder ?? 0;
    const bForder = b.forder ?? 0;

    if (aForder > bForder) return 1;
    if (aForder < bForder) return -1;

    return 0;
}

const formatDefaultFieldsForADDatas = (adDatas: any[]) => {
    if (!adDatas || adDatas.length === 0) {
        return;
    }

    for (let index = 0; index < adDatas.length; index++) {
        const element = adDatas[index];

        for (let j = 0; j < InvoiceMoneyFieldsSetDefault.length; j++) {
            const field = InvoiceMoneyFieldsSetDefault[j];

            element[field] = element[field] ?? 0;
        }
    }
}

const ADDatasTinhTong = (adDatas: any[], fieldName: string): number => {
    return (adDatas || []).map(x => {
        const value = Number(x[fieldName]);

        if (Number.isNaN(value)) {
            return 0;
        }

        return value;
    }).reduce((a, b) => a + b, 0);
}

const formCopyValues = (form: FormInstance<any>, fromColumn: string, toColumns: string[]) => {
    if (!form) {
        return;
    }

    const fromValue = form.getFieldValue(fromColumn);

    const data = {} as any;
    for (let index = 0; index < (toColumns || []).length; index++) {
        const toColumn = toColumns[index];

        data[toColumn] = fromValue;
    }

    form.setFieldsValue(data);
}

const objectCopyValues = (objectValues: any, fromColumn: string, toColumns: string[]) => {
    if (!objectValues) {
        return;
    }

    const fromValue = objectValues[fromColumn];

    for (let index = 0; index < (toColumns || []).length; index++) {
        const toColumn = toColumns[index];

        objectValues[toColumn] = fromValue;
    }
}

export const InvoiceFunc = {
    invoiceAutoColumnsSortedByForder,
    formatDefaultFieldsForADDatas,
    ADDatasTinhTong,
    formCopyValues,
    objectCopyValues
}