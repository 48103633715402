import apiServices from '@app/api/api.services';
import { BlockingPathTracingUpdateDto } from '@app/api/v6.api';
import { Modal } from '@app/components/common/Modal/Modal';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import { antFormFinishErrorNotification } from '@app/shared/functions/common.function';
import { Col, Form, Input, Row } from 'antd';
import { useState } from 'react';

interface IBlockingPathTracingModal {
    visible: boolean;
    mode: "add";
    onClose: (reload?: boolean) => void;
}

export const BlockingPathTracingModal: React.FC<IBlockingPathTracingModal> = ({
    visible,
    onClose
}: IBlockingPathTracingModal) => {
    const [form] = BaseForm.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const handleCancel = async () => {
        onClose();
    };

    const onFinish = async (data: BlockingPathTracingUpdateDto) => {
        setLoading(true);
        const promise = apiServices.v6Api.blockingPathTracingsCreateBlockingPathTracing({
            path: data.path
        } as BlockingPathTracingUpdateDto);

        promise.then((response) => {
            if (response.isSuccess) {
                notificationController.success({ message: 'Thành công' });
                onClose(true);
                return;
            }

            notificationController.error({ message: response.message });
        }).catch(() => {
            notificationController.error({ message: 'Thất bại' });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal
            title={'Thêm mới lưu vết API Url'}
            confirmLoading={loading}
            open={visible}
            destroyOnClose={true}
            width={500}
            onOk={() => form.submit()}
            onCancel={() => handleCancel()}
            okText={"Lưu"}
            cancelText="Hủy"
            okButtonProps={{ style: { padding: "5px", height: "36px" } }}
            cancelButtonProps={{ style: { padding: "5px", height: "36px" } }}
        >
            <div className='basic-form'>
                <div style={{
                    width: 200,
                    overflow: 'auto'
                }}>
                    <div style={{ width: 300 }}>aaa</div>
                    <span>aaa</span>
                </div>

                <Form
                    form={form}
                    layout='inline'
                    style={{
                        width: "95%",
                        display: 'flex',
                        overflow: 'auto'
                    }}
                    name="basic"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onFinish={onFinish}
                    onFinishFailed={antFormFinishErrorNotification}
                >
                    <div style={{ width: 500, display: 'flex' }}>
                        <div style={{ width: 100, minWidth: 100, maxWidth: 100 }}><Form.Item
                            name={'path'}
                            label={'API Url'}
                            style={{ width: 100, minWidth: 100, maxWidth: 100 }}
                            rules={[
                                {
                                    required: true,
                                    message: `Vui lòng nhập API Url`,
                                }
                            ]}
                            className="w-100"
                        >
                            <Input size="small" placeholder={'Nhập'}
                            />
                        </Form.Item></div>

                        <div style={{ width: 100, minWidth: 100, maxWidth: 100 }}><Form.Item
                            name={'path'}
                            label={'API Url'}
                            style={{ width: 100, minWidth: 100, maxWidth: 100 }}
                            rules={[
                                {
                                    required: true,
                                    message: `Vui lòng nhập API Url`,
                                }
                            ]}
                            className="w-100"
                        >
                            <Input size="small" placeholder={'Nhập'}
                            />
                        </Form.Item></div>


                        <div style={{ width: 100, minWidth: 100, maxWidth: 100 }}><Form.Item
                            name={'path'}
                            label={'API Url'}
                            style={{ width: 100, minWidth: 100, maxWidth: 100 }}
                            rules={[
                                {
                                    required: true,
                                    message: `Vui lòng nhập API Url`,
                                }
                            ]}
                            className="w-100"
                        >
                            <Input size="small" placeholder={'Nhập'}
                            />
                        </Form.Item></div>
                        <div style={{ width: 100, minWidth: 100, maxWidth: 100 }}><Form.Item
                            name={'path'}
                            label={'API Url'}
                            style={{ width: 100, minWidth: 100, maxWidth: 100 }}
                            rules={[
                                {
                                    required: true,
                                    message: `Vui lòng nhập API Url`,
                                }
                            ]}
                            className="w-100"
                        >
                            <Input size="small" placeholder={'Nhập'}
                            />
                        </Form.Item></div>
                        <div style={{ width: 100, minWidth: 100, maxWidth: 100 }}><Form.Item
                            name={'path'}
                            label={'API Url'}
                            style={{ width: 100, minWidth: 100, maxWidth: 100 }}
                            rules={[
                                {
                                    required: true,
                                    message: `Vui lòng nhập API Url`,
                                }
                            ]}
                            className="w-100"
                        >
                            <Input size="small" placeholder={'Nhập'}
                            />
                        </Form.Item></div>

                        <div style={{ width: 100, minWidth: 100, maxWidth: 100 }}><Form.Item
                            name={'path'}
                            label={'API Url'}
                            style={{ width: 100, minWidth: 100, maxWidth: 100 }}
                            rules={[
                                {
                                    required: true,
                                    message: `Vui lòng nhập API Url`,
                                }
                            ]}
                            className="w-100"
                        >
                            <Input size="small" placeholder={'Nhập'}
                            />
                        </Form.Item></div>

                        <div style={{ width: 100, minWidth: 100, maxWidth: 100 }}><Form.Item
                            name={'path'}
                            label={'API Url'}
                            style={{ width: 100, minWidth: 100, maxWidth: 100 }}
                            rules={[
                                {
                                    required: true,
                                    message: `Vui lòng nhập API Url`,
                                }
                            ]}
                            className="w-100"
                        >
                            <Input size="small" placeholder={'Nhập'}
                            />
                        </Form.Item></div>

                        <div style={{ width: 100, minWidth: 100, maxWidth: 100 }}><Form.Item
                            name={'path'}
                            label={'API Url'}
                            style={{ width: 100, minWidth: 100, maxWidth: 100 }}
                            rules={[
                                {
                                    required: true,
                                    message: `Vui lòng nhập API Url`,
                                }
                            ]}
                            className="w-100"
                        >
                            <Input size="small" placeholder={'Nhập'}
                            />
                        </Form.Item></div>
                    </div>
                </Form>
            </div>
        </Modal>
    )
}