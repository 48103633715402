import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { FixedAssetTypeListView } from '../views/FixedAssetTypeListView';

const FixedAssetTypeListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách phân loại tài sản</PageTitle>
            <FixedAssetTypeListView />
        </>
    );
};

export default FixedAssetTypeListPage;
