import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import apiServices from '@app/api/api.services';
import { MaterialDto, MaterialUpdateDto } from '@app/api/v6.api';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { PageConstant } from '@app/constants/page.constant';
import { notificationController } from '@app/controllers/notificationController';
import { antFormFinishErrorNotification } from '@app/shared/functions/common.function';
import { Button, Form, Row, Tabs, TabsProps, message } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MaterialMainInfomation from './material-tabs/MaterialMainInfomation';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import MaterialAccount from './material-tabs/MaterialAccount';
import MaterialInventory from './material-tabs/MaterialInventory';
import { Rule } from 'antd/lib/form';
import MaterialOtherInfomation from './material-tabs/MaterialOtherInfomation';

interface IMaterialEdit {
    code: string;
}

const MaterialAddOrUpdateView: React.FC<IMaterialEdit> = ({
    code
}: IMaterialEdit) => {
    const navigate = useNavigate();
    const isEditting = useMemo(() => Boolean(code), [code]);
    const [form] = BaseForm.useForm<MaterialUpdateDto>();
    const [loading, setLoading] = useState<boolean>(false);
    const [editMaterialData, setEditMaterialData] = useState<MaterialDto>(null!);

    const fetchData = useCallback(async () => {
        if (isEditting) {
            const material = await apiServices.v6Api.materialsGetByCode(code);
            form.setFieldsValue({
                ...material,
                status: (material.status === '1') as any,
                units: (material.units === '1') as any
            })
            setEditMaterialData(material);
        }
    }, [code, form, isEditting])

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onFinish = async () => {
        const data = await form.validateFields();
        data.status = data.status ? '1' : '0';
        data.units = data.units ? '1' : '0';

        setLoading(true);
        const promise = isEditting
            ? apiServices.v6Api.materialsUpdateUser(code, data)
            : apiServices.v6Api.materialsCreateUser(data);

        promise.then((response) => {
            if (response.isSuccess) {
                notificationController.success({ message: 'Thành công' });
                goToMaterialList();
                return;
            }

            notificationController.error({ message: response.message });
        }).catch(() => {
            notificationController.error({ message: 'Thất bại' });
        }).finally(() => {
            setLoading(false);
        });
    }

    const tabItems: TabsProps['items'] = useMemo(() => {
        return [
            {
                key: '1',
                label: 'Thông tin chính',
                children: <MaterialMainInfomation
                    form={form}
                    isEditting={isEditting}
                    editMaterial={editMaterialData} />,
            },
            {
                key: '2',
                label: 'Tài khoản',
                children: <MaterialAccount
                    form={form}
                    isEditting={isEditting}
                    editMaterial={editMaterialData} />,
            },
            {
                key: '3',
                label: 'Tồn kho',
                children: <MaterialInventory
                    form={form}
                    isEditting={isEditting}
                    editMaterial={editMaterialData} />,
            },
            {
                key: '4',
                label: 'Thông tin khác',
                children: <MaterialOtherInfomation
                    isEditting={isEditting}
                />,
            }
        ];
    }, [isEditting, form, editMaterialData])

    const validateTextCharater = (maxLen: number) => (_: any, value: any) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const masterCustomerInformations: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'ma_vt', label: 'Mã vật tư', disabled: isEditting,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, {
                    required: true,
                    message: "Vui lòng nhập mã vật tư"
                }, { validator: validateTextCharater(25) }] as Rule[],
            },
            {
                colSpan: 12, name: 'shortName', label: 'Tên ngắn',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(30) }] as Rule[],
            },
            {
                colSpan: 24, name: 'materialName', label: 'Tên vật tư',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập tên vật tư" },
                { validator: validateTextCharater(254) }] as Rule[],
            },
            {
                colSpan: 24, name: 'materialName2', label: 'Tên khác',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(254) }] as Rule[],
            },
            {
                colSpan: 12, name: 'countryCode', label: 'Nước sản xuất',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(8) }] as Rule[],
            }
        ]
    }, [isEditting])

    const renderCustomerInformation = () => (
        <div>
            <Row gutter={24}>
                {
                    masterCustomerInformations.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        </div>
    );

    function goToMaterialList() {
        navigate(PageConstant.MATERIALS);
    }

    return (
        <>
            <h1>
                {
                    isEditting && (
                        <span>
                            Chỉnh sửa vật tư <span style={{ fontWeight: 500 }}>{code}</span>
                        </span>
                    )
                }

                {
                    !isEditting && (
                        <span>Tạo vật tư</span>
                    )
                }
            </h1>

            <div className='basic-form'>
                <Form
                    form={form}
                    style={{ width: "95%" }}
                    name="basic"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onFinish={onFinish}
                    onFinishFailed={antFormFinishErrorNotification}
                >
                    {renderCustomerInformation()}

                    <Tabs defaultActiveKey="1" items={tabItems} />

                    <div>
                        <Button type="primary" htmlType="submit" style={{ marginRight: "8px" }} disabled={loading}
                            loading={loading}
                        >
                            <CheckOutlined type='submit' />Nhận
                        </Button>

                        <Button type="primary" htmlType="reset" style={{ backgroundColor: "red", border: "1px red" }} onClick={goToMaterialList}>
                            <CloseCircleOutlined />Hủy
                        </Button>
                    </div>
                </Form>
            </div >
        </>
    );
};

export default MaterialAddOrUpdateView;
