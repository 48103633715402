import { Modal } from '@app/components/common/Modal/Modal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Col, Form, Row, Tabs, TabsProps } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import apiServices from '@app/api/api.services';
import { antFormFinishErrorNotification } from '@app/shared/functions/common.function';
import { CustomerDto, CustomerUpdateDto } from '@app/api/v6.api';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Rule } from 'antd/lib/form';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import CustomerMainInfomation from './customer-tabs/CustomerMainInfomation';
import InputSearchLookup from '@app/components/common/input-search-lookup/InputSearchLookup';
import { InputSearchApiService } from '@app/api/input-search-api.service';
import { CatalogAddModalType } from '@app/components/shared/modals/catalog-add-modal/catalog-add-modal';
import { InputSearchLookupColumns } from '@app/components/common/input-search-lookup/constants/input-lookup-entity-columns.constant';

interface IModalCustomer {
    visible: boolean;
    onClose: (reload?: boolean) => void;
    onDispatchResponse?: (data: CustomerDto) => void;
}

export const CustomerModal: React.FC<IModalCustomer> = ({ visible, onClose, onDispatchResponse }: IModalCustomer) => {
    const [form] = BaseForm.useForm<CustomerUpdateDto>();
    const [titleModal, setTileModal] = useState("Thêm mới khách hàng");
    const [loading, setLoading] = useState<boolean>(false);

    const handleCancel = async () => {
        onClose();
    };

    const fetchData = useCallback(async () => {
        setTileModal(`Thêm mới khách hàng`);
    }, [form])

    useEffect(() => {
        form.resetFields();
        fetchData();
    }, [fetchData, form])

    const renderForm = useCallback(() => {
        return <>
            <Form
                form={form}
                name="basic"
                initialValues={{ remember: true }}
                autoComplete="off"
                onFinish={onFinish}
                onFinishFailed={antFormFinishErrorNotification}
            >
                <Row gutter={24}>
                    {formItemInputWithColumn({
                        colSpan: 12, name: 'customerCode',
                        label: 'Mã khách hàng',
                        rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, {
                            required: true,
                            message: "Vui lòng nhập mã khách hàng"
                        }, { validator: validateTextCharater(16) }] as Rule[],
                    }, 1)}
                    <Col span={12}>
                        <Form.Item
                            name={'baseUnitCode'}
                            label={'Mã đvcs'}
                            labelCol={{ style: { width: 130 } }}
                            labelAlign='left'
                            className="w-100"
                            rules={[
                                { validator: validateTextCharater(8) }
                            ]}
                        >
                            <InputSearchLookup
                                fetchDataFunc={InputSearchApiService.getCatalogDymamic("MA_DVCS")}
                                placeHolder='Nhập mã đvcs'
                                keyField={'ma_dvcs'}
                                lookupAdding={{
                                    initValues: {
                                    },
                                    catalogAddModalType: CatalogAddModalType.BaseUnit
                                }}
                                columns={InputSearchLookupColumns.baseUnit}
                                onOutputChange={(value: string) => {
                                    form.setFieldsValue({
                                        baseUnitCode: value
                                    })
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {renderColumns(session1Cols)}
                <Tabs defaultActiveKey="1" items={items} />
            </Form>
        </>
    }, [])

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Thông tin chính',
            children: <CustomerMainInfomation
                form={form} />,
        }
    ];

    const validateTextCharater = (maxLen: number) => (_: any, value: any) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'customerName', label: 'Tên khách hàng',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập tên khách hàng" }, { validator: validateTextCharater(254) }] as Rule[],
            },
            {
                colSpan: 12, name: 'customerName2', label: 'Tên khách hàng 2',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(254) }] as Rule[],
            },
        ]
    }, []);

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={24}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, []);


    const onFinish = async () => {
        const data = await form.validateFields();
        data.status = data.status ? '1' : '0';

        setLoading(true);
        const promise = apiServices.v6Api.customersCreateUser(data);
        promise.then((response) => {
            if (response.isSuccess) {
                notificationController.success({ message: 'Thành công' });
                onClose(true);
                if (onDispatchResponse) {
                    onDispatchResponse(response.data as CustomerDto);
                }
                return;
            }

            notificationController.error({ message: response.message });
        }).catch(() => {
            notificationController.error({ message: 'Thất bại' });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal
            title={titleModal}
            confirmLoading={loading}
            open={visible}
            destroyOnClose={true}
            width={"1000px"}
            onOk={() => form.submit()}
            onCancel={() => handleCancel()}
            okText={"Lưu"}
            cancelText="Hủy"
            okButtonProps={{ style: { padding: "5px", height: "36px" } }}
            cancelButtonProps={{ style: { padding: "5px", height: "36px" } }}
        >
            <div className='basic-form'>
                {renderForm()}
            </div>
        </Modal>
    )
}