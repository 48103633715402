import { PaginatedList } from "@app/shared/models/paginated-list.model";
import { AccountGroupDto, AlSonBDto, BankContractGroupDto, CatalogSPParams, CustomerGroupDto, MaterialGroupDto } from "./v6.api";
import apiServices from "./api.services";
import { IInputSearchLookupApiRequest } from "@app/components/common/input-search-lookup/models/input-search-lookup-column";

export class InputSearchApiService {
    public static getCatalog(vvar: string): (request: IInputSearchLookupApiRequest) => Promise<PaginatedList<any>> {
        return async (request: IInputSearchLookupApiRequest) => {
            const pagingRes = await apiServices.v6Api.catalogsGetCatalogDynamicPaging({
                vvar: vvar,
                vvalue: request.searchText,
                pageNumber: request.pageNumber,
                pageSize: request.pageSize
            } as CatalogSPParams);

            const result: PaginatedList<any> = {
                pageNumber: pagingRes?.pageNumber ?? 0,
                totalPages: pagingRes?.totalPages ?? 0,
                totalCount: pagingRes?.totalCount ?? 0,
                hasMoreData: pagingRes?.hasMoreData ?? false,
                items: pagingRes.items || []
            };

            return result;
        }
    }

    public static getCatalogDymamic(vvar: string): (request: IInputSearchLookupApiRequest) => Promise<PaginatedList<any>> {
        return async (request: IInputSearchLookupApiRequest) => {
            const pagingRes = await apiServices.v6Api.catalogsGetCatalogDynamicPaging({
                vvar: vvar,
                vvalue: request.searchText,
                pageNumber: request.pageNumber,
                pageSize: request.pageSize
            } as CatalogSPParams);

            const result: PaginatedList<any> = {
                pageNumber: pagingRes?.pageNumber ?? 0,
                totalPages: pagingRes?.totalPages ?? 0,
                totalCount: pagingRes?.totalCount ?? 0,
                hasMoreData: pagingRes?.hasMoreData ?? false,
                items: pagingRes.items || []
            };

            return result;
        }
    }

    public static getCustomerGroupType(groupType?: number): (request: IInputSearchLookupApiRequest) => Promise<PaginatedList<CustomerGroupDto>> {
        return async (request: IInputSearchLookupApiRequest) => {
            const pagingRes = await apiServices.v6Api.customerGroupsGet(request.searchText, true, groupType ?? undefined, request.pageNumber, request.pageSize);

            const result: PaginatedList<CustomerGroupDto> = {
                pageNumber: pagingRes?.pageNumber ?? 0,
                totalPages: pagingRes?.totalPages ?? 0,
                totalCount: pagingRes?.totalCount ?? 0,
                hasMoreData: pagingRes?.hasMoreData ?? false,
                items: pagingRes.items || []
            };

            return result;
        }
    }

    public static getMaterialGroups(materialType?: number): (request: IInputSearchLookupApiRequest) => Promise<PaginatedList<MaterialGroupDto>> {
        return async (request: IInputSearchLookupApiRequest) => {
            const pagingRes = await apiServices.v6Api.materialGroupsGet(request.searchText, true, materialType ?? undefined, request.pageNumber, request.pageSize);

            const result: PaginatedList<MaterialGroupDto> = {
                pageNumber: pagingRes?.pageNumber ?? 0,
                totalPages: pagingRes?.totalPages ?? 0,
                totalCount: pagingRes?.totalCount ?? 0,
                hasMoreData: pagingRes?.hasMoreData ?? false,
                items: pagingRes.items || []
            };

            return result;
        };
    }

    public static getAccountGroups(accountGroupType?: number): (request: IInputSearchLookupApiRequest) => Promise<PaginatedList<AccountGroupDto>> {
        return async (request: IInputSearchLookupApiRequest) => {
            const pagingRes = await apiServices.v6Api.accountGroupsGet(request.searchText, true, accountGroupType ?? undefined, request.pageNumber, request.pageSize);

            const result: PaginatedList<AccountGroupDto> = {
                pageNumber: pagingRes?.pageNumber ?? 0,
                totalPages: pagingRes?.totalPages ?? 0,
                totalCount: pagingRes?.totalCount ?? 0,
                hasMoreData: pagingRes?.hasMoreData ?? false,
                items: pagingRes.items || []
            };

            return result;
        };
    }

    public static getBankContractGroups(bankContractType?: number): (request: IInputSearchLookupApiRequest) => Promise<PaginatedList<BankContractGroupDto>> {
        return async (request: IInputSearchLookupApiRequest) => {
            const pagingRes = await apiServices.v6Api.bankContractGroupsGet(request.searchText, true, bankContractType ?? undefined, request.pageNumber, request.pageSize);

            const result: PaginatedList<BankContractGroupDto> = {
                pageNumber: pagingRes?.pageNumber ?? 0,
                totalPages: pagingRes?.totalPages ?? 0,
                totalCount: pagingRes?.totalCount ?? 0,
                hasMoreData: pagingRes?.hasMoreData ?? false,
                items: pagingRes.items || []
            };

            return result;
        };
    }

    public static getCatalogSoNBsByMaCTNB(maCTNB?: string): (request: IInputSearchLookupApiRequest) => Promise<PaginatedList<AlSonBDto>> {
        return async (request: IInputSearchLookupApiRequest) => {
            const pagingRes = await apiServices.v6Api.alSonBsGet(request.searchText, maCTNB ?? undefined, request.pageNumber, request.pageSize);

            const result: PaginatedList<AlSonBDto> = {
                pageNumber: pagingRes?.pageNumber ?? 0,
                totalPages: pagingRes?.totalPages ?? 0,
                totalCount: pagingRes?.totalCount ?? 0,
                hasMoreData: pagingRes?.hasMoreData ?? false,
                items: pagingRes.items || []
            };

            return result;
        };
    }
}