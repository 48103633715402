import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Checkbox, Row } from 'antd';
import Form, { Rule } from 'antd/lib/form';
import React, { useMemo } from 'react';

interface IForeignCurrencyEdit {
    code?: string;
    currentMode: "add" | "edit" | "detail";
}

const ForeignCurrencyMainInfomation: React.FC<IForeignCurrencyEdit> = ({ code, currentMode }: IForeignCurrencyEdit) => {

    const validateTextCharater = (maxLen: number) => (_: any, value: string) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const mainInforCols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'foreignCurrencyCode', label: 'Mã ngoại tệ', disabled: code ? true : false,
                placeholder: "Nhập mã ngoại tệ", width: 150,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập mã ngoại tệ" },
                { validator: validateTextCharater(3) }] as Rule[]
            },
            {
                colSpan: 12, name: 'foreignCurrencyName', label: 'Tên ngoại tệ',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập tên ngoại tệ" },
                { validator: validateTextCharater(48) }],
                disabled: currentMode === "detail", placeholder: "Nhập tên ngoại tệ"
            },
            {
                colSpan: 24, name: 'foreignCurrencyName2', label: 'Tên ngoại tệ 2', width: 150,
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(48) }],
                placeholder: "Nhập tên ngoại tệ 2", disabled: currentMode === "detail"
            },
            {
                colSpan: 8, name: 'begin1', label: 'Cách đọc tên tiếng việt', width: 150,
                placeholder: "Nhập begin 1", rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(48) }],
                disabled: currentMode === "detail"
            },
            {
                colSpan: 4, name: 'end1', label: '', width: 0, placeholder: "Nhập end 1",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(48) }],
                disabled: currentMode === "detail"
            },
            {
                colSpan: 4, name: 'point1', label: '', width: 0, placeholder: "Nhập point 1",
                disabled: currentMode === "detail", rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(48) }],
            },
            {
                colSpan: 4, name: 'endPoint1', label: '', width: 0,
                placeholder: "Nhập end point 1", rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(48) }],
                disabled: currentMode === "detail"
            },
            {
                colSpan: 4, name: 'only1', label: '', width: 0,
                placeholder: "Nhập only 1", rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(48) }],
                disabled: currentMode === "detail"
            },
            {
                colSpan: 8, name: 'begin2', label: 'Cách đọc tên tiếng anh', width: 150,
                placeholder: "Nhập begin 2",
                disabled: currentMode === "detail", rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(48) }],
            },
            {
                colSpan: 4, name: 'end2', label: '', width: 0,
                placeholder: "Nhập end 2",
                disabled: currentMode === "detail", rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(48) }],
            },
            {
                colSpan: 4, name: 'point2', label: '', width: 0,
                placeholder: "Nhập point 2",
                disabled: currentMode === "detail", rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(48) }],
            },
            {
                colSpan: 4, name: 'endPoint2', label: '', width: 0,
                placeholder: "Nhập end point 2",
                disabled: currentMode === "detail", rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(48) }],
            },
            {
                colSpan: 4, name: 'only2', label: '', width: 0,
                placeholder: "Nhập only 2",
                disabled: currentMode === "detail", rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(48) }],
            },
        ]
    }, [currentMode, code])

    const renderColumns = () => {
        return (
            <Row gutter={24}>
                {
                    mainInforCols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }

    return (
        <>
            {renderColumns()}
            <Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 130 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={currentMode === "detail"}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item>
        </>
    );
};

export default ForeignCurrencyMainInfomation;
