import { RightOutlined } from '@ant-design/icons';
import { useAppSelector } from '@app/hooks/reduxHooks';
import v6Logo from 'assets/images/v6-logo-image-2.jpg';
import { useResponsive } from 'hooks/useResponsive';
import React from 'react';
import * as S from './MainSider/MainSider.styles';

interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({ isSiderCollapsed, toggleSider }) => {
  const { tabletOnly } = useResponsive();

  const theme = useAppSelector((state) => state.theme.theme);

  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink to="/">
        <img src={v6Logo} alt="V6 Software" width={60} height={48} />
        <span
          style={{
            fontWeight: 700,
            color: '#fff',
            fontSize: 13,
            marginLeft: 6
          }}>
          V6 SOFTWARE
        </span>
      </S.SiderLogoLink>
      {tabletOnly && (
        <S.CollapseButton
          shape="circle"
          size="small"
          $isCollapsed={isSiderCollapsed}
          icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
          onClick={toggleSider}
        />
      )}
    </S.SiderLogoDiv>
  );
};
