import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { MaterialGroupListView } from '../views/MaterialGroupListView';

const MaterialGroupListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách nhóm vật tư</PageTitle>
            <MaterialGroupListView />
        </>
    );
};

export default MaterialGroupListPage;
