import axios from "axios";
import { V6Api } from "./v6.api";
import { readToken } from "@app/services/localStorage.service";
import { Constant } from "@app/styles/themes/constants";

axios.defaults.headers.common = {
    Authorization: `Bearer ${readToken()}`,
    MADVCS: localStorage.getItem(Constant.BASE_UNIT)!
};

const v6Api = new V6Api(process.env.REACT_APP_API_BASE_URL);

const apiServices = {
    v6Api
}

export default apiServices;

export const axiosSetAccessTokenMiddleware = () => {
    axios.defaults.headers.common = {
        Authorization: `Bearer ${readToken()}`,
        MADVCS: localStorage.getItem(Constant.BASE_UNIT)!
    };
    apiServices.v6Api = new V6Api(process.env.REACT_APP_API_BASE_URL);
}