import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';
import apiServices from '@app/api/api.services';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const token = useAppSelector((state) => state.auth.token);
  const [hasAccess, setHasAccess] = useState<boolean | null>(null);

  useEffect(() => {
    async function fetchUserProfile() {
      try {
        const profile = await apiServices.v6Api.authsProfile();
        if (profile.hasAccess) {
          setHasAccess(true);
        } else {
          setHasAccess(false);
        }
      } catch (error) {
        setHasAccess(false);
      }
    }

    if (token) {
      fetchUserProfile();
    } else {
      setHasAccess(false);
    }
  }, [token]);

  if (hasAccess === null) {
    return null;
  }

  return hasAccess ? <>{children}</> : <Navigate to="/auth/login" replace />;
};

export default RequireAuth;
