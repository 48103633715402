import { IInputSearchLookupAddingModel } from "@app/components/common/input-search-lookup/models/input-search-lookup-adding.model";
import { AccountGroupModal } from "@app/pages/account-groups/modals/AccountGroupModal";
import { BankContractGroupModal } from "@app/pages/bank-contract-groups/modals/BankContractGroupModal";
import BaseUnitModal from "@app/pages/base-units/modals/BaseUnitModal";
import { CustomerGroupModal } from "@app/pages/customer-groups/modals/CustomerGroupModal"
import { CustomerModal } from "@app/pages/customers/modals/CustomerModal";
import { ForeignCurrencyModal } from "@app/pages/foreign-curencies/modals/ForeignCurrencyModal";
import { MaterialGroupModal } from "@app/pages/material-groups/modals/MaterialGroupModal";
import { MaterialModal } from "@app/pages/materials/modals/MaterialModal";
import { MeasurementUnitModal } from "@app/pages/measurement-units/modals/MeasurementUnitModal";
import { TaxModal } from "@app/pages/tax/modals/TaxModal";

interface ICatalogAddModalProps {
    catalogAddModalType: CatalogAddModalType;
    lookupAdding: IInputSearchLookupAddingModel;
    onClose: () => void;
    onSelect: (data: any) => void;
}

export enum CatalogAddModalType {
    CustomerGroup = 'CustomerGroup',
    BaseUnit = 'BaseUnit',
    ForeignCurencies = 'ForeignCurencies',
    AccountGroup = 'AccountGroup',
    Tax = 'Tax',
    MeasurementUnit = "MeasurementUnit",
    Material = "Material",
    MaterialGroup = "MaterialGroup",
    BankContractGroup = "BankContractGroup",
    Customer = "Customer"
}

const CatalogAddModal: React.FC<ICatalogAddModalProps> = ({
    catalogAddModalType,
    lookupAdding,
    onClose,
    onSelect
}: ICatalogAddModalProps) => {
    let modalComponent;
    switch (catalogAddModalType) {
        case CatalogAddModalType.CustomerGroup:
            modalComponent = (
                <CustomerGroupModal
                    visible={true}
                    mode="add"
                    initialValues={lookupAdding.initValues}
                    disableGroupType={true}
                    onClose={onClose}
                    onDispatchResponse={onSelect}
                />
            );
            break;
        case CatalogAddModalType.BaseUnit:
            modalComponent = (
                <BaseUnitModal
                    visible={true}
                    mode="add"
                    onClose={onClose}
                    onDispatchResponse={onSelect}
                />
            );
            break;
        case CatalogAddModalType.ForeignCurencies:
            modalComponent = (
                <ForeignCurrencyModal
                    visible={true}
                    mode="add"
                    onClose={onClose}
                    onDispatchResponse={onSelect}
                />
            )
            break;
        case CatalogAddModalType.AccountGroup:
            modalComponent = (
                <AccountGroupModal
                    visible={true}
                    mode="add"
                    initialValues={lookupAdding.initValues}
                    disableGroup={true}
                    onClose={onClose}
                    onDispatchResponse={onSelect}
                />
            )
            break;
        case CatalogAddModalType.Tax:
            modalComponent = (
                <TaxModal
                    visible={true}
                    mode="add"
                    onClose={onClose}
                    onDispatchResponse={onSelect}
                />
            )
            break;
        case CatalogAddModalType.MeasurementUnit:
            modalComponent = (
                <MeasurementUnitModal
                    visible={true}
                    mode="add"
                    onClose={onClose}
                    onDispatchResponse={onSelect}
                />
            )
            break;
        case CatalogAddModalType.MaterialGroup:
            modalComponent = (
                <MaterialGroupModal
                    visible={true}
                    mode="add"
                    disableGroup={true}
                    initialValues={lookupAdding.initValues}
                    onClose={onClose}
                    onDispatchResponse={onSelect}
                />
            )
            break;
        case CatalogAddModalType.BankContractGroup:
            modalComponent = (
                <BankContractGroupModal
                    visible={true}
                    mode="add"
                    disableGroup={true}
                    initialValues={lookupAdding.initValues}
                    onClose={onClose}
                    onDispatchResponse={onSelect}
                />
            )
            break;
        case CatalogAddModalType.Material:
            modalComponent = (
                <MaterialModal
                    visible={true}
                    onClose={onClose}
                    onDispatchResponse={onSelect}
                />
            )
            break;
        case CatalogAddModalType.Customer:
            modalComponent = (
                <CustomerModal
                    visible={true}
                    onClose={onClose}
                    onDispatchResponse={onSelect}
                />
            )
            break;
        default:
            modalComponent = <></>;
            break;
    }

    return modalComponent;
};

export default CatalogAddModal;
