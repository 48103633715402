import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { BankContractListView } from '../views/BankContractListView';

const BankContractListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách khế ước</PageTitle>
            <BankContractListView />
        </>
    );
};

export default BankContractListPage;
