import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { Checkbox, Row } from 'antd';
import Form, { Rule } from 'antd/lib/form';
import React, { useMemo } from 'react';

interface IFixedAssetTypeEdit {
    code?: string;
    currentMode: "add" | "edit" | "detail";
}

const FixedAssetTypeMainInfomation: React.FC<IFixedAssetTypeEdit> = ({ code, currentMode }: IFixedAssetTypeEdit) => {

    const validateTextCharater = (maxLen: number) => (_: any, value: string) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const mainInforCols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'typeCode', label: 'Mã phân loại tài sản', disabled: code ? true : false,
                placeholder: "Nhập mã loại tài sản",
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập mã phân loại tài sản" },
                { validator: validateTextCharater(8) }] as Rule[]
            },
            {
                colSpan: 12, name: 'nameType', label: 'Tên phân loại tài sản',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" },
                { validator: validateTextCharater(254) }] as Rule[],
                disabled: currentMode === "detail"
            },
            {
                colSpan: 12, name: 'nameType2', label: 'Tên phân loại tài sản 2',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(254) }],
                disabled: currentMode === "detail", placeholder: "Nhập tên phân loại tài sản 2"
            },
        ]
    }, [currentMode])

    const renderColumns = () => {
        return (
            <Row gutter={24}>
                {
                    mainInforCols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }

    return (
        <>
            {renderColumns()}
            <Form.Item
                label="Trạng thái"
                name="status"
                labelCol={{ style: { width: 130 } }}
                labelAlign='left'
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox
                    style={{
                        marginRight: "10px",
                    }}
                    disabled={currentMode === "detail"}
                >
                    Hoạt động?
                </Checkbox>
            </Form.Item>
        </>
    );
};

export default FixedAssetTypeMainInfomation;
