import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import apiServices from '@app/api/api.services';
import { CatalogSPParams, CustomerDto, CustomerUpdateDto } from '@app/api/v6.api';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { formItemInputWithColumn } from '@app/components/shared/form-item/form-item.function';
import { IColFormItemModel } from '@app/components/shared/form-item/form-item.model';
import { PageConstant } from '@app/constants/page.constant';
import { notificationController } from '@app/controllers/notificationController';
import { antFormFinishErrorNotification } from '@app/shared/functions/common.function';
import { Button, Col, Form, Row, Tabs, TabsProps, message } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomerAnotherInfomation from './customer-tabs/CustomerAnotherInfomation';
import CustomerKeyInfomation from './customer-tabs/CustomerKeyInfomation';
import InputSearchLookup from '@app/components/common/input-search-lookup/InputSearchLookup';
import { InputSearchLookupColumns } from '@app/components/common/input-search-lookup/constants/input-lookup-entity-columns.constant';
import { InputSearchApiService } from '@app/api/input-search-api.service';
import { Rule } from 'antd/lib/form';
import { CatalogAddModalType } from '@app/components/shared/modals/catalog-add-modal/catalog-add-modal';

interface ICustomerEdit {
    code: string;
}

const CustomerAddOrUpdateView: React.FC<ICustomerEdit> = ({
    code
}: ICustomerEdit) => {
    const navigate = useNavigate();
    const isEditting = useMemo(() => Boolean(code), [code]);
    const [form] = BaseForm.useForm<CustomerUpdateDto>();
    const [loading, setLoading] = useState<boolean>(false);
    const [editCustomerData, setEditCustomerData] = useState<CustomerDto>(null!);
    const [changeBaseUnitValue, setChangeBaseUnitValue] = useState<{ text: string }>(null!);

    const fetchData = useCallback(async () => {
        if (isEditting) {
            const dataEditCustomer = await apiServices.v6Api.customersGetById(code);
            form.setFieldsValue({
                ...dataEditCustomer,
                status: (dataEditCustomer.status === '1' ? true : false) as any,
                customerName: (dataEditCustomer.customerName)
            })
            setChangeBaseUnitValue({ text: dataEditCustomer.baseUnitCode as string });
            setEditCustomerData(dataEditCustomer);
        }
    }, [code, form, isEditting])

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const checkInputLookup = async () => {
        const value = await form.getFieldValue(`taxCode`) as number;

        const filter = {
            vvar: 'MA_THUE30',
            pageNumber: 1,
            pageSize: 20
        } as CatalogSPParams;

        if (value) {
            const checkExist = await apiServices.v6Api.catalogsGetCatalogDynamicPaging(filter);
            if (!checkExist.items?.find(item => item.taxRate == value)) {
                return "Không tìm thấy loại thuế VAT này";
            }
        }
        return ""
    }

    const onFinish = async (data: CustomerUpdateDto) => {
        data.status = data.status ? '1' : '0';

        const resultCheckInputLookup = await checkInputLookup();

        if (resultCheckInputLookup !== "") {
            message.error(resultCheckInputLookup);
            return;
        }

        setLoading(true);
        const response = isEditting
            ? await apiServices.v6Api.customersUpdateUser(code, data)
            : await apiServices.v6Api.customersCreateUser(data);
        setLoading(false);

        if (response.isSuccess) {
            notificationController.success({ message: 'Thành công' });
            goToCustomerList();
            return;
        }

        notificationController.error({ message: response.message });
    }

    const tabItems: TabsProps['items'] = useMemo(() => {
        return [
            {
                key: '1',
                label: 'Thông tin chính',
                children: <CustomerKeyInfomation
                    form={form}
                    isEditting={isEditting}
                    editCustomer={editCustomerData}
                />,
            },
            {
                key: '2',
                label: 'Thông tin khác',
                children: <CustomerAnotherInfomation />,
            },
        ];
    }, [editCustomerData, form, isEditting])

    const validateTextCharater = (maxLen: number) => (_: any, value: any) => {
        if (value) {
            if (value.length > maxLen) {
                return Promise.reject(`Tối đa ${maxLen} kí tự`);
            }
        }
        return Promise.resolve();
    };

    const session1Cols: IColFormItemModel[] = useMemo(() => {
        return [
            {
                colSpan: 12, name: 'customerName', label: 'Tên khách hàng',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { required: true, message: "Vui lòng nhập tên khách hàng" }, { validator: validateTextCharater(254) }] as Rule[],
            },
            {
                colSpan: 12, name: 'customerName2', label: 'Tên khách hàng 2',
                rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, { validator: validateTextCharater(254) }] as Rule[],
            },
        ]
    }, [])

    const renderColumns = useCallback((cols: IColFormItemModel[]) => {
        return (
            <Row gutter={24}>
                {
                    cols.map((col, index) => (
                        formItemInputWithColumn(col, index)
                    ))
                }
            </Row>
        )
    }, []);

    function goToCustomerList() {
        navigate(PageConstant.CUSTOMERS);
    }

    const validateText8Charater = (_: any, value: any) => {
        if (value && value.length > 8) {
            return Promise.reject('Tối đa 8 kí tự');
        }
        return Promise.resolve();
    };

    return (
        <>
            <h1> {`${isEditting ? 'Chỉnh sửa' : 'Tạo'}`} khách hàng {code}</h1>

            <div className='basic-form'>
                <Form
                    form={form}
                    style={{ width: "95%" }}
                    name="basic"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onFinish={onFinish}
                    onFinishFailed={antFormFinishErrorNotification}
                >
                    <Row gutter={24}>
                        {formItemInputWithColumn({
                            colSpan: 12, name: 'customerCode',
                            label: 'Mã khách hàng',
                            rules: [{ whitespace: true, message: "Không nhập khoảng cách" }, {
                                required: true,
                                message: "Vui lòng nhập mã khách hàng"
                            }, { validator: validateTextCharater(16) }] as Rule[],
                            disabled: isEditting
                        }, 1)}
                        <Col span={12}>
                            <Form.Item
                                name={'baseUnitCode'}
                                label={'Mã đvcs'}
                                labelCol={{ style: { width: 130 } }}
                                labelAlign='left'
                                className="w-100"
                                rules={[
                                    { validator: validateText8Charater }
                                ]}
                            >
                                <InputSearchLookup
                                    fetchDataFunc={InputSearchApiService.getCatalogDymamic("MA_DVCS")}
                                    placeHolder='Nhập mã đvcs'
                                    keyField={'ma_dvcs'}
                                    lookupAdding={{
                                        initValues: {
                                        },
                                        catalogAddModalType: CatalogAddModalType.BaseUnit
                                    }}
                                    columns={InputSearchLookupColumns.baseUnit}
                                    onOutputChange={(value: string) => {
                                        form.setFieldsValue({
                                            baseUnitCode: value
                                        })
                                    }}
                                    patchValue={changeBaseUnitValue}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {renderColumns(session1Cols)}

                    <Tabs defaultActiveKey="1" items={tabItems} />

                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ marginRight: "8px" }} loading={loading} disabled={loading}
                        >
                            <CheckOutlined type='submit' />Nhận
                        </Button>

                        <Button type="primary" htmlType="reset" style={{ backgroundColor: "red", border: "1px red" }} onClick={goToCustomerList}>
                            <CloseCircleOutlined />Hủy
                        </Button>
                    </Form.Item>
                </Form>
            </div >
        </>
    );
};

export default CustomerAddOrUpdateView;
