import { DeleteOutlined } from '@ant-design/icons';
import { InvoinceAutoColumnDto } from '@app/api/v6.api';
import { notificationController } from '@app/controllers/notificationController';
import { IS_AD_NUMBER_COLUMN, InvoiceADColumnFtype } from '@app/pages/Invoices/shared/enums/invoice.enum';
import { InvoiceFunc } from '@app/pages/Invoices/shared/functions/invoice.func';
import { ADAutoColumnsForm } from '@app/pages/Invoices/shared/views/ad-auto-columns-form';
import { Utils } from '@app/shared/helpers/utils';
import { Button, Form, FormInstance, InputNumber, Select } from 'antd';
import { Table } from 'components/common/Table/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import '../../../../shared/invoice.less';
import { ColumnsDisabledByLoaiCKFunc, ColumnsDisabledByThanhTienFunc, Invoice91ColumnsDisabled, Invoice91_FColumnConstant, MoneyColumnsSetDefaultByGiaNT21 } from '../../../constants/invoice-91.contant';
import './am-91-modal-details.less';
import { InputSearchLookupColumns } from '@app/components/common/input-search-lookup/constants/input-lookup-entity-columns.constant';
import InputSearchLookup from '@app/components/common/input-search-lookup/InputSearchLookup';
import { InputSearchApiService } from '@app/api/input-search-api.service';
import useAM91ModalStore from '../../../stores/am-91-modal.store';
import { ICatalogMaterial } from '@app/shared/models/catalog-material.model';

const detailFields = [
    'ma_vt',
    'dvt1',
    'ma_vv_i',
    'so_luong1',
    'gia_nt21',
    'tien2',
    'tien_nt2',
    'ma_sp',
    'ma_vv',
    'ma_bpht',
    'ma_phi',
    'ck',
    'pt_cki',
    'ma_kho_i'
]

const DATA = 'data';

const FColumnConstant = Invoice91_FColumnConstant;

interface IAM91ModalDetailsProps {
    loaiCK: boolean;
    summarySuaTien: boolean;
    adAutoColumns: InvoinceAutoColumnDto[];
    adListForm: FormInstance<any>;
    disabled?: boolean;
    onADDatasChange: () => void;
    dispatchADFormListChange: {
        adDetailsInForm: any[]
    }
}

export const AM91ModalDetails: React.FC<IAM91ModalDetailsProps> = ({
    loaiCK,
    summarySuaTien,
    adAutoColumns,
    adListForm,
    disabled,
    onADDatasChange,
    dispatchADFormListChange
}: IAM91ModalDetailsProps) => {
    // #region State
    const {
        materials_units,

        updateMaterial_Unit,
        fetchUnitConverionsByMaterial
    } = useAM91ModalStore();
    // #endregion State

    const [addForm] = Form.useForm<any>();

    const [adList, setADList] = useState<any[]>([]);

    const [detailADColumns, setDetailADColumns] = useState<InvoinceAutoColumnDto[]>([]);

    // #region AD Form - Events - Validations
    const validateADDataError = useCallback((formData: any): boolean => {
        const formADColumns = adAutoColumns.filter(x => x.fstatus);

        const notEmptyColumns = formADColumns.filter(x => x.notempty);

        const inValidColumn = notEmptyColumns.find(column => {
            const fcolumn = column.fcolumn?.toLowerCase() ?? '';

            if (!formData[fcolumn]) {
                return true;
            }

            return false;
        })

        if (inValidColumn) {
            notificationController.error({ message: `Chưa nhập ${inValidColumn.caption}` });
            return false;
        }

        return true;
    }, [adAutoColumns])

    const addADData = useCallback(async function addADData() {
        const formValues = addForm.getFieldsValue();

        const isValid = validateADDataError(formValues);
        if (!isValid) {
            return;
        }

        try {
            await addForm.validateFields();
        } catch (e: any) {
            const errorFields = e.errorFields;
            if (errorFields?.length > 0) {
                const errors = errorFields[0].errors
                if (errors?.length > 0) {
                    const error = errors.join(', ');
                    notificationController.error({ message: error });

                    return;
                }
            }

            notificationController.error({ message: 'Lỗi form' });

            return;
        }

        const adList = adListForm.getFieldValue(DATA) || [];
        adList.push(formValues);

        adListForm.setFieldsValue({
            data: adList
        });
        setADList(adList);
        addForm.resetFields();

        onADDatasChange();
    }, [addForm, validateADDataError, onADDatasChange])

    useEffect(() => {
        const keydownListener = (event: KeyboardEvent) => {
            if (event.key === 'F4') {
                addADData();
            }

            if (event.key === 'F2') {
                console.log(document.activeElement)
            }
        };

        document.addEventListener(`keydown`, keydownListener);

        return () => {
            document.removeEventListener(`keydown`, keydownListener);
        };
    }, [addADData]);

    // useEffect(() => {
    //     const handleFocusIn = (e: any) => {
    //         setActive(document.activeElement);
    //     }

    //     document.addEventListener('focusin', handleFocusIn)
    //     return () => {
    //         document.removeEventListener('focusin', handleFocusIn)
    //     };
    // }, [])
    // #endregion AD Form - Events - Validations

    useEffect(() => {
        setDetailADColumns(adAutoColumns.filter(x => x.fstatus2 && detailFields.includes(x.fcolumn?.toLowerCase() ?? '')).sort(InvoiceFunc.invoiceAutoColumnsSortedByForder));
    }, [adAutoColumns])

    useEffect(() => {
        if (dispatchADFormListChange && dispatchADFormListChange.adDetailsInForm) {
            setADList([...(dispatchADFormListChange.adDetailsInForm || [])]);
        }
    }, [dispatchADFormListChange])

    // #region Handle Money, Discount Change
    function setADLists(dataInForm: any[]) {
        adListForm.setFieldValue(DATA, [...dataInForm]);
        setADList([...dataInForm]);
    }

    function calculateTotalMoney(index: number) {
        const dataInForm = adListForm.getFieldValue(DATA) as any[];

        const soLuong1 = dataInForm[index][FColumnConstant.SO_LUONG1] ?? 0;
        const giaNT21 = dataInForm[index][FColumnConstant.GIA_NT21] ?? 0;

        const totalMoney = soLuong1 * giaNT21;

        dataInForm[index][FColumnConstant.TIEN2] = dataInForm[index][FColumnConstant.TIEN_NT2] = totalMoney;
        setADLists(dataInForm);
    }

    function calculateGiaFromTienNT2(index: number) {
        const dataInForm = adListForm.getFieldValue(DATA) as any[];

        const tienNT2 = dataInForm[index][FColumnConstant.TIEN_NT2] ?? 0;
        const soLuong1 = dataInForm[index][FColumnConstant.SO_LUONG1] ?? 0;

        const giaNT21 = soLuong1 > 0 ? tienNT2 / soLuong1 : 0;

        dataInForm[index][FColumnConstant.GIA_NT21] = giaNT21;

        setADLists(dataInForm);
    }

    function onDiscountPercentChange(index: number) {
        const dataInForm = adListForm.getFieldValue(DATA) as any[];

        let ck_nt = dataInForm[index][FColumnConstant.CK_NT] ?? 0;
        const ptCKI = dataInForm[index][FColumnConstant.PT_CKI] ?? 0;

        if (ptCKI > 0) {
            const soLuong1 = dataInForm[index][FColumnConstant.SO_LUONG1] ?? 0;
            const giaNT21 = dataInForm[index][FColumnConstant.GIA_NT21] ?? 0;
            const totalMoney = soLuong1 * giaNT21;

            ck_nt = +(totalMoney * ptCKI / 100).toFixed(0);
        } else {
            ck_nt = 0;
        }

        dataInForm[index][FColumnConstant.CK_NT] = dataInForm[index][FColumnConstant.CK] = ck_nt;
        setADLists(dataInForm);
    }

    function onDiscountChange(index: number) {
        const dataInForm = adListForm.getFieldValue(DATA) as any[];

        const ptCKI = dataInForm[index][FColumnConstant.PT_CKI] ?? 0;

        if (ptCKI > 0) {
            onDiscountPercentChange(index);
        }
    }

    function onSoLuong1Change(index: number) {
        const dataInForm = adListForm.getFieldValue(DATA) as any[];
        const soLuong1 = dataInForm[index][FColumnConstant.SO_LUONG1] ?? 0;
        dataInForm[index][FColumnConstant.SO_LUONG] = soLuong1;
        setADLists(dataInForm);

        calculateTotalMoney(index);
    }

    function onGiaNT21Change(index: number) {
        const dataInForm = adListForm.getFieldValue(DATA) as any[];
        InvoiceFunc.objectCopyValues(dataInForm[index], FColumnConstant.GIA_NT21, MoneyColumnsSetDefaultByGiaNT21);
        setADLists(dataInForm);

        calculateTotalMoney(index);
    }

    function onTienNT2Change(index: number) {
        const dataInForm = adListForm.getFieldValue(DATA) as any[];

        InvoiceFunc.objectCopyValues(dataInForm[index], FColumnConstant.TIEN_NT2, [FColumnConstant.TIEN2]);
        calculateGiaFromTienNT2(index);
        InvoiceFunc.objectCopyValues(dataInForm[index], FColumnConstant.GIA_NT21, MoneyColumnsSetDefaultByGiaNT21);

        setADLists(dataInForm);
    }

    // #endregion Handle Money, Discount Change

    // #region Handle AD Details Form Change
    function formADDetailsFieldChange(fcolumn: string, index: number, value?: any) {
        switch (fcolumn) {
            case FColumnConstant.ma_vt:
                handleMaterialChanged(index, value as ICatalogMaterial);
                break;

            case FColumnConstant.SO_LUONG1:
                onSoLuong1Change(index);
                break;
            case FColumnConstant.GIA_NT21:
                onGiaNT21Change(index);
                break;

            case FColumnConstant.TIEN_NT2:
                onTienNT2Change(index);
                break;

            case FColumnConstant.PT_CKI:
                onDiscountPercentChange(index);
                break;
            case FColumnConstant.CK_NT:
                onDiscountChange(index);
                break;

            default:
                break;
        }

        onADDatasChange();
    }

    // #region Handle Material Change
    const handleMaterialChanged = (index: number, value: ICatalogMaterial) => {
        const dataInForm = adListForm.getFieldValue(DATA) as any[];

        const record = dataInForm[index];

        fetchUnitConverionsByMaterial(value.ma_vt, value.nhieu_dvt === '1');

        record.ten_vt = value.ten_vt;
        record.dvt1 = value.dvt;
        record.dvt = value.dvt;

        setADLists(dataInForm);
    }
    // #endregion Handle Material Change

    // #endregion Handle AD Details Form Change

    const columns = useMemo(() => {
        const autoColumns: any[] = [];
        detailADColumns.forEach(column => {
            const columnWidth = (column.width ?? 100);
            const fcolumn = column.fcolumn?.toLowerCase() ?? '';
            const requiredVar = column.checkvvar; // check value before leaving // Todo: Phi
            const notEmpty = column.notempty ?? false;
            const formItemDisplay = column.fstatus ? '' : 'none';
            const disabledByLoaiCK = ColumnsDisabledByLoaiCKFunc(fcolumn, loaiCK);
            const disabledByThanhTien = ColumnsDisabledByThanhTienFunc(fcolumn, summarySuaTien);
            const numberColumnForceDisabled = Invoice91ColumnsDisabled.includes(fcolumn);

            autoColumns.push({
                title: column.caption,
                width: columnWidth,
                render: (_: any, record: any, index: number) => {
                    if (column.ftype === InvoiceADColumnFtype.Catalog && fcolumn === FColumnConstant.DVT1) {
                        const ma_vt = record[Invoice91_FColumnConstant.ma_vt];
                        const material_units = materials_units.find(x => x.ma_vt === ma_vt);
                        const nhieuDVT = material_units?.nhieu_dvt ?? false;
                        const units = material_units?.units || [];

                        return (
                            <Form.Item
                                key={index}
                                name={[DATA, index, fcolumn]}
                                style={{
                                    marginLeft: 3,
                                    minWidth: '100%',
                                    maxWidth: '100%',
                                    marginBottom: 0,
                                    display: formItemDisplay
                                }}
                                className='add-form-item-dvt'
                                rules={
                                    notEmpty
                                        ? [
                                            { required: true }
                                        ] : undefined
                                }
                            >
                                <Select
                                    disabled={!nhieuDVT}
                                    allowClear={false}
                                    showSearch
                                    placeholder={column.caption!}
                                >
                                    {units?.map((i, index) => (
                                        <Select.Option key={index} value={i.dvt}>
                                            {i.dvt}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )
                    }

                    if (column.ftype === InvoiceADColumnFtype.Catalog) {
                        const fvvar = column.fvvar?.toLowerCase() ?? '';
                        const columns = InputSearchLookupColumns.catalogColumns[fvvar];
                        const catalogCodeKeyField = columns?.length > 0 ? columns[0].dataIndex : fcolumn;
                        const disabled = !fvvar;
                        const columnValue = record[fcolumn];

                        return (<Form.Item
                            key={index}
                            name={[DATA, index, fcolumn]}
                            className='add-form-item add-form-item-p-m-0'
                            style={{
                                marginLeft: 3,
                                zIndex: 99999,
                                display: formItemDisplay
                            }}>
                            <InputSearchLookup
                                notEmpty={notEmpty}
                                disabled={disabled || disabledByLoaiCK}
                                key={index}
                                style={{
                                    marginLeft: 3,
                                    minWidth: '100%',
                                    maxWidth: '100%'
                                }}
                                inputStyle={{
                                    padding: 0,
                                    paddingLeft: 2
                                }}
                                fetchDataFunc={InputSearchApiService.getCatalogDymamic(fvvar)}
                                placeHolder={column.caption!}
                                keyField={catalogCodeKeyField}
                                columns={columns}
                                onOutputSelectRecord={(record: any) => {
                                    const dataInForm = adListForm.getFieldValue(DATA) as any[];
                                    dataInForm[index][fcolumn] = record[catalogCodeKeyField];
                                    setADLists(dataInForm);

                                    formADDetailsFieldChange(fcolumn, index, record);
                                }}
                                patchValue={{ text: columnValue }}
                            />
                        </Form.Item>
                        )
                    }

                    if (IS_AD_NUMBER_COLUMN(column.ftype ?? '')) {
                        if (disabledByThanhTien) {
                            return (
                                <span className='invoice-table-form-item-number-text'>
                                    {Utils.numberFormatter(adListForm.getFieldValue([DATA, index, fcolumn]) ?? 0)}
                                </span>
                            )
                        }

                        return (
                            <Form.Item
                                key={index}
                                name={[DATA, index, fcolumn]}
                                className='add-form-item invoice-table-form-item-number w-100 add-form-item-p-m-0'
                            >
                                <InputNumber formatter={Utils.numberFormatter}
                                    disabled={
                                        disabledByThanhTien
                                        || ColumnsDisabledByLoaiCKFunc(fcolumn, loaiCK)
                                        || disabledByLoaiCK
                                        || numberColumnForceDisabled
                                    }
                                    className='w-100'
                                    style={{ padding: 0 }}
                                    placeholder={column.caption ?? ''}
                                    autoComplete='off'
                                    onBlur={() => {
                                        formADDetailsFieldChange(fcolumn, index);
                                    }}
                                />
                            </Form.Item>
                        );
                    }

                    return adListForm.getFieldValue([DATA, index, fcolumn]);
                }
            });

            if (fcolumn === Invoice91_FColumnConstant.ma_vt) {
                autoColumns.push({
                    title: 'Tên vật tư',
                    width: 120,
                    render: (record: any) => {
                        return (
                            <div
                                title={record.ten_vt}
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: 'vertical',
                                }}
                            >
                                {record.ten_vt}
                            </div>
                        )
                    }
                });
            }
        });

        autoColumns.push({
            title: 'Hành động',
            fixed: 'right',
            align: 'center',
            width: 80,
            render: (_: any, record: any, index: number) => {
                if (disabled) {
                    return <></>;
                }

                return (
                    <DeleteOutlined size={12}
                        onClick={() => {
                            adList.splice(index, 1);
                            setADList([...adList])

                            const formADList = adListForm.getFieldValue(DATA);
                            formADList.splice(index, 1);
                            adListForm.setFieldValue(DATA, formADList);
                        }}
                    />
                );
            },
        })

        return autoColumns;
    }, [detailADColumns,
        adListForm,
        adList,
        loaiCK,
        summarySuaTien,
        materials_units
    ])

    return (
        <div className='am-91-modal-details-container app-table-list'>
            <div className='w-100' style={{ marginBottom: 5 }}>
                <div style={{ width: 100 }}>
                    <Button type="primary" hidden={disabled}
                        style={{
                            padding: 5,
                            fontSize: 12,
                            height: 28
                        }}
                        onClick={() => {
                            addADData();
                        }}>
                        Thêm mới (F4)
                    </Button>
                </div>

                <div style={{
                    width: '100%'
                }}>
                    <ADAutoColumnsForm
                        loaiCK={loaiCK}
                        suaTien={summarySuaTien}
                        addForm={addForm}
                        adAutoColumns={adAutoColumns}
                    />
                </div>
            </div>

            <Form form={adListForm}>
                <Table
                    className='invoice-ad-details-table'
                    style={{ width: '100%' }}
                    columns={columns}
                    dataSource={adList}
                    bordered
                    pagination={false}
                    scroll={{
                        y: 170
                    }}
                />
            </Form>
        </div >
    )
}