import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { MeasurementUnitListView } from '../views/MeasurementUnitListView';

const MeasurementUnitListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách đơn vị tính</PageTitle>
            <MeasurementUnitListView />
        </>
    );
};

export default MeasurementUnitListPage;
