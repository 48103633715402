import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import apiServices from '@app/api/api.services';
import { Pagination } from '@app/api/table.api';
import { CatalogSPParams, CatalogPriceIdDto } from '@app/api/v6.api';
import { PAGE_SIZE } from '@app/constants/page.constant';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'components/common/Table/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CatalogPriceIdListViewFilter } from './CatalogPriceIdListViewFilter';
import { commonFunc } from '@app/shared/functions/common.function';
import { CatalogPriceIdModal } from '../modals/catalog-price-id-modal/CatalogPriceIdModal';
import moment from 'moment';

export const CatalogPriceIdListView: React.FC = () => {
    const [catalogPriceIdDataModal, setCatalogPriceIdDataModal] = useState<{
        visible: boolean;
        mode?: "add" | "edit" | "detail";
        code?: string
    }>({
        visible: false
    });
    const [catalogPriceIds, setCatalogPriceIds] = useState<CatalogPriceIdDto[]>([]);
    const [pagination, setPagination] = useState<Pagination>();
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<CatalogSPParams>({
        vvar: 'MA_GIA',
        pageNumber: 1,
        pageSize: PAGE_SIZE
    } as CatalogSPParams)

    const fetchData = useCallback(async () => {
        setLoading(true);
        const catalogPriceIdsPaging = await apiServices.v6Api.catalogsGetCatalogDynamicPaging(filter);
        setCatalogPriceIds(catalogPriceIdsPaging?.items || []);
        setPagination({
            current: catalogPriceIdsPaging?.pageNumber,
            pageSize: filter.pageSize,
            total: catalogPriceIdsPaging.totalCount
        })
        setLoading(false);
    }, [filter])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleEdit = async (code: string) => {
        setCatalogPriceIdDataModal({
            visible: true,
            mode: 'edit',
            code: code
        })
    };

    const handleDetail = async (code: string) => {
        setCatalogPriceIdDataModal({
            visible: true,
            mode: 'detail',
            code: code
        })
    };

    const handleCloseModal = async (reload?: boolean) => {
        setCatalogPriceIdDataModal({ ...catalogPriceIdDataModal, code: "", visible: false });
        if (reload) {
            const updatedFilter = {
                vvar: 'MA_GIA',
                pageNumber: 1,
                pageSize: PAGE_SIZE
            } as CatalogSPParams;
            setFilter(updatedFilter);
        }
    };

    const columns = useMemo(() => {
        const columns: ColumnsType<CatalogPriceIdDto> = [
            {
                title: 'STT',
                align: 'center',
                dataIndex: 'rownum',
                width: 25
            },
            {
                title: 'Mã giá',
                dataIndex: 'ma_gia',
                width: 35
            },
            {
                title: 'Tên giá',
                dataIndex: 'ten_gia',
                width: 60
            },
            {
                title: 'Loại',
                dataIndex: 'loai',
                width: 30
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'date0',
                width: 45,
                render: (date0: Date | null | undefined) => {
                    if (date0) {
                        return moment(date0).format('DD/MM/YYYY');
                    }
                    return "";
                }
            },
            {
                title: 'Thời gian tạo mới',
                dataIndex: 'time0',
                width: 65
            },
            {
                title: 'Người tạo mới',
                dataIndex: 'user_id0',
                width: 60
            },
            {
                title: 'Trạng thái',
                width: 55,
                render: (catalogPriceId: CatalogPriceIdDto) => commonFunc.statusRender(catalogPriceId.status as string)
            },
            {
                title: 'Ngày sửa sau cùng',
                dataIndex: 'date2',
                width: 65,
                render: (date2: Date | null | undefined) => {
                    if (date2) {
                        return moment(date2).format('DD/MM/YYYY');
                    }
                    return "";
                }
            },
            {
                title: 'Thời gian sửa',
                dataIndex: 'time2',
                width: 50
            },
            {
                title: 'Người sửa',
                dataIndex: 'user_id2',
                width: 40
            },
            {
                title: 'PT_GIA',
                dataIndex: 'pt_gia',
                width: 40
            },
            {
                title: 'MA_GIA0',
                dataIndex: 'ma_gia0',
                width: 40
            },
            {
                title: 'Hành động',
                fixed: 'right',
                align: 'center',
                width: 45,
                render: (catalogPriceId: CatalogPriceIdDto) => {
                    return (
                        <>
                            <Button type="primary"
                                shape="circle"
                                icon={<EditOutlined />}
                                title='Chỉnh sửa'
                                style={{ marginRight: 3 }}
                                onClick={() => {
                                    handleEdit(catalogPriceId.ma_gia!)
                                }}>
                            </Button>
                            <Button type="primary"
                                shape="circle"
                                icon={<InfoCircleOutlined />}
                                title='Chi tiết'
                                style={{ marginRight: 3 }}
                                onClick={() => {
                                    handleDetail(catalogPriceId.ma_gia!)
                                }}>
                            </Button>

                        </>
                    );
                },
            },
        ];

        return columns;
    }, [])

    function onPagingChange(pageNum: number, pageSize: number) {
        const params: CatalogSPParams = {
            ...filter,
            pageNumber: pageNum,
            pageSize: pageSize,
        } as CatalogSPParams;
        setFilter(params);
    }
    return (
        <div className='app-table-list'>
            <div className='app-table-list-header'>
                <h1 className='app-table-list-header-title'>
                    Danh sách mã giá
                </h1>

                <div className='app-table-list-header-actions'>
                    <Button type="primary" onClick={() => {
                        setCatalogPriceIdDataModal({
                            visible: true,
                            mode: 'add'
                        })
                    }}>
                        Thêm mới
                    </Button>

                </div>
            </div>
            <CatalogPriceIdListViewFilter
                filter={filter}
                onFilter={(data: CatalogSPParams) => {
                    setFilter({
                        ...filter,
                        vvalue: data.vvalue as string,
                        pageNumber: 1
                    } as CatalogSPParams);
                }} />
            <Table
                columns={columns}
                scroll={{ x: 1550 }}
                dataSource={catalogPriceIds}
                pagination={{
                    ...pagination,
                    onChange: onPagingChange
                }}
                loading={loading}
                bordered
            />

            {
                catalogPriceIdDataModal && catalogPriceIdDataModal.visible && (
                    <CatalogPriceIdModal
                        visible={catalogPriceIdDataModal.visible}
                        mode={catalogPriceIdDataModal.mode!}
                        code={catalogPriceIdDataModal.code}
                        onClose={(e) => handleCloseModal(e)}
                    />
                )
            }
        </div>
    )
}