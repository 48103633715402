import React from 'react';

const CustomerAnotherInfomation: React.FC = () => {

    return (
        <>
            <h1>Inprogress</h1>
        </>
    );
};

export default CustomerAnotherInfomation;
