import { Modal } from '@app/components/common/Modal/Modal';
import { useCallback, useEffect, useState } from 'react';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Form, Tabs, TabsProps } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import apiServices from '@app/api/api.services';
import { antFormFinishErrorNotification } from '@app/shared/functions/common.function';
import { BankContractGroupDto } from '@app/api/v6.api';
import BankContractGroupMainInfomation from './bank-contract-group-tabs/BankContractGroupTabMainInfomation';

interface IModalBankContractGroup {
    code?: string;
    visible: boolean;
    mode: "add" | "edit" | "detail";
    disableGroup?: boolean;
    initialValues?: BankContractGroupDto;
    onClose: (reload?: boolean) => void;
    onDispatchResponse?: (data: BankContractGroupDto) => void;
}

interface ITab {
    dataEdit?: BankContractGroupDto;
    mode: "add" | "edit" | "detail";
}

export const BankContractGroupModal: React.FC<IModalBankContractGroup> = ({ code, visible, mode, disableGroup, initialValues, onClose, onDispatchResponse }: IModalBankContractGroup) => {
    const [form] = BaseForm.useForm();
    const [titleModal, setTileModal] = useState("Thêm mới nhóm khế ước");
    const [loading, setLoading] = useState<boolean>(false);
    const [dataTab, setDataTab] = useState<ITab>();

    const handleCancel = async () => {
        setDataTab(undefined);
        onClose();
    };

    const fetchData = useCallback(async () => {
        setDataTab(undefined);
        if (code) {
            if (code) {
                const dataEditBankContractGroup = await apiServices.v6Api.bankContractGroupsGetById(code);
                form.setFieldsValue({
                    ...dataEditBankContractGroup,
                    status: (dataEditBankContractGroup.status === '1' ? true : false) as any,
                })
                setDataTab({ dataEdit: dataEditBankContractGroup, mode: mode })
            }
            setTileModal(`${mode === "detail" ? "Chi tiết" : "Chỉnh sửa"} nhóm khế ước - ${code}`);
        } else {
            if (initialValues) {
                form.setFieldValue("groupType", initialValues.groupType);
            }
            setTileModal(`Thêm mới nhóm khế ước`);
        }
    }, [code, mode, form])

    useEffect(() => {
        form.resetFields();
        code ? setDataTab({ dataEdit: dataTab?.dataEdit, mode: mode }) : setDataTab({ dataEdit: dataTab?.dataEdit, mode: "add" })
        fetchData();
    }, [fetchData, form, code, mode])

    const handleDisable = (() => {
        setDataTab({ dataEdit: dataTab?.dataEdit, mode: "edit" })
        setTileModal(`${dataTab?.mode !== "detail" ? "Chi tiết" : "Chỉnh sửa"} nhóm khế ước - ${code}`);
    });

    const renderForm = useCallback(() => {
        return <>
            <Form
                form={form}
                name="basic"
                initialValues={{ remember: true }}
                autoComplete="off"
                onFinish={onFinish}
                onFinishFailed={antFormFinishErrorNotification}
            >
                <Tabs defaultActiveKey="1" items={items} />
            </Form>
        </>
    }, [dataTab, code])

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Thông tin chính',
            children: <BankContractGroupMainInfomation
                code={code}
                form={form}
                disableGroup={disableGroup}
                dataTab={dataTab}
            />,
        }
    ];

    const onFinish = async () => {
        if (dataTab?.mode === "detail") {
            handleDisable();
            return;
        }

        const data = await form.validateFields();
        data.status = data.status ? '1' : '0';
        data.dinhdang = '00000';

        setLoading(true);
        const promise = code
            ? apiServices.v6Api.bankContractGroupsUpdatAlnhku(code, data)
            : apiServices.v6Api.bankContractGroupsCreateBankContract(data);

        promise.then((response) => {
            if (response.isSuccess) {
                notificationController.success({ message: 'Thành công' });
                if (onDispatchResponse) {
                    onDispatchResponse(response.data as BankContractGroupDto);
                }
                onClose(true);
                return;
            }

            notificationController.error({ message: response.message });
        }).catch(() => {
            notificationController.error({ message: 'Thất bại' });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal
            title={titleModal}
            confirmLoading={loading}
            open={visible}
            destroyOnClose={true}
            width={"1000px"}
            onOk={() => form.submit()}
            onCancel={() => handleCancel()}
            okText={dataTab?.mode === "detail" ? "Chỉnh sửa" : "Lưu"}
            cancelText="Hủy"
            okButtonProps={{ style: { padding: "5px", height: "36px" } }}
            cancelButtonProps={{ style: { padding: "5px", height: "36px" } }}
        >
            <div className='basic-form'>
                {renderForm()}
            </div>
        </Modal>
    )
}