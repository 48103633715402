import apiServices from '@app/api/api.services';
import { Pagination } from '@app/api/table.api';
import { MeasurementUnitDto, CatalogSPParams } from '@app/api/v6.api';
import { PAGE_SIZE } from '@app/constants/page.constant';
import { commonFunc } from '@app/shared/functions/common.function';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'components/common/Table/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MeasurementUnitListViewFilter } from './MeasurementUnitListViewFilter';
import { Button } from 'antd';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { MeasurementUnitModal } from '../modals/MeasurementUnitModal';
import moment from 'moment';

export const MeasurementUnitListView: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [chosenMode, setChosenMode] = useState<"add" | "edit" | "detail">("add");
    const [codeEdit, setCodeEdit] = useState<string | undefined>();
    const [measurementUnits, setMeasurementUnits] = useState<MeasurementUnitDto[]>([]);
    const [pagination, setPagination] = useState<Pagination>();
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<CatalogSPParams>({
        vvar: 'DVT',
        pageNumber: 1,
        pageSize: PAGE_SIZE
    } as CatalogSPParams)

    const fetchData = useCallback(async () => {
        setLoading(true);

        const measurementUnitsPaging = await apiServices.v6Api.catalogsGetCatalogDynamicPaging(filter);

        setMeasurementUnits(measurementUnitsPaging?.items || []);
        setPagination({
            current: measurementUnitsPaging?.pageNumber,
            pageSize: filter.pageSize,
            total: measurementUnitsPaging.totalCount
        })
        setLoading(false);
    }, [filter])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleEdit = async (code: any) => {
        setCodeEdit(code ? code : undefined)
        setChosenMode("edit");
        setIsModalOpen(true);
    };

    const handleDetail = async (code: any) => {
        setCodeEdit(code ? code : undefined)
        setChosenMode("detail");
        setIsModalOpen(true);
    };

    const handleCloseModal = async (reload?: boolean) => {
        setCodeEdit("");
        setIsModalOpen(false);
        if (reload) {
            const updatedFilter = {
                vvar: 'DVT',
                pageNumber: 1,
                pageSize: PAGE_SIZE
            } as CatalogSPParams;
            setFilter(updatedFilter);
        }
    };

    const columns = useMemo(() => {
        const columns: ColumnsType<MeasurementUnitDto> = [
            {
                title: 'Thứ tự',
                dataIndex: 'rownum',
                width: 30,
                align: 'center'
            },
            {
                title: 'Mã ĐVT',
                dataIndex: 'dvt',
                width: 80
            },
            {
                title: 'Tên ĐVT',
                dataIndex: 'ten_dvt',
                width: 100
            },
            {
                title: 'Tên ĐVT 2',
                dataIndex: 'ten_dvt2',
                width: 100
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'date0',
                width: 50,
                render: (date0: Date | null | undefined) => {
                    if (date0) {
                        return moment(date0).format('DD/MM/YYYY');
                    }
                    return "";
                }
            },
            {
                title: 'Thời gian tạo mới',
                dataIndex: 'time0',
                width: 70
            },
            {
                title: 'Người tạo mới',
                dataIndex: 'user_id0',
                width: 45
            },
            {
                title: 'Trạng thái',
                width: 60,
                dataIndex: 'status',
                render: (status: any) => commonFunc.statusRender(status as string)
            },
            {
                title: 'Ngày sửa sau cùng',
                dataIndex: 'date2',
                width: 60,
                render: (date2: Date | null | undefined) => {
                    if (date2) {
                        return moment(date2).format('DD/MM/YYYY');
                    }
                    return "";
                }
            },
            {
                title: 'Thời gian sửa',
                dataIndex: 'time2',
                width: 50
            },
            {
                title: 'Người sửa',
                dataIndex: 'user_id2',
                width: 40
            },
            {
                title: 'Hành động',
                fixed: 'right',
                dataIndex: 'dvt',
                align: 'center',
                width: 50,
                render: (dvt: string) => {
                    return (
                        <>
                            <Button type="primary"
                                shape="circle"
                                icon={<EditOutlined />}
                                title='Chỉnh sửa'
                                style={{ marginRight: 3 }}
                                onClick={() => {
                                    handleEdit(dvt)
                                }}>
                            </Button>
                            <Button type="primary"
                                shape="circle"
                                icon={<InfoCircleOutlined />}
                                title='Chi tiết'
                                style={{ marginRight: 3 }}
                                onClick={() => {
                                    handleDetail(dvt)
                                }}>
                            </Button>

                        </>
                    );
                },
            },
        ];
        return columns;
    }, [])

    function onPagingChange(pageNum: number, pageSize: number) {
        const params: CatalogSPParams = {
            ...filter,
            pageNumber: pageNum,
            pageSize: pageSize,
        } as CatalogSPParams;
        setFilter(params);
    }

    return (
        <div className='app-table-list'>
            <div className='app-table-list-header'>
                <h1 className='app-table-list-header-title'>
                    Danh sách đơn vị tính
                </h1>
                <div className='app-table-list-header-actions'>
                    <Button type="primary" onClick={() => { setIsModalOpen(true); setCodeEdit(undefined) }}>
                        Thêm mới
                    </Button>

                </div>
            </div>
            <MeasurementUnitListViewFilter
                filter={filter}
                onFilter={(data: CatalogSPParams) => {
                    setFilter({
                        ...filter,
                        vvalue: data.vvalue as string,
                        pageNumber: 1
                    } as CatalogSPParams);
                }} />
            <Table
                columns={columns}
                dataSource={measurementUnits}
                scroll={{ x: 1700, scrollToFirstRowOnChange: true }}
                pagination={{
                    ...pagination,
                    onChange: onPagingChange
                }}
                loading={loading}
                bordered
            />
            <MeasurementUnitModal
                visible={isModalOpen}
                mode={chosenMode}
                code={codeEdit}
                onClose={(e) => handleCloseModal(e)}
            />
        </div>
    )
}