import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import apiServices from '@app/api/api.services';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Button, Form, Tabs, TabsProps } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialTypeMainInfomation from './material-type-tabs/MaterialTypeMainInfomation';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { PageConstant } from '@app/constants/page.constant';
import { MaterialTypeUpdateDto } from '@app/api/v6.api';
import { antFormFinishErrorNotification } from '@app/shared/functions/common.function';

interface IMaterialTypeEdit {
    code: string;
}

const MaterialTypeAddOrUpdateView: React.FC<IMaterialTypeEdit> = ({
    code
}: IMaterialTypeEdit) => {
    const navigate = useNavigate();
    const isEdit = Boolean(code);
    const [form] = BaseForm.useForm<MaterialTypeUpdateDto>();
    const [loading, setLoading] = useState<boolean>(false);

    const fetchData = useCallback(async () => {
        if (isEdit) {
            const dataEditMaterialType = await apiServices.v6Api.materialTypesGetById(code);
            form.setFieldsValue({
                ...dataEditMaterialType,
                status: (dataEditMaterialType.status === '1' ? true : false) as any
            })
        }
    }, [isEdit, form, code])

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onFinish = async () => {
        const data = await form.validateFields();
        data.status = data.status ? '1' : '0';

        setLoading(true);
        const response = isEdit
            ? await apiServices.v6Api.materialTypesUpdateUser(code, data)
            : await apiServices.v6Api.materialTypesCreateUser(data);
        setLoading(false);

        if (response.isSuccess) {
            notificationController.success({ message: 'Thành công' });
            goToMaterialTypeList();
            return;
        }

        notificationController.error({ message: response.message });
    }

    const tabItems: TabsProps['items'] = useMemo(() => {
        return [
            {
                key: '1',
                label: 'Thông tin chính',
                children: <MaterialTypeMainInfomation isEditting={isEdit} />,
            }
        ];
    }, [isEdit])

    function goToMaterialTypeList() {
        navigate(PageConstant.MATERIAL_TYPES);
    }

    return (
        <>
            <h1> {`${isEdit ? 'Chỉnh sửa' : 'Tạo'}`} Loại vật tư {code?.trim() as string}</h1>

            <div className='basic-form'>
                <Form
                    form={form}
                    style={{ width: "95%" }}
                    name="basic"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onFinish={onFinish}
                    onFinishFailed={antFormFinishErrorNotification}
                >
                    <Tabs defaultActiveKey="1" items={tabItems} />

                    <div>
                        <Button type="primary" htmlType="submit" style={{ marginRight: "8px" }} disabled={loading}
                            loading={loading}
                        >
                            <CheckOutlined type='submit' />Nhận
                        </Button>

                        <Button type="primary" htmlType="reset" style={{ backgroundColor: "red", border: "1px red" }} onClick={goToMaterialTypeList}>
                            <CloseCircleOutlined />Hủy
                        </Button>
                    </div>
                </Form>
            </div >
        </>
    );
};

export default MaterialTypeAddOrUpdateView;
