import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { UserListView } from '../user-views/UserListView';

const UserListPage: React.FC = () => {
  return (
    <>
      <PageTitle>{'User'}</PageTitle>
      <UserListView />
    </>
  );
};

export default UserListPage;
