import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import { AccountGroupListView } from '../views/AccountGroupListView';

const AccountGroupListPage: React.FC = () => {
    return (
        <>
            <PageTitle>Danh sách nhóm tài khoản</PageTitle>
            <AccountGroupListView />
        </>
    );
};

export default AccountGroupListPage;
