import { FilterOutlined } from "@ant-design/icons";
import { notificationController } from "@app/controllers/notificationController";
import { Radio, RadioChangeEvent, Space, Tag } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

const statusRender = (status: string) => {
    const isActive = status === '1';

    return (
        <Tag color={isActive ? 'green' : 'volcano'}>
            {isActive ? 'Hoạt động' : 'Không hoạt động'}
        </Tag>
    )
}

export const commonFunc = {
    statusRender
}

export function antFormFinishErrorNotification<T>(errorInfo: ValidateErrorEntity<T>) {
    let errorMessage = errorInfo?.errorFields?.map(x => x.errors)?.reduce((a, b) => a.concat(b), [])?.join('. ');
    if (!errorMessage) {
        errorMessage = 'Lỗi form, làm ơn kiểm tra thông tin';
    }

    notificationController.error({ message: errorMessage });
}

export function antFormColumnFilterStatus(status: boolean | undefined, onChangeStatus: (status: boolean | undefined) => void) {
    const onChangeFilterStatus = (e: RadioChangeEvent) => {
        onChangeStatus(e.target.value);
    }

    return {
        filterDropdown: () => (
            <Space style={{ padding: 8 }} direction="vertical">
                <Radio.Group onChange={onChangeFilterStatus} value={status}>
                    <Space direction="vertical">
                        <Radio key={0} value={undefined}>Tất cả</Radio>
                        <Radio key={1} value={true}>Hoạt động</Radio>
                        <Radio key={2} value={false}>Không hoạt động</Radio>
                    </Space>
                </Radio.Group>
            </Space>
        ),
        filterIcon: () => (
            <FilterOutlined style={{ color: status !== undefined ? '#c46969' : undefined }} />
        )
    };
}