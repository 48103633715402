export class PageConstant {
  public static ROLES = "/roles";
  public static ROLES_LIST = `${PageConstant.ROLES}/list`;

  public static USERS = "/users";

  public static CUSTOMERS = "/customers";
  public static CUSTOMERS_ADD = `${PageConstant.CUSTOMERS}/add`;
  public static CUSTOMERS_EDIT = (customerCode: string) =>
    `${PageConstant.CUSTOMERS}/${customerCode}/edit`;

  public static CUSTOMER_GROUPS = "/customer-groups";

  public static ACCOUNT_GROUPS = "/account-groups";

  public static ACCOUNTS = "/accounts";

  public static AFFAIR_GROUPS = "/affair-groups";

  public static ASSET_GROUPS = "/asset-groups";
  public static FIXED_ASSET_TYPES = "/fixed-asset-types";

  public static MEASUREMENT_UNITS = "/measurement-units";

  public static UNIT_CONVERSIONS = "/unit-conversions";

  public static FOREIGN_CURRENCIES = "/foreign-currencies";

  public static COUNTRIES = "/countries";

  public static WAREHOUSES = "/warehouses";

  public static BLOCKING_PATH_TRACING = "/blocking-path-tracing";

  public static DICTIONARIES = "/dictionaries";

  public static BANK_CONTRACT_GROUPS = "/bank-contract-groups";

  public static BATCH_NO = "/batch-nsó";

  public static BANK_ACCOUNTS = "/bank-accounts";
  public static DEPARTMENTS = "/departments";

  public static BASE_UNITS = "/dvcss";

  public static MATERIALS = "/materials";
  public static MATERIALS_ADD = `${PageConstant.MATERIALS}/add`;
  public static MATERIALS_EDIT = (ma_vt: string) =>
    `${PageConstant.MATERIALS}/${ma_vt}/edit`;

  public static MATERIAL_TYPES = "/material-types";
  public static MATERIAL_TYPES_ADD = `${PageConstant.MATERIAL_TYPES}/add`;
  public static MATERIAL_TYPES_EDIT = (materialTypeCode: string) =>
    `${PageConstant.MATERIAL_TYPES}/${materialTypeCode}/edit`;

  public static MATERIAL_GROUPS = "/material-groups";
  public static MATERIAL_GROUPS_ADD = `${PageConstant.MATERIAL_GROUPS}/add`;
  public static MATERIAL_GROUPS_EDIT = (materialGroupCode: string) =>
    `${PageConstant.MATERIAL_GROUPS}/${materialGroupCode}/edit`;
  public static MATERIAL_GROUP_PRICES = "/material-group-prices";

  public static TAXES = "/taxes";
  public static BANK_CONTRACT = "/bank-contracts";
  public static THUE30S = "/thue30s";
  public static CUSTOMER_GROUP_PRICES = "/customer-group-prices";

  public static AM_91 = "/am91s";
  public static FEES = "/fees";
  public static CATALOG_DISCOUNT_TYPES = "/catalog-discount-types";
  public static CATALOG_DISCOUNTS = "/catalog-discounts";
  public static EMPLOYEES = "/employees";
  public static CATALOG_PRICE_IDS = "/catalog-price-ids";
  public static PAYMENT_METHODS = "/payment-methods";
  public static ARS82S = "/ars82s";
}
export const PAGE_SIZE = 15;
